import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import IconBack from "@assets/SvgComponents/IconBack";
import Spacing from "@components/Spacing";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import StepComponent from "./StepComponent";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearResponseCompany,
  clearSelectedType,
} from "@stores/createNewCompany/createNewCompany.creator";
import { clearAllActivities } from "@stores/savedActivities/savedActivities.creator";
import { clearAllDataContract } from "@stores/contract/contract.creator";
import { clearDetailCompany } from "@stores/searchCompany/searchCompany.creator";

const Contract = () => {
  const textStyles = TextStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isActiveStep1, setIsActiveStep1] = useState<boolean>(false);
  const [titleBack, setTitleBack] = useState<string>(
    "Revenir à l’étape initiale"
  );

  const handleBack = () => {
    if (currentStep === 0) {
      navigate("/select-type");
    }
    if (currentStep === 1) {
      navigate("/contract");
    }
    if (currentStep === 2) {
      navigate("/contract/activities");
    }
    if (currentStep === 3) {
      navigate("/contract/insurance-history");
    }
  };

  useEffect(() => {
    let { pathname } = location;
    if (pathname === "/contract") {
      setCurrentStep(0);
      setIsActiveStep1(true);
      setTitleBack("Revenir à l’étape initiale");
    }
    if (pathname === "/contract/activities") {
      setCurrentStep(1);
      setTitleBack("Revenir à l’étape précédente");
    }
    if (pathname === "/contract/insurance-history") {
      setCurrentStep(2);
      setTitleBack("Revenir à l’étape précédente");
    }
    if (pathname === "/contract/calculate-result") {
      setCurrentStep(3);
      setTitleBack("Revenir à l’étape précédente");
    }
  }, [location]);

  useEffect(() => {
    return () => {
      dispatch(clearResponseCompany());
      dispatch(clearSelectedType());
      dispatch(clearAllActivities());
      dispatch(clearAllDataContract());
      dispatch(clearDetailCompany());
    };
  }, [dispatch]);

  useEffect(() => {
    !isActiveStep1 && navigate("/contract");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveStep1]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        display="flex"
        alignItems="center"
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={handleBack}
        >
          <span style={{ display: "flex", transform: "scale(0.8)" }}>
            <IconBack color={Colors.secondary} />
          </span>
          <Spacing width={10} />
          <Typography
            className={textStyles.underline}
            style={{ color: "#5E5873" }}
          >
            {titleBack}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
        <StepComponent currentStep={currentStep} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Contract;
