import { Box, LinearProgress } from "@mui/material";

export interface LinearLoadingProps {
  left?: number;
  right?: number;
}

export default function LinearLoading({
  left = 0,
  right = 0,
}: LinearLoadingProps) {
  return (
    <Box
      component="div"
      position="absolute"
      style={{ top: 0, left, right, zIndex: 999 }}
    >
      <LinearProgress color="success" />
    </Box>
  );
}
