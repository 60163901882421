import React from "react";
import { Font, Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import Optim from "../../../assets/Images/optim.png";
import CF from "../../../assets/Images/cf.png";
import MADE from "../../../assets/Images/logo.png";
import Pama from "../../../assets/Images/pama.png";
import ACPR from "../../../assets/Images/acpr.png";
import generatingResultPDFStyles from "@pages/Contract/GeneratingResultPDF/generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage9Props {
  isPIB?: boolean;
}

const ContentPage9 = ({ isPIB = true }: IContentPage9Props) => {
  return (
    <View break>
      {isPIB ? (
        <View>
          <View style={styles.titleBody}>
            <Text
              style={{
                fontSize: 12,
                color: Colors.primary,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Réclamation
            </Text>
          </View>
          <View>
            <Text style={styles.mainText}>
              La « réclamation », telle que définie par l’ACPR, s’entend de
              toute déclaration actant le mécontentement d’un client envers un
              professionnel.
            </Text>
            <Text style={styles.mainText}>
              Ne sont pas considérés comme réclamation :
            </Text>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainText}>
                toute demande de service ou de prestation, demande
                d’information, de clarification ou une demande d’avis,
              </Text>
            </View>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainText}>
                tout acte extra-judiciaire ayant pour finalité l’introduction
                d’une instance (assignation, convocation devant une Juridiction
                de Proximité ou une quelconque instance de médiation).
              </Text>
            </View>
            <View style={{ marginVertical: 10 }}>
              <Text style={styles.mainTextBold}>
                Comment puis-je faire part d’une réclamation à MADECENNALE ?{" "}
              </Text>
              <Text style={styles.mainText}>
                Vous pouvez faire part de votre réclamation :
              </Text>
              <Text style={styles.mainText}>
                Par email : reclamation@madecennale.com
              </Text>
              <Text style={styles.mainText}>
                Par courrier : MADECENNALE – SERVICE RECLAMATIONS – 1047 Route
                des Dolines, Business Pôle Bat A, 06560 Valbonne
              </Text>
            </View>
            <View>
              <Text style={styles.mainText}>
                MADECENNALE s’engage à accuser réception de votre réclamation
                dans un délai de 10 jours calendaires à compter de la réception
                de votre réclamation et à vous apporter une réponse sous deux
                mois maximum à compter de cette même date.
              </Text>
            </View>
            <View style={{ marginTop: 10 }}>
              <Text style={styles.mainTextBold}>
                Quels recours sont possibles si les réponses apportées ne me
                satisfont pas ?
              </Text>
              <Text style={styles.mainText}>
                Si malgré la réponse apportée votre insatisfaction persiste,
                sans préjudice d’intenter une action en justice, vous pouvez
                contacter La Médiation de l’Assurance :
              </Text>
              <Text style={styles.mainText}>
                En ligne : http://www.mediation-assurance.org ou par courrier à
                : Médiateur de l’Assurance – TSA 50110 – 75441 PARIS CEDEX 09
              </Text>
            </View>
          </View>
          <View style={{ height: 40 }} />
        </View>
      ) : null}

      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Mentions légales
        </Text>
      </View>

      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Image src={Optim} style={{ width: 62, height: 44 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>L’Assureur :</Text> Société
              d’Assurance{" "}
              <Text style={styles.mainTextBold}>Mutuelle Optim Assurance</Text>,
              en activité depuis 1897 (membre de l’union UNIRE – Matricule ACPR
              n°4050548) – Siège social : 14 Rue Pasteur 01000 – Bourg en Bresse
              – Enregistrée au RCS sous le numéro : 77931332900020 –{" "}
              <Link src="www.optimassurance.fr">www.optimassurance.fr</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={CF} style={{ width: 62, height: 24 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>Le courtier grossiste</Text>{" "}
              est <Text style={styles.mainTextBold}>CF SOUSCRIPTION</Text> –
              Marque de la société International Insurance Underwriting (IIU) –
              Société de courtage d’assurance au capital de 10 000 € - Siège
              social : Zone des Beurrons, RD191, 78680 Epône – RCS Versailles
              823 943 212 –{" "}
              <Link src="www.cf-souscription.com">www.cf-souscription.com</Link>{" "}
              - ORIAS : 17000414 - site web Orias :{" "}
              <Link src="www.orias.fr">www.orias.fr</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={MADE} style={{ width: 62, height: 16 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>La souscription</Text> a été
              confiée à<Text style={styles.mainTextBold}>MADECENNALE</Text> –{" "}
              Société par action simplifiée de courtage d’assurance au capital
              de social de 500€ - Siège social : 1047 Route des Dolines,
              Business Pôle Bat A, 06560 Valbonne – RCS Grasse 811 021 260 –
              Orias : 15003712 -{" "}
              <Link src="www.madecennale.com">www.madecennale.com</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={Pama} style={{ width: 62, height: 18 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>
                Pour la garantie DPRSA/PJ :
              </Text>{" "}
              <Text style={styles.mainTextBold}>
                GROUPAMA PROTECTION JURIDIQUE
              </Text>{" "}
              – Entreprise régie par le Code des assurances – Société Anonyme au
              capital de 2 216 500 € - RCS PARIS B 321 776 775 – Siège social :
              8-10 rue d’Astorg 75008 PARIS
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={ACPR} style={{ width: 62, height: 18 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>ACPR :</Text> Autorité de
              Contrôle Prudentiel et de Résolution : 4 Place de Budapest, CS
              92459, 75436 PARIS CEDEX 09
            </Text>
          </View>
        </View>
      </View>

      {!isPIB ? (
        <View>
          <View style={generatingResultPDFStyles.titleBody}>
            <Text
              style={{
                fontSize: 12,
                color: Colors.primary,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              "Obligations de l’assuré"
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 9,
                textDecoration: "underline",
                color: Colors.primary,
                marginBottom: 8,
              }}
            >
              Le souscripteur a été informé qu’il doit :{" "}
            </Text>

            <View>
              <View style={generatingResultPDFStyles.li}>
                <View style={generatingResultPDFStyles.dotLi} />
                <Text style={generatingResultPDFStyles.mainContentBold}>
                  L’Assuré a été informé qu’il doit :
                </Text>
              </View>

              <View style={{ height: 5 }} />
              <View style={generatingResultPDFStyles.li}>
                <View style={generatingResultPDFStyles.dotLi} />
                <Text style={generatingResultPDFStyles.mainContentBold}>
                  {" "}
                  disposer d’un code NAF conforme aux activités déclarées au
                  présent contrat;
                </Text>
              </View>

              <View style={{ height: 5 }} />
              <View style={generatingResultPDFStyles.li}>
                <View style={generatingResultPDFStyles.dotLi} />
                <Text style={generatingResultPDFStyles.mainContentBold}>
                  {" "}
                  informer l'Assureur si son marché est supérieur à 5.000.000 €;
                </Text>
              </View>

              <View style={{ height: 5 }} />
              <View style={generatingResultPDFStyles.li}>
                <View style={generatingResultPDFStyles.dotLi} />
                <Text style={generatingResultPDFStyles.mainContentBold}>
                  {" "}
                  informer l’Assureur, sous quinze jours, de toute modification
                  du risque en cours de contrat conduisant à rendre inexactes
                  les réponses faites aux questions posées (art. L.113-2 du Code
                  des assurances), sous réserve des sanctions prévues (art.
                  L.113-8 et L.113-9 du Code des assurances). Il s’agit
                  notamment des changements relatifs à son activité, à une
                  modification de son effectif ainsi qu’au dépassement éventuel
                  de son chiffre d'affaires déclaré.
                </Text>
              </View>

              <View style={{ height: 5 }} />
              <View style={generatingResultPDFStyles.li}>
                <View style={generatingResultPDFStyles.dotLi} />
                <Text style={generatingResultPDFStyles.mainContentBold}>
                  Satisfaire aux consignes de sécurité édictées à l’Annexe
                  PREVENTION
                </Text>
              </View>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  itemCheckbox: {
    borderBottom: "1px dashed #FFCC4D",
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
  },
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
  mainTextCheckbox: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  mainTextBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: "bold",
  },
  mainTextYellow: {
    fontSize: 9,
    color: Colors.primary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.7,
  },
  checkbox: {
    width: 11,
    height: 11,
    border: "1.2px solid #FFCC4D",
    borderRadius: 2,
    marginRight: 6,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.8,
    height: 1.8,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage9;
