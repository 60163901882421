import { IResponseListQuotes } from "./listQuotes.dto";
import { ListQuotesActions, ListQuotesTypes } from "./listQuotes.type";

const initialListQuotes: IResponseListQuotes = {
  listQuotes: {
    data: [],
    last_page: 1,
    limit: 1,
    page: 1,
    total: 0,
  },
  loading: false,
  message: undefined,
  error: undefined,
  loading_get: false,
};

export default function listQuotesReducer(
  state = initialListQuotes,
  action: ListQuotesActions
): IResponseListQuotes {
  switch (action.type) {
    case ListQuotesTypes.GET_LIST_QUOTES_REQUESTED: {
      return {
        ...state,
        loading_get: true,
      };
    }

    case ListQuotesTypes.GET_LIST_QUOTES_SUCCEEDED: {
      return {
        ...state,
        loading_get: false,
        listQuotes: action.listQuotes,
      };
    }

    case ListQuotesTypes.GET_LIST_QUOTES_FAILED: {
      return {
        ...state,
        loading_get: false,
        error: action?.error,
      };
    }

    case ListQuotesTypes.CREATE_DUPLICATE_QUOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case ListQuotesTypes.CREATE_DUPLICATE_QUOTE_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        message: action?.message,
      };
    }

    case ListQuotesTypes.CREATE_DUPLICATE_QUOTE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case ListQuotesTypes.CLEAR_RESPONSE_DUPLICATE: {
      return {
        ...state,
        loading: false,
        message: undefined,
        error: undefined,
      };
    }

    case ListQuotesTypes.RESET_LIST_QUOTES_STATE: {
      return {
        ...state,
        listQuotes: {
          data: [],
          last_page: 1,
          limit: 1,
          page: 1,
          total: 0,
        },
        loading: false,
        message: undefined,
        error: undefined,
        loading_get: false,
      };
    }

    default:
      return state;
  }
}
