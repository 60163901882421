import {
  ILogin,
  ILoginParams,
  IRequestEmail,
  IResetPassword,
} from "./login.dto";
import {
  ClearDataLogin,
  ClearLoginParamsAction,
  ClearMessageErrorLogin,
  LoginFailedAction,
  LoginRequestedAction,
  LoginSucceededAction,
  LoginType,
  RequestEmailFailedAction,
  RequestEmailRequestedAction,
  RequestEmailSucceededAction,
  ResetPasswordFailedAction,
  ResetPasswordRequestedAction,
  ResetPasswordSucceededAction,
  SetLoginParamsAction,
} from "./login.type";

export function loginRequested(params: ILoginParams): LoginRequestedAction {
  return {
    type: LoginType.LOGIN_REQUESTED,
    params,
  };
}

export function loginSucceeded(data: ILogin): LoginSucceededAction {
  return {
    type: LoginType.LOGIN_SUCCEEDED,
    data,
  };
}

export function loginFailed(error: any): LoginFailedAction {
  return {
    type: LoginType.LOGIN_FAILED,
    error,
  };
}

export function requestEmailRequested(
  data: IRequestEmail
): RequestEmailRequestedAction {
  return {
    type: LoginType.REQUEST_EMAIL_REQUESTED,
    data,
  };
}

export function requestEmailSucceeded(
  message: string
): RequestEmailSucceededAction {
  return {
    type: LoginType.REQUEST_EMAIL_SUCCEEDED,
    message,
  };
}

export function requestEmailFailed(error: any): RequestEmailFailedAction {
  return {
    type: LoginType.REQUEST_EMAIL_FAILED,
    error,
  };
}

export function resetPasswordRequested(
  data: IResetPassword
): ResetPasswordRequestedAction {
  return {
    type: LoginType.RESET_PASSWORD_REQUESTED,
    data,
  };
}

export function resetPasswordSucceeded(
  message: string
): ResetPasswordSucceededAction {
  return {
    type: LoginType.RESET_PASSWORD_SUCCEEDED,
    message,
  };
}

export function resetPasswordFailed(error: any): ResetPasswordFailedAction {
  return {
    type: LoginType.RESET_PASSWORD_FAILED,
    error,
  };
}

export function setLoginParams(params: ILoginParams): SetLoginParamsAction {
  return {
    type: LoginType.SET_LOGIN_PARAMS,
    params,
  };
}
export function clearLoginParams(): ClearLoginParamsAction {
  return {
    type: LoginType.CLEAR_LOGIN_PARAMS,
  };
}

export function clearMessageErrorLogin(): ClearMessageErrorLogin {
  return {
    type: LoginType.CLEAR_MESSAGE_ERROR,
  };
}

export function clearDataLogin(): ClearDataLogin {
  return {
    type: LoginType.CLEAR_DATA_LOGIN,
  };
}
