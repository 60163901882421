import { Font, Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import ACPR from "../../../assets/Images/acpr.png";
import CF from "../../../assets/Images/cf.png";
import MADE from "../../../assets/Images/logo.png";
import Optim from "../../../assets/Images/optim.png";
import Pama from "../../../assets/Images/pama.png";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage9Props {
  isPIB?: boolean;
}

const ContentPage9 = ({ isPIB = true }: IContentPage9Props) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Pièces à fournir
        </Text>
      </View>
      <View style={{ marginTop: 10 }}>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            Règlement à l’ordre de Madecennale
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            La présente proposition dument remplie, signée et tamponnée
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            Kbis ou un extrait d’immatriculation de la chambre des métiers de
            moins de 3 mois
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            L’attestation de non sinistralité jointe au devis dument signée
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>C.V.</Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            Diplôme
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            Copie recto verso de la carte d’identité du dirigeant
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckboxStart}>
          <View style={generatingResultPDFStyles.checkbox} />
          <View style={{ flex: 1, paddingRight: 15 }}>
            <Text style={generatingResultPDFStyles.mainTextCheckbox}>
              Société ayant déjà été assurée : Relevé de sinistralité de moins
              de 3 mois sur les 36 derniers mois, qui mentionne les activités
              précédemment assurées (joindre l’attestation d’assurance ou le
              contrat), ainsi que le dont acte de résiliation
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemCheckboxStart}>
          <View style={generatingResultPDFStyles.checkbox} />
          {isPIB ? (
            <Text style={generatingResultPDFStyles.mainTextCheckbox}>
              Société n'ayant jamais été assuré, justificatif de qualifications
              professionnelle : stages des personnes déclarées et du/des
              gérants, Attestation de travail ou fiches de paie des employeurs
              précédents tant pour la direction que pour les employés,
              permettant de justifier de l'expérience professionnelle pour les
              activités demandées
            </Text>
          ) : null}

          {!isPIB ? (
            <View>
              <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                Société n’ayant jamais été assurée :
              </Text>
              <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                -
                <Text
                  style={{
                    fontSize: 9,
                    color: "#5E5873",
                    fontFamily: "Montserrat",
                    lineHeight: 1.5,
                    fontWeight: 500,
                  }}
                >
                  Pour les activités de classe 9 à 7
                </Text>
                : 2 ans d’expérience en rapport avec les activités souscrites
                (certificats de travail ou bulletins de salaire, diplômes,
                factures clients acquittées : 1 par trimestre
              </Text>

              <View style={{ height: 5 }} />
              <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                -{" "}
                <Text
                  style={{
                    fontSize: 9,
                    color: "#5E5873",
                    fontFamily: "Montserrat",
                    lineHeight: 1.5,
                    fontWeight: 500,
                  }}
                >
                  Pour les activités de classe 6 à 3
                </Text>{" "}
                : 3 ans d’expérience en rapport avec les activités souscrites
                (certificats de travail ou bulletins de salaires)
              </Text>

              <View style={{ height: 5 }} />
              <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                -{" "}
                <Text
                  style={{
                    fontSize: 9,
                    color: "#5E5873",
                    fontFamily: "Montserrat",
                    lineHeight: 1.5,
                    fontWeight: 500,
                  }}
                >
                  Pour les activités de classe 2 à 1
                </Text>{" "}
                : 3 ans d’expérience minimum en rapport avec les activités
                souscrites (bulletins de salaires uniquement, certificats de
                travail refusés)
              </Text>
            </View>
          ) : null}
        </View>

        <View style={generatingResultPDFStyles.itemCheckbox}>
          <View style={generatingResultPDFStyles.checkbox} />
          <Text style={generatingResultPDFStyles.mainTextCheckbox}>
            Si mode de paiement par prélèvement : RIB + mandat SEPA complété et
            signé
          </Text>
        </View>
      </View>

      {isPIB ? (
        <View>
          <View style={{ height: 40 }} />
          <View style={generatingResultPDFStyles.titleBody}>
            <Text
              style={{
                fontSize: 12,
                color: Colors.primary,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Mentions légales
            </Text>
          </View>
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Image src={Optim} style={{ width: 62, height: 44 }} />
              <View style={{ width: 12 }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.mainText}>
                  <Text style={styles.mainTextYellow}>L’Assureur :</Text>{" "}
                  Société d’Assurance{" "}
                  <Text style={styles.mainTextBold}>
                    Mutuelle Optim Assurance
                  </Text>
                  , en activité depuis 1897 (membre de l’union UNIRE – Matricule
                  ACPR n°4050548) – Siège social : 14 Rue Pasteur 01000 – Bourg
                  en Bresse – Enregistrée au RCS sous le numéro : 77931332900020
                  –{" "}
                  <Link src="www.optimassurance.fr">www.optimassurance.fr</Link>
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Image src={CF} style={{ width: 62, height: 24 }} />
              <View style={{ width: 12 }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.mainText}>
                  <Text style={styles.mainTextYellow}>
                    Le courtier grossiste
                  </Text>{" "}
                  est <Text style={styles.mainTextBold}>CF SOUSCRIPTION</Text> –
                  Marque de la société International Insurance Underwriting
                  (IIU) – Société de courtage d’assurance au capital de 10 000 €
                  - Siège social : Zone des Beurrons, RD191, 78680 Epône – RCS
                  Versailles 823 943 212 –{" "}
                  <Link src="www.cf-souscription.com">
                    www.cf-souscription.com
                  </Link>{" "}
                  - ORIAS : 17000414 - site web Orias :{" "}
                  <Link src="www.orias.fr">www.orias.fr</Link>
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Image src={MADE} style={{ width: 62, height: 16 }} />
              <View style={{ width: 12 }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.mainText}>
                  <Text style={styles.mainTextYellow}>La souscription</Text> a
                  été confiée à
                  <Text style={styles.mainTextBold}>MADECENNALE</Text> – Société
                  par action simplifiée de courtage d’assurance au capital de
                  social de 500€ - Siège social : 1047 Route des Dolines,
                  Business Pôle Bat A, 06560 Valbonne – RCS Grasse 811 021 260 –
                  Orias : 15003712 -{" "}
                  <Link src="www.madecennale.com">www.madecennale.com</Link>
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Image src={Pama} style={{ width: 62, height: 18 }} />
              <View style={{ width: 12 }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.mainText}>
                  <Text style={styles.mainTextYellow}>
                    Pour la garantie DPRSA/PJ :
                  </Text>{" "}
                  <Text style={styles.mainTextBold}>
                    GROUPAMA PROTECTION JURIDIQUE
                  </Text>{" "}
                  – Entreprise régie par le Code des assurances – Société
                  Anonyme au capital de 2 216 500 € - RCS PARIS B 321 776 775 –
                  Siège social : 8-10 rue d’Astorg 75008 PARIS
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Image src={ACPR} style={{ width: 62, height: 18 }} />
              <View style={{ width: 12 }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.mainText}>
                  <Text style={styles.mainTextYellow}>ACPR :</Text> Autorité de
                  Contrôle Prudentiel et de Résolution : 4 Place de Budapest, CS
                  92459, 75436 PARIS CEDEX 09
                </Text>
              </View>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
  mainTextBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: "bold",
  },
  mainTextYellow: {
    fontSize: 9,
    color: Colors.primary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.7,
  },
});

export default ContentPage9;
