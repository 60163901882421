import * as React from "react";

const LogoutIcon: React.FC<{ color?: string }> = ({ color = "#5E5873" }) => (
  <svg width={16} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.753 9c0 .45-.3.75-.748.75-.45 0-.749-.3-.749-.75V1.5c0-.45.3-.75.749-.75s.748.3.748.75V9Zm-.748 8.25c1.946 0 3.817-.75 5.315-2.175 2.92-2.925 2.92-7.725-.075-10.65-.3-.3-.749-.3-1.048 0-.3.3-.3.75 0 1.05 2.32 2.325 2.32 6.15 0 8.475-2.32 2.325-6.139 2.325-8.46 0-2.32-2.325-2.32-6.15 0-8.475.3-.3.3-.75 0-1.05-.299-.3-.748-.3-1.047 0-2.92 2.925-2.92 7.725 0 10.65C4.187 16.5 6.058 17.25 8.005 17.25Z"
      fill="#000"
    />
  </svg>
);

export default LogoutIcon;
