import { IAppState } from "@stores/state";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useCheckToken = () => {
  const [result, setResult] = useState({ loading: true, resultCheck: false });

  const getToken = localStorage.getItem("AccessToken");
  const tokenAuth = useSelector(
    (state: IAppState) => state.responseLogin?.data?.token
  );

  const checkToken = () => {
    if (!getToken) setResult({ resultCheck: false, loading: false });
    setResult({ resultCheck: true, loading: false });
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAuth]);

  return result;
};
