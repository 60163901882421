import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import BuildingIcon from "@assets/SvgComponents/BuildingIcon";
import CardIcon from "@assets/SvgComponents/CardIcon";
import DocumentIcon from "@assets/SvgComponents/DocumentIcon";
import HouseIcon from "@assets/SvgComponents/HouseIcon";
import { TextStyles } from "@themes/textStyles";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import ButtonCustom from "@components/ButtonCustom";
import IconBack from "@assets/SvgComponents/IconBack";
import { Colors } from "@themes/colors";
import { PaperNoneShadowCustom } from "@components/PaperCustom";
import { useNavigate } from "react-router-dom";

interface StepComponentProps {
  currentStep?: number;
}

interface DataStep {
  primary?: string;
  secondary?: string;
}

const StepComponent: FC<StepComponentProps> = ({ currentStep = 0 }) => {
  const textStyles = TextStyles();
  const classes = useStyles();
  const navigate = useNavigate();

  const dataStep: Array<DataStep> = [
    {
      primary: "L’entreprise",
      secondary: "Informations",
    },
    {
      primary: "Les activités",
      secondary: "Séléction",
    },
    {
      primary: "Antécédents d’assurance",
      secondary: "Questionnaire",
    },
    {
      primary: "Simulation et résultat",
      secondary: "Comparateur",
    },
  ];
  return (
    <PaperNoneShadowCustom
      sx={{ py: 1, px: 6 }}
      style={{ position: "relative" }}
    >
      <List className={classes.listStep}>
        {dataStep.map((item: DataStep, index: number) => (
          <React.Fragment key={index}>
            <ListItem
              className={classes.itemStep}
              style={{ pointerEvents: index < currentStep ? "auto" : "none" }}
              onClick={() => {
                if (index === 0) navigate("/contract");
                else if (index === 1) navigate("/contract/activities");
                else if (index === 2) navigate("/contract/insurance-history");
              }}
            >
              <ListItemAvatarStyled>
                <Box component="p" className={classes.iconItemStep}>
                  <ButtonCustom
                    startIcon={
                      index === 0 ? (
                        <BuildingIcon
                          color={currentStep === index ? "#fff" : "#6E6B7B"}
                        />
                      ) : index === 1 ? (
                        <HouseIcon
                          color={currentStep === index ? "#fff" : "#6E6B7B"}
                        />
                      ) : index === 2 ? (
                        <CardIcon
                          color={currentStep === index ? "#fff" : "#6E6B7B"}
                        />
                      ) : (
                        <DocumentIcon
                          color={currentStep === index ? "#fff" : "#6E6B7B"}
                        />
                      )
                    }
                    boxShadow={
                      currentStep === index
                        ? "0px 3px 6px rgba(255, 204, 77, 0.31)"
                        : "unset"
                    }
                    borderRadius={6}
                    padding="0"
                    backgroundColor={
                      currentStep === index
                        ? Colors.primary
                        : "rgba(186, 191, 199, 0.12)"
                    }
                    disabled
                  />
                </Box>
              </ListItemAvatarStyled>
              <ListItemText
                primary={
                  <Typography
                    className={textStyles.paragraph_semi_bold}
                    style={{
                      color: currentStep === index ? Colors.primary : "#6E6B7B",
                    }}
                  >
                    {item.primary}
                  </Typography>
                }
                secondary={
                  <Typography
                    className={textStyles.paragraph_small}
                    style={{ color: "#B9B9C3" }}
                  >
                    {item.secondary}
                  </Typography>
                }
              />
            </ListItem>
            {index < 3 && (
              <ListItem
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className={classes.iconNext}>
                  <IconBack color="#6E6B7B" />
                </p>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </PaperNoneShadowCustom>
  );
};

const ListItemAvatarStyled = styled(ListItemAvatar)({
  minWidth: "unset",
  marginRight: 12,
});

const useStyles = makeStyles({
  root: {},
  iconItemStep: {
    margin: 0,
    width: 38,
    height: 38,
  },
  listStep: {
    display: "flex",
    justifyContent: "space-between",
  },
  itemStep: {
    width: "fit-content",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(248, 248, 249)",
    },
  },
  iconNext: {
    margin: 0,
    transform: "rotate(180deg)",
  },
});

export default StepComponent;
