import {
  IListContractValidated,
  IListQuoteCreated,
  IStatisticDto,
} from "./statistic.dto";
import {
  IGetListContractValidatedFailedAction,
  IGetListContractValidatedRequestedAction,
  IGetListContractValidatedSucceededAction,
  IGetStatisticFailedAction,
  IGetStatisticRequestedAction,
  IGetStatisticSucceededAction,
  ISetListQuoteCreatedAction,
  ISetListStatisticAction,
  StatisticTypes,
} from "./statistic.type";

export function getStatistic(): IGetStatisticRequestedAction {
  return {
    type: StatisticTypes.GET_STATISTIC_REQUESTED,
  };
}

export function getStatisticSucceeded(): IGetStatisticSucceededAction {
  return {
    type: StatisticTypes.GET_STATISTIC_SUCCEEDED,
  };
}

export function getStatisticFailed(error?: any): IGetStatisticFailedAction {
  return {
    type: StatisticTypes.GET_STATISTIC_FAILED,
    error,
  };
}

//

export function getListContractValidated(
  dateRange: Array<string>
): IGetListContractValidatedRequestedAction {
  return {
    type: StatisticTypes.GET_LIST_CONTRACT_VALIDATED_REQUESTED,
    dateRange,
  };
}

export function getListContractValidatedSucceeded(
  listContractValidated: Array<IListContractValidated>
): IGetListContractValidatedSucceededAction {
  return {
    type: StatisticTypes.GET_LIST_CONTRACT_VALIDATED_SUCCEEDED,
    listContractValidated,
  };
}

export function getListContractValidatedFailed(
  error?: any
): IGetListContractValidatedFailedAction {
  return {
    type: StatisticTypes.GET_LIST_CONTRACT_VALIDATED_FAILED,
    error,
  };
}

//

export function setListStatistic(
  listStatistic: Array<IStatisticDto>
): ISetListStatisticAction {
  return {
    type: StatisticTypes.SET_LIST_STATISTIC,
    listStatistic,
  };
}

export function setListQuoteCreated(
  listQuoteCreated: Array<IListQuoteCreated>
): ISetListQuoteCreatedAction {
  return {
    type: StatisticTypes.SET_LIST_QUOTE_CREATED,
    listQuoteCreated,
  };
}
