import { Font, Image, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import Signature from "../../../assets/Images/signature.png";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage11Props {
  isPIB?: boolean;
}
const ContentPage11 = ({ isPIB = true }: IContentPage11Props) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Déclarations
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 9,
            textDecoration: "underline",
            color: Colors.primary,
            marginBottom: 8,
          }}
        >
          {isPIB ? "Le souscripteur déclare :" : "Je reconnais : "}
        </Text>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            avoir recu et pris connaissance de la Nomenclature_V0993;
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            avoir reçu et pris connaissance des Conditions générales
            CG_RCD_V2793 ainsi que l’Annexe JURI’LAW 03 2020;
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            avoir reçu et pris connaissance des Dispositions générales Groupama
            Protection Juridique 202000082/DG2020376V01;
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            que les déclarations faites en réponse aux questions qui m’ont été
            posées sont conformes à la réalité.{" "}
            <Text
              style={{
                fontSize: 9,
                color: "#EA5455",
                fontFamily: "Montserrat",
                lineHeight: 1.8,
              }}
            >
              Les déclarations effectuées par l’Assuré sont essentielles et
              détérminantes du consentement de l’Assureur quant à l’acceptation
              du risque à garantir et au tarif proposé. Toute omission ou fausse
              déclaration sera passible des sanctions prévues aux articles
              L.113-8 et L.113-9 du Code des assurances;
            </Text>
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            avoir pris connaissance des obligations suscitées m’incombant et
            m’engager à les respecter.
          </Text>
        </View>

        {!isPIB ? (
          <View>
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 9,
                textDecoration: "underline",
                color: Colors.primary,
                marginBottom: 8,
              }}
            >
              Je déclare :
            </Text>

            <View style={generatingResultPDFStyles.itemCheckboxStart}>
              <View style={generatingResultPDFStyles.checkbox} />
              <View style={{ flex: 1, paddingRight: 15 }}>
                <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                  être, moi-même, ma(mon) conjoint(e), mon(mes) enfant(s),
                  mon(mes) parent(s) une personne politiquement exposée au sens
                  de l’article R.561-18* du code monétaire et financier.
                </Text>
              </View>
            </View>

            <View style={generatingResultPDFStyles.itemCheckboxStart}>
              <View style={generatingResultPDFStyles.checkbox} />
              <View style={{ flex: 1, paddingRight: 15 }}>
                <Text style={generatingResultPDFStyles.mainTextCheckbox}>
                  ne pas être, ni moi-même, ni ma(mon) conjoint(e), ni mon(mes)
                  enfant(s), ni mon(mes) parent(s) une personne politiquement
                  exposée au sens de l’article R.561-18* du Code monétaire et
                  financier.
                </Text>
              </View>
            </View>
          </View>
        ) : null}
      </View>

      <View style={{ height: 30 }} />
      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <Text
            style={[generatingResultPDFStyles.titleHeaderTable, { padding: 4 }]}
          >
            SIGNATURE DES PARTIS
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#FFF9E8",
          }}
        >
          <View style={{ padding: "6px", flex: 1 }}>
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              L’assureur
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ padding: "6px", flex: 1 }}>
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Le souscripteur
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 1, padding: "10px 5px" }}>
            <Image src={Signature} style={{ width: 239, height: 70 }} />
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1, padding: "10px 5px" }}>
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 4,
                marginLeft: 10,
              }}
            >
              Signature + Tampon
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                textAlign: "left",
                marginBottom: 4,
                marginLeft: 10,
                // fontStyle: "italic",
              }}
            >
              Mention manuscrite “Lu et approuvé”
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                textAlign: "left",
                marginLeft: 10,
              }}
            >
              Date :
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ContentPage11;
