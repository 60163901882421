import React from "react";
import { Paper, styled } from "@mui/material";

export const PaperShadowCustom = ({ children, ...rest }: any) => {
  return <PaperShadowStyled {...rest}>{children}</PaperShadowStyled>;
};

const PaperShadowStyled = styled(Paper)({
  borderRadius: "6.65519px",
  boxShadow: "0px 4.43679px 26.6208px rgba(0, 0, 0, 0.06)",
});

export const PaperNoneShadowCustom = ({ children, ...rest }: any) => {
  return <PaperNoneShadowStyled {...rest}>{children}</PaperNoneShadowStyled>;
};

const PaperNoneShadowStyled = styled(Paper)({
  borderRadius: "6.65519px",
  boxShadow: "unset",
});
