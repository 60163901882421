import * as React from "react";

const DeleteUserIcon: React.FC<{ color?: string }> = ({
  color = "#BABFC7",
}) => (
  <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.002 0H5.344c-.2 0-.4.067-.532.2L.15 5.533c-.2.267-.2.6 0 .867l4.662 5.333c.133.2.333.267.532.267h8.658C15.134 12 16 11.133 16 10V2c0-1.133-.866-2-1.998-2Zm.666 10c0 .4-.266.667-.666.667H5.677L1.615 6l4.062-4.667h8.325c.4 0 .666.267.666.667v8Zm-3.73-4 1.532-1.533a.645.645 0 0 0 0-.934.643.643 0 0 0-.932 0l-1.532 1.534-1.531-1.534a.643.643 0 0 0-.933 0 .645.645 0 0 0 0 .934L9.074 6 7.542 7.533a.645.645 0 0 0 0 .934c.133.133.267.2.466.2.2 0 .333-.067.467-.2l1.531-1.534 1.532 1.534c.133.133.266.2.466.2s.333-.067.466-.2a.645.645 0 0 0 0-.934L10.94 6Z"
      fill={color}
    />
  </svg>
);

export default DeleteUserIcon;
