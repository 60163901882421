import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  clearResponseDuplicate,
  createDuplicateContractFailed,
  createDuplicateContractSucceeded,
  getListQuotesFailed,
  getListQuotesSucceeded,
} from "./listQuotes.creator";
import { IResponseListQuotes } from "./listQuotes.dto";
import {
  ICreateDuplicateQuoteRequestedAction,
  IGetListQuotesRequestedAction,
  ListQuotesTypes,
} from "./listQuotes.type";
import {
  createDuplicateContractService,
  getListQuotesService,
} from "./listQuotes.service";

function* getListQuotes({ filterContractDto }: IGetListQuotesRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IResponseListQuotes> = yield call(
      getListQuotesService,
      filterContractDto
    );
    const listQuotes: any = dataResponse.data;
    yield put(getListQuotesSucceeded(listQuotes));
  } catch (err) {
    yield put(getListQuotesFailed(err));
  }
}

function* watchGetListQuotes() {
  yield takeLatest(ListQuotesTypes.GET_LIST_QUOTES_REQUESTED, getListQuotes);
}

function* createDuplicateContract({
  id,
}: ICreateDuplicateQuoteRequestedAction) {
  try {
    yield call(createDuplicateContractService, id);
    yield put(
      createDuplicateContractSucceeded(
        "Créer un contrat en double avec succès."
      )
    );
    yield put(clearResponseDuplicate());
  } catch (err) {
    yield put(
      createDuplicateContractFailed(
        "Échec de la création du contrat en double."
      )
    );
    yield put(clearResponseDuplicate());
  }
}

function* watchCreateDuplicateContract() {
  yield takeLatest(
    ListQuotesTypes.CREATE_DUPLICATE_QUOTE_REQUESTED,
    createDuplicateContract
  );
}

function* listQuotesSaga() {
  yield all([fork(watchGetListQuotes), fork(watchCreateDuplicateContract)]);
}

export default listQuotesSaga;
