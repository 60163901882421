import { makeStyles } from "@mui/styles";
import { Colors } from "@themes/colors";
import * as React from "react";

const CheckedIcon = () => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <svg
        width={18}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y={0.225} width={18} height={18} rx={3} fill="#FFDB5C" />
        <path
          d="m5 9.825 3.43 2.4 4.57-6"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: 18,
    height: 19,
    backgroundColor: Colors.primary,
    borderRadius: "50%",
    boxShadow: "0px 2px 4px rgba(115, 103, 240, 0.4)",
  },
});

export default CheckedIcon;
