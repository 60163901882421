import { call, put, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import {
  getListContractValidatedFailed,
  getListContractValidatedSucceeded,
  getStatisticFailed,
  getStatisticSucceeded,
  setListQuoteCreated,
  setListStatistic,
} from "./statistic.creator";
import {
  IListContractValidated,
  IListQuoteCreated,
  IStatisticDto,
} from "./statistic.dto";
import {
  getContractValidatedService,
  getQuoteCreatedService,
  getStatisticService,
} from "./statistic.service";
import {
  IGetListContractValidatedRequestedAction,
  StatisticTypes,
} from "./statistic.type";

function* getStatistic() {
  try {
    yield all([
      call(getListStatistic),
      // , call(getQuoteCreated)
    ]);

    yield put(getStatisticSucceeded());
  } catch (err: any) {
    yield put(getStatisticFailed(err?.message));
  }
}

function* getListStatistic() {
  try {
    const dataResponse: AxiosResponse<IStatisticDto[]> = yield call(
      getStatisticService
    );

    yield put(setListStatistic(dataResponse.data));
  } catch (err: any) {
    yield put(getStatisticFailed(err?.message));
  }
}

function* getContractValidated({
  dateRange,
}: IGetListContractValidatedRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IListContractValidated[]> = yield call(
      getContractValidatedService,
      dateRange
    );

    yield put(getListContractValidatedSucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(getListContractValidatedFailed(err?.message));
  }
}

function* getQuoteCreated() {
  try {
    const dataResponse: AxiosResponse<IListQuoteCreated[]> = yield call(
      getQuoteCreatedService
    );

    yield put(setListQuoteCreated(dataResponse.data));
  } catch (err: any) {
    yield put(getStatisticFailed(err?.message));
  }
}

function* watchGetStatistic() {
  yield takeLatest(StatisticTypes.GET_STATISTIC_REQUESTED, getStatistic);
}

function* watchGetListContractValidated() {
  yield takeLatest(
    StatisticTypes.GET_LIST_CONTRACT_VALIDATED_REQUESTED,
    getContractValidated
  );
}

function* statisticSaga() {
  yield all([fork(watchGetStatistic), fork(watchGetListContractValidated)]);
}

export default statisticSaga;
