export const calculatePackagesInfo = (revenue: number) => {
  if (revenue <= 70_000) {
    return {
      bundle: "Auto",
      garantiePrice: 500_000,
      marchePrice: 70_000,
      maxCAPrice: 70_000,
    };
  } else if (revenue <= 500_000) {
    return {
      bundle: "Mini",
      garantiePrice: 1_000_000,
      marchePrice: 150_000,
      maxCAPrice: 500_000,
    };
  } else if (revenue <= 1_000_000) {
    return {
      bundle: "Médium",
      garantiePrice: 2_000_000,
      marchePrice: 300_000,
      maxCAPrice: 1_000_000,
    };
  } else if (revenue <= 2_100_000) {
    return {
      bundle: "Niveau Inter",
      garantiePrice: 3_000_000,
      marchePrice: 400_000,
      maxCAPrice: 2_100_000,
    };
  } else if (revenue <= 5_000_000) {
    return {
      bundle: "Maxi",
      garantiePrice: 5_000_000,
      marchePrice: 500_000,
      maxCAPrice: 5_000_000,
    };
  } else {
    return {
      bundle: "",
      garantiePrice: 0,
      marchePrice: 0,
      maxCAPrice: 0,
    };
  }
};
