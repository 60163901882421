import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import CheckIcon from "../../../assets/Images/radio.png";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage4Props {
  isPIB?: boolean;
}

const ContentPage4 = ({ isPIB = true }: IContentPage4Props) => {
  return (
    <View break>
      <View>
        <View>
          <Text style={styles.titleBoxSubContent}>
            Montant de la garantie responsabilité décennale obligatoire
          </Text>
          <View style={generatingResultPDFStyles.underlineTitleBoxContent} />
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              <Text
                style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 9,
                  color: "#5E5873",
                }}
              >
                En habitation :
              </Text>{" "}
              le montant de la garantie couvre le coût des travaux de réparation
              des dommages à l’ouvrage.
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              <Text
                style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 9,
                  color: "#5E5873",
                }}
              >
                Hors habitation :
              </Text>{" "}
              le montant de la garantie couvre le coût des travaux de réparation
              des dommages à l’ouvrage dans la limite du coût total de la
              construction déclaré par le maître d’ouvrage et sans pouvoir être
              supérieur au montant prévu à l’article R.243-3 alinéa 1er du Code
              des assurances.
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              Lorsqu’un contrat collectif de responsabilité décennale est
              souscrit au bénéfice du souscripteur, le montant de la garantie
              est égal au montant de la franchise absolue stipulée par ledit
              contrat collectif.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ margin: "30px 0" }}>
        <View>
          <Text style={styles.titleBoxSubContent}>
            Durée et maintien de la garantie responsabilité civile décennale
          </Text>
          <View style={generatingResultPDFStyles.underlineTitleBoxContent} />
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              La garantie proposée s’applique pour la durée de la responsabilité
              décennale pesant sur le souscripteur en vertu des articles 1792 et
              suivants du Code civil. Elle est maintenue dans tous les cas pour
              la même durée.
            </Text>
          </View>
        </View>
      </View>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Garantie {isPIB && "proposée"}
        </Text>
      </View>

      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            {isPIB ? "Responsabilité civile professionnelle" : null}
            {!isPIB
              ? "Responsabilité civile décennale (assurance obligatoire)"
              : null}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            {isPIB
              ? "Responsabilité civile décennale (assurance obligatoire)"
              : null}
            {!isPIB ? "Responsabilité civile Générale" : null}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            {isPIB ? "Défense pénale / Protection juridique" : null}
            {!isPIB ? "Dommages avant reception" : null}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            {isPIB ? "Garanties facultatives (complémentaire)" : null}
            {!isPIB ? "Défense pénale / Protection juridique" : null}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  underlineTitleBoxContent: {
    width: "69px",
    height: "4px",
    backgroundColor: "#FFCC4D",
    borderRadius: 5,
    marginTop: 4,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 10,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },
  textRadio: {
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#5E5873",
  },
});

export default ContentPage4;
