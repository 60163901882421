import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, lighten, styled, Theme } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";

import { TextStyles } from "@themes/textStyles";

import { CartIcon } from "@assets/SvgComponents/Cart";
import AddIcon from "@mui/icons-material/Add";
import EuroIcon from "@mui/icons-material/Euro";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import phoneHumanImage from "@assets/Images/phone-human.png";
import { Colors } from "@themes/colors";

import TopBar from "@components/TopBar";
import { LinearProgress } from "@mui/material";
import { IAppState } from "@stores/state";
import { getStatistic } from "@stores/statistic/statistic.creator";
import formatMoney from "@utils/formatMoney";
import _, { debounce } from "lodash";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dataBarChart } from "./mockChartData";
import LinearLoading from "@components/LinearLoading";
import DateRangePickerCustom from "@components/DateRangePickerCustom";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  "&:hover": {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  position: "absolute",
  right: 4,
  bottom: 0,
  display: "block",
  height: "80%",
}));

const ContractChartWrapper = styled("div")({
  transform: "translateX(-32px)",
  marginTop: 16,
});

export interface IDashboardProps {}

export default function Dashboard() {
  const textStyles = TextStyles();

  const now = new Date();
  const lastYearDate = moment(
    new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())
  ).format("MM/DD/YYYY");
  const currentDate = moment(now).format("MM/DD/YYYY");

  const boxContainsBarChartRef = useRef<any>(null);
  const buttonDateRangePickerRef = useRef<any>();
  const [barChartWidth, setBarChartWidth] = useState<number>(600);
  const [dateRange, setDateRange] = useState<string[]>([
    lastYearDate,
    currentDate,
  ]);

  const isUnderLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isUnderXl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );

  const dispatch = useDispatch();

  const {
    listStatistic,
    listContractValidated,
    listQuoteCreated,
    loading,
    loadingContract,
  } = useSelector((state: IAppState) => state.statistic);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeDateRange = useCallback(
    debounce(() => buttonDateRangePickerRef.current.click(), 500),
    []
  );

  const onSubmit = (value: any) => {
    const dateRangeParsed = value?.date_range?.map((date: string) =>
      moment(date).format("MM/DD/YYYY")
    );

    setDateRange(dateRangeParsed);
    // dispatch(getContractsValidated(dateRangeParsed))
  };

  useEffect(() => {
    dispatch(getStatistic());
    // dispatch(getContractsValidated(dateRange))
  }, [dateRange, dispatch]);

  useEffect(() => {
    const updateBarChartWidth = () => {
      if (!_.isEmpty(boxContainsBarChartRef.current)) {
        const style = window.getComputedStyle(
          boxContainsBarChartRef.current,
          null
        );
        setBarChartWidth(
          parseInt(style.getPropertyValue("width")) -
            parseInt(style.getPropertyValue("padding")) -
            20
        );
      }
    };
    updateBarChartWidth();
    window.addEventListener("resize", updateBarChartWidth);

    return () => {
      window.removeEventListener("resize", updateBarChartWidth);
    };
  }, []);

  const renderWelcome = () => {
    return (
      <Box
        key="bonjour"
        sx={{
          position: "relative",
          flexBasis: "50%",
          background:
            "linear-gradient(85.39deg, #FCD859 24.53%, rgba(255, 242, 128, 0.66) 98.99%);",
          p: 2,
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography
          color="white"
          className={textStyles.h1}
          sx={{ fontSize: "35px !important" }}
        >
          Bonjour Nicolas
        </Typography>
        <Typography color="white" className={textStyles.h5}>
          Un devis à créer ? C’est parti !
        </Typography>
        <Link to="/select-type" style={{ textDecoration: "none" }}>
          <StyledButton
            startIcon={
              <AddIcon
                sx={{
                  border: (theme) => `2px solid ${theme.palette.primary.main}`,
                  borderRadius: 1,
                }}
                fontSize="small"
              />
            }
            variant="contained"
          >
            Créer un nouveau devis
          </StyledButton>
        </Link>
        <StyledImage src={phoneHumanImage} alt="phone human" />
      </Box>
    );
  };

  const renderMoney = () => {
    return (
      <Stack direction="column" spacing={4} sx={{ flexBasis: "50%" }}>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            backgroundColor: (theme) => theme.palette.common.white,
            boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography className={textStyles.h2}>
                {formatMoney(
                  (
                    listStatistic?.reduce(
                      (acc, current) => acc + current.avgAmount,
                      0
                    ) / listStatistic.length
                  ).toFixed(0)
                )}{" "}
                €
              </Typography>
              <Typography className={textStyles.paragraph}>
                Panier moyen
              </Typography>
            </div>
            <Avatar sx={{ bgcolor: alpha(Colors.purple, 0.2) }}>
              <CartIcon />
            </Avatar>
          </Stack>
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            backgroundColor: (theme) => theme.palette.common.white,
            boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography className={textStyles.h2}>
                {formatMoney(
                  (
                    listStatistic?.reduce(
                      (acc, current) => acc + current.totalAmount,
                      0
                    ) / listStatistic.length
                  ).toFixed(0)
                )}{" "}
                €
              </Typography>
              <Typography className={textStyles.paragraph}>
                CA depuis le début de l’année
              </Typography>
            </div>
            <Avatar sx={{ bgcolor: alpha(Colors.success, 0.2) }}>
              <EuroIcon sx={{ color: Colors.success }} />
            </Avatar>
          </Stack>
        </Box>
      </Stack>
    );
  };

  const renderBarChart = () => {
    return (
      <Box
        ref={boxContainsBarChartRef}
        sx={{
          p: 2,
          flexBasis: "50%",
          borderRadius: 2,
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          backgroundColor: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={textStyles.h4}>
            Nombre de contrats validés
          </Typography>

          <Stack direction="row" alignItems="center">
            {/* <Box
              component="form"
              sx={{ marginBottom: "-1.5px" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="date_range"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DateRangePickerCustom
                    onChange={(e) => {
                      onChange(e);
                      debounceChangeDateRange();
                    }}
                    maxDate={new Date()}
                    value={value}
                    error={errors?.date_range && errors.date_range?.message}
                  />
                )}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />

              <button
                type="submit"
                style={{ display: "none" }}
                ref={buttonDateRangePickerRef}
              ></button>
            </Box> */}

            <Typography className={textStyles.paragraph_small_semi_bold}>
              06/01/2021 - 06/21/2022
              {/* {dateRange?.[0]} - {dateRange?.[1]} */}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Typography className={textStyles.paragraph_small_semi_bold}>
            Total:&nbsp;
          </Typography>
          <Typography
            className={textStyles.paragraph_small_semi_bold}
            sx={{
              color: "#FFCC4D",
              display: "inline",
              fontSize: "13px !important",
            }}
          >
            {listStatistic?.reduce(
              (acc, current) => acc + current.totalContracts,
              0
            )}
          </Typography>
        </Stack>

        <ContractChartWrapper>
          <BarChart height={300} width={barChartWidth} data={dataBarChart}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#EBE9F1" />
            <Bar
              dataKey="value"
              fill="#FFCC4D"
              barSize={12}
              radius={[100, 100, 0, 0]}
            />
          </BarChart>
        </ContractChartWrapper>
      </Box>
    );
  };

  const renderPieChart = () => {
    const data = [
      {
        name: "Professions",
        value: 50,
      },
      {
        name: "Artisans",
        value: 14,
      },
    ];

    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      index,
    }: any) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          className={textStyles.h4}
        >
          {data[index].value}
        </text>
      );
    };

    return (
      <Box
        sx={{
          p: 2,
          flexBasis: "50%",
          borderRadius: 2,
          boxShadow: "0px 5.00971px 30.0583px rgba(0, 0, 0, 0.06)",
          backgroundColor: "white",
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography className={textStyles.h4}>
            Nombre de devis créé
          </Typography>
          <Typography align="right" className={textStyles.paragraph}>
            Sur 30 jours glissants
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" sx={{ mt: 4 }}>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={false}
            >
              <Cell fill={Colors.info} />
              <Cell fill={Colors.warning} />
            </Pie>
          </PieChart>
        </Stack>

        <Stack sx={{ mt: 4 }} direction="row" alignItems="center">
          <svg width={24} height={24} viewBox="0 0 100 100">
            <circle cx="50%" cy="50%" r={24} fill={Colors.info} />
          </svg>
          <Typography className={textStyles.h6}>
            Professions intellectuelles du bâtiment
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <svg width={24} height={24} viewBox="0 0 100 100">
            <circle cx="50%" cy="50%" r={24} fill={Colors.warning} />
          </svg>
          <Typography className={textStyles.h6}>
            Professions intellectuelles artisants
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <div>
      <TopBar />

      <Box mt={4} px={4} pt={0.5} sx={{ position: "relative" }}>
        {(loading || loadingContract) && <LinearLoading left={32} right={32} />}

        <Stack direction="column" spacing={4}>
          <Stack direction={isUnderXl ? "column" : "row"} spacing={4}>
            {renderWelcome()}
            {renderMoney()}
          </Stack>

          <Stack direction={isUnderLg ? "column" : "row"} spacing={4}>
            {renderBarChart()}
            {renderPieChart()}
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}
