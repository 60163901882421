import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";

export function getFranchiseService(professionId: string) {
  const apiUrl = BaseUrl.dev + Endpoint.franchises;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      params: {
        professionId,
      },
      url: apiUrl,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err));
  });
}
