import { IFilterContractDto, IResponseQuote } from "./listQuotes.dto";
import {
  IClearResponseDuplicateAction,
  ICreateDuplicateQuoteFailedAction,
  ICreateDuplicateQuoteRequestedAction,
  ICreateDuplicateQuoteSucceededAction,
  IGetListQuotesFailedAction,
  IGetListQuotesRequestedAction,
  IGetListQuotesSucceededAction,
  IResetListQuotesStateAction,
  ListQuotesTypes,
} from "./listQuotes.type";

export function getListQuotes(
  filterContractDto?: IFilterContractDto
): IGetListQuotesRequestedAction {
  return {
    type: ListQuotesTypes.GET_LIST_QUOTES_REQUESTED,
    filterContractDto,
  };
}

export function getListQuotesSucceeded(
  listQuotes: IResponseQuote
): IGetListQuotesSucceededAction {
  return {
    type: ListQuotesTypes.GET_LIST_QUOTES_SUCCEEDED,
    listQuotes,
  };
}

export function getListQuotesFailed(error?: any): IGetListQuotesFailedAction {
  return {
    type: ListQuotesTypes.GET_LIST_QUOTES_FAILED,
    error,
  };
}

export function createDuplicateContract(
  id: string
): ICreateDuplicateQuoteRequestedAction {
  return {
    type: ListQuotesTypes.CREATE_DUPLICATE_QUOTE_REQUESTED,
    id,
  };
}

export function createDuplicateContractSucceeded(
  message?: string
): ICreateDuplicateQuoteSucceededAction {
  return {
    type: ListQuotesTypes.CREATE_DUPLICATE_QUOTE_SUCCEEDED,
    message,
  };
}

export function createDuplicateContractFailed(
  error?: string
): ICreateDuplicateQuoteFailedAction {
  return {
    type: ListQuotesTypes.CREATE_DUPLICATE_QUOTE_FAILED,
    error,
  };
}

export function clearResponseDuplicate(): IClearResponseDuplicateAction {
  return {
    type: ListQuotesTypes.CLEAR_RESPONSE_DUPLICATE,
  };
}

export function resetListQuotesState(): IResetListQuotesStateAction {
  return {
    type: ListQuotesTypes.RESET_LIST_QUOTES_STATE,
  };
}
