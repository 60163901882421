export const convertFormatPhone = (phone: any) => {
  if (phone.indexOf("+33") !== -1) {
    return phone.replace("+33", "0");
  }
  return phone;
};

export const phoneFormated = (phone: any) => {
  if (phone) return phone.replace(phone[0], "+33");
};
