import {
  FiltersActivities,
  IFilterActivitiesDto,
  IResponseActivitiesOfContract,
} from "./activitiesOfContract.dto";
import {
  ActivitiesOfContractTypes,
  IChangeActivitiesFiltersAction,
  IClearMessageErrorAction,
  IGetActivitiesFailedAction,
  IGetActivitiesRequestedAction,
  IGetActivitiesSucceededAction,
  ISaveCurrentIdProfessionAction,
} from "./activitiesOfContract.type";

export function getActivitiesContract(
  filterDto?: IFilterActivitiesDto
): IGetActivitiesRequestedAction {
  return {
    type: ActivitiesOfContractTypes.GET_ACTIVITIES_REQUESTED,
    filterDto,
  };
}

export function getActivitiesContractSucceeded(
  activities: IResponseActivitiesOfContract
): IGetActivitiesSucceededAction {
  return {
    type: ActivitiesOfContractTypes.GET_ACTIVITIES_SUCCEEDED,
    activities,
  };
}

export function getActivitiesContractFailed(
  error?: any
): IGetActivitiesFailedAction {
  return {
    type: ActivitiesOfContractTypes.GET_ACTIVITIES_FAIL,
    error,
  };
}

export function changeActivitiesFilters(
  filters: Partial<FiltersActivities>
): IChangeActivitiesFiltersAction {
  return {
    type: ActivitiesOfContractTypes.CHANGE_FILTERS,
    filters,
  };
}

export function saveCurrentIdProfession(
  currentIdProfession: string
): ISaveCurrentIdProfessionAction {
  return {
    type: ActivitiesOfContractTypes.SAVE_CURRENT_ID_PROFESSION,
    currentIdProfession,
  };
}

export function clearMessageErrorActivities(): IClearMessageErrorAction {
  return {
    type: ActivitiesOfContractTypes.CLEAR_MESSAGE_ERROR,
  };
}
