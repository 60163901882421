const addCommasMoney = (num: any) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
const addCommasPhone = (num: any) =>
  num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
const addCommasNumber = (num: any) =>
  num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, "");
const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");

export const formatCustomInput = (num: any, type = "money") => {
  if (type === "number") return addCommasNumber(removeNonNumeric(num));
  if (type === "phone") return addCommasPhone(removeNonNumeric(num));
  return addCommasMoney(removeNonNumeric(num));
};
