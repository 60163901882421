import generatingResultPDFStyles from "@pages/Contract/GeneratingResultPDF/generatingResultPDFStyles";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage8Artisans = () => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Lois applicables
        </Text>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text style={styles.mainText}>
          Tout litige concernant l’interprétation ou l’exécution du contrat sera
          soumis aux règles et principes du droit français et au Code des
          assurances, et relève de la seule compétence des tribunaux français.
          Il est précisé que seule la société d’Assurance Mutuelle Optim
          Assurance porte le risque et la responsabilité contractuelle
          d'indemnisation en cas de sinistre. En cas de défaillance ou défaut
          seule la compagnie ou ses ré assureurs pourraient être recherchés en
          paiement. Madecennale a un statut de courtier en assurance uniquement.
        </Text>
        <Text style={styles.mainText}>
          Il n'intervient en aucun cas en tant que compagnie ou dans la présente
          proposition en conseil ou en négociation contractuelle avec le client
          final souscripteur.
        </Text>
      </View>

      <View style={{ height: 15 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          DECLARATION DE SINISTRE
        </Text>
      </View>
      <View
        style={{ borderRadius: 5, backgroundColor: "#FFF9E8", padding: "15px" }}
      >
        <Text style={styles.mainText}>
          Toute correspondance devra être adressée dans un premier temps au
          courtier, et si cela est nécessaire directement à :
        </Text>
        <Text
          style={{
            fontSize: 9,
            fontFamily: "Montserrat",
            color: "#5E5873",
            fontWeight: 500,
            lineHeight: 1.7,
            marginTop: 10,
          }}
        >
          MADECENNALE
        </Text>
        <Text style={styles.mainText}>
          1047 Route des Dolines, Business Pôle Bat A, 06560 Valbonne
        </Text>
        <Text style={styles.mainText}>Par mail : info@madecennale.com</Text>
      </View>

      <View style={{ height: 15 }} />

      <View>
        <View style={styles.titleBody}>
          <Text
            style={{
              fontSize: 12,
              color: Colors.primary,
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            Réclamation
          </Text>
        </View>
        <View>
          <Text style={styles.mainText}>
            La « réclamation », telle que définie par l’ACPR, s’entend de toute
            déclaration actant le mécontentement d’un client envers un
            professionnel.
          </Text>
          <Text style={styles.mainText}>
            Ne sont pas considérés comme réclamation :
          </Text>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainText}>
              toute demande de service ou de prestation, demande d’information,
              de clarification ou une demande d’avis,
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainText}>
              tout acte extra-judiciaire ayant pour finalité l’introduction
              d’une instance (assignation, convocation devant une Juridiction de
              Proximité ou une quelconque instance de médiation).
            </Text>
          </View>
          <View style={{ marginVertical: 10 }}>
            <Text style={styles.mainTextBold}>
              Comment puis-je faire part d’une réclamation à MADECENNALE ?{" "}
            </Text>
            <Text style={styles.mainText}>
              Vous pouvez faire part de votre réclamation :
            </Text>
            <Text style={styles.mainText}>
              Par email : reclamation@madecennale.com
            </Text>
            <Text style={styles.mainText}>
              Par courrier : MADECENNALE – SERVICE RECLAMATIONS – 1047 Route des
              Dolines, Business Pôle Bat A, 06560 Valbonne
            </Text>
          </View>
          <View>
            <Text style={styles.mainText}>
              MADECENNALE s’engage à accuser réception de votre réclamation dans
              un délai de 10 jours calendaires à compter de la réception de
              votre réclamation et à vous apporter une réponse sous deux mois
              maximum à compter de cette même date.
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={styles.mainTextBold}>
              Quels recours sont possibles si les réponses apportées ne me
              satisfont pas ?
            </Text>
            <Text style={styles.mainText}>
              Si malgré la réponse apportée votre insatisfaction persiste, sans
              préjudice d’intenter une action en justice, vous pouvez contacter
              La Médiation de l’Assurance :
            </Text>
            <Text style={styles.mainText}>
              En ligne : http://www.mediation-assurance.org ou par courrier à :
              Médiateur de l’Assurance – TSA 50110 – 75441 PARIS CEDEX 09
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  itemCheckbox: {
    borderBottom: "1px dashed #FFCC4D",
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
  },
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
  mainTextCheckbox: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  mainTextBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: "bold",
  },
  mainTextYellow: {
    fontSize: 9,
    color: Colors.primary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.7,
  },
  checkbox: {
    width: 11,
    height: 11,
    border: "1.2px solid #FFCC4D",
    borderRadius: 2,
    marginRight: 6,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.8,
    height: 1.8,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage8Artisans;
