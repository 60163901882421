import * as React from "react"

const NextIcon: React.FC<{color: string}> = ({color}) => (
  <svg
    width={11}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.088 1.004a.583.583 0 0 0 0 .825l3.087 3.087H1.417a.583.583 0 1 0 0 1.167h6.758L5.087 9.171a.583.583 0 1 0 .825.824l4.084-4.083a.582.582 0 0 0 .17-.403m-.044-.233a.582.582 0 0 0-.126-.189L5.912 1.004a.583.583 0 0 0-.824 0m5.034 4.272a.581.581 0 0 1 .045.214l-.045-.214Z"
      fill={color}
    />
  </svg>
)

export default NextIcon
