import { makeStyles } from "@mui/styles";
import { Colors } from "@themes/colors";
import * as React from "react";

const RadioChecked = () => {
  const classes = useStyles();
  return <span className={classes.root} />;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: 20,
    height: 20,
    backgroundColor: Colors.primary,
    borderRadius: "50%",
    boxShadow: '0px 2px 4px rgba(115, 103, 240, 0.4)'
  },
});

export default RadioChecked;
