const BaseUrl = {
  dev: process.env.REACT_APP_BASE_URL || "http://localhost:5000",
};

export const TOKEN_PAPPERS = "49d0c1d634ee26893c1dac719476ff86f3c75e84cdf1179d";

const Endpoint = {
  signin: "/auth/signin",
  request_email: "/auth/request-email",
  reset_password: "/auth/reset-password",
  detail_company: "https://api.pappers.fr/v2/entreprise",
  get_companies: "https://suggestions.pappers.fr/v2",
  get_detail_activities: "/activities/detailed",
  company: "/companies",
  profession: "/professions",
  draft_contract: "/contracts/draft",
  contracts: "/contracts",
  duplicate_contracts: "/contracts/duplicate",
  franchises: "/franchises",
  calculate_amount: "/contracts/calculate-amount",
  statistic_contract: "/contracts/statistic",
  users: "/users",
  archive_contract: "/contracts/archive",
  pass_in_guarantee: "/contracts/pass-in-guarantee",
  generate_contact_code: "/contracts/generate-contract-code",
};

export { BaseUrl, Endpoint };
