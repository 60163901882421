import React from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import Signature from "../../../assets/Images/signature.png";
import generatingResultPDFStyles from "@pages/Contract/GeneratingResultPDF/generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage11Props {
  isPIB?: boolean;
}

const ContentPage11 = ({ isPIB = true }: IContentPage11Props) => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Déclarations
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 9,
            textDecoration: "underline",
            color: Colors.primary,
            marginBottom: 8,
          }}
        >
          Je reconnais :
        </Text>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            avoir recu et pris connaissance du référentiel PIB_V1694;
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            avoir reçu et pris connaissance des Conditions générales
            CG_PIB_V1293 ainsi que l’Annexe JURI’LAW 03 2020;
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            avoir reçu et pris connaissance des Dispositions générales Groupama
            Protection Juridique 202000082/DG2020376V01 ;
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            que durant la période d’étude écoulée et la date d’effet du contrat
            à venir, il n’existe aucun évènement ou sinistre dont il aurait
            connaissance susceptible de mobiliser les garanties proposées.
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            que les déclarations qu’il a faites en réponse aux questions posées
            sont conformes à la réalité.{" "}
            <Text
              style={{
                fontSize: 9,
                color: "#EA5455",
                fontFamily: "Montserrat",
                lineHeight: 1.8,
              }}
            >
              Les déclarations effectuées par le souscripteur sont essentielles
              et détérminantes du consentement de l’Assureur quant à
              l’acceptation du risque à garantir et au tarif proposé. Toute
              omission ou fausse déclaration sera passible des sanctions prévues
              aux articles L.113-8 et L.113-9 du Code des assurances ;
            </Text>
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            avoir pris connaissance des obligations suscitées lui incombant et
            s’engager à les respecter.
          </Text>
        </View>
      </View>
      <View style={{ height: 5 }} />
      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 9,
            textDecoration: "underline",
            color: Colors.primary,
            marginBottom: 4,
          }}
        >
          Je reconnais :
        </Text>
        <View style={generatingResultPDFStyles.itemCheckboxStart}>
          <View style={styles.checkbox} />
          <Text style={styles.mainTextCheckbox}>
            être, moi-même, ma(mon) conjoint(e), mon(mes) enfant(s), mon(mes)
            parent(s) une personne politiquement exposée au sens de l’article
            R.561-18* du code monétaire et financier.
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemCheckboxStart}>
          <View style={styles.checkbox} />
          <Text style={styles.mainTextCheckbox}>
            ne pas être, ni moi-même, ni ma(mon) conjoint(e), ni mon(mes)
            enfant(s), ni mon(mes) parent(s) une personne politiquement exposée
            au sens de l’article R.561-18* du Code monétaire et financier.
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: isPIB ? "column" : "column-reverse" }}>
        <View style={{ marginVertical: 10 }}>
          <Text style={styles.mainTextBold}>
            *Article R.561-18 du Code monétaire et financier :{" "}
          </Text>
          <Text style={styles.mainContent}>
            Les personnes qui exercent, ou ont cessé d’exercer depuis moins d’un
            an, les fonctions suivantes :
          </Text>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Chef d’Etat, chef de gouvernement, membre d’un gouvernement
              national ou de la Commission européenne ;{" "}
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Membre d’une assemblée parlementaire nationale (député comme
              sénateur) ou du Parlement européen, dirigeant d’un parti politique
              ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Magistrat de la Cour de Cassation, conseiller d’Etat et membre du
              Conseil Constitutionnel ou leurs équivalents à l’étranger ;{" "}
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Magistrat de la Cour des comptes ou leurs équivalents à l’étranger
              ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Dirigeant ou membre de l’organe de direction d’une banque centrale
              ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Ambassadeur ou chargé d’affaires ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Officier général, ou officier supérieur assurant le commandement
              d’une armée ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Membre d’un organe d’administration, de direction ou de
              surveillance d’une entreprise publique exerçant son activité au
              niveau national ;
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Directeur, directeur adjoint, membre du conseil d’une organisation
              international.
            </Text>
          </View>
        </View>

        <View style={styles.specialTable}>
          <View style={styles.headerSpecialTable}>
            <Text style={styles.titleHeaderTable}>SIGNATURE DES PARTIS</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#FFF9E8",
            }}
          >
            <View style={{ padding: "6px", flex: 1 }}>
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                L’assureur
              </Text>
            </View>
            <View
              style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
            />
            <View style={{ padding: "6px", flex: 1 }}>
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Le souscripteur
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 1, padding: "10px 5px" }}>
              <Image src={Signature} style={{ width: 239, height: 70 }} />
            </View>
            <View
              style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
            />
            <View style={{ flex: 1, padding: "10px 5px" }}>
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: 4,
                  marginLeft: 10,
                }}
              >
                Signature + Tampon
              </Text>
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  marginBottom: 4,
                  marginLeft: 10,
                  // fontStyle: "italic",
                }}
              >
                Mention manuscrite “Lu et approuvé”
              </Text>
              <Text
                style={{
                  fontSize: 9,
                  color: "#5E5873",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  marginLeft: 10,
                }}
              >
                Date :
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginBottom: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.5,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 6,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.8,
  },
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    padding: "6px 0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
  },
  checkbox: {
    width: 11,
    height: 11,
    border: "1.2px solid #FFCC4D",
    borderRadius: 2,
    marginRight: 6,
  },
  itemCheckbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 6,
  },
  mainTextCheckbox: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  mainTextBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: "bold",
  },
});

export default ContentPage11;
