import * as React from "react";

const BackgroundForgotPass = () => (
  <svg width={650} height={574} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={0.053}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M650 173.93C650 77.87 571.83 0 475.402 0c-96.428 0-174.598 77.87-174.598 173.93 0 96.058 78.17 173.929 174.598 173.929C571.83 347.859 650 269.988 650 173.93Zm-283.926 0c0-60.149 48.948-108.91 109.328-108.91S584.73 113.781 584.73 173.93c0 60.148-48.948 108.909-109.328 108.909S366.074 234.078 366.074 173.93Z"
      fill="#FFCC4D"
    />
    <g opacity={0.141} filter="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.993 275.795c0-27.232-22.16-49.307-49.496-49.307C28.16 226.488 6 248.563 6 275.795c0 27.232 22.16 49.307 49.497 49.307 27.336 0 49.496-22.075 49.496-49.307Zm-66.358 0c0-9.277 7.55-16.797 16.862-16.797 9.312 0 16.861 7.52 16.861 16.797 0 9.277-7.549 16.797-16.861 16.797-9.313 0-16.862-7.52-16.862-16.797Z"
        fill="#8BF78F"
      />
    </g>
    <ellipse
      cx={208.338}
      cy={433.47}
      rx={97.905}
      ry={97.531}
      fill="#7367F0"
      fillOpacity={0.061}
    />
    <ellipse
      opacity={0.141}
      cx={175.703}
      cy={92.112}
      rx={34.811}
      ry={34.678}
      fill="#CEF5FF"
    />
    <g filter="url(#b)">
      <ellipse
        cx={585.818}
        cy={433.47}
        rx={34.811}
        ry={34.678}
        fill="#7367F0"
        fillOpacity={0.136}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M208.54 144.137c0-13.883 11.254-25.137 25.137-25.137h169.805c13.883 0 25.137 11.254 25.137 25.137v403.199c0 13.882-11.254 25.137-25.137 25.137H233.677c-13.883 0-25.137-11.255-25.137-25.137V144.137Z"
      fill="#C4E3E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.477 146.66c0-10.229 8.293-18.522 18.522-18.522h30.89c.812 0 1.478.646 1.501 1.459.17 5.822 4.938 10.454 10.763 10.454h80.131c5.825 0 10.594-4.632 10.763-10.454a1.503 1.503 0 0 1 1.502-1.459h30.889c10.23 0 18.522 8.293 18.522 18.522v395.445c0 10.23-8.292 18.522-18.522 18.522H234.999c-10.229 0-18.522-8.292-18.522-18.522V146.66Z"
      fill="#fff"
    />
    <mask
      id="c"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={216}
      y={128}
      width={204}
      height={433}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.477 146.66c0-10.229 8.293-18.522 18.522-18.522h30.89c.812 0 1.478.646 1.501 1.459.17 5.822 4.938 10.454 10.763 10.454h80.131c5.825 0 10.594-4.632 10.763-10.454a1.503 1.503 0 0 1 1.502-1.459h30.889c10.23 0 18.522 8.293 18.522 18.522v395.445c0 10.23-8.292 18.522-18.522 18.522H234.999c-10.229 0-18.522-8.292-18.522-18.522V146.66Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#c)">
      <ellipse
        opacity={0.099}
        cx={293.623}
        cy={391.368}
        rx={17.453}
        ry={17.328}
        fill="#FFCC4D"
      />
      <path
        opacity={0.141}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M398.068 374.04c46.539 0 84.266 37.456 84.266 83.66 0 46.204-37.727 83.659-84.266 83.659-46.538 0-84.265-37.455-84.265-83.659 0-46.204 37.727-83.66 84.265-83.66Zm0 36.55c-26.206 0-47.45 21.092-47.45 47.11 0 26.017 21.244 47.109 47.45 47.109 26.206 0 47.451-21.092 47.451-47.109 0-26.018-21.245-47.11-47.451-47.11Z"
        fill="#C8F5D0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.264 208.075a5.686 5.686 0 0 1 5.686-5.686h144.069a5.686 5.686 0 0 1 0 11.371H246.95a5.686 5.686 0 0 1-5.686-5.685Z"
        fill="#68DE9E"
        fillOpacity={0.642}
      />
    </g>
    <path
      d="M330.508 126.939c1.883 0 3.409 1.455 3.409 3.249 0 1.754-1.459 3.184-3.284 3.247l-.125.002h-38.545c-1.882 0-3.408-1.455-3.408-3.249 0-1.755 1.459-3.184 3.284-3.247l.124-.002h38.545ZM344.322 126.397c1.883 0 3.409 1.516 3.409 3.385 0 1.827-1.459 3.317-3.284 3.382l-.125.002h-.179c-1.882 0-3.408-1.515-3.408-3.384 0-1.828 1.459-3.317 3.283-3.382l.125-.003h.179Z"
      fill="#56CDD9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.72 276.463a4 4 0 0 1 4-4h7.594a4 4 0 0 1 4 4v3.503a1.22 1.22 0 0 0 1.219 1.219h50.01a4 4 0 0 1 4 4v42.698a4 4 0 0 1-4 4H226.72a4 4 0 0 1-4-4v-51.42Z"
      fill="url(#d)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.536 277.616a1.22 1.22 0 0 1 1.203-1.026h54.322c.751 0 1.323.671 1.204 1.413l-8.16 50.791a1.22 1.22 0 0 1-1.204 1.026h-54.322a1.22 1.22 0 0 1-1.204-1.412l8.161-50.792Z"
      fill="url(#e)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M298.087 290.108a4 4 0 0 1 3.925 4.769l-6.113 31.214a8 8 0 0 1-7.851 6.463H227.58a4 4 0 0 1-3.926-4.769l6.113-31.215a8 8 0 0 1 7.851-6.462h60.469Z"
      fill="#FFCC7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142 222.468a6 6 0 0 1 6-6h33.667l12.692 12.995v42.734a6 6 0 0 1-6 6H148a6 6 0 0 1-6-6v-49.729Z"
      fill="#B7B1FB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.1 257.505v-17.047c0-1.811-1.385-3.196-3.197-3.196h-5.328c-.639 0-1.066.426-1.066 1.065s.427 1.066 1.066 1.066h5.328c.639 0 1.066.426 1.066 1.065v17.047c0 .639-.427 1.065-1.066 1.065h-5.328c-.639 0-1.066.426-1.066 1.066 0 .639.427 1.065 1.066 1.065h5.328c1.812 0 3.197-1.385 3.197-3.196Zm-6.5-8.95c.106.32.106.639 0 .853 0 .106-.107.213-.213.319l-4.263 4.262a1.15 1.15 0 0 1-.746.319 1.15 1.15 0 0 1-.746-.319 1.03 1.03 0 0 1 0-1.492l2.451-2.45h-10.23c-.639 0-1.065-.426-1.065-1.066 0-.639.426-1.065 1.065-1.065h10.23l-2.451-2.451a1.03 1.03 0 0 1 0-1.491 1.03 1.03 0 0 1 1.492 0l4.263 4.262c.053.053.079.106.106.159.027.054.053.107.107.16Z"
      fill="#000"
    />
    <mask
      id="f"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={154}
      y={237}
      width={23}
      height={24}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.1 257.505v-17.047c0-1.811-1.385-3.196-3.197-3.196h-5.328c-.639 0-1.066.426-1.066 1.065s.427 1.066 1.066 1.066h5.328c.639 0 1.066.426 1.066 1.065v17.047c0 .639-.427 1.065-1.066 1.065h-5.328c-.639 0-1.066.426-1.066 1.066 0 .639.427 1.065 1.066 1.065h5.328c1.812 0 3.197-1.385 3.197-3.196Zm-6.5-8.95c.106.32.106.639 0 .853 0 .106-.107.213-.213.319l-4.263 4.262a1.15 1.15 0 0 1-.746.319 1.15 1.15 0 0 1-.746-.319 1.03 1.03 0 0 1 0-1.492l2.451-2.45h-10.23c-.639 0-1.065-.426-1.065-1.066 0-.639.426-1.065 1.065-1.065h10.23l-2.451-2.451a1.03 1.03 0 0 1 0-1.491 1.03 1.03 0 0 1 1.492 0l4.263 4.262c.053.053.079.106.106.159.027.054.053.107.107.16Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#f)">
      <path fill="#fff" d="M152.656 236.196h25.575v25.57h-25.575z" />
    </g>
    <path
      opacity={0.34}
      d="M194.359 229.463h-9.692a3 3 0 0 1-3-3v-9.995l12.692 12.995Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.907 382.748a6 6 0 0 1 6-6h62.175a6 6 0 0 1 6 6v49.729a6 6 0 0 1-6 6h-62.175a6 6 0 0 1-6-6v-49.729Z"
      fill="#6FD9E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M209.841 405.159h-1.243v-3.729c0-4.102-3.357-7.458-7.46-7.458-4.102 0-7.459 3.356-7.459 7.458v3.729h-1.243c-2.114 0-3.73 1.616-3.73 3.729v8.701c0 2.113 1.616 3.729 3.73 3.729h17.405c2.113 0 3.729-1.616 3.729-3.729v-8.701c0-2.113-1.616-3.729-3.729-3.729Zm-13.676-3.729c0-2.734 2.238-4.972 4.973-4.972s4.973 2.238 4.973 4.972v3.729h-9.946v-3.729Zm13.676 17.402c.746 0 1.243-.497 1.243-1.243v-8.701c0-.746-.497-1.243-1.243-1.243h-17.405c-.746 0-1.244.497-1.244 1.243v8.701c0 .746.498 1.243 1.244 1.243h17.405Z"
      fill="#000"
    />
    <mask
      id="g"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={188}
      y={393}
      width={26}
      height={29}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.841 405.159h-1.243v-3.729c0-4.102-3.357-7.458-7.46-7.458-4.102 0-7.459 3.356-7.459 7.458v3.729h-1.243c-2.114 0-3.73 1.616-3.73 3.729v8.701c0 2.113 1.616 3.729 3.73 3.729h17.405c2.113 0 3.729-1.616 3.729-3.729v-8.701c0-2.113-1.616-3.729-3.729-3.729Zm-13.676-3.729c0-2.734 2.238-4.972 4.973-4.972s4.973 2.238 4.973 4.972v3.729h-9.946v-3.729Zm13.676 17.402c.746 0 1.243-.497 1.243-1.243v-8.701c0-.746-.497-1.243-1.243-1.243h-17.405c-.746 0-1.244.497-1.244 1.243v8.701c0 .746.498 1.243 1.244 1.243h17.405Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#g)">
      <path fill="#fff" d="M186.22 392.729h29.837v29.832H186.22z" />
    </g>
    <path
      d="M229.265 185.646a6 6 0 0 1 6-6h62.175a6 6 0 0 1 6 6v49.73a6 6 0 0 1-6 6h-62.175a6 6 0 0 1-6-6v-49.73Z"
      fill="#BAF3C4"
    />
    <mask
      id="h"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={229}
      y={179}
      width={75}
      height={63}
    >
      <path
        d="M229.265 185.646a6 6 0 0 1 6-6h62.175a6 6 0 0 1 6 6v49.73a6 6 0 0 1-6 6h-62.175a6 6 0 0 1-6-6v-49.73Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#h)">
      <ellipse cx={261.99} cy={202.389} rx={9.817} ry={9.747} fill="#fff" />
      <ellipse
        cx={239.628}
        cy={252.747}
        rx={35.451}
        ry={35.197}
        fill="#28C76F"
      />
      <ellipse
        cx={303.986}
        cy={244.625}
        rx={41.996}
        ry={41.153}
        fill="#69E09E"
      />
    </g>
    <g filter="url(#i)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M327.478 164.778c-.022-5.538 4.461-10.04 10-10.04h110.243c5.507 0 9.978 4.453 9.999 9.961l.575 144.611c.022 5.539-4.461 10.04-9.999 10.04H338.053c-5.508 0-9.978-4.453-10-9.96l-.575-144.612Z"
        fill="#fff"
      />
    </g>
    <path
      opacity={0.34}
      d="M368.889 259.787a3.249 3.249 0 0 1 3.249-3.249h41.498a3.249 3.249 0 1 1 0 6.497h-41.498a3.249 3.249 0 0 1-3.249-3.248ZM357.981 277.114a3.249 3.249 0 0 1 3.249-3.249h63.314a3.25 3.25 0 0 1 0 6.498H361.23a3.249 3.249 0 0 1-3.249-3.249Z"
      fill="#FFCC4D"
    />
    <g filter="url(#j)">
      <ellipse cx={392.969} cy={207.357} rx={25.17} ry={25.545} fill="#fff" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M393.443 206.51c3.556 0 6.439-2.862 6.439-6.392 0-3.531-2.883-6.393-6.439-6.393s-6.439 2.862-6.439 6.393c0 3.53 2.883 6.392 6.439 6.392Zm-.812 1.81a9.562 9.562 0 0 0-9.562 9.562c0 .603.49 1.092 1.093 1.092h18.562c.603 0 1.093-.489 1.093-1.092a9.562 9.562 0 0 0-9.562-9.562h-1.624Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M517.545 340.071s-12.198 11.98-11.45 13.152c.748 1.179 6.896-1.561 6.896-1.561s-7.67 12.585-6.092 13.552c1.577.966 9.646-.991 12.12-3.585 2.475-2.594 6.294-15.685 6.294-15.685l18.552-34.495-13.455-4.623-12.865 33.245Z"
      fill="url(#k)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M505.088 276.306c5.123 7.902 22.367 30.423 22.367 30.423l-1.257 4.493 15.847 10.786s4.502-12.348 4.893-14.085c1.288-5.73-24.746-42.297-30.576-47.581-9.178-8.32-19.102 3.887-11.274 15.964Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M531.576 312.707c.296-.572-4.096-6.059-4.096-6.059l-.744 2.668s4.545 3.964 4.84 3.391Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m526.1 309.714 16.836 12.765-3.752 8.255-14.72-16.256 1.636-4.764Z"
      fill="#FFEDC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m515.472 542.024-.935 15.818 11.988.31-2.254-16.128h-8.799Z"
      fill="url(#l)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M527.093 548.256s-.337 9.526 1.65 12.852c2 3.325 2.606 5.904 2.694 7.893.095 2.132-.108 4.064-.108 4.064h-19.841s-.497-10.023.818-11.913c1.314-1.889 2.176-1.922 2.176-3.592.01-1.67.294-9.242.294-9.242l12.317-.062Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m473.24 330.84 31.096-3.356.457.422c2.216 2.096 11.848 11.833 14.616 25.142 5.224 25.125-1.768 71.53-1.614 81.705.148 10.176 4.991 16.315 7.004 30.703 2.013 14.387 2.203 78.687 2.203 78.687-5.337 3.139-10.032 3.364-16.395-.189L473.24 330.84Z"
      fill="#4E3319"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M501.017 489.255 473.24 330.84l17.289-1.866c.771 55.033 11.523 129.423 10.488 160.281Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m479.609 540.361-10.222.165s.672 10.534-3.259 14.102l-.214.187c-4.746 3.949 13.562 2.524 13.562 2.524l.133-16.978Z"
      fill="url(#m)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m480.152 548.572-.295 24.484-7.279.005-.063-6.033s-2.295 5.625-5.804 5.95c-3.52.325-19.202.126-19.202.126s-1.303-5.649 6.503-8.213c6.493-2.132 13.672-16.319 13.672-16.319h12.468Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m457.814 333.019 34.919-1.175.261.479c1.694 3.191 10.978 21.872 8.112 43.105-4.024 29.804-14.253 64.743-14.754 70.081-.893 9.516.893 16.406 1.625 26.235s-2.792 71.647-2.792 71.647c-5.182 3.094-11.348 3.701-19.187.093 0 0-7.077-83.966-7.129-97.255-.052-13.288-1.055-113.21-1.055-113.21Z"
      fill="#604021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m465 365.255-.232 2.208c-2.327 23.285-2.31 72.735-1.408 80.361l.123.983c1 8.605 1.032 30.453.097 65.543l-1.216-15.142c-2.306-29.136-3.471-46.796-3.495-52.979l-.042-6.399c-.201-25.874-1.013-106.812-1.013-106.812l7.365-.247c.787 12.776.727 23.604-.179 32.484Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M471.129 222.653c-4.296.467-3.992 7.966 1.239 8.357 1.069 7.23 4.881 10.674 9.529 11.073l-.258 10.935 20.56-.013s-5.79-7.724-5.501-18.299c.288-10.576-.194-20.511-.194-20.511l-26.629.475 1.254 7.983Z"
      fill="url(#n)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M470.195 223.215c5.004-1.965 8.7 6.494 12.529 12.373 3.829 5.88 13.749 4.881 15.117 0 5.176-10.689 9.637-25.221 3.596-26.414-.85-15.449-41.578-11.801-31.242 14.041Z"
      fill="#8B5030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M498.14 203.079h-8.971l-.074.083c-7.849 8.697-9.595 21.519-5.904 33.073.256.321.532.618.825.891-4.085-11.645-2.503-24.745 5.376-33.547h9.341a9.965 9.965 0 0 0-.593-.5Z"
      fill="#000"
      fillOpacity={0.199}
    />
    <ellipse cx={492.696} cy={203.329} rx={4.909} ry={4.873} fill="#FB9934" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M465.735 255.136c-4.587 6.689-12.231 29.394-12.665 34.502-.488 5.891 5.874 15.277 5.979 23.806.093 8.531-4.746 34.215-3.292 36.614 1.442 2.399 42.796 13.022 45.494 12.965 2.687-.058 19.65-14.516 17.521-19.512-2.14-4.996-12.073-17.412-11.061-24.219 1.001-6.806 6.809-31.932 9.694-40.483 2.872-8.551 1.915-17.529-3.447-21.209-2.653-1.821-9.733-6.993-22.34-6.97-12.607.024-25.527 3.987-25.883 4.506Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M479.97 250.141s-.53 2.877 1.792 2.877c2.323 0 17.902.509 20.012-.4 2.11-.91 1.477-2.477 1.477-2.477H479.97Z"
      fill="#FFF6E1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m459.078 269.604.141-.071c8.495-4.21 13.597-4.396 15.308-.557 2.211 4.961-3.523 16.882-17.201 35.764-1.878-5.726-4.58-11.186-4.256-15.103.251-2.956 2.917-11.805 6.008-20.033Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M402.413 255.659s-1.53-3.299-3.936-4.191c-2.405-.892-6.084-6.803-5.515-8.519.569-1.716 5.545-6.476 5.545-6.476s-4.399-11.72-2.451-13.043c1.721-1.169 8.235 13.594 8.235 13.594s3.83.573 4.713 2.082c2.228 3.805.544 12.362.544 12.362l22.608 32.799-10.374 13.205-19.369-41.813Z"
      fill="url(#o)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M460.124 258.202c-7.646 6.743-26.347 24.634-26.347 24.634l-2.779-4.227-13.986 14.219s10.551 15.537 13.986 15.645c8.952.283 24.991-16.497 40.07-29.599 13.52-11.747 3.309-33.243-10.944-20.672Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M433.833 282.858s-5.247 6.024-5.68 5.367c-.433-.657 3.958-7.98 3.958-7.98l1.722 2.613Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m432.672 279.567-16.22 17.905-4.998-10.218 18.132-11.297 3.086 3.61Z"
      fill="#FFEDC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M491.835 199.45s4.594-5.94 9.898-4.381c5.304 1.558 10.101 11.111 7.823 20.09-2.266 8.981-.397 14.103 2.353 20.156 7.386 16.28-18.188 26.618-18.188 26.618s1.996-12.064-1.886-19.349c-3.997-7.502-6.267-17.123-6.267-23.01 0-10.238 6.267-20.124 6.267-20.124Z"
      fill="#8B5030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M434.337 134.277c18.48-10.572 37.213-9.946 56.155 0l-1.2 13.268a57.746 57.746 0 0 1-26.877 43.75 56.199 56.199 0 0 1-27.335-44.86l-.743-12.158Z"
      fill="#28C76F"
    />
    <ellipse cx={462.415} cy={155.723} rx={15.315} ry={15.205} fill="#1CA258" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M462.196 144.921c4.871 0 9.088 3.26 10.311 7.856a.665.665 0 0 1-.477.814.672.672 0 0 1-.819-.474c-1.068-4.015-4.755-6.865-9.015-6.865-5.148 0-9.321 4.128-9.321 9.22 0 2.874.949 5.457 2.859 7.774a.661.661 0 0 1-.094.936.674.674 0 0 1-.943-.093c-2.104-2.553-3.162-5.433-3.162-8.617 0-5.828 4.774-10.551 10.661-10.551Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M459.827 148.663c3.792-1.296 7.886.58 9.285 4.249l.076.21c.719 2.072 1.697 3.01 2.948 2.995a.667.667 0 0 1 .678.657.667.667 0 0 1-.661.673c-1.865.023-3.25-1.245-4.142-3.64l-.09-.252c-1.078-3.107-4.489-4.717-7.658-3.634-2.853.975-4.378 4.423-3.707 7.462a9.5 9.5 0 0 0 .508 1.635l.191.442c.636 1.396 1.463 2.443 5.199 6.9a.662.662 0 0 1-.087.937.674.674 0 0 1-.944-.086l-2.003-2.403c-2.404-2.912-2.947-3.743-3.553-5.182l-.042-.102a10.902 10.902 0 0 1-.579-1.856c-.805-3.652 1.026-7.79 4.581-9.005Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M461.375 152.255c1.964-.672 4.137.377 4.748 2.338.88 2.825 2.501 4.58 4.892 5.334a.664.664 0 0 1 .436.835.67.67 0 0 1-.841.433c-2.83-.892-4.763-2.985-5.768-6.209-.384-1.234-1.772-1.904-3.031-1.474-1.339.458-2.003 1.742-1.637 3.498.185.893.92 2.109 2.171 3.693l.213.268c.176.218.36.443.56.682l2.153 2.529a.662.662 0 0 1-.089.937.673.673 0 0 1-.943-.089l-1.723-2.018-.431-.511a53.49 53.49 0 0 1-.574-.7l-.434-.55c-1.246-1.61-1.988-2.879-2.216-3.972-.496-2.389.512-4.34 2.514-5.024Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M462.567 155.877a.671.671 0 0 1 .837.442c.723 2.341 2.137 4.001 4.797 5.984a.663.663 0 0 1 .134.932.675.675 0 0 1-.939.132c-2.865-2.136-4.453-4-5.273-6.659a.666.666 0 0 1 .444-.831Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="d"
        x1={299.099}
        y1={320.699}
        x2={299.099}
        y2={251.956}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8C19" />
        <stop offset={1} stopColor="#FFC967" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={290.964}
        y1={293.294}
        x2={290.964}
        y2={261.963}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8FDFF" />
        <stop offset={1} stopColor="#AAD4FF" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={502.994}
        y1={290.908}
        x2={507.069}
        y2={361.834}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={512.463}
        y1={538.098}
        x2={514.125}
        y2={557.264}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={462.622}
        y1={536.167}
        x2={464.195}
        y2={556.674}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={462.261}
        y1={204.745}
        x2={465.659}
        y2={250.978}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={386.111}
        y1={205.324}
        x2={396.752}
        y2={292.76}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <filter
        id="a"
        x={0.563}
        y={221.051}
        width={109.866}
        height={109.487}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={2.718}
          result="effect1_foregroundBlur_674_93061"
        />
      </filter>
      <filter
        id="b"
        x={542.852}
        y={390.637}
        width={85.931}
        height={85.665}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={4.077}
          result="effect1_foregroundBlur_674_93061"
        />
      </filter>
      <filter
        id="i"
        x={307.478}
        y={135.738}
        width={186.817}
        height={220.612}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={8} dy={9} />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.0755213 0 0 0 0 0.582569 0 0 0 0 0.878793 0 0 0 0.229458 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93061"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={7.5} />
        <feColorMatrix values="0 0 0 0 0.503982 0 0 0 0 0.700178 0 0 0 0 1 0 0 0 0.233829 0" />
        <feBlend
          in2="effect1_dropShadow_674_93061"
          result="effect2_dropShadow_674_93061"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_674_93061"
          result="shape"
        />
      </filter>
      <filter
        id="j"
        x={347.798}
        y={162.812}
        width={106.341}
        height={107.09}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={8} dy={9} />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.0755213 0 0 0 0 0.582569 0 0 0 0 0.878793 0 0 0 0.229458 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93061"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={7.5} />
        <feColorMatrix values="0 0 0 0 0.503982 0 0 0 0 0.700178 0 0 0 0 1 0 0 0 0.233829 0" />
        <feBlend
          in2="effect1_dropShadow_674_93061"
          result="effect2_dropShadow_674_93061"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_674_93061"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default BackgroundForgotPass;
