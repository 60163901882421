import { IResponseFranchise } from "./franchises.dto";

export enum FranchiseTypes {
  GET_FRANCHISE_REQUESTED = "franchise/GET_REQUESTED",
  GET_FRANCHISE_SUCCEEDED = "franchise/GET_SUCCEEDED",
  GET_FRANCHISE_FAILED = "franchise/GET_FAILED",
}

export interface IGetFranchiseRequestedAction {
  type: typeof FranchiseTypes.GET_FRANCHISE_REQUESTED;
  professionId: string;
}

export interface IGetFranchiseSucceededAction {
  type: typeof FranchiseTypes.GET_FRANCHISE_SUCCEEDED;
  data?: IResponseFranchise;
}

export interface IGetFranchiseFailedAction {
  type: typeof FranchiseTypes.GET_FRANCHISE_FAILED;
  error?: any;
}

export type FranchiseActions =
  | IGetFranchiseRequestedAction
  | IGetFranchiseSucceededAction
  | IGetFranchiseFailedAction;
