export interface IUser {
  _id: string;
  email: string;
  password: any;
}

export interface ILoginParams {
  email: string;
  password: string;
}

export interface ILogin {
  token: string;
  user: IUser;
}

export enum RequestEmailType {
  FORGOT = "FORGOT",
}

export interface IRequestEmail {
  email: string;
  type: RequestEmailType;
}

export interface IResetPassword {
  newPassword: string;
  token: string;
}

export interface IResponseLogin {
  data: ILogin;
  params: ILoginParams;
  loading: boolean;
  error?: string;
  message?: string;
}
