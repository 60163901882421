import FilterTableHead from "@assets/SvgComponents/FilterTableHead";
import NextIcon from "@assets/SvgComponents/NextIcon";
import ButtonCustom from "@components/ButtonCustom";
import CheckboxCustom from "@components/CheckboxCustom";
import LinearLoading from "@components/LinearLoading";
import NumberInputCustom from "@components/NumberInputCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import Spacing from "@components/Spacing";
import TooltipCustom from "@components/TooltipCustom";
import { CircularProgress, Stack, TableHead } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  changeActivitiesFilters,
  getActivitiesContract,
  saveCurrentIdProfession,
} from "@stores/activitiesOfContract/activitiesOfContract.creator";
import {
  FiltersActivitiesSortValue,
  IActivityOfContract,
  IdProfessionType,
} from "@stores/activitiesOfContract/activitiesOfContract.dto";
import { initActivitiesFilters } from "@stores/activitiesOfContract/activitiesOfContract.reducer";
import {
  selectActivitiesPerPage,
  selectCurrentIdProfession,
  selectFiltersActivities,
  selectTotalFilteredActivities,
} from "@stores/activitiesOfContract/activitiesOfContract.selector";
import {
  saveDraftContract,
  saveInfoContractIntoRedux,
  updateDraftContract,
} from "@stores/contract/contract.creator";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import {
  addActivity,
  removeActivity,
  updateActivity,
} from "@stores/savedActivities/savedActivities.creator";
import { IActivityRedux } from "@stores/savedActivities/savedActivities.dto";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import isNumberic from "@utils/checkNumberic";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import parse from "html-react-parser";
import { debounce } from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormQueryTableHead from "./FormQueryTableHead";

interface HeadCell {
  id: string;
  label: string;
  valueSort: FiltersActivitiesSortValue;
}

const headCells: readonly HeadCell[] = [
  {
    id: "n°",
    label: "N°",
    valueSort: "number",
  },
  {
    id: "activite",
    label: "ACTIVITE",
    valueSort: "name",
  },
  {
    id: "pourcentage",
    label: "POURCENTAGE",
    valueSort: "percent",
  },
  {
    id: "definition",
    label: "definition",
    valueSort: "",
  },
];

interface EnhancedTableTopProps {
  onRequestSort: (valueSort: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableTop(props: EnhancedTableTopProps) {
  const { onSelectAllClick, onRequestSort } = props;
  const createSortHandler = (valueSort: string) => {
    if (valueSort) onRequestSort(valueSort);
  };

  const classes = useStyles();
  const textStyles = TextStyles();

  const formQueryTableHeight = 84;
  // https://github.com/mui/material-ui/issues/23090#issuecomment-709986122

  const getWidth = (index: number) => {
    return index === 0
      ? "10%"
      : index === 1
      ? "30%"
      : index === 2
      ? "15"
      : "45%";
  };

  return (
    <TableRow>
      {headCells.map((headCell, index: number) => (
        <TableCell
          key={headCell.id}
          align={index > 1 ? "left" : "center"}
          className={classes.headerTable}
          sx={{
            borderBottom: "unset",
            py: 0,
            width: getWidth(index),
            top: formQueryTableHeight,
            background: "#F3F2F7",
            zIndex: 1,
          }}
        >
          {index === 1 ? (
            <Box component="div" display="flex">
              <Box
                component="div"
                sx={{
                  borderBottom: "unset",
                  pl: 3,
                  pr: 8,
                  py: 1,
                }}
                display="flex"
                alignItems="center"
              >
                <Box component="div" display="none">
                  <CheckboxCustom onChange={onSelectAllClick} />
                </Box>
              </Box>
              <TableSortLabel
                onClick={() => createSortHandler(headCell.valueSort)}
                hideSortIcon={true}
              >
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  sx={{ my: 1 }}
                >
                  <Typography
                    className={textStyles.paragraph_small_semi_bold}
                    sx={{
                      color: "#5E5873",
                      textTransform: "uppercase",
                    }}
                  >
                    {headCell.label}
                  </Typography>
                  <FilterTableHead />
                </Box>
              </TableSortLabel>
            </Box>
          ) : (
            <TableSortLabel
              onClick={() => createSortHandler(headCell.valueSort)}
              hideSortIcon={true}
              sx={{ px: 0 }}
            >
              <Box component="div" display="flex" alignItems="center">
                <Typography
                  className={textStyles.paragraph_small_semi_bold}
                  sx={{
                    color: "#5E5873",
                    textTransform: "uppercase",
                    ml: index === 0 ? 1 : 0,
                  }}
                >
                  {headCell.label}
                </Typography>
              </Box>
              {index !== 3 && <FilterTableHead />}
            </TableSortLabel>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

const Activities = () => {
  const textStyles = TextStyles();
  const classes = useStyles();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [totalPercent, setTotalPercent] = useState<number>(0);
  const [errorTotalPercent, setErrorTotalPercent] = useState<boolean>(false);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);
  const tableRef = useRef<any>(null);

  const dispatch = useDispatch();

  const totalFilteredActivities = useSelector(selectTotalFilteredActivities);
  const activitiesPerPage = useSelector(selectActivitiesPerPage);
  const currentIdProfession = useSelector(selectCurrentIdProfession);

  const filtersActivities = useSelector(selectFiltersActivities);

  const activitiesRedux = useSelector(
    (state: IAppState) => state.activitiesRedux.activities
  );

  const { error } = useSelector((state: IAppState) => state.dataActivities);

  const loadingGetActivities = useSelector(
    (state: IAppState) => state.dataActivities.loading
  );

  const { detail_company } = useSelector(
    (state: IAppState) => state.dataSearchCompany
  );

  const responseDraftContract = useSelector(
    (state: IAppState) => state.dataContract.response
  );

  const { info_contract, loading } = useSelector(
    (state: IAppState) => state.dataContract
  );

  const idContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.idContractUpdate
  );

  const dataContractUpdate = useSelector(
    (state: IAppState) => state.dataContract.data_contract_update
  );

  const { selected_type } = useSelector(
    (state: IAppState) => state.responseCreateCompany
  );

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Variables
  const currentProductType =
    (dataContractUpdate?.type ?? selected_type) === ContractType.Artisans_BTP
      ? ContractType.Artisans_BTP
      : ContractType.PIB;

  const isPIB = currentProductType === ContractType.PIB;

  const lengthActivitiesCanSelect = isPIB ? 4 : 12;

  const activitiesPerPageLength = activitiesPerPage.length;

  // May smaller than `rowsPerPage` when search result length < `rowsPerPage`
  const currentActivitiesDisplay =
    filtersActivities.rowsPerPage > activitiesPerPageLength
      ? activitiesPerPageLength
      : filtersActivities.rowsPerPage;

  // Handlers
  const handleRequestSort = (newSortValue: FiltersActivitiesSortValue) => {
    const newSortBy = filtersActivities.sortBy;

    if (filtersActivities.sortBy.sortValue === newSortValue) {
      newSortBy.type = newSortBy.type === "asc" ? "desc" : "asc";
    } else {
      newSortBy.sortValue = newSortValue;
      newSortBy.type = "desc";
    }

    scrollTableToTopWhenFiltersChange();

    dispatch(
      changeActivitiesFilters({
        sortBy: newSortBy,
        page: 1,
      })
    );
  };

  const scrollTableToTopWhenFiltersChange = () => {
    tableRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleChangeSearchInput = (searchTerm: string) => {
    scrollTableToTopWhenFiltersChange();

    dispatch(
      changeActivitiesFilters({
        searchTerm,
        page: 1,
      })
    );
  };

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {};

  const handleChangeRowsPerPage = (newRow: number) => {
    if (newRow < filtersActivities.rowsPerPage) {
      scrollTableToTopWhenFiltersChange();
    }

    dispatch(
      changeActivitiesFilters({
        rowsPerPage: newRow,
        page: 1,
      })
    );
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const checkBETActivity = (activity: string) => activity.indexOf("BET") === 0;

  // Select up to 4 activities (PIB, max 2 non BET), up to 12 activities (Artisans_BTP)
  const handleSelect = (activity: IActivityOfContract, index: number) => {
    let checkNonBET = 0;
    for (let i = 0; i < activitiesRedux.length; i++) {
      if (!checkBETActivity(activitiesRedux[i].name)) checkNonBET++;
    }
    if (!isSelected(activity._id)) {
      // PIB rules only
      if (isPIB) {
        if (checkNonBET === 2 && !checkBETActivity(activity.name)) return;
        // limit amount select
        if (activitiesRedux.length === lengthActivitiesCanSelect) return;
      }

      // add activity into activities redux
      setSelected([...selected, activity._id]);
      const newActivity: IActivityRedux = {
        id: activity._id,
        percent: 0,
        name: activity.name,
      };
      dispatch(addActivity(newActivity));
    } else {
      // remove activity from activities redux
      const newSelecteds = selected.filter((n) => n !== activity._id);
      setSelected(newSelecteds);
      dispatch(removeActivity(activity._id));

      // reset value of activity
      setValue(
        `activity_${
          (filtersActivities.page - 1) * activitiesPerPageLength + (index + 1)
        }`,
        ""
      );
    }
  };

  const findActivity = (
    activities: Array<IActivityRedux>,
    idActivity: string
  ) => {
    return activities.findIndex(
      (activity: IActivityRedux) => activity.id === idActivity
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdate = useCallback(
    debounce((nextValue) => dispatch(updateActivity(nextValue)), 500),
    []
  );

  // Change input activity
  const handleChangeInput = (value: any, idActivity: string, name: string) => {
    if (isNumberic(value)) {
      const updateItemActivity: IActivityRedux = {
        id: idActivity,
        percent: parseInt(value),
        name,
      };
      debounceUpdate(updateItemActivity);
    }
  };

  // Update/save contract or (save to redux + navigate to next step)
  const onSubmit = (value: any) => {
    if (totalPercent === 100) {
      const newDraftContract = {
        ...info_contract,
        companyId: detail_company?._id,
        activities: [...activitiesRedux],
      };

      if (isSaveDraft) {
        newDraftContract.type = currentProductType;

        if (idContractUpdate) {
          dispatch(updateDraftContract(newDraftContract, idContractUpdate));
        } else {
          dispatch(saveDraftContract(newDraftContract));
        }
      } else {
        dispatch(saveInfoContractIntoRedux(newDraftContract));
        navigate("/contract/insurance-history");
      }
    } else {
      setErrorTotalPercent(true);
    }
  };

  // To make a infinite scroll
  const observer = useRef<any>();
  const lastActivityRef = useCallback(
    (node) => {
      if (observer?.current) observer.current.disconnect();

      const option = {
        root: null,
        threshold: 0.9,
      };

      observer.current = new IntersectionObserver((entries) => {
        const oldRowsPerPage = filtersActivities.rowsPerPage;
        const newRowsPerPage =
          oldRowsPerPage + 5 > totalFilteredActivities
            ? totalFilteredActivities
            : oldRowsPerPage + 5;
        const hasMore = oldRowsPerPage < totalFilteredActivities;

        if (entries[0].isIntersecting && hasMore) {
          const page =
            newRowsPerPage === totalFilteredActivities
              ? 1
              : filtersActivities.page;
          setTimeout(() => {
            dispatch(
              changeActivitiesFilters({
                rowsPerPage: newRowsPerPage,
                page,
              })
            );
          }, 100);
        }
      }, option);

      if (node) observer?.current?.observe(node);
    },
    [
      dispatch,
      filtersActivities.page,
      filtersActivities.rowsPerPage,
      totalFilteredActivities,
    ]
  );

  // get activities
  useEffect(() => {
    const idProfession =
      (currentProductType === ContractType.Artisans_BTP
        ? detail_company?.professionIds?.[1]
        : detail_company?.professionIds?.[0]) ?? IdProfessionType.PIB;

    // Early return if activities data is already exist and type is the same
    if (activitiesPerPage && currentIdProfession === idProfession) return;

    dispatch(
      getActivitiesContract({
        idProfession,
        sortBy: { name: 1 },
      })
    );

    dispatch(saveCurrentIdProfession(idProfession));
    dispatch(changeActivitiesFilters(initActivitiesFilters));
  }, [
    activitiesPerPage,
    currentIdProfession,
    currentProductType,
    detail_company?.professionIds,
    dispatch,
  ]);

  // update total selected percentage
  useEffect(() => {
    let total: number = 0;
    let arraySelected: string[] = [];

    activitiesRedux.forEach((activity) => {
      if (activity?.percent) {
        total += activity.percent;
      }
      arraySelected.push(activity.id);
    });

    setTotalPercent(total);
    setErrorTotalPercent(false);
    setSelected(arraySelected);
  }, [activitiesRedux]);

  // catch and show error
  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  // check and set value for selected activity.
  useEffect(() => {
    for (let i = 0; i < activitiesPerPageLength; i++) {
      const isActivitySelected =
        findActivity(activitiesRedux, activitiesPerPage[i]._id) !== -1;

      const activitySelected =
        activitiesRedux[
          findActivity(activitiesRedux, activitiesPerPage[i]._id)
        ];

      if (isActivitySelected) {
        setValue(
          `activity_${
            (filtersActivities.page - 1) * activitiesPerPageLength + (i + 1)
          }`,
          activitySelected?.percent
        );
      } else {
        setValue(
          `activity_${
            (filtersActivities.page - 1) * activitiesPerPageLength + (i + 1)
          }`,
          ""
        );
      }
    }
  }, [
    activitiesPerPage,
    activitiesPerPageLength,
    activitiesRedux,
    filtersActivities.page,
    setValue,
  ]);

  // Notify and navigate after save draft
  useEffect(() => {
    if (responseDraftContract) {
      !idContractUpdate && notistack.success("Création de brouillon réussie.");
      idContractUpdate && notistack.success("Projet de mise à jour réussi.");
      navigate("/select-type");
    }
  }, [responseDraftContract, idContractUpdate, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ mb: 3 }} className={textStyles.h3}>
        Les activités de la société{" "}
      </Typography>
      <PaperShadowCustom sx={{ pb: 3, position: "relative" }}>
        {loadingGetActivities && <LinearLoading />}
        <TableContainer sx={{ maxHeight: 600 }} ref={tableRef}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <TableHead>
              <FormQueryTableHead
                selected={activitiesRedux.length}
                maxSelected={lengthActivitiesCanSelect}
                totalPercent={totalPercent}
                errorTotalPercent={errorTotalPercent}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangeSearchInput={handleChangeSearchInput}
              />

              <EnhancedTableTop
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />
            </TableHead>

            <TableBody>
              {activitiesPerPage?.map(
                (activity: IActivityOfContract, index: number) => {
                  const activitySelected =
                    findActivity(activitiesRedux, activity._id) !== -1;

                  const isLastActivity = activitiesPerPage.length === index + 1;

                  return (
                    <Fragment key={activity._id}>
                      <TableRow role="checkbox" tabIndex={-1}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          sx={{
                            borderBottom: "1px solid #E9ECEF",
                            px: 0,
                            py: 0,
                          }}
                        >
                          <Box
                            component="div"
                            sx={{ borderBottom: "unset" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography className={textStyles.paragraph}>
                              {activity.seq_no}
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          sx={{
                            borderBottom: "1px solid #E9ECEF",
                            px: 0,
                            py: 0,
                          }}
                        >
                          <Box
                            component="div"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              component="div"
                              sx={{ borderBottom: "unset", pl: 3, pr: 5 }}
                              display="flex"
                              alignItems="center"
                            >
                              <CheckboxCustom
                                onChange={() => handleSelect(activity, index)}
                                checked={activitySelected}
                              />
                            </Box>
                            <Typography
                              className={textStyles.paragraph}
                              sx={{ p: 1 }}
                            >
                              {activity.name}
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid #E9ECEF",
                            px: 0,
                            py: 2,
                          }}
                        >
                          <Box
                            component="div"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              component="div"
                              display="flex"
                              alignItems="center"
                              sx={{ width: "60%" }}
                            >
                              <Controller
                                name={`activity_${
                                  (filtersActivities.page - 1) *
                                    filtersActivities.rowsPerPage +
                                  (index + 1)
                                }`}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  const isDisabledInput = !activitySelected;

                                  return (
                                    <NumberInputCustom
                                      value={
                                        value
                                          ? formatCustomInput(value, "number")
                                          : value ?? ""
                                      }
                                      disabled={isDisabledInput}
                                      placeholder={isDisabledInput ? "-" : ""}
                                      onChange={(e) => {
                                        onChange(e);
                                        if (e.target.value) {
                                          handleChangeInput(
                                            e.target.value,
                                            activity._id,
                                            activity.name
                                          );
                                        } else {
                                          debounceUpdate({
                                            id: activity._id,
                                            value: 0,
                                          });
                                        }
                                      }}
                                      error={
                                        errors?.[
                                          `activity_${
                                            (filtersActivities.page - 1) *
                                              filtersActivities.rowsPerPage +
                                            (index + 1)
                                          }`
                                        ] &&
                                        errors?.[
                                          `activity_${
                                            (filtersActivities.page - 1) *
                                              filtersActivities.rowsPerPage +
                                            (index + 1)
                                          }`
                                        ]?.message
                                      }
                                    />
                                  );
                                }}
                                rules={{
                                  min: {
                                    value: 1,
                                    message: "Valeur invalide.",
                                  },
                                  max: {
                                    value: 100,
                                    message: "Valeur invalide.",
                                  },
                                  pattern: {
                                    value: /^[1-9]/,
                                    message: "Mauvais format",
                                  },
                                  required: {
                                    value: activitySelected,
                                    message: "Ce champ est requis.",
                                  },
                                }}
                              />
                            </Box>
                            <Spacing width={15} />
                            <Typography
                              className={textStyles.paragraph_small_semi_bold}
                              sx={{ color: "#5E5873" }}
                            >
                              %
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid #E9ECEF",
                            px: 0,
                            py: 2,
                          }}
                        >
                          <TooltipCustom
                            title={
                              activity.definition && parse(activity.definition)
                            }
                            maxWidth="538px"
                          >
                            <Box
                              className={classes.boxDescription}
                              component="div"
                              sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                pr: 2,
                              }}
                            >
                              <Box className={textStyles.paragraph}>
                                {activity.definition &&
                                  parse(activity.definition)}
                              </Box>
                            </Box>
                          </TooltipCustom>
                        </TableCell>
                      </TableRow>

                      {isLastActivity &&
                        activitiesPerPage.length < totalFilteredActivities && (
                          <TableRow sx={{ height: 100 }} ref={lastActivityRef}>
                            <TableCell sx={{ borderBottom: "unset" }} />
                            <TableCell sx={{ borderBottom: "unset" }} />
                            <TableCell sx={{ borderBottom: "unset" }}>
                              <Typography
                                className={textStyles.paragraph}
                                sx={{
                                  p: 1,
                                  textAlign: "center",
                                  fontWeight: "bold !important",
                                }}
                              >
                                Défiler vers le bas...
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "unset" }} />
                          </TableRow>
                        )}
                    </Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ my: 3 }}
        >
          <Stack flexDirection="row" alignItems="center" mr={8}>
            <Typography
              className={textStyles.paragraph}
              sx={{ color: "#B9B9C3" }}
            >
              {`${currentActivitiesDisplay} éléments sur ${totalFilteredActivities}`}
            </Typography>
          </Stack>
        </Stack>
      </PaperShadowCustom>
      <Box
        component="div"
        style={{ width: 400 }}
        sx={{
          mr: "auto",
          ml: "auto",
          mt: 6,
        }}
      >
        <Box display="flex">
          <ButtonCustom
            title="Précédent"
            color={Colors.primary}
            startIcon={
              <span
                style={{
                  margin: 0,
                  display: "flex",
                  transform: "rotateY(180deg)",
                  marginRight: 6,
                }}
              >
                <NextIcon color={Colors.primary} />
              </span>
            }
            backgroundColor="#B9B9C3"
            variant="outlined"
            onClick={() => navigate("/contract")}
          />
          <Spacing width={55} />
          <ButtonCustom
            type="submit"
            title="Suivant"
            endIcon={<NextIcon color="#fff" />}
            onClick={() => setIsSaveDraft(false)}
          />
        </Box>
        <Spacing height={35} />
        <Box
          width={225}
          sx={{
            mr: "auto",
            ml: "auto",
          }}
        >
          <ButtonCustom
            type="submit"
            backgroundColor="#B9B9C3"
            variant="outlined"
            title="Enregistrer en brouillon"
            color={Colors.primary}
            onClick={() => setIsSaveDraft(true)}
            endIcon={
              loading && (
                <CircularProgress size={18} style={{ color: Colors.primary }} />
              )
            }
          />
        </Box>
      </Box>

      <Spacing height={100} />
    </form>
  );
};

const useStyles = makeStyles({
  headerTable: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "& > .MuiBox-root": {
      "& > .MuiButtonBase-root": {
        "& > .MuiSvgIcon-root": {
          display: "none",
        },
      },
    },
  },
  boxDescription: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export default Activities;
