import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage3Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage3: FC<IContentPage3Props> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <View break>
      <View style={styles.specialTable}>
        <View style={styles.headerSpecialTable}>
          <Text style={styles.titleHeaderTable}>
            {isPIB
              ? "DECLARATIONS DE L’ASSURE"
              : "INFORMATIONS DU SOUSCRIPTEUR"}
          </Text>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Avez-vous déjà été assuré ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_2?.hasRCD}
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Avez-vous un contrat en cours ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_2?.contrat_en_cours}
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Quelle est la date de réalisation du dernier contrat?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.date_de_resiliation
              }
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Nombre de sinistre déclaré dans les 24 derniers mois ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.assure_lors_des_24_derniers_mois
              }
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>Pour un montant de :</Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.pour_un_montant_de
              }
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Dans les 24 derniers mois, avez-vous été résilié non paiement ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.esilie_non_paiement
              }
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={styles.contentTablePage2}>
              Votre contrat a-t-il déjà été annulé / résilié pour fausse
              déclaration ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.resilie_pour_fausse_declaration
              }
            </Text>
          </View>
        </View>

        {isPIB ? (
          <View>
            <View style={styles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={styles.contentTablePage2}>
                  Effectuez-vous plus de 30% de sous-traitance ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={styles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.de_sous_traitance
                  }
                </Text>
              </View>
            </View>
            <View style={styles.itemTableEven}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={styles.contentTablePage2}>
                  Etes-vous en redressement judiciaire ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={styles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.en_redressement_judiciaire
                  }
                </Text>
              </View>
            </View>
          </View>
        ) : null}

        {!isPIB ? (
          <View>
            <View style={styles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={styles.contentTablePage2}>
                  Confiez-vous plus de 30% de sous-traitance ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={styles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.de_sous_traitance
                  }
                </Text>
              </View>
            </View>

            <View style={styles.itemTableEven}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={styles.contentTablePage2}>
                  Etes-vous en redressement judiciaire ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={styles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.en_redressement_judiciaire
                  }
                </Text>
              </View>
            </View>

            <View style={styles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={styles.contentTablePage2}>
                  Souhaitez-vous la reprise du passé ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={styles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.reprise_du_passe
                  }
                </Text>
              </View>
            </View>
          </View>
        ) : null}
      </View>
      <View style={{ height: 10 }} />
      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 300,
          color: "#FF5C5D",
          fontSize: 8,
          lineHeight: 1.5,
        }}
      >
        Ces éléments sont essentiels et déterminants pour le consentement de
        l’Assureur quant au contrat et au tarif proposé.
      </Text>
      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 300,
          color: "#FF5C5D",
          fontSize: 8,
          lineHeight: 1.5,
        }}
      >
        Toute fausse déclaration ou déclaration inexacte sera sanctionnée par la
        nullité du contrat ou la réduction proportionnelle de l’indemnité
        conformément aux dispositions des articles L.113-8 et L.113-9 du Code
        des assurances.
      </Text>

      {isPIB ? (
        <View>
          <View style={styles.titleBody}>
            <Text
              style={{
                fontSize: 12,
                color: Colors.primary,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Objet de la garantie proposée
            </Text>
          </View>
          <View>
            <View>
              <Text style={styles.titleBoxSubContent}>
                Nature de la garantie
              </Text>
              <View style={styles.underlineTitleBoxContent} />
            </View>
            <View style={{ marginTop: 12, paddingRight: 20 }}>
              <Text style={styles.textUnderline}>
                Responsabilité civile décennale obligatoire :
              </Text>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  Le contrat proposé garantit la responsabilité décennale du
                  souscripteur instaurée par les articles 1792 et suivants du
                  Code civil, dans le cadre et les limites prévues par les
                  dispositions des articles L.241-1 et L.241-2 du Code des
                  assurances relatives à l’obligation d’assurance décennale, et
                  pour des travaux de construction d’ouvrages qui y sont soumis,
                  au regard de l’article L.243-1-1 du même code.
                </Text>
              </View>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  La garantie couvre les travaux de réparation, notamment en cas
                  de remplacement des ouvrages, qui comprennent également les
                  travaux de démolition, déblaiement, dépose ou de démontage
                  éventuellement nécessaires.
                </Text>
              </View>
            </View>
            <View style={{ marginTop: 15, paddingRight: 20 }}>
              <Text style={styles.textUnderline}>
                Responsabilité civile professionnelle :
              </Text>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  La garantie proposée couvre les conséquences pécuniaires de la
                  responsabilité civile du souscripteur pour les dommages
                  corporels, matériels et immatériels causés aux tiers et
                  résultant de ses activités professionnelles déclarées.
                  Conformément aux dispositions de l'article L 124-5 alinéas 4
                  et 5 du Code des assurances, le contrat est établi en "base
                  réclamation" pour ces chapitres du contrat.
                </Text>
              </View>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    padding: "4px 0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginTop: 40,
    marginBottom: 25,
    padding: "8px 0px",
  },
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  underlineTitleBoxContent: {
    width: "69px",
    height: "4px",
    backgroundColor: "#FFCC4D",
    borderRadius: 5,
    marginTop: 4,
  },
  textUnderline: {
    fontFamily: "Montserrat",
    color: "#FFCC4D",
    fontSize: 10,
    textDecoration: "underline",
    fontWeight: 500,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage3;
