import React, { FC } from "react";

interface SpacingProps {
  width?: number;
  height?: number;
}

const Spacing: FC<SpacingProps> = ({ width, height = 12 }) => (
  <div style={{ width, height }} />
);

export default Spacing;
