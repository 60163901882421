// import { useCheckToken } from "@hooks/useCheckToken";
import { FC } from "react";
import { Navigate } from "react-router-dom";
export interface IProtectedPageProps {
  role?: string;
}

export const ProtectedPage: FC<IProtectedPageProps> = (props) => {
  const token = localStorage.getItem("AccessToken");

  return token ? <>{props.children}</> : <Navigate to={"/login"} />;
};
