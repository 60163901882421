import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import formatMoney from "@utils/formatMoney";
import * as _ from "lodash";
import parse from "html-react-parser";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
}

const ContentPage2: FC<IContentPage1Props> = ({ infoInsurance }) => {
  return (
    <View break>
      <View style={{ marginBottom: 40 }}>
        <View style={{ marginBottom: 20 }}>
          <Text style={styles.mainTitle}>Professions Déclarées</Text>
          <View style={styles.underlineMainTitle} />
        </View>
        <View style={styles.specialTable}>
          <View style={styles.headerSpecialTable}>
            <View style={{ flex: 0.3 }}>
              <Text style={styles.titleHeaderTable}>N°</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />
            <View style={{ flex: 1.2 }}>
              <Text style={styles.titleHeaderTable}>Activités</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />
            <View style={{ flex: 0.8 }}>
              <Text style={styles.titleHeaderTable}>Franchises</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />
            <View style={{ flex: 1.4 }}>
              <Text style={styles.titleHeaderTable}>Définitions</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />
          </View>
          {!_.isEmpty(infoInsurance?.activities) &&
            _.isArray(infoInsurance?.activities) &&
            infoInsurance?.activities.map((item, index) => (
              <View
                key={index}
                style={[
                  index % 2 === 0 ? styles.itemTableOdd : styles.itemTableEven,
                ]}
              >
                <View style={{ flex: 0.3 }}>
                  <Text style={styles.textRadio}>{index + 1}</Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />
                <View style={{ flex: 1.2 }}>
                  <Text style={styles.textMoney}>{item?.name}</Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />
                <View style={{ flex: 0.8 }}>
                  <Text style={styles.textMoney}>
                    {infoInsurance?.page6?.amount_franchise_3 &&
                      formatMoney(infoInsurance.franchise_du_contrat)}{" "}
                    €
                  </Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />
                <View style={{ flex: 1.4 }}>
                  <Text style={styles.textMoney}>
                    {item?.definition && parse(item.definition)}
                  </Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />
              </View>
            ))}
        </View>
      </View>
      <View>
        <Text style={styles.mainTitle}>Conditions de garantie</Text>
        <View style={styles.underlineMainTitle} />
      </View>
      <View style={{ margin: "15px 0" }}>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text
            style={[
              styles.mainContent,
              { fontWeight: 500, textDecoration: "underline" },
            ]}
          >
            Seules sont assurées les entreprises dont le Chiffre d’Affaires ne
            dépasse pas 5.000.000 € (HT) et l’effectif ne dépasse pas 49
            employés. La garantie est également limitée aux marchés dont le coût
            total de construction est inférieur à 15.000.000 € (HT) et pour
            lesquels les honoraires de l'Assuré ne dépassent pas 500.000 € (HT).
            Ces conditions cumulatives sont substantielles et déterminantes de
            l’engagement de l’assureur et de la mobilisation des garanties.
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            En cas de sous-traitance (limitée à 30% de l’activité sauf accord
            exprès de l’Assureur), la garantie est conditionnée à la production
            par l’assuré des attestations RC professionnelle et RC décennale du
            sous-traitant couvrant les activités réellement sous-traitées
            pendant la période de réalisation du chantier. Ces conditions
            cumulatives sont substantielles et déterminantes de l’engagement de
            l’assureur et de la mobilisation des garanties.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 12,
    color: "#5E5873",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  underlineMainTitle: {
    width: "69px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginBottom: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "left",
    paddingLeft: 8,
    paddingVertical: 15,
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  textFranchise: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  textRadio: {
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#5E5873",
    padding: 8,
  },
  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
  },
});

export default ContentPage2;
