import * as React from "react";

const WarningIcon = () => (
  <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.333 40A36.561 36.561 0 0 0 40 76.667 36.561 36.561 0 0 0 76.667 40 36.561 36.561 0 0 0 40 3.333 36.561 36.561 0 0 0 3.334 40ZM10 40c0-16.667 13.334-30 30-30 16.667 0 30 13.333 30 30S56.667 70 40 70c-16.666 0-30-13.333-30-30Zm33.334 0V26.667c0-2-1.334-3.334-3.334-3.334s-3.333 1.334-3.333 3.334V40c0 2 1.333 3.333 3.333 3.333S43.334 42 43.334 40ZM43 54.667c0 .333-.333.666-.666 1-.667.666-1.334 1-2.667 1-1 0-1.667-.334-2.333-1-.667-.667-1-1.334-1-2.334 0-.39.114-.666.209-.895.067-.162.124-.3.124-.438 0-.333.333-.667.666-1 1-1 2.334-1.333 3.667-.667.167 0 .25.084.334.167.083.083.166.167.333.167.333 0 .667.333.667.333.166.167.25.333.333.5.083.167.167.333.333.5.334.333.334 1 .334 1.333 0 .167-.084.417-.167.667-.083.25-.167.5-.167.667Z"
      fill="#000"
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={3}
      width={74}
      height={74}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 40A36.561 36.561 0 0 0 40 76.667 36.561 36.561 0 0 0 76.667 40 36.561 36.561 0 0 0 40 3.333 36.561 36.561 0 0 0 3.334 40ZM10 40c0-16.667 13.334-30 30-30 16.667 0 30 13.333 30 30S56.667 70 40 70c-16.666 0-30-13.333-30-30Zm33.334 0V26.667c0-2-1.334-3.334-3.334-3.334s-3.333 1.334-3.333 3.334V40c0 2 1.333 3.333 3.333 3.333S43.334 42 43.334 40ZM43 54.667c0 .333-.333.666-.666 1-.667.666-1.334 1-2.667 1-1 0-1.667-.334-2.333-1-.667-.667-1-1.334-1-2.334 0-.39.114-.666.209-.895.067-.162.124-.3.124-.438 0-.333.333-.667.666-1 1-1 2.334-1.333 3.667-.667.167 0 .25.084.334.167.083.083.166.167.333.167.333 0 .667.333.667.333.166.167.25.333.333.5.083.167.167.333.333.5.334.333.334 1 .334 1.333 0 .167-.084.417-.167.667-.083.25-.167.5-.167.667Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="#FF9F43" d="M0 0h80v80H0z" />
    </g>
  </svg>
);

export default WarningIcon;
