/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { BaseUrl } from "@constants/handleRequest";
import axios from "axios";
import { notistack } from "./notistack";

const clientRequest = axios.create({
  baseURL: BaseUrl.dev,
  responseType: "json",
  timeout: 15 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

clientRequest.interceptors.request.use(async (config: any) => {
  const accessToken = await localStorage.getItem("AccessToken");
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});
clientRequest.interceptors.response.use(
  (response) => {
    return new Promise((resolve, rejects) => {
      if (response.status === 401) {
        localStorage.removeItem("AccessToken");
        window.location.href = "/login";
      }
      if (response.status === 404) {
        rejects(response);
      }
      resolve(response);
    });
  },
  (error) => {
    return new Promise((_resolve, rejects) => {
      if (error?.response?.status === 401) {
        notistack.error("Session expired, please login again");
        localStorage.removeItem("AccessToken");
        setTimeout(() => (window.location.href = "/login"), 2000);
      }
      if (error) {
        rejects(error);
      }
    });
  }
);

export default clientRequest;
