import React, { FC } from "react";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextStyles } from "@themes/textStyles";
import ButtonCustom from "@components/ButtonCustom";
import { Colors } from "@themes/colors";
import Spacing from "@components/Spacing";

interface IConfirmDialogProps {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  titleCancel: string;
  titleConfirm: string;
  titleDialog: string;
}

const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  titleCancel,
  titleConfirm,
  titleDialog,
}) => {
  const textStyles = TextStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 6,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ my: 2, px: 4 }}>
        <Typography className={textStyles.h4}>{titleDialog}</Typography>
      </Box>
      <Box
        display="flex"
        sx={{ mt: 2, mb: 3, px: 4 }}
        justifyContent="flex-end"
      >
        <Box component="div" width={85}>
          <ButtonCustom
            onClick={onClose}
            title={titleCancel}
            backgroundColor={Colors.secondary}
          />
        </Box>
        <Spacing width={12} />
        <Box width={150}>
          <ButtonCustom
            onClick={onConfirm}
            title={titleConfirm}
            backgroundColor={Colors.primary}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
