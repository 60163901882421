import { IUsersState } from "./users.dto";
import { UsersActions, UsersTypes } from "./users.type";

const initialUsersState: IUsersState = {
  dataUsers: {
    total: 0,
    page: 1,
    last_page: 1,
    limit: 10,
  },
  loadingGet: false,
  idUserUpdate: undefined,
};

export function usersReducer(
  state = initialUsersState,
  action: UsersActions
): IUsersState {
  switch (action.type) {
    case UsersTypes.GET_USERS_REQUESTED: {
      return {
        ...state,
        loadingGet: true,
      };
    }

    case UsersTypes.GET_USERS_SUCCEEDED: {
      return {
        ...state,
        loadingGet: false,
        dataUsers: action.dataUsers,
      };
    }

    case UsersTypes.GET_USERS_FAILED: {
      return {
        ...state,
        loadingGet: false,
        error: action?.error,
      };
    }

    case UsersTypes.GET_DETAIL_USER_REQUESTED: {
      return {
        ...state,
        loadingGet: true,
        idUserUpdate: action?.id,
      };
    }

    case UsersTypes.GET_DETAIL_USER_SUCCEEDED: {
      return {
        ...state,
        loadingGet: false,
        detailUser: action.dataUser,
      };
    }

    case UsersTypes.GET_DETAIL_USER_FAILED: {
      return {
        ...state,
        loadingGet: false,
        error: action?.error,
      };
    }

    case UsersTypes.DELETE_USER_REQUESTED: {
      return {
        ...state,
        loadingDelete: true,
      };
    }

    case UsersTypes.DELETE_USER_SUCCEEDED: {
      return {
        ...state,
        loadingDelete: false,
        message: action?.message,
      };
    }

    case UsersTypes.DELETE_USER_FAILED: {
      return {
        ...state,
        loadingDelete: false,
        error: action?.error,
      };
    }

    case UsersTypes.UPDATE_USER_REQUESTED: {
      return {
        ...state,
        loadingCreate: true,
      };
    }

    case UsersTypes.UPDATE_USER_SUCCEEDED: {
      return {
        ...state,
        loadingCreate: false,
        message: action?.message,
      };
    }

    case UsersTypes.UPDATE_USER_FAILED: {
      return {
        ...state,
        loadingCreate: false,
        error: action?.error,
      };
    }

    case UsersTypes.CREATE_USER_REQUESTED: {
      return {
        ...state,
        loadingCreate: true,
      };
    }

    case UsersTypes.CREATE_USER_SUCCEEDED: {
      return {
        ...state,
        loadingCreate: false,
        message: action?.message,
      };
    }

    case UsersTypes.CREATE_USER_FAILED: {
      return {
        ...state,
        loadingCreate: false,
        error: action?.error,
      };
    }

    case UsersTypes.CLEAR_USER_UPDATE: {
      return {
        ...state,
        idUserUpdate: undefined,
        detailUser: undefined,
      };
    }

    case UsersTypes.CLEAR_MESSAGE_RESPONSE: {
      return {
        ...state,
        loadingGet: false,
        loadingDelete: false,
        error: undefined,
        message: undefined,
      };
    }

    default:
      return state;
  }
}
