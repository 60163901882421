import TopBar from "@components/TopBar";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

const ManagementUsers = () => {
  return (
    <Grid container>
      <TopBar />

      <Grid item xs={12} sx={{ mt: 3 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default ManagementUsers;
