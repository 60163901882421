import * as React from "react";

const SearchIcon: React.FC<{ color: string }> = ({ color = "#000" }) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.034 8.217 2.625 2.625a.564.564 0 0 1 0 .817.63.63 0 0 1-.409.175.63.63 0 0 1-.408-.175L8.217 9.034c-.875.641-1.925 1.05-3.092 1.05A4.954 4.954 0 0 1 .167 5.125 4.954 4.954 0 0 1 5.125.167a4.954 4.954 0 0 1 4.959 4.958 5.066 5.066 0 0 1-1.05 3.092ZM5.125 1.334a3.784 3.784 0 0 0-3.791 3.791c0 2.1 1.691 3.792 3.791 3.792 1.05 0 1.984-.408 2.684-1.108.7-.7 1.108-1.634 1.108-2.684 0-2.1-1.692-3.791-3.792-3.791Z"
      fill={color}
    />
  </svg>
);

export default SearchIcon;
