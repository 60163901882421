import Logo from "@assets/Images/logo_dashboard.jpg";
import BackgroundForgotPass from "@assets/SvgComponents/BackgroundForgotPass";
import IconBack from "@assets/SvgComponents/IconBack";
import ButtonCustom from "@components/ButtonCustom";
import InputCustom from "@components/InputCustom";
import Spacing from "@components/Spacing";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { requestEmailRequested } from "@stores/login/login.creator";
import { RequestEmailType } from "@stores/login/login.dto";
import { IAppState } from "@stores/state";
import { notistack } from "@utils/notistack";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors } from "themes/colors";
import { TextStyles } from "themes/textStyles";

const COUNT_DOWN_TIME = 60;

interface ForgotPassProps {
  onBack?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ForgotPass: FC<ForgotPassProps> = ({ onBack = () => {} }) => {
  const [countDown, setCountDown] = useState<number>(COUNT_DOWN_TIME);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const classes = useStyles();
  const textStyles = TextStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error } = useSelector(
    (state: IAppState) => state.responseLogin
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (formValue: any) => {
    setIsSubmitted(true);
    dispatch(
      requestEmailRequested({
        email: formValue?.email,
        type: RequestEmailType.FORGOT,
      })
    );
  };

  useEffect(() => {
    if (!isSubmitted) return;

    const timerId = setTimeout(() => {
      if (countDown === 1) {
        setCountDown(COUNT_DOWN_TIME);
        clearTimeout(timerId);
        setIsSubmitted(false);
        return;
      }
      setCountDown((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [countDown, isSubmitted]);

  useEffect(() => {
    message && notistack.success(message);
  }, [message, navigate]);

  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        style={{ background: "#F8F8F8" }}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>
          <BackgroundForgotPass />
        </p>
        <p className={classes.logo}>
          <img width="100%" height="100%" src={Logo} alt="" />
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        sx={{ pl: 10, pr: 10 }}
      >
        <Typography className={textStyles.h2}>
          Mot de passe oublié ? 🔑
        </Typography>
        <Typography className={textStyles.paragraph}>
          Entrez vous email et nous vous enverrons un lien pour créer votre
          nouveau mot de passe
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputCustom
                onChange={onChange}
                value={value}
                label="Email"
                placeholder="johndoe@gmail.com"
                error={errors?.email && errors?.email?.message}
                autoComplete="username"
                sizeLabel="sm"
              />
            )}
            rules={{
              required: "L'e-mail est requis.",
              pattern: {
                value:
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "Email invalide.",
              },
            }}
          />
          <Spacing height={16} />
          <Box component="div">
            <ButtonCustom
              title={
                isSubmitted
                  ? `Vous pouvez réessayer dans ${countDown
                      .toString()
                      .padStart(2, "0")} secondes`
                  : "Envoyer le lien de réinitialisation"
              }
              type="submit"
              disabled={isSubmitted}
            />
          </Box>
        </form>
        <Spacing height={20} />
        <div className={classes.boxBack} onClick={onBack}>
          <IconBack />
          <Spacing width={8} />
          <Typography
            className={textStyles.paragraph}
            style={{ color: Colors.primary }}
          >
            Retour à l’authentification
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  logo: {
    position: "absolute",
    zIndex: 9,
    left: 50,
    top: 30,
    margin: 0,
    width: "170px",
  },
  form: {
    width: "100%",
    marginTop: "1.5rem",
  },
  boxBack: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
});

export default ForgotPass;
