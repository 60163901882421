import React from "react";
import { Box } from "@mui/material";
import IconBack from "../IconBack";

const FilterTableHead = () => (
  <Box component="div" sx={{ ml: 1 }}>
    <Box
      component="p"
      sx={{
        m: 0,
        transform: "rotate(90deg) scale(0.7) translateX(2px)",
        display: "flex",
        height: "fit-content",
      }}
    >
      <IconBack color="#BABFC7" />
    </Box>
    <Box
      component="p"
      sx={{
        m: 0,
        transform: "rotate(-90deg) scale(0.7) translateX(2px)",
        display: "flex",
        height: "fit-content",
      }}
    >
      <IconBack color="#BABFC7" />
    </Box>
  </Box>
);

export default FilterTableHead;
