import { IAppState } from "@stores/state";
import normalizeString from "@utils/normalizeString";
import { createSelector } from "reselect";
import { IActivityOfContract } from "./activitiesOfContract.dto";

const selectActivities = (state: IAppState) =>
  state.dataActivities.activities.data ?? [];

export const selectCurrentIdProfession = (state: IAppState) =>
  state.dataActivities.currentIdProfession;

export const selectFiltersActivities = (state: IAppState) =>
  state.dataActivities.filters;

const selectSortByActivities = (state: IAppState) =>
  state.dataActivities.filters.sortBy;

const selectActivitiesSelectedInRedux = (state: IAppState) =>
  state.activitiesRedux.activities;

const selectActivitiesQueryByName = createSelector(
  selectActivities,
  selectFiltersActivities,
  (activities, filters) => {
    return activities.filter((activity) =>
      normalizeString(activity.name)
        .toLowerCase()
        .includes(filters.searchTerm.toLowerCase())
    );
  }
);

const selectFilteredActivities = createSelector(
  selectActivitiesQueryByName,
  selectSortByActivities,
  selectActivitiesSelectedInRedux,
  (activities, sortBy, activitiesSelectedInRedux) => {
    const newSortType = sortBy.type === "asc" ? -1 : 1;

    const sortByValue: { [key: string]: () => IActivityOfContract[] } = {
      number: () =>
        activities.slice().sort((activity, nextActivity) => {
          const activitySeqNoLength = activity.seq_no.length;
          const nextActivitySeqNoLength = nextActivity.seq_no.length;

          // Ex:
          // 1    ---> 100
          // 10   ---> 1000
          // 1.1  ---> 101
          // 1.10 ---> 110
          // 11.1 ---> 1101
          const convertActivitySeqNo = (
            lengthToCheck: number,
            activitySeqNoToConvert: string
          ) => {
            let result = "";

            switch (lengthToCheck) {
              case 1:
                result = activitySeqNoToConvert.padEnd(3, "0");
                break;
              case 2:
                result = activitySeqNoToConvert.padEnd(4, "0");
                break;
              case 3:
                result = activitySeqNoToConvert.replace(".", "0");
                break;
              default:
                if (activitySeqNoToConvert.indexOf(".") === 1) {
                  result = activitySeqNoToConvert.replace(".", "");
                } else {
                  result = activitySeqNoToConvert.replace(".", "0");
                }
            }

            return Number.parseInt(result);
          };

          const activitySeqNoConverted = convertActivitySeqNo(
            activitySeqNoLength,
            activity.seq_no
          );
          const nextActivitySeqNoConverted = convertActivitySeqNo(
            nextActivitySeqNoLength,
            nextActivity.seq_no
          );

          return (
            (activitySeqNoConverted - nextActivitySeqNoConverted) * newSortType
          );
        }),

      name: () =>
        activities.slice().sort((activity, nextActivity) => {
          return activity.name.localeCompare(nextActivity.name) * newSortType;
        }),

      percent: () => {
        if (activitiesSelectedInRedux.length === 0) return activities;

        const activitiesSelectedToSort: IActivityOfContract[] = [];

        const activitiesNonSelected = activities.filter((activity) => {
          const isExistsInActivitiesRedux = activitiesSelectedInRedux.some(
            (activitySelected) => activitySelected.id === activity._id
          );

          if (isExistsInActivitiesRedux) {
            activitiesSelectedToSort.push(activity);
            return false;
          } else {
            return true;
          }
        });

        activitiesSelectedToSort.sort((activity, nextActivity) => {
          const activityRedux = activitiesSelectedInRedux.find(
            (activitySelected) => activitySelected.id === activity._id
          );
          const nextActivityRedux = activitiesSelectedInRedux.find(
            (nextActivitySelected) =>
              nextActivitySelected.id === nextActivity._id
          );

          return (
            ((activityRedux?.percent ?? 1) -
              (nextActivityRedux?.percent ?? 1)) *
            newSortType
          );
        });

        return [...activitiesSelectedToSort, ...activitiesNonSelected];
      },
    };

    return sortByValue[sortBy.sortValue]();
  }
);

export const selectTotalFilteredActivities = createSelector(
  selectFilteredActivities,
  (activities) => activities.length
);

export const selectActivitiesPerPage = createSelector(
  selectFilteredActivities,
  selectFiltersActivities,
  (activities, filters) => {
    const start = (filters.page - 1) * filters.rowsPerPage;
    const end = start + filters.rowsPerPage;

    return activities.slice(start, end);
  }
);
