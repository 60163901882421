import * as React from "react"

const CardIcon: React.FC<{color: string}> = ({color}) => (
  <svg
    width={18}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75.25H2.25C.975.25 0 1.225 0 2.5v9c0 1.275.975 2.25 2.25 2.25h13.5c1.274 0 2.25-.975 2.25-2.25v-9c0-1.275-.976-2.25-2.25-2.25Zm-13.5 1.5h13.5c.45 0 .75.3.75.75v2.25h-15V2.5c0-.45.3-.75.75-.75Zm0 10.5h13.5c.45 0 .75-.3.75-.75V6.25h-15v5.25c0 .45.3.75.75.75Z"
      fill={color}
    />
  </svg>
)

export default CardIcon
