import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { Colors } from "@themes/colors";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage3Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage3: FC<IContentPage3Props> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <Text
            style={[
              generatingResultPDFStyles.titleHeaderTable,
              { paddingVertical: 4 },
            ]}
          >
            INFORMATIONS DU SOUSCRIPTEUR
          </Text>
        </View>
        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Avez-vous déjà été assuré ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_2?.hasRCD}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Avez-vous un contrat en cours ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_2?.contrat_en_cours}
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Quelle est la date de réalisation du dernier contrat?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.date_de_resiliation
              }
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Nombre de sinistre déclaré dans les 24 derniers mois ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.assure_lors_des_24_derniers_mois
              }
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Pour un montant de :
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.pour_un_montant_de
              }
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Dans les 24 derniers mois, avez-vous été résilié non paiement ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.esilie_non_paiement
              }
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              Votre contrat a-t-il déjà été annulé / résilié pour fausse
              déclaration ?
            </Text>
          </View>
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={generatingResultPDFStyles.contentTablePage2}>
              {
                infoInsurance?.informations_du_souscripteur_2
                  ?.resilie_pour_fausse_declaration
              }
            </Text>
          </View>
        </View>

        {isPIB ? (
          <View>
            <View style={generatingResultPDFStyles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  Effectuez-vous plus de 30% de sous-traitance ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.de_sous_traitance
                  }
                </Text>
              </View>
            </View>

            <View style={generatingResultPDFStyles.itemTableEven}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  Etes-vous en redressement judiciaire ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.en_redressement_judiciaire
                  }
                </Text>
              </View>
            </View>
          </View>
        ) : null}

        {!isPIB ? (
          <View>
            <View style={generatingResultPDFStyles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  Confiez-vous plus de 30% de sous-traitance ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.de_sous_traitance
                  }
                </Text>
              </View>
            </View>

            <View style={generatingResultPDFStyles.itemTableEven}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  Etes-vous en redressement judiciaire ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.en_redressement_judiciaire
                  }
                </Text>
              </View>
            </View>

            <View style={generatingResultPDFStyles.itemTableOdd}>
              <View style={{ padding: "5px 0 5px 20px", flex: 4 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  Souhaitez-vous la reprise du passé ?
                </Text>
              </View>
              <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
                <Text style={generatingResultPDFStyles.contentTablePage2}>
                  {
                    infoInsurance?.informations_du_souscripteur_2
                      ?.reprise_du_passe
                  }
                </Text>
              </View>
            </View>
          </View>
        ) : null}
      </View>
      <View style={{ height: 5 }} />
      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 300,
          color: "#FF5C5D",
          fontSize: 8,
          lineHeight: 1.5,
        }}
      >
        Ces éléments sont essentiels et déterminants pour le consentement de
        l’Assureur quant au contrat et au tarif proposé.
      </Text>
      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 300,
          color: "#FF5C5D",
          fontSize: 8,
          lineHeight: 1.5,
        }}
      >
        Toute fausse déclaration ou déclaration inexacte sera sanctionnée par la
        nullité du contrat ou la réduction proportionnelle de l’indemnité
        conformément aux dispositions des articles L.113-8 et L.113-9 du Code
        des assurances.
      </Text>
      <View style={{ height: 30 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Objet de la garantie proposée
        </Text>
      </View>
      <View>
        <View>
          <Text style={styles.titleBoxSubContent}>Nature de la garantie</Text>
          <View style={generatingResultPDFStyles.underlineTitleBoxContent} />
        </View>
        <View style={{ marginTop: 12, paddingRight: 20 }}>
          <Text style={generatingResultPDFStyles.textUnderline}>
            Responsabilité civile décennale obligatoire :
          </Text>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              Le contrat proposé garantit la responsabilité décennale du
              souscripteur instaurée par les articles 1792 et suivants du Code
              civil, dans le cadre et les limites prévues par les dispositions
              des articles L.241-1 et L.241-2 du Code des assurances relatives à
              l’obligation d’assurance décennale, et pour des travaux de
              construction d’ouvrages qui y sont soumis, au regard de l’article
              L.243-1-1 du même code.
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              La garantie couvre les travaux de réparation, notamment en cas de
              remplacement des ouvrages, qui comprennent également les travaux
              de démolition, déblaiement, dépose ou de démontage éventuellement
              nécessaires.
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              Responsabilité du sous-traitant en cas de dommages de nature
              décennale. Cette garantie est proposée, conformément à l’article
              1792-4-2 du Code civil, pour une durée de dix ans à compter de la
              réception et est gérée selon le régime de la capitalisation.
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 15, paddingRight: 20 }}>
          <Text style={generatingResultPDFStyles.textUnderline}>
            Responsabilité civile professionnelle :
          </Text>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              La garantie proposée couvre les conséquences pécuniaires de la
              responsabilité civile du souscripteur pour les dommages corporels,
              matériels et immatériels causés aux tiers et résultant de ses
              activités professionnelles déclarées. Conformément aux
              dispositions de l'article L 124-5 alinéas 4 et 5 du Code des
              assurances, le contrat est établi en "base réclamation" pour ces
              chapitres du contrat.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
});

export default ContentPage3;
