import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import axios from "axios";
import { ILoginParams, IRequestEmail, IResetPassword } from "./login.dto";

export function loginService(params: ILoginParams) {
  const headers = { "Content-Type": "application/json" };
  const apiUrl = BaseUrl.dev + Endpoint.signin;

  return new Promise<any>((resolve, rejects) => {
    axios
      .post(apiUrl, params, { headers })
      .then(async (res: any) => {
        resolve(res?.data);
      })
      .catch((e) => {
        rejects(e?.response?.data);
      });
  });
}

export function requestEmailService(data: IRequestEmail) {
  const apiUrl = BaseUrl.dev + Endpoint.request_email;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function resetPasswordService({ newPassword, token }: IResetPassword) {
  const apiUrl = BaseUrl.dev + Endpoint.reset_password + `/${token}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data: {
        newPassword,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}
