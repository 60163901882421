import SearchIcon from "@assets/SvgComponents/SearchIcon";
import InputCustom from "@components/InputCustom";
import NumberInputCustom from "@components/NumberInputCustom";
import SelectCustom from "@components/SelectCustom";
import Spacing from "@components/Spacing";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  selectFiltersActivities,
  selectTotalFilteredActivities,
} from "@stores/activitiesOfContract/activitiesOfContract.selector";
import { IAppState } from "@stores/state";
import { TextStyles } from "@themes/textStyles";
import { debounce } from "lodash";
import { FC, useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ActivitiesSelection from "./ActivitesSelection";

interface IFormQueryTableHeadProps {
  selected: number;
  maxSelected: number;
  totalPercent: number;
  errorTotalPercent: boolean;
  handleChangeRowsPerPage: (count: number) => void;
  handleChangeSearchInput: (value: string) => void;
}

const FormQueryTableHead: FC<IFormQueryTableHeadProps> = ({
  selected,
  maxSelected,
  totalPercent,
  errorTotalPercent,
  handleChangeRowsPerPage = () => {},
  handleChangeSearchInput = () => {},
}) => {
  const textStyles = TextStyles();

  const { loading } = useSelector((state: IAppState) => state.dataActivities);
  const totalFilteredActivities = useSelector(selectTotalFilteredActivities);
  const { rowsPerPage } = useSelector(selectFiltersActivities);

  const { control } = useForm();

  const dataPage = useMemo(
    () => [
      { value: totalFilteredActivities, label: "Tous" },
      { value: 5, label: "05" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
    [totalFilteredActivities]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue: string) => handleChangeSearchInput(nextValue), 500),
    []
  );

  const handlingSearch = (value: string) => {
    debounceSearch(value);
  };

  return (
    <TableRow>
      <TableCell padding="checkbox" sx={{ pl: 3, borderBottom: "unset" }} />

      <TableCell sx={{ borderBottom: "unset" }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <ActivitiesSelection selected={selected} maxSelected={maxSelected} />

          <Typography
            className={textStyles.paragraph_semi_bold}
            sx={{
              color: "#5E5873",
              textAlign: "right",
            }}
          >
            Total
          </Typography>
        </Stack>
      </TableCell>

      <TableCell
        component="th"
        scope="row"
        padding="none"
        sx={{ px: 0, py: 2, borderBottom: "unset" }}
      >
        <Stack flexDirection="row" alignItems="center">
          <Stack flexDirection="row" alignItems="center" sx={{ width: "60%" }}>
            <NumberInputCustom
              disabled
              value={totalPercent.toString()}
              background="rgba(255, 204, 77, 0.2)"
              error={
                errorTotalPercent ? "Le total doit être égal à 100" : undefined
              }
              border={errorTotalPercent ? "1px solid #D8D6DE" : "unset"}
            />
          </Stack>
          <Spacing width={15} />
          <Typography
            className={textStyles.paragraph_small_semi_bold}
            sx={{ color: "#5E5873" }}
          >
            %
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ px: 0, py: 3, borderBottom: "unset" }}>
        <Stack flexDirection="row" alignItems="center">
          <Box style={{ flex: 1 }} mr={3}>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputCustom
                  onChange={(e) => {
                    onChange(e);
                    handlingSearch(e.target.value);
                  }}
                  value={value}
                  placeholder="Rechercher par référence ou activité"
                  endAdornmentIcon={
                    loading && value ? (
                      <Box
                        component="p"
                        style={{
                          margin: 0,
                          display: "flex",
                          transform: "scale(0.5)",
                        }}
                      >
                        <CircularProgress style={{ color: "#5E5873" }} />
                      </Box>
                    ) : (
                      <SearchIcon color="#BABFC7" />
                    )
                  }
                />
              )}
            />
          </Box>

          <Stack flexDirection="row" alignItems="center" mr={1}>
            <Typography className={textStyles.paragraph}>Afficher</Typography>
            <Spacing width={5} />
            <Box sx={{ minWidth: "75px" }} ml={-1}>
              <SelectCustom
                onChange={(value: number) => {
                  if (typeof value === "number") {
                    handleChangeRowsPerPage(value);
                  }
                }}
                data={dataPage}
                defaultValue={rowsPerPage}
                border={false}
              />
            </Box>
          </Stack>
          <Spacing width={20} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default FormQueryTableHead;
