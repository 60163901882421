import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import { ICreateCompanyDto } from "./createNewCompany.dto";

export function createNewCompanyService(dataCompany: ICreateCompanyDto) {
  const apiUrl = BaseUrl.dev + Endpoint.company;

  return new Promise((resovle, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data: dataCompany,
    })
      .then((res) => resovle(res?.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function updateCompanyService(
  dataCompany: ICreateCompanyDto,
  idCompany: string
) {
  const apiUrl = BaseUrl.dev + Endpoint.company + `/${idCompany}`;

  return new Promise((resovle, rejects) => {
    clientRequest({
      method: "PUT",
      url: apiUrl,
      data: dataCompany,
    })
      .then((res) => resovle(res?.data))
      .catch((err) => rejects(err?.response?.data));
  });
}
