import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage13Props {
  infoInsurance?: IInfoContractPDF;
}

const ContentPage13: FC<IContentPage13Props> = ({ infoInsurance }) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          ATTESTATION DE NON SINITRALITE
        </Text>
      </View>
      <View
        style={{
          marginTop: 15,
          borderBottom: "1px solid #EBE9F1",
          paddingBottom: 6,
        }}
      >
        <Text style={styles.mainTitle}>Informations assuré</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Assuré :{" "}
          <Text style={generatingResultPDFStyles.mainContent}>
            {infoInsurance?.informations_du_souscripteur_1?.souscripteur}
          </Text>
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Courtier / Numéro ORIAS: Madecennale / 15003712
        </Text>
      </View>
      <View
        style={{
          marginTop: 25,
        }}
      >
        <Text style={generatingResultPDFStyles.mainContent}>
          Dossier N°{infoInsurance?.contract_id}
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Je soussigné,{" "}
          {infoInsurance?.informations_du_souscripteur_1?.dirigeant} agissant en
          qualité de gérant
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          (Cocher la mention qui correspond à votre situation)
        </Text>
      </View>
      <View style={{ marginTop: 25 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={generatingResultPDFStyles.checkbox} />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.mainContent}>
              Déclare ne pas avoir eu de sinistre depuis la date de création de
              ma structure le :
              .................................................
            </Text>
            <Text style={generatingResultPDFStyles.mainContent}>
              Ne pas avoir connaissance de faits ou d'évenements susceptibles
              d'engager ma responsabilité à la date de prise de garantie.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "#EBE9F1",
            margin: "6px 0px 10px 0",
          }}
        />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={generatingResultPDFStyles.checkbox} />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.mainContent}>
              Déclare ne pas avoir eu de sinistre depuis la date de mon dernier
              relevé d'information ci-joint.
            </Text>
            <Text style={generatingResultPDFStyles.mainContent}>
              Ne pas avoir connaissance de faits ou d'évenements susceptibles
              d'engager ma responsabilité à la date de prise de garantie.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ height: 15 }} />
      <Text style={generatingResultPDFStyles.mainContent}>
        Je prends note qu'en cas de fausse déclaration je me verrai opposer la
        nullité et la déchéance contractuelle.
      </Text>
      <View style={{ height: 15 }} />
      <Text style={generatingResultPDFStyles.mainContent}>
        Certifié sincère et véritable.
      </Text>
      <View style={{ height: 15 }} />
      <Text style={generatingResultPDFStyles.mainContent}>
        Fait à ................................., le
        ................................
      </Text>
      <View style={{ height: 20 }} />
      <Text
        style={{
          fontSize: 9,
          color: "#5E5873",
          fontFamily: "Montserrat",
          lineHeight: 1.8,
          textAlign: "right",
        }}
      >
        Signature, cachet "Obligatoire" et mention : Bon pour accord
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: "underline",
    color: Colors.primary,
    marginBottom: 8,
  },
});

export default ContentPage13;
