import Logo from "@assets/Images/logo_dashboard.jpg";
import BackgroundChangePass from "@assets/SvgComponents/BackgroundChangePass";
import IconBack from "@assets/SvgComponents/IconBack";
import ButtonCustom from "@components/ButtonCustom";
import InputCustom from "@components/InputCustom";
import Spacing from "@components/Spacing";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  resetPasswordRequested,
  setLoginParams,
} from "@stores/login/login.creator";
import { IAppState } from "@stores/state";
import { generalMessage } from "@utils/generalMessage";
import { notistack } from "@utils/notistack";
import queryString from "query-string";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "themes/colors";
import { TextStyles } from "themes/textStyles";
import * as yup from "yup";

export interface IResetPasswordProps {}

export const ResetPassword: FC<IResetPasswordProps> = () => {
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("Mot de passe requis.")
      .min(4, "Veuillez entrer au moins 4 caractères."),
    confirmNewPassword: yup
      .string()
      .required("Retaper le mot de passe requis.")
      .oneOf([yup.ref("newPassword")], "Le mot de passe ne correspond pas."),
  });

  const classes = useStyles();
  const textStyles = TextStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { message, error, loading } = useSelector(
    (state: IAppState) => state.responseLogin
  );

  const { email, token } = queryString.parse(location.search);

  const defaultValues = {
    newPassword: "",
    confirmNewPassword: "",
  };

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const onSubmit = ({
    newPassword,
    confirmNewPassword,
  }: {
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    if (!token || typeof token !== "string") {
      return notistack.success(generalMessage.unexpectedError);
    }

    dispatch(resetPasswordRequested({ newPassword, token }));
  };

  useEffect(() => {
    if (!message || typeof email !== "string") return;

    const newPassword = getValues("newPassword");
    dispatch(
      setLoginParams({
        email,
        password: newPassword,
      })
    );

    notistack.success(message);
    navigate("/login");
  }, [dispatch, email, getValues, message, navigate]);

  useEffect(() => {
    error && notistack.error(error);
  }, [error]);

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        style={{ background: "#F8F8F8" }}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>
          <BackgroundChangePass />
        </p>
        <p className={classes.logo}>
          <img width="100%" height="100%" src={Logo} alt="" />
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        sx={{ pl: 10, pr: 10 }}
      >
        <Typography className={textStyles.h2}>
          Changer de mot de passe 🔐
        </Typography>
        <Typography className={textStyles.paragraph}>
          Mettez à jour le mot de passe de votre compte.
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputCustom
                onChange={onChange}
                value={value}
                label="Nouveau mot de passe"
                type="password"
                placeholder="Password"
                error={errors?.newPassword && errors?.newPassword?.message}
                autoComplete="current-password"
                sizeLabel="sm"
              />
            )}
          />
          <Spacing height={16} />
          <Controller
            name="confirmNewPassword"
            render={({ field: { onChange, value } }) => (
              <InputCustom
                label="Confirmer le mot de passe"
                type="password"
                error={
                  errors?.confirmNewPassword &&
                  errors?.confirmNewPassword?.message
                }
                value={value}
                placeholder="Password"
                onChange={onChange}
                autoComplete="new-password"
                sizeLabel="sm"
              />
            )}
            control={control}
          />
          <Spacing height={16} />
          <Box component="div">
            <ButtonCustom
              disabled={loading}
              endIcon={
                loading && (
                  <CircularProgress
                    style={{ color: "#fff", width: "16px", height: "16px" }}
                  />
                )
              }
              type="submit"
              title="Définir un nouveau mot de passe"
            />
          </Box>
          <Spacing height={20} />
          <div className={classes.boxBack} onClick={() => navigate("/login")}>
            <IconBack />
            <Spacing width={8} />
            <Typography
              className={textStyles.paragraph}
              style={{ color: Colors.primary }}
            >
              Retour à l’authentification
            </Typography>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

// retry pipeline

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  logo: {
    position: "absolute",
    zIndex: 9,
    left: 50,
    top: 30,
    margin: 0,
    width: "170px",
  },
  form: {
    width: "100%",
    marginTop: "1.5rem",
  },
  titleForgot: {
    fontWeight: "500 !important",
    fontSize: "0.75rem !important",
    cursor: "pointer",
  },
  boxBack: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
});
