import React, { FC } from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import WarningImg from "../../../assets/Images/warning.png";
import formatMoney from "@utils/formatMoney";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import moment from "moment";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage8Props {
  fractionnement?: string;
  NETCompanyAvecCom?: number;
  frais_de_souscription?: number;
  pricingByPeriod?: Array<number>;
  cotisations?: {
    JURY_LAW: number;
    PJ_GROUPAMA: number;
    fractionement?: number;
    fraisInclus5LPP: number;
    primeCODE: number;
    primeNetHT?: number;
    totalMAJO_MINO?: number;
  };
  taux_de_revision_du_contrat?: number;
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage8: FC<IContentPage8Props> = ({
  fractionnement,
  pricingByPeriod,
  cotisations = {
    PJ_GROUPAMA: 0,
    JURY_LAW: 0,
    fraisInclus5LPP: 85,
    primeCODE: 0,
  },
  frais_de_souscription = 0,
  NETCompanyAvecCom = 0,
  taux_de_revision_du_contrat = 0,
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <View break>
      {isPIB ? (
        <View>
          <View style={styles.titleBody}>
            <Text
              style={{
                fontSize: 12,
                color: Colors.primary,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Franchise
            </Text>
          </View>
          <View style={{ marginBottom: 20 }}>
            <Text style={styles.mainContent}>
              Le montant de la franchise applicable à chaque garantie est
              indiqué au tableau ci-dessus.
            </Text>
            <Text style={styles.mainContent}>
              Il est précisé que la franchise applicable est celle qui
              correspond à l’activité exercée.
            </Text>
            <Text style={styles.mainContent}>
              En cas de survenance d'un sinistre couvert, le montant dû par
              l'assuré à l'assureur au titre de la franchise sera immédiatement
              exigible.
            </Text>
            <Text style={styles.mainContentBold}>
              Sans paiement immédiat à la première demande, l'Assuré prend note
              de sa responsabilité et des dommages et intérêts que réclamera
              l'assureur par voie judiciaire.
            </Text>
          </View>
        </View>
      ) : null}

      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Cotisations
        </Text>
      </View>
      <View>
        <Text style={styles.mainContentBold}>
          La cotisation annuelle provisionnelle est fixée à la souscription à{" "}
          {formatMoney(NETCompanyAvecCom, 3, ",", " ", 2)} €{" "}
          <Text style={styles.mainContent}>
            (frais et taxes d'assurances inclus)
          </Text>
          {/* , soit {formatMoney(NETCompanyAvecCom, 3, ",", " ", 2)} € toutes taxes
          comprises. */}
        </Text>
        <Text style={styles.mainContent}>Décomposée comme suit :</Text>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Prime Optim Assurance (incluant les frais de fractionnement) :{" "}
            {fractionnement &&
              formatMoney(cotisations?.primeCODE, 3, ",", " ", 2)}{" "}
            €
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Prime DPRSA/PJ GROUPAMA et Juri’law :{" "}
            {formatMoney(cotisations.PJ_GROUPAMA + cotisations.JURY_LAW)} €
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Fractionnement choisi : {fractionnement}
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Prime fractionnée (incluant les frais de courtage et de
            quittancement à chaque échéance) :{" "}
            {fractionnement === "Mensuel"
              ? `${formatMoney(NETCompanyAvecCom / 12, 3, ",", " ", 2)} €`
              : fractionnement === "Annuel"
              ? `${formatMoney(NETCompanyAvecCom, 3, ",", " ", 2)} €`
              : fractionnement === "Semestriel"
              ? `${formatMoney(NETCompanyAvecCom / 2, 3, ",", " ", 2)} €`
              : `${formatMoney(NETCompanyAvecCom / 4, 3, ",", " ", 2)} €`}
          </Text>
        </View>
        <View>
          <Text style={styles.textYellow}>Ajustement de la cotisation</Text>
          <Text style={styles.mainContent}>
            Cette cotisation provisionnelle annuelle, ou ses parties en cas de
            fractionnement, sera égale à 100,00 % de la dernière cotisation
            annuelle définitive connue avant l'échéance concernée.
          </Text>
        </View>
        <View>
          <Text style={styles.textYellow}>Cotisation annuelle définitive</Text>
          <Text style={styles.mainContent}>
            Conformément aux dispositions de l'article 9.2.1 des Conditions
            Générales, la cotisation annuelle définitive sera calculée à la fin
            de l'année d'assurance à raison de :
          </Text>
          <Text style={styles.mainContent}>
            <Text style={styles.mainContentBold}>
              {taux_de_revision_du_contrat}% du Chiffre d’affaires H.T
            </Text>
            . déclaré à l’administration fiscale
          </Text>
          <Text style={styles.mainContent}>
            Dans le cas où la cotisation annuelle provisionnelle excède la
            cotisation annuelle définitive, il sera procédé à un remboursement
            du trop-perçu dans la limite de 100 % de la cotisation, sans déroger
            à la cotisation minimale.
          </Text>
        </View>
        <View>
          <Text style={styles.textYellow}>Cotisation minimale</Text>
          <Text style={styles.mainContent}>
            La cotisation annuelle minimale est fixée à{" "}
            {formatMoney(NETCompanyAvecCom, 3, ",", " ", 2)} €, frais et taxes
            en sus.
          </Text>
          <Text style={styles.mainContent}>
            Le contrat prend effet au :{" "}
            {moment(infoInsurance?.contractStartDate).format("DD/MM/YYYY")}
          </Text>
          <Text style={styles.mainContent}>
            Première période couverte par le contrat : 12 mois
          </Text>
          <Text style={styles.mainContent}>
            Ce contrat est à tacite reconduction annuelle avec une échéance
            principale fixée au : {infoInsurance?.date_decheance}
          </Text>
          <Text style={styles.mainContent}>
            Prélèvement du terme :{" "}
            {moment(
              new Date(
                new Date(
                  moment(infoInsurance?.contractStartDate)
                    .toDate()
                    .setMonth(
                      moment(infoInsurance?.contractStartDate)
                        .toDate()
                        .getMonth() + 1
                    )
                ).setDate(
                  new Date(
                    moment(infoInsurance?.contractStartDate)
                      .toDate()
                      .setMonth(
                        moment(infoInsurance?.contractStartDate)
                          .toDate()
                          .getMonth() + 1
                      )
                  ).getDate() + 3
                )
              )
            ).format("DD/MM/YYYY")}
          </Text>
          <Text style={styles.mainContent}>
            Le 1er règlement est de :{" "}
            {fractionnement === "Mensuel"
              ? `${formatMoney(
                  NETCompanyAvecCom / 12 + 85 + frais_de_souscription,
                  3,
                  ",",
                  " ",
                  2
                )} €`
              : fractionnement === "Annuel"
              ? `${formatMoney(
                  NETCompanyAvecCom + 85 + frais_de_souscription,
                  3,
                  ",",
                  " ",
                  2
                )} €`
              : fractionnement === "Semestriel"
              ? `${formatMoney(
                  NETCompanyAvecCom / 2 + 85 + frais_de_souscription,
                  3,
                  ",",
                  " ",
                  2
                )} €`
              : `${formatMoney(
                  NETCompanyAvecCom / 4 + 85 + frais_de_souscription,
                  3,
                  ",",
                  " ",
                  2
                )} €`}
          </Text>
        </View>
        <View style={{ margin: "12px 0" }}>
          <Text style={styles.mainContent}>
            Le prix ci-dessus tient compte des différents frais et taxes
            d'assurances, des honoraires de courtage de{" "}
            {infoInsurance?.frais_de_souscription &&
              formatMoney(infoInsurance.frais_de_souscription)}{" "}
            €. Les frais de souscription et de gestion de 85 € sont ajoutés à
            votre 1er versement et ne sont pas soumis à la TVA (conformément au
            CGI).
          </Text>
        </View>
        <Text style={styles.mainContent}>
          Ce contrat est résiliable conformément aux Conditions Générales qui
          vous ont été remises.
        </Text>
        <View style={{ height: 15 }} />
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              marginBottom: 5,
            }}
          >
            <Image
              src={WarningImg}
              style={{ width: 12, height: 12, marginRight: 5 }}
            />
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              La prise d’effet des garanties est conditionnée :
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContentBold}>
              à l’encaissement de la première cotisation en totalité. La
              garantie est maintenue sous réserve du paiement intégral de la
              cotisation par l’Assuré pour la période de validité susmentionnée.
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContentBold}>
              au retour à l’Assureur des Conditions particulières signées.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
  mainContentBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
    fontWeight: 500,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.8,
    height: 1.8,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  textYellow: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: Colors.primary,
    textDecoration: "underline",
    marginTop: 8,
    marginBottom: 5,
  },
});

export default ContentPage8;
