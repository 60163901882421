import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  ActivitiesOfContractTypes,
  IGetActivitiesRequestedAction,
} from "./activitiesOfContract.type";
import {
  clearMessageErrorActivities,
  getActivitiesContractFailed,
  getActivitiesContractSucceeded,
} from "./activitiesOfContract.creator";
import { IResponseActivitiesOfContract } from "./activitiesOfContract.dto";
import { getActivitiesContractService } from "./activitiesOfContract.service";

function* getActivities({ filterDto }: IGetActivitiesRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IResponseActivitiesOfContract> =
      yield call(getActivitiesContractService, filterDto);
    yield put(getActivitiesContractSucceeded(dataResponse.data));
  } catch (err) {
    yield put(getActivitiesContractFailed(err));
    yield put(clearMessageErrorActivities());
  }
}

function* watchGetActivities() {
  yield takeLatest(
    ActivitiesOfContractTypes.GET_ACTIVITIES_REQUESTED,
    getActivities
  );
}

function* getActivitiesSaga() {
  yield all([fork(watchGetActivities)]);
}

export default getActivitiesSaga;
