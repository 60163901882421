import {
  ICreateUserDto,
  IFilterUsersDto,
  IResponseUsers,
  IUser,
} from "./users.dto";

export enum UsersTypes {
  GET_USERS_REQUESTED = "users/GET_REQUESTED",
  GET_USERS_SUCCEEDED = "users/GET_SUCCEEDED",
  GET_USERS_FAILED = "users/GET_FAILED",

  GET_DETAIL_USER_REQUESTED = "users/GET_DETAIL_REQUESTED",
  GET_DETAIL_USER_SUCCEEDED = "users/GET_DETAIL_SUCCEEDED",
  GET_DETAIL_USER_FAILED = "users/GET_DETAIL_FAILED",

  CREATE_USER_REQUESTED = "users/CREATE_REQUESTED",
  CREATE_USER_SUCCEEDED = "users/CREATE_SUCCEEDED",
  CREATE_USER_FAILED = "users/CREATE_FAILED",

  DELETE_USER_REQUESTED = "users/DELETE_REQUESTED",
  DELETE_USER_SUCCEEDED = "users/DELETE_SUCCEEDED",
  DELETE_USER_FAILED = "users/DELETE_FAILED",

  UPDATE_USER_REQUESTED = "users/UPDATE_REQUESTED",
  UPDATE_USER_SUCCEEDED = "users/UPDATE_SUCCEEDED",
  UPDATE_USER_FAILED = "users/UPDATE_FAILED",

  CLEAR_USER_UPDATE = "users/CLEAR_ID_USER",
  CLEAR_MESSAGE_RESPONSE = "users/CLEAR_MESSAGE_RESPONSE",
}

export interface IGetUsersRequestedAction {
  type: typeof UsersTypes.GET_USERS_REQUESTED;
  filterUsersDto: IFilterUsersDto;
}

export interface IGetUsersSucceededAction {
  type: typeof UsersTypes.GET_USERS_SUCCEEDED;
  dataUsers: IResponseUsers;
}

export interface IGetUsersFailedAction {
  type: typeof UsersTypes.GET_USERS_FAILED;
  error?: any;
}
export interface IGetDetailUserRequestedAction {
  type: typeof UsersTypes.GET_DETAIL_USER_REQUESTED;
  id: string;
}

export interface IGetDetailUserSucceededAction {
  type: typeof UsersTypes.GET_DETAIL_USER_SUCCEEDED;
  dataUser: IUser;
}

export interface IGetDetailUserFailedAction {
  type: typeof UsersTypes.GET_DETAIL_USER_FAILED;
  error?: any;
}
export interface IDeleteUserRequestedAction {
  type: typeof UsersTypes.DELETE_USER_REQUESTED;
  id: string;
}

export interface IDeleteUserSucceededAction {
  type: typeof UsersTypes.DELETE_USER_SUCCEEDED;
  message?: string;
}

export interface IDeleteUserFailedAction {
  type: typeof UsersTypes.DELETE_USER_FAILED;
  error?: any;
}
export interface IUpdateUserRequestedAction {
  type: typeof UsersTypes.UPDATE_USER_REQUESTED;
  user: ICreateUserDto;
  id: string;
}

export interface IUpdateUserSucceededAction {
  type: typeof UsersTypes.UPDATE_USER_SUCCEEDED;
  message?: string;
}

export interface IUpdateUserFailedAction {
  type: typeof UsersTypes.UPDATE_USER_FAILED;
  error?: any;
}
export interface ICreateUserRequestedAction {
  type: typeof UsersTypes.CREATE_USER_REQUESTED;
  user: ICreateUserDto;
}

export interface ICreateUserSucceededAction {
  type: typeof UsersTypes.CREATE_USER_SUCCEEDED;
  message?: string;
}

export interface ICreateUserFailedAction {
  type: typeof UsersTypes.CREATE_USER_FAILED;
  error?: any;
}
export interface IClearUserUpdateAction {
  type: typeof UsersTypes.CLEAR_USER_UPDATE;
}
export interface IClearMessageResponseAction {
  type: typeof UsersTypes.CLEAR_MESSAGE_RESPONSE;
}

export type UsersActions =
  | IGetUsersRequestedAction
  | IGetUsersSucceededAction
  | IGetUsersFailedAction
  | IGetDetailUserRequestedAction
  | IGetDetailUserSucceededAction
  | IGetDetailUserFailedAction
  | IDeleteUserRequestedAction
  | IDeleteUserSucceededAction
  | IDeleteUserFailedAction
  | IUpdateUserRequestedAction
  | IUpdateUserSucceededAction
  | IUpdateUserFailedAction
  | ICreateUserRequestedAction
  | ICreateUserSucceededAction
  | ICreateUserFailedAction
  | IClearUserUpdateAction
  | IClearMessageResponseAction;
