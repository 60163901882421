import { IFranchiseState } from "./franchises.dto";
import { FranchiseActions, FranchiseTypes } from "./franchises.type";

const initialFranchiseState: IFranchiseState = {
  loading: false,
};

export function franchiseReducer(
  state = initialFranchiseState,
  action: FranchiseActions
): IFranchiseState {
  switch (action.type) {
    case FranchiseTypes.GET_FRANCHISE_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case FranchiseTypes.GET_FRANCHISE_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        data: action?.data,
      };
    }

    case FranchiseTypes.GET_FRANCHISE_FAILED: {
      return {
        ...state,
        error: action?.error,
      };
    }

    default:
      return state;
  }
}
