import ButtonCustom from "@components/ButtonCustom";
import InputCustom from "@components/InputCustom";
import { PaperNoneShadowCustom } from "@components/PaperCustom";
import SelectCustom from "@components/SelectCustom";
import Spacing from "@components/Spacing";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { IAppState } from "@stores/state";
import {
  clearIdUserUpdate,
  createUser,
  updateUser,
} from "@stores/users/users.creator";
import { RoleName } from "@stores/users/users.dto";
import { TextStyles } from "@themes/textStyles";
import { convertFormatPhone, phoneFormated } from "@utils/convertFormatPhone";
import { formatCustomInput } from "@utils/formatCustomInput";
import { notistack } from "@utils/notistack";
import { removeSpacing } from "@utils/removeSpacing";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

interface TypeData {
  value: any;
  label: string;
}

const dataRole: TypeData[] = [
  { value: RoleName.admin, label: "Administrateur" },
  { value: RoleName.user, label: "Utilisateur" },
  // { value: RoleName.client, label: "Client" },
];

const InformationDetail = () => {
  const textStyles = TextStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();

  const {
    loadingCreate,
    message,
    error,
    loadingGet,
    detailUser,
    idUserUpdate,
  } = useSelector((state: IAppState) => state.users);

  const onSubmit = (value: any) => {
    const userInfo = {
      ...value,
      phoneNumber: phoneFormated(removeSpacing(value?.phoneNumber)),
    };

    if (idUserUpdate) {
      dispatch(updateUser(userInfo, idUserUpdate));
    } else {
      dispatch(createUser(userInfo));
    }
  };

  // Notifies
  useEffect(() => {
    message && notistack.success(message);
    error && notistack.error(error);
  }, [error, message]);

  // Set fields value
  useEffect(() => {
    if (detailUser) {
      setValue("name", detailUser?.name);
      setValue("firstName", detailUser?.firstName);
      setValue("email", detailUser?.email);
      detailUser?.phoneNumber &&
        setValue("phoneNumber", convertFormatPhone(detailUser?.phoneNumber));
      setValue("role", detailUser?.roles[0]?.name);
    }
  }, [detailUser, setValue]);

  // Clear after unmount
  useEffect(() => {
    return () => {
      dispatch(clearIdUserUpdate());
    };
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <Grid container sx={{ pt: 2 }}>
        <Grid item xs={12} position="relative">
          <form onSubmit={handleSubmit(onSubmit)}>
            {loadingGet && (
              <Box
                component="div"
                position="absolute"
                sx={{ width: "100%", left: 0, top: 0, zIndex: 1 }}
              >
                <LinearProgress color="success" />
              </Box>
            )}
            <PaperNoneShadowCustom
              sx={{
                pt: 5,
                px: 7,
                pb: 10,
                position: "relative",
              }}
            >
              <Typography className={textStyles.h3}>
                Informations sur l’utilisateur
              </Typography>
              <Spacing height={35} />

              <Box component="div">
                <Box component="div" display="flex">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="Nom"
                        label="Nom"
                        sizeLabel="sm"
                        onChange={onChange}
                        value={value}
                        error={errors?.name && errors?.name?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                    }}
                  />
                  <Spacing width={20} />

                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="Prénom"
                        label="Prénom"
                        sizeLabel="sm"
                        onChange={onChange}
                        value={value}
                        error={errors?.firstName && errors?.firstName?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                    }}
                  />
                </Box>
                <Spacing height={18} />

                <Box component="div" display="flex">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="Email"
                        label="Email"
                        sizeLabel="sm"
                        onChange={onChange}
                        value={value}
                        error={errors?.email && errors?.email?.message}
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                      pattern: {
                        value:
                          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                        message: "Invalide.",
                      },
                    }}
                  />
                  <Spacing width={20} />

                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputCustom
                        placeholder="06 XX XX XX XX"
                        label="Téléphone"
                        sizeLabel="sm"
                        onChange={(e) => {
                          onChange(formatCustomInput(e.target.value, "phone"));
                        }}
                        value={
                          value ? formatCustomInput(value, "phone") : value
                        }
                        error={
                          errors?.phoneNumber && errors?.phoneNumber?.message
                        }
                      />
                    )}
                    rules={{
                      required: "Ce champ est requis.",
                      pattern: {
                        value:
                          /^\+?([0-9]{2})\)?[ ]?([0-9]{2})[ ]?([0-9]{2})[ ]?([0-9]{2})[ ]?([0-9]{2})$/,
                        message: "Invalide.",
                      },
                    }}
                  />
                </Box>
                <Spacing height={18} />

                <Box display="flex">
                  <Box component="div" flex={1}>
                    <Typography
                      className={textStyles.paragraph_small_500}
                      sx={{ mb: 0.5 }}
                    >
                      Rôle
                    </Typography>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectCustom
                          onChange={onChange}
                          defaultValue={value}
                          placeholder="Sélectionner un rôle"
                          data={dataRole}
                          error={errors?.role && errors.role?.message}
                        />
                      )}
                      rules={{
                        required: "Ce champ est requis.",
                      }}
                    />
                  </Box>
                  {!idUserUpdate && (
                    <>
                      <Spacing width={20} />
                      <Box component="div" flex={1}>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputCustom
                              placeholder="Mot de passe"
                              label="Mot de passe"
                              sizeLabel="sm"
                              onChange={onChange}
                              value={value}
                              error={
                                errors?.password && errors?.password?.message
                              }
                            />
                          )}
                          rules={{
                            required: "Ce champ est requis.",
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </PaperNoneShadowCustom>
            <Spacing height={28} />
            <Box
              component="div"
              style={{ width: 225 }}
              sx={{ mr: "auto", ml: "auto" }}
            >
              <ButtonCustom
                type="submit"
                title="Valider"
                endIcon={
                  loadingCreate && (
                    <CircularProgress
                      style={{ color: "#fff", width: "16px", height: "16px" }}
                    />
                  )
                }
              />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InformationDetail;
