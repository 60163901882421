import React, { FC } from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import ManImg from "../../../assets/Images/man.png";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import moment from "moment";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage1: FC<IContentPage1Props> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <View>
      <Text style={styles.titleHeader}>CONDITIONS PARTICULIÈRES</Text>
      <Text style={styles.titleHeader2}>
        RC DECENNALE {isPIB ? "PIB" : null}
      </Text>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
            marginBottom: 3,
          }}
        >
          ASSURANCE RESPONSABILITE CIVILE & DECENNALE
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          des {isPIB ? "professions intellectuelles" : "entreprises"} du
          bâtiment
        </Text>
      </View>
      <View style={styles.imgContainer}>
        <View style={{ width: "45%", paddingRight: "20px" }}>
          <Image
            style={{
              width: "100%",
            }}
            src={ManImg}
          />
        </View>
        <View style={styles.resultsContainer}>
          <View style={styles.inforResultsContainer}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                N° de Police
              </Text>
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                {infoInsurance?.contract_code}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.subInfoResult}>Date d’effet</Text>
              <Text style={styles.subInfoResult}>
                {moment(infoInsurance?.contractStartDate).format("DD/MM/YYYY")}
              </Text>
            </View>

            {!isPIB ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 8,
                }}
              >
                <Text style={styles.subInfoResult}>Reprise du passé :</Text>
                <Text style={styles.subInfoResult}>
                  {infoInsurance?.resumptionDatePast &&
                    moment(infoInsurance.resumptionDatePast).format(
                      "DD/MM/YYYY"
                    )}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.itemInfoDetail}>
              <Text style={styles.labelItemInfo}>Assureur :</Text>
              <Text style={styles.contentItemInfo}>Optim Assurance</Text>
            </View>
            <View style={styles.itemInfoDetail}>
              <Text style={styles.labelItemInfo}>Assureur DPRSA & PJ :</Text>
              <Text style={styles.contentItemInfo}>
                Groupama protection juridique
              </Text>
            </View>
            <View style={styles.itemInfoDetail}>
              <Text style={styles.labelItemInfo}>Courtier :</Text>
              <Text style={styles.contentItemInfo}>Madecennale</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 60 }}>
        <Text style={styles.subTitleYellow}>
          Les présentes Conditions Particulières complètent les Conditions
          Générales.
        </Text>
        <Text
          style={{
            color: "#5E5873",
            fontSize: 10,
            fontFamily: "Montserrat",
            marginTop: "5px",
            lineHeight: 1.6,
            fontWeight: 300,
          }}
        >
          Elles contiennent les données qui vous sont propres, les garanties
          souscrites, les montants assurés ainsi que la prime. En cas de
          contradiction entre les présentes Conditions Particulières et les
          Conditions Générales, ce sont les Conditions Particulières qui
          prévalent.
        </Text>
        <Text
          style={{
            color: "#5E5873",
            fontSize: 10,
            fontFamily: "Montserrat",
            marginTop: "15px",
            lineHeight: 1.6,
            fontWeight: 300,
          }}
        >
          Ce contrat a pour objet de répondre conformément à la loi, à
          l'obligation d'assurance qui pèse sur les personnes réputées
          constructeur en matière d'assurance de Responsabilité Civile Décennale
          au sens des articles L.241-1 et L.241-2 du Code des assurances. Seule
          la garantie légale obligatoire est acquise sauf si mention contraire
          en est faite dans les pages suivantes.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleHeader: {
    fontSize: 19,
    fontWeight: "bold",
    color: Colors.primary,
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 20,
  },
  titleHeader2: {
    fontSize: 18,
    color: "#5E5873",
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 5,
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginTop: 40,
    marginBottom: 35,
    padding: "8px 0px",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row",
  },
  resultsContainer: {
    width: "55%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  underlineTitleBoxContent: {
    width: "52px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 4,
  },
  inforResultsContainer: {
    border: "1px solid #FFCC4D",
    borderRadius: 5,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  subInfoResult: {
    color: "#5E5873",
    fontSize: 10,
    fontFamily: "Montserrat",
  },
  itemInfoDetail: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
  },
  labelItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    marginRight: 4,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  contentItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
  subTitleYellow: {
    fontSize: 10,
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: Colors.primary,
  },
});

export default ContentPage1;
