import { FC } from "react";

export interface ICartIconProps {}

export const CartIcon: FC<ICartIconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.642 8.28086C23.3286 7.89052 22.8608 7.66427 22.3669 7.66427H20.7824L20.7536 7.62214L14.977 0.345369C14.8432 0.158593 14.6408 0.0363348 14.4166 0.00687713C14.1924 -0.0225805 13.9662 0.0434078 13.7909 0.189234C13.6156 0.335354 13.5062 0.548344 13.4887 0.779012C13.4711 1.00968 13.5465 1.2374 13.6976 1.40945L18.6491 7.6684H5.03238L9.98383 1.40945H9.98412C10.1352 1.23741 10.2109 1.00968 10.1931 0.779012C10.1755 0.548344 10.0664 0.335354 9.89114 0.189234C9.71557 0.0434093 9.48963 -0.0225805 9.26541 0.00687713C9.04119 0.0363348 8.83856 0.158593 8.70501 0.345369L2.92839 7.62214L2.8996 7.66427H1.63262C1.14159 7.66957 0.678494 7.89847 0.369098 8.28881C0.0593996 8.67915 -0.0640784 9.18996 0.0317688 9.6831L2.15274 20.6004L2.15245 20.6001C2.33867 21.5587 2.8441 22.4215 3.58206 23.0414C4.32037 23.6612 5.24539 24 6.2004 24H17.8158C18.7705 24 19.6959 23.6612 20.4342 23.0414C21.1722 22.4216 21.6773 21.5587 21.8635 20.6001L23.9638 9.68281C24.0665 9.18965 23.9482 8.67532 23.642 8.28052L23.642 8.28086ZM20.2503 20.2706C20.1386 20.8453 19.8358 21.3626 19.3934 21.7344C18.951 22.1065 18.3964 22.31 17.8239 22.3103H6.17971C5.60578 22.3118 5.04941 22.1091 4.60532 21.737C4.16121 21.365 3.85728 20.8467 3.74529 20.2706L1.63264 9.35328H22.3627L20.2503 20.2706Z"
        fill="#7D84EB"
      />
      <path
        d="M11.998 18.9443C12.2168 18.9443 12.4269 18.8553 12.5814 18.6968C12.7363 18.5386 12.8232 18.3236 12.8232 18.0997V13.7792C12.8232 13.3128 12.4539 12.9346 11.998 12.9346C11.5421 12.9346 11.1729 13.3128 11.1729 13.7792V18.0997C11.1729 18.3236 11.2598 18.5386 11.4146 18.6968C11.5692 18.8553 11.7793 18.9443 11.998 18.9443H11.998Z"
        fill="#7D84EB"
      />
      <path
        d="M16.124 18.9443C16.3427 18.9443 16.5529 18.8553 16.7074 18.6968C16.8623 18.5386 16.9492 18.3236 16.9492 18.0997V13.7792C16.9492 13.3128 16.5799 12.9346 16.124 12.9346C15.6681 12.9346 15.2988 13.3128 15.2988 13.7792V18.0997C15.2988 18.3236 15.3858 18.5386 15.5406 18.6968C15.6952 18.8553 15.9053 18.9443 16.124 18.9443H16.124Z"
        fill="#7D84EB"
      />
      <path
        d="M7.87155 18.9443C8.0903 18.9443 8.30041 18.8553 8.45498 18.6968C8.60983 18.5386 8.69674 18.3236 8.69674 18.0997V13.7792C8.69674 13.3128 8.32747 12.9346 7.87157 12.9346C7.41567 12.9346 7.04639 13.3128 7.04639 13.7792V18.0997C7.04639 18.3236 7.13331 18.5386 7.28816 18.6968C7.44272 18.8553 7.65283 18.9443 7.87158 18.9443H7.87155Z"
        fill="#7D84EB"
      />
    </svg>
  );
};
