import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextStyles } from "@themes/textStyles";

const ErrorFallback = ({ error }: any) => {
  const classes = useStyles();
  const textStyles = TextStyles();
  return (
    <Grid
      container
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item position="relative">
        <Box className={classes.boxImg}>
          <img
            width={100}
            height={100}
            src="https://colorlib.com/etc/404/colorlib-error-404-18/img/emoji.png"
            alt=""
          />
        </Box>
        <Box className={classes.boxContent}>
          <Typography className={textStyles.h4}>
            OPPS! SOMETHING WENT WRONG!
          </Typography>
          <Typography className={textStyles.paragraph_small} color="error">
            {error?.message}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  boxImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 250,
    height: 250,
    backgroundColor: "#F2F5F8",
    borderRadius: "50%",
  },
  boxContent: {
    position: "absolute",
    bottom: 20,
    width: "max-content",
    left: "60%",
  },
});

export default ErrorFallback;
