import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  IGetDetailCompanyMongoRequestedAction,
  IGetDetailCompanyRequestedAction,
  ISearchCompanyRequestedAction,
  SearchCompanyTypes,
} from "./searchCompany.type";
import {
  clearDataSearchCompany,
  clearDetailCompany,
  getDetailCompanyFailed,
  getDetailCompanySucceeded,
  searchCompanyFailed,
  searchCompanySucceeded,
} from "./searchCompany.creator";
import {
  getDetailCompanyService,
  searchCompanyService,
  searchCompanyMongoService,
  getDetailCompanyMongoService,
} from "./searchCompany.service";
import {
  IResponseCompanyPappers,
  IResponseDetailCompany,
  IFilterCompanyMongoDto,
  IResponseCompanyMongo,
} from "./searchCompany.dto";
import isNumberic from "@utils/checkNumberic";

type LegalStatusType =
  | "AutoEntrepreneur"
  | "EI"
  | "EIRL"
  | "EURL"
  | "SA"
  | "SARL"
  | "SAS"
  | "SASU"
  | "SCI"
  | "Autre";

function* searchCompany({ q }: ISearchCompanyRequestedAction) {
  try {
    const dataMap = new Map();
    let dataRedux: Array<{ value: any; label: any; _id?: string }> = [];

    // handle fetch data of the company from Pappers
    const dataResponse: AxiosResponse<IResponseCompanyPappers> = yield call(
      searchCompanyService,
      q
    );
    const dataCheck: any = dataResponse?.data;
    if (dataCheck) {
      if (dataCheck?.resultats_nom_entreprise) {
        for (let i = 0; i < dataCheck?.resultats_nom_entreprise?.length; i++) {
          let company: any = dataCheck?.resultats_nom_entreprise[i];
          dataMap.set(company?.siren, {
            name: company?.nom_entreprise,
            postalCode: company?.siege?.code_postal,
          });
        }
      }
      if (dataCheck?.resultats_siret) {
        for (let i = 0; i < dataCheck?.resultats_siret?.length; i++) {
          let company: any = dataCheck?.resultats_siret[i];
          dataMap.set(company?.siren, {
            name: company?.nom_entreprise,
            postalCode: company?.siege?.code_postal,
          });
        }
      }
      if (dataCheck?.resultats_siren) {
        for (let i = 0; i < dataCheck?.resultats_siren?.length; i++) {
          let company: any = dataCheck?.resultats_siren[i];
          dataMap.set(company?.siren, {
            name: company?.nom_entreprise,
            postalCode: company?.siege?.code_postal,
          });
        }
      }
    }

    //handle fetch data of the company from MongoDB
    const filterCompanyMongoDto: IFilterCompanyMongoDto = {};
    if (isNumberic(q) && q.length <= 14) {
      filterCompanyMongoDto.sirenOrSiret = q;
    } else {
      filterCompanyMongoDto.name = q;
    }
    const dataMongoResponse: AxiosResponse<IResponseCompanyPappers> =
      yield call(searchCompanyMongoService, filterCompanyMongoDto);
    const dataCheckMongo: any = dataMongoResponse.data;
    if (dataCheckMongo?.data?.length > 0) {
      for (let i = 0; i < dataCheckMongo?.data?.length; i++) {
        const company: any = dataCheckMongo.data?.[i];
        dataMap.set(company?.siren, {
          name: company?.name,
          _id: company?._id,
          postalCode: company?.codePostal,
        });
      }
    }

    dataRedux = Array.from(dataMap, ([key, value]) => ({
      value: key,
      label: value?.name,
      _id: value?._id,
      postalCode: value?.postalCode,
    }));

    yield put(searchCompanySucceeded({ companies: dataRedux }));
  } catch (err: any) {
    if (q) {
      yield put(searchCompanyFailed(err?.message));
    } else {
      yield put(searchCompanyFailed());
    }
    yield put(clearDataSearchCompany());
  }
}

function* watchSearchCompany() {
  yield takeLatest(SearchCompanyTypes.SEARCH_COMPANY_REQUESTED, searchCompany);
}

function* getDetailCompany({ siren }: IGetDetailCompanyRequestedAction) {
  try {
    const dataResponse: IResponseDetailCompany = yield call(
      getDetailCompanyService,
      siren
    );
    const dataPapper = dataResponse.data;

    let legalStatus: LegalStatusType = "Autre";
    switch (dataPapper?.categorie_juridique) {
      case "Auto entrepreneur": {
        legalStatus = "AutoEntrepreneur";
        break;
      }
      case "1000": {
        legalStatus = "EI";
        break;
      }
      case "EIRL - Entreprise individuelle responsabilité limité": {
        legalStatus = "EIRL";
        break;
      }
      case "5498": {
        legalStatus = "EURL";
        break;
      }
      case "5510": {
        legalStatus = "SA";
        break;
      }
      case "5410": {
        legalStatus = "SARL";
        break;
      }
      case "5710": {
        legalStatus = "SAS";
        break;
      }
      case "5720": {
        legalStatus = "SASU";
        break;
      }
      case "6540": {
        legalStatus = "SCI";
        break;
      }
      default: {
        legalStatus = "Autre";
      }
    }
    const dataRedux: IResponseCompanyMongo = {
      name: dataPapper?.nom_entreprise,
      siren: dataPapper?.siren,
      codeNAF: dataPapper?.code_naf,
      legalStatus,
      headquarterAddress: dataPapper?.siege?.adresse_ligne_1,
      city: dataPapper?.siege?.ville,
      codePostal: dataPapper?.siege?.code_postal,
      capitalSocial: dataPapper?.capital && parseFloat(dataPapper?.capital),
      birthday: dataPapper?.date_creation,
      legalRepresentative: {
        name: dataPapper?.representants?.[0]?.nom_complet,
        birthday: dataPapper?.representants?.[0]?.date_de_naissance,
      },
    };
    yield put(getDetailCompanySucceeded(dataRedux));
  } catch (err: any) {
    yield put(getDetailCompanyFailed(err?.message));
    yield put(clearDetailCompany());
  }
}

function* watchGetDetailCompany() {
  yield takeLatest(
    SearchCompanyTypes.GET_DETAIL_COMPANY_REQUESTED,
    getDetailCompany
  );
}

function* getDetailCompanyMongo({ id }: IGetDetailCompanyMongoRequestedAction) {
  try {
    const dataResponse: IResponseDetailCompany = yield call(
      getDetailCompanyMongoService,
      id
    );

    yield put(getDetailCompanySucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(getDetailCompanyFailed(err?.message));
    yield put(clearDetailCompany());
  }
}

function* watchGetDetailCompanyMongo() {
  yield takeLatest(
    SearchCompanyTypes.GET_DETAIL_COMPANY_MONGO_REQUESTED,
    getDetailCompanyMongo
  );
}

function* searchCompanySaga() {
  yield all([
    fork(watchSearchCompany),
    fork(watchGetDetailCompany),
    fork(watchGetDetailCompanyMongo),
  ]);
}

export default searchCompanySaga;
