import React, { FC } from "react";
import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import ManImg from "../../../assets/Images/man.png";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import moment from "moment";
import { formatCustomInput } from "@utils/formatCustomInput";
import generatingResultPDFStyles from "@pages/Contract/GeneratingResultPDF/generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
  qrCode?: string;
  isPIB?: boolean;
}

const ContentPage1: FC<IContentPage1Props> = ({
  infoInsurance,
  qrCode = "",
  isPIB = true,
}) => {
  return (
    <View>
      <View
        style={{
          width: "100%",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={styles.titleHeader}>Attestation D’ASSURANCE</Text>
        <Text style={styles.titleHeader2}>
          délivrée le {moment(new Date()).format("DD/MM/YYYY")}
        </Text>
        <Image
          source={qrCode}
          style={{ width: 65, height: 65, position: "absolute", right: 0 }}
        />
      </View>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          ASSURANCE RESPONSABILITE CIVILE & DECENNALE OBLIGATOIRE & DE
          RESPONSABILITE CIVILE PROFESSIONNELLE
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          des {isPIB ? "professions intellectuelles" : "entreprises"} du
          bâtiment
        </Text>
      </View>
      <View style={styles.imgContainer}>
        <View style={{ width: "45%", paddingRight: "20px" }}>
          <Image
            style={{
              width: "100%",
            }}
            src={ManImg}
          />
        </View>
        <View style={styles.resultsContainer}>
          <View style={styles.inforResultsContainer}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                N° de Police
              </Text>
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                {infoInsurance?.contract_code}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <Text style={styles.subInfoResult}>Date d’effet</Text>
              <Text style={styles.subInfoResult}>
                {moment(infoInsurance?.contractStartDate).format("DD/MM/YYYY")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                Valide du :
              </Text>
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                {moment(infoInsurance?.contractStartDate).format("DD/MM/YYYY")}{" "}
                au{" "}
                {infoInsurance?.contractStartDate &&
                  moment(
                    new Date(infoInsurance?.contractStartDate).setDate(
                      new Date(infoInsurance?.contractStartDate).getDate() + 364
                    )
                  ).format("DD/MM/YYYY")}
              </Text>
            </View>

            {!isPIB ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <Text style={styles.subInfoResult}>Reprise du passé</Text>
                <Text style={styles.subInfoResult}>
                  {infoInsurance?.resumptionDatePast &&
                    moment(infoInsurance.resumptionDatePast).format(
                      "DD/MM/YYYY"
                    )}
                </Text>
              </View>
            ) : null}
          </View>

          <View style={{ flex: 1 }}>
            <View style={styles.itemInfoDetail}>
              <Text style={styles.labelItemInfo}>Assureur :</Text>
              <Text style={styles.contentItemInfo}>Optim Assurance</Text>
            </View>
            <View style={styles.itemInfoDetail}>
              <Text style={styles.labelItemInfo}>Courtier :</Text>
              <Text style={styles.contentItemInfo}>Madecennale</Text>
            </View>
          </View>
        </View>
      </View>
      <Text
        style={{
          color: "#5E5873",
          fontWeight: 500,
          fontFamily: "Montserrat",
          fontSize: 10,
          marginVertical: 20,
        }}
      >
        La société d’Assurance Mutuelle Optim Assurance atteste que l’entreprise
        :
      </Text>
      <View>
        <View style={styles.headerSpecialTable}>
          <Text style={styles.titleHeaderTable}>INFORMATIONS DE l’ASSURE</Text>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>Nom :</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.souscripteur}
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>Adresse</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.address},{" "}
              {infoInsurance?.informations_du_souscripteur_1?.codePostal &&
                formatCustomInput(
                  infoInsurance?.informations_du_souscripteur_1?.codePostal
                )}
              , {infoInsurance?.informations_du_souscripteur_1?.city}
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>N° d’identification</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.siren &&
                formatCustomInput(
                  infoInsurance?.informations_du_souscripteur_1?.siren
                )}
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ padding: "5px 0 5px 20px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>Forme Juridique :</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: 0.5,
              backgroundColor: "#FFCC4D",
            }}
          />
          <View style={{ padding: "5px 0 5px 15px", flex: 1 }}>
            <Text style={styles.contentTablePage2}>
              {infoInsurance?.informations_du_souscripteur_1?.forme_juridique}
            </Text>
          </View>
        </View>
      </View>
      <Text
        style={{
          color: "#5E5873",
          fontWeight: 500,
          fontFamily: "Montserrat",
          fontSize: 10,
          marginTop: 20,
        }}
      >
        Est titulaire d’un contrat d’assurance responsabilité civile décennale
        obligatoire et responsabilité civile professionnelle n°
        {infoInsurance?.contract_code} à effet du{" "}
        {moment(infoInsurance?.contractStartDate).format("DD/MM/YYYY")}.
      </Text>

      {!isPIB ? (
        <Text
          style={[generatingResultPDFStyles.mainContent, { marginTop: 20 }]}
        >
          La garantie ne peut engager l'Assureur en dehors des termes et limites
          précisés par les clauses et conditions de la garantie au sein des
          Conditions générales CG_RCD_V2793 et de la Nomenclature V0993.
        </Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  titleHeader: {
    fontSize: 19,
    fontWeight: "bold",
    color: Colors.primary,
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 20,
  },
  titleHeader2: {
    fontSize: 18,
    color: "#5E5873",
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 5,
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginTop: 40,
    marginBottom: 35,
    padding: "8px 40px",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row",
  },
  resultsContainer: {
    width: "55%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  underlineTitleBoxContent: {
    width: "52px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 4,
  },
  inforResultsContainer: {
    border: "1px solid #FFCC4D",
    borderRadius: 5,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  subInfoResult: {
    color: "#5E5873",
    fontSize: 10,
    fontFamily: "Montserrat",
  },
  itemInfoDetail: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
  },
  labelItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    marginRight: 4,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  contentItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
  subTitleYellow: {
    fontSize: 10,
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: Colors.primary,
  },
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    padding: "4px 0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
});

export default ContentPage1;
