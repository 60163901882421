import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage7 = () => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Champ d’application
        </Text>
      </View>
      <View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            La garantie proposée ne pourra engager l'Assureur en dehors des
            termes et limites précisés par les clauses et conditions de la
            garantie au sein de la présente proposition d’assurance, des
            Conditions générales CG_PIB_V1293 et du référentiel PIB_V1694.
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Il est également précisé que, par la présente, il est proposé de
            couvrir uniquement la responsabilité civile et décennale du
            souscripteur liée aux activités déclarées en page 1 de la présente.
          </Text>
        </View>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            La garantie responsabilité civile décennale est applicable aux seuls
            chantiers démarrés durant la période d'effet du contrat. La garantie
            responsabilité civile professionnelle est établie sur la « base
            réclamation ».
          </Text>
        </View>
      </View>
      <View style={{ height: 25 }} />
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Territorialités
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            color: "#5E5873",
            fontFamily: "Montserrat",
            lineHeight: 1.6,
          }}
        >
          Ce contrat couvre les chantiers réalisés par l'Assuré en France
          métropolitaine.
        </Text>
      </View>
      <View style={{ height: 25 }} />
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Exclusions et déchéances
        </Text>
      </View>
      <View style={{ height: 25 }} />
      <Text
        style={{
          fontSize: 10,
          fontFamily: "Montserrat",
          color: "#EA5455",
          textDecoration: "underline",
        }}
      >
        Les exclusions et déchéances de garantie sont énoncées au sein des
        conditions générales qui vous ont été remises. Il est ici rappelé que ne
        sont pas couverts par le présent contrat notamment :
      </Text>
      <View style={{ marginTop: 20 }}>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            tout sinistre ayant pour origine des faits ou circonstances connus
            du souscripteur antérieurs à la date d'effet du contrat,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            les chantiers démarrés antérieurement à la date d'effet du contrat,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            l'abandon de chantier en cours,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            les entreprises déclarées comme étant en état de cessation des
            paiements à la date de la souscription du contrat,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            toute activité autre que celles mentionnées dans les présentes
            conditions particulières,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            l’assuré est déchu de tout droit de garantie en cas d’inobservation
            volontaire ou inexcusable par lui des règles de l’art, telles
            qu’elles sont définies par les réglementations en vigueur, les
            Documents Techniques Unifiés ou les Normes établis par les
            Organismes compétents à caractère officiel, ou dans le marché de
            travaux concerné.
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            tous travaux de désamiantage (sauf disposition contraire indiquée au
            sein d’une activité
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            l’activité d’entrepreneur en construction ou promoteur immobilier ;
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            tous travaux de techniques non courantes et ouvrages exceptionnels ;
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            tous travaux de piscine et accessoires ;
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            les dommages relevant normalement des contrats d’entretien,
            d’exploitation ou de maintenance ou de l’usure normale,
          </Text>
        </View>
        <View style={styles.liRed}>
          <View style={styles.dotLiRed} />
          <Text style={styles.mainContentRed}>
            les ouvrages non soumis à l’assurance décennale obligatoire.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  liRed: {
    position: "relative",
    paddingLeft: 12,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  dotLiRed: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#EA5455",
    left: 5,
    top: 4.5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
  mainContentRed: {
    fontSize: 9,
    color: "#EA5455",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
});

export default ContentPage7;
