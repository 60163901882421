import ContentPage12ArtisansResult from "@pages/Contract/GeneratingResultPDF/ContentPage12";
import ContentPage5 from "@pages/Contract/GeneratingResultPDF/ContentPage5";
import ContentPage6ArtisansResult from "@pages/Contract/GeneratingResultPDF/ContentPage6Artisans";
import ContentPage7ArtisansResult from "@pages/Contract/GeneratingResultPDF/ContentPage7Artisans";
import ContentPage8 from "@pages/Contract/GeneratingResultPDF/ContentPage8";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { FC } from "react";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import LogoCompany from "../../../assets/Images/logo.png";
import Logo2 from "../../../assets/Images/logo_2.png";
import ContentPage1 from "./ContentPage1";
import ContentPage10 from "./ContentPage10";
import ContentPage11 from "./ContentPage11";
import ContentPage2 from "./ContentPage2";
import ContentPage3 from "./ContentPage3";
import ContentPage4 from "./ContentPage4";
import ContentPage6 from "./ContentPage6";
import ContentPage7 from "./ContentPage7";
import ContentPage8Artisans from "./ContentPage8Artisans";
import ContentPage9 from "./ContentPage9";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IPDFConditionProps {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const PDFCondition: FC<IPDFConditionProps> = ({
  infoInsurance,
  isPIB = true,
}) => {
  return (
    <Document>
      <Page size={[595, 956]} style={styles.rootPage}>
        <View style={styles.header} fixed>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: "172px",
                height: "42px",
              }}
              src={LogoCompany}
            />
            <Image
              style={{
                width: "87px",
                height: "68px",
              }}
              src={Logo2}
            />
          </View>
        </View>

        <ContentPage1 infoInsurance={infoInsurance} isPIB={isPIB} />

        <ContentPage2 infoInsurance={infoInsurance} isPIB={isPIB} />

        <ContentPage3 infoInsurance={infoInsurance} isPIB={isPIB} />

        {/* 4 */}
        {isPIB ? (
          <ContentPage5
            franchiseDuContrat={infoInsurance?.franchise_du_contrat}
          />
        ) : (
          <ContentPage4
            franchiseDuContrat={infoInsurance?.franchise_du_contrat}
          />
        )}

        {/* 5 */}
        {isPIB ? (
          <ContentPage6 infoInsurance={infoInsurance} />
        ) : (
          <ContentPage6ArtisansResult infoInsurance={infoInsurance} />
        )}

        {/* 6 */}
        {isPIB ? <ContentPage7 /> : <ContentPage7ArtisansResult />}

        <ContentPage8
          fractionnement={infoInsurance?.fractionnement}
          NETCompanyAvecCom={infoInsurance?.NETCompanyAvecCom}
          pricingByPeriod={infoInsurance?.pricingByPeriod}
          cotisations={infoInsurance?.cotisations}
          taux_de_revision_du_contrat={
            infoInsurance?.taux_de_revision_du_contrat
          }
          infoInsurance={infoInsurance}
          frais_de_souscription={infoInsurance?.frais_de_souscription}
          isPIB={isPIB}
        />

        {/* 8 */}
        {isPIB ? <ContentPage9 /> : <ContentPage8Artisans />}

        {!isPIB ? <ContentPage9 isPIB={false} /> : null}

        {/* 10 */}
        {isPIB ? <ContentPage10 /> : <ContentPage11 isPIB={false} />}

        {/* 11 */}
        {isPIB ? (
          <ContentPage11 />
        ) : (
          <ContentPage12ArtisansResult
            dataActivities={infoInsurance?.dataActivities}
            isPIB={false}
          />
        )}

        <View style={styles.footer} fixed>
          <View style={styles.containerPageNumber}>
            <Text style={styles.textFooter}>Conditions Particulières</Text>
            <Text style={styles.textFooter}>
              Police N°{infoInsurance?.contract_code}
            </Text>
            <Text
              render={({ pageNumber, totalPages }) => (
                <Text style={styles.textFooter}>
                  Page <Text style={styles.textPage}>{pageNumber}</Text>/
                  <Text style={styles.textPage}>{totalPages}</Text>
                </Text>
              )}
            ></Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 5,
            }}
          >
            <Image
              style={{
                width: "72px",
                height: "18px",
              }}
              src={LogoCompany}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  rootPage: {
    paddingBottom: 80,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  header: {
    display: "flex",
    marginBottom: 10,
  },

  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    padding: "0 40px",
  },
  containerPageNumber: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  textFooter: {
    fontFamily: "Montserrat",
    fontSize: 6,
    color: "#6E6B7B",
    fontWeight: 300,
  },
  textPage: {
    fontFamily: "Montserrat",
    fontSize: 6,
    color: "#6E6B7B",
    fontWeight: 500,
  },
});

export default PDFCondition;
