import ButtonCustom from "@components/ButtonCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

export interface TopBarProps {}

export default function TopBar(props: TopBarProps) {
  return (
    <Grid item xs={12}>
      <PaperShadowCustom sx={{ py: 1.5, px: 4 }}>
        <Box component="div" width={181} sx={{ mr: 0, ml: "auto" }}>
          <Link to="/select-type" style={{ textDecoration: "none" }}>
            <ButtonCustom
              title="Nouveau devis"
              startIcon={
                <AddCircleOutlineIcon
                  style={{ marginRight: 5, fontSize: 14 }}
                />
              }
            />
          </Link>
        </Box>
      </PaperShadowCustom>
    </Grid>
  );
}
