import { IActivityRedux } from "./savedActivities.dto";
import {
  IAddActivityAction,
  IAddManyActivitiesAction,
  IClearAllActivitiesAction,
  IRemoveActivityAction,
  IUpdateActivityAction,
  SavedActivitiesTypes,
} from "./savedActivities.type";

export function addActivity(activity: IActivityRedux): IAddActivityAction {
  return {
    type: SavedActivitiesTypes.ADD_ACTIVITY,
    activity,
  };
}

export function addManyActivities(
  activities: Array<IActivityRedux>
): IAddManyActivitiesAction {
  return {
    type: SavedActivitiesTypes.ADD_MANY_ACTIVITY,
    activities,
  };
}

export function updateActivity(
  activity: IActivityRedux
): IUpdateActivityAction {
  return {
    type: SavedActivitiesTypes.UPDATE_ACTIVITY,
    activity,
  };
}

export function removeActivity(idActivity: string): IRemoveActivityAction {
  return {
    type: SavedActivitiesTypes.REMOVE_ACTIVITY,
    idActivity,
  };
}

export function clearAllActivities(): IClearAllActivitiesAction {
  return {
    type: SavedActivitiesTypes.CLEAR_ALL_ACTIVITY,
  };
}
