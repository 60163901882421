import { AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getFranchiseFailed,
  getFranchiseSucceeded,
} from "./franchises.creator";
import { IResponseFranchise } from "./franchises.dto";
import { getFranchiseService } from "./franchises.service";
import {
  FranchiseTypes,
  IGetFranchiseRequestedAction,
} from "./franchises.type";

function* getFranchises({ professionId }: IGetFranchiseRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IResponseFranchise> = yield call(
      getFranchiseService,
      professionId
    );
    yield put(getFranchiseSucceeded(dataResponse.data));
  } catch (err) {
    yield put(getFranchiseFailed(err));
  }
}

function* watchGetFranchise() {
  yield takeLatest(FranchiseTypes.GET_FRANCHISE_REQUESTED, getFranchises);
}

function* franchiseSaga() {
  yield all([fork(watchGetFranchise)]);
}

export default franchiseSaga;
