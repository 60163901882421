import StatusContract from "@components/StatusContract";
import { Box, CircularProgress, Grow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/system";
import { updateDraftContract } from "@stores/contract/contract.creator";
import {
  ContractStatus,
  ContractType,
} from "@stores/listQuotes/listQuotes.dto";
import { IAppState } from "@stores/state";
import { TextStyles } from "@themes/textStyles";
import { notistack } from "@utils/notistack";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IBoxStatusProps {
  status?: string;
  contractType: ContractType;
  id: string;
}

interface TypeData {
  value: any;
  label: string;
  disabled?: boolean;
  color: string;
}

const BoxStatus: FC<IBoxStatusProps> = ({ status, contractType, id }) => {
  const classes = useStyles();
  const textStyles = TextStyles();
  const dispatch = useDispatch();

  const { loading, error, response } = useSelector(
    (state: IAppState) => state.dataContract
  );

  const data: TypeData[] = useMemo(
    () => [
      {
        value: ContractStatus.DRAFT,
        label: ContractStatus.DRAFT,
        color: "#B9B9C3",
        disabled: true,
      },
      {
        value: ContractStatus.IN_PROGRESS,
        label: ContractStatus.IN_PROGRESS,
        color: "#FF9F43",
        disabled: true,
      },
      {
        value: ContractStatus.VALIDATE,
        label: ContractStatus.VALIDATE,
        color: "#28C76F",
        disabled: true,
      },
      {
        value: ContractStatus.INVALIDATE,
        label: "Refusé",
        color: "#EA5455",
      },
      {
        value: ContractStatus.OBSOLETE,
        label: ContractStatus.OBSOLETE,
        color: "#293241",
      },
    ],
    []
  );

  const [open, setOpen] = useState<boolean>(false);
  const [color, setColor] = useState<string>("#B9B9C3");
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (status === ContractStatus.DRAFT) {
      setColor("#B9B9C3");
    } else if (status === ContractStatus.IN_PROGRESS) {
      setColor("#FF9F43");
    } else if (status === ContractStatus.VALIDATE) {
      setColor("#28C76F");
    } else if (status === ContractStatus.OBSOLETE) {
      setColor("#293241");
    } else {
      setColor("#EA5455");
    }
  }, [status]);

  const handleChangeStatus = (status: string) => {
    dispatch(updateDraftContract({ status, type: contractType }, id));
    setSelected(status);
  };

  useEffect(() => {
    error && notistack.error("La mise à jour du statut a échoué");
  }, [error]);

  useEffect(() => {
    setOpen(false);
  }, [response]);

  return (
    <>
      <Box
        position="relative"
        component="div"
        display="flex"
        justifyContent="center"
      >
        <StatusContract
          title={
            status === ContractStatus.INVALIDATE
              ? "Refusé"
              : status
              ? status
              : ""
          }
          onClick={() => setOpen(!open)}
          color={color}
          sx={{
            ml: "auto",
            mr: "auto",
            background: lighten(color, 0.8),
          }}
          disabled={
            status === ContractStatus.INVALIDATE ||
            status === ContractStatus.VALIDATE ||
            status === ContractStatus.OBSOLETE
          }
        />
        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 300 } : {})}
        >
          <Box component="ul" className={classes.boxDropdown}>
            {data?.map((item: TypeData, index: number) => (
              <Box
                style={{
                  pointerEvents: item?.disabled ? "none" : "auto",
                }}
                key={index}
                component="li"
                className={classes.itemDropdown}
                onClick={() => handleChangeStatus(item.value)}
              >
                <Typography
                  className={`${textStyles.paragraph_small_semi_bold} ${classes.textDropdown}`}
                  style={{
                    color: item?.disabled ? "#d1d1d1" : item.color,
                    background: lighten(item.color, 0.8),
                    border:
                      item.value === status
                        ? `1px solid ${item.color}`
                        : "unset",
                  }}
                >
                  {item?.label}
                  {loading && selected === item.value && (
                    <CircularProgress
                      size={12}
                      style={{ color: item.color, marginLeft: 5 }}
                    />
                  )}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grow>
      </Box>
      {open && (
        <Box
          component="span"
          sx={{
            m: 0,
            width: "100vw",
            height: "100vh",
            left: 0,
            top: 0,
            zIndex: 9,
          }}
          display="flex"
          position="fixed"
          onClick={() => setOpen(false)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  boxDropdown: {
    zIndex: 9999,
    background: "#fff",
    boxShadow: "0px 4.43679px 26.6208px rgba(0, 0, 0, 0.06)",
    listStyle: "none",
    margin: 0,
    borderRadius: "6.65519px",
    padding: 0,
    position: "absolute",
    top: "125%",
    width: "127px",
  },
  itemDropdown: {
    cursor: "pointer",
    "&:hover": {
      background: "#F8F8F9",
    },
    padding: "0.6em 1em",
  },
  textDropdown: {
    borderRadius: 20,
    padding: "2px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default BoxStatus;
