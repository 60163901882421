import * as React from "react";

const CalendarIcon = ({ width = 12, height = 14 }: any) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.083 1.75H8.917v-.584c0-.35-.234-.583-.584-.583s-.583.233-.583.583v.584h-3.5v-.584c0-.35-.233-.583-.583-.583s-.584.233-.584.583v.584H1.917c-.992 0-1.75.758-1.75 1.75v8.166c0 .992.758 1.75 1.75 1.75h8.166c.992 0 1.75-.758 1.75-1.75V3.5c0-.992-.758-1.75-1.75-1.75ZM1.917 2.916h1.166V3.5c0 .35.234.583.584.583s.583-.233.583-.583v-.584h3.5V3.5c0 .35.233.583.583.583s.584-.233.584-.583v-.584h1.166c.35 0 .584.234.584.584v1.75H1.333V3.5c0-.35.234-.584.584-.584Zm0 9.334h8.166c.35 0 .584-.234.584-.584v-5.25H1.333v5.25c0 .35.234.584.584.584Z"
      fill="#BABFC7"
    />
  </svg>
);

export default CalendarIcon;
