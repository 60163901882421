import { IContract } from "./contract.dto";
import { CreateContractActions, CreateContractTypes } from "./contract.type";

const initialCreateContractState: IContract = {
  information_company: undefined,
  history_insurance: undefined,
  loading: false,
  info_contract: undefined,
  idContractUpdate: undefined,
  loadingCalculate: false,
};

export default function createContractReducer(
  state = initialCreateContractState,
  action: CreateContractActions
): IContract {
  switch (action.type) {
    case CreateContractTypes.CREATE_CONTRACT_REQUESTED: {
      return {
        ...state,
      };
    }

    case CreateContractTypes.GET_DETAIL_CONTRACT_REQUESTED: {
      return {
        ...state,
        loadingGet: true,
      };
    }

    case CreateContractTypes.GET_DETAIL_CONTRACT_SUCCEEDED: {
      return {
        ...state,
        loadingGet: false,
        data_contract_update: action?.response,
      };
    }

    case CreateContractTypes.GET_DETAIL_CONTRACT_FAILED: {
      return {
        ...state,
        loadingGet: false,
        error: action?.error,
      };
    }

    case CreateContractTypes.SAVE_DRAFT_CONTRACT_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case CreateContractTypes.SAVE_DRAFT_CONTRACT_SUCCEEDED: {
      return {
        ...state,
        response: action?.response,
        loading: false,
        message: action?.message,
        contract_id: action?.response?.contract_id,
      };
    }

    case CreateContractTypes.SAVE_DRAFT_CONTRACT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case CreateContractTypes.SAVE_INFO_CONTRACT_INTO_REDUX: {
      return {
        ...state,
        info_contract: action.info_contract,
      };
    }

    case CreateContractTypes.CLEAR_INFO_CONTRACT_REDUX: {
      return {
        ...state,
        info_contract: undefined,
      };
    }

    case CreateContractTypes.CLEAR_RESPONSE_DRAFT_CONTRACT: {
      return {
        ...state,
        loading: false,
        response: undefined,
        error: undefined,
        message: undefined,
      };
    }

    case CreateContractTypes.UPDATE_DRAFT_CONTRACT_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case CreateContractTypes.UPDATE_DRAFT_CONTRACT_SUCCEEDED: {
      return {
        ...state,
        response: action?.response,
        loading: false,
        contract_id: action?.response?.contract_id,
      };
    }

    case CreateContractTypes.UPDATE_DRAFT_CONTRACT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case CreateContractTypes.PASS_IN_GUARANTEE_REQUESTED: {
      return {
        ...state,
        loadingGet: true,
      };
    }
    case CreateContractTypes.PASS_IN_GUARANTEE_SUCCEEDED: {
      return {
        ...state,
        responsePassGuarantee: action?.response,
        loadingGet: false,
      };
    }
    case CreateContractTypes.PASS_IN_GUARANTEE_FAILED: {
      return {
        ...state,
        loadingGet: false,
        error: action?.error,
      };
    }

    case CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        message: action?.message,
      };
    }

    case CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case CreateContractTypes.INFO_DRAFT_CONTRACT_UPDATE: {
      return {
        ...state,
        idContractUpdate: action.idContract,
      };
    }

    case CreateContractTypes.CLEAR_INFO_DRAFT_CONTRACT_UPDATE: {
      return {
        ...state,
        idContractUpdate: undefined,
      };
    }

    case CreateContractTypes.SAVE_INFORMATION_COMPANY: {
      return {
        ...state,
        information_company: action.informationCompany,
      };
    }

    case CreateContractTypes.SAVE_HISTORY_INSURANCE: {
      return {
        ...state,
        history_insurance: action.historyInsurance,
      };
    }

    case CreateContractTypes.CALCULATE_RESULT_REQUESTED: {
      return {
        ...state,
        loadingCalculate: true,
      };
    }

    case CreateContractTypes.CALCULATE_RESULT_SUCCEEDED: {
      return {
        ...state,
        resultCalculate: action?.response,
        loadingCalculate: false,
      };
    }

    case CreateContractTypes.CALCULATE_RESULT_FAILED: {
      return {
        ...state,
        error: action?.error,
        loadingCalculate: false,
      };
    }

    case CreateContractTypes.ADD_COMMENTS: {
      return {
        ...state,
        comments: action.comments,
      };
    }

    case CreateContractTypes.CLEAR_RESULT_CALCULATE: {
      return {
        ...state,
        comments: undefined,
        resultCalculate: undefined,
      };
    }

    case CreateContractTypes.CLEAR_ALL_DATA_CONTRACT: {
      return {
        ...state,
        information_company: undefined,
        history_insurance: undefined,
        info_contract: undefined,
        response: undefined,
        error: undefined,
        loading: false,
        message: undefined,
        idContractUpdate: undefined,
        data_contract_update: undefined,
        resultCalculate: undefined,
        contract_id: undefined,
        responsePassGuarantee: undefined,
      };
    }
    case CreateContractTypes.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        error: undefined,
        message: undefined,
      };

    default:
      return state;
  }
}
