import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import { Colors } from "@themes/colors";

interface PaginationCustomProps {
  count?: number;
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  page?: number;
}

const PaginationCustom: FC<PaginationCustomProps> = ({
  count,
  onChange = () => {},
  page,
}) => {
  return <PaginationStyled page={page} onChange={onChange} count={count} />;
};

const PaginationStyled = styled(Pagination)({
  "& .MuiPagination-ul": {
    "& .MuiButtonBase-root": {
      minWidth: 28,
      height: 28,
    },
    "& .MuiPaginationItem-previousNext": {
      background: "#F3F2F7",
      opacity: 1,
      color: Colors.primary,
    },
    "& .MuiPaginationItem-page": {
      margin: 0,
      borderRadius: 0,
      background: "#F3F2F7",
    },
    "& .MuiPaginationItem-ellipsis": {
      height: 28,
      lineHeight: 2.5,
      backgroundColor: "#F3F2F7",
      borderRadius: 0,
      margin: 0,
      minWidth: 28,
    },
    "& .Mui-selected": {
      backgroundColor: Colors.primary,
      color: "#fff",
      borderRadius: "50%",
      transform: "scale(1.15)",
      zIndex: 9,
      "&:hover": {
        color: "#fff",
        background: Colors.primary,
      },
    },
    "& li": {
      backgroundColor: "#F3F2F7",
    },
    "& li:nth-of-type(1)": {
      backgroundColor: "unset",
    },
    "& li:nth-last-of-type(1)": {
      backgroundColor: "unset",
    },
    "& li:nth-of-type(2)": {
      borderRadius: "16px 0 0 16px",
      marginLeft: 5,
      "& button": {
        borderRadius: "16px 0 0 16px",
        "&.Mui-selected": {
          borderRadius: "50%",
        },
      },
    },
    "& li:nth-last-of-type(2)": {
      borderRadius: "0 16px 16px 0px",
      marginRight: 5,
      "& button": {
        borderRadius: "0 16px 16px 0px",
        "&.Mui-selected": {
          borderRadius: "50%",
        },
      },
    },
  },
});

export default PaginationCustom;
