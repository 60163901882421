import SliderCustom from "@components/SliderCustom";
import Spacing from "@components/Spacing";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";

interface IBoxSlideProps {
  defaultValue?: number;
  onChange?: (e: Event, number: number | number[]) => void;
}

const BoxSlide: FC<IBoxSlideProps> = ({
  defaultValue,
  onChange = () => {},
}) => {
  const classes = useStyles();
  const [prevNumber, setPrevNumber] = useState<number>(0);
  const handleChange = (e: Event, number: number | number[]) => {
    onChange(e, number);
    if (typeof number === "number") {
      setPrevNumber(number);
    }
  };
  useEffect(() => {
    defaultValue !== undefined && setPrevNumber(defaultValue);
  }, [defaultValue]);
  return (
    <>
      <Box component="div" display="flex" justifyContent="space-between">
        <Box component="p" className={classes.labelSlider}>
          {`${prevNumber}%`}
        </Box>
        <Box component="p" className={classes.labelSlider}>
          {`${100 - prevNumber}%`}
        </Box>
      </Box>
      <Spacing height={15} />
      <SliderCustom
        onChange={handleChange}
        defaultValue={defaultValue || 0}
        type="none-number"
      />
    </>
  );
};

const useStyles = makeStyles({
  labelSlider: {
    width: 50,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    color: "#6E6B7B",
    borderRadius: "5px",
    "&::before": {
      display: "none",
    },
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
  },
});

export default BoxSlide;
