import * as React from "react"

const BackgroundChangePass = () => (
  <svg
    width={637}
    height={572}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.1} filter="url(#a)">
      <ellipse
        cx={121.531}
        cy={275.607}
        rx={115.531}
        ry={116.393}
        fill="#FFCC4D"
      />
    </g>
    <path
      opacity={0.05}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M380.75 177.185c0-60.751 49.249-110 110-110s110 49.249 110 110-49.249 110-110 110-110-49.249-110-110Zm173-.5c0-34.518-27.982-62.5-62.5-62.5s-62.5 27.982-62.5 62.5 27.982 62.5 62.5 62.5 62.5-27.982 62.5-62.5Z"
      fill="#FF9F43"
    />
    <ellipse
      opacity={0.1}
      cx={312.383}
      cy={53.041}
      rx={37.944}
      ry={38.041}
      stroke="#FFCC4D"
      strokeWidth={30}
    />
    <path
      opacity={0.1}
      d="M491.165 67.185c60.522 0 109.585 49.249 109.585 110s-49.063 110-109.585 110c-36.461 0-69.928-18.033-90.242-47.58-7.393-10.753-4.702-25.485 6.01-32.906 10.605-7.346 25.096-4.772 32.558 5.713l.224.32c11.606 16.882 30.647 27.141 51.45 27.141 34.491 0 62.452-28.066 62.452-62.688 0-34.275-27.404-62.126-61.419-62.68l-1.033-.008c-13.015 0-23.567-10.591-23.567-23.656s10.552-23.656 23.567-23.656Z"
      fill="#FF9F43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.975 178.137c0-13.883 11.254-25.137 25.137-25.137h151.09c13.882 0 25.137 11.254 25.137 25.137v367.186c0 13.883-11.255 25.137-25.137 25.137h-151.09c-13.883 0-25.137-11.254-25.137-25.137V178.137Z"
      fill="#8EE9F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.237 179.935c0-10.23 8.293-18.522 18.522-18.522h26.679c.749 0 1.361.595 1.383 1.342a9.91 9.91 0 0 0 9.906 9.624h73.199a9.91 9.91 0 0 0 9.906-9.624 1.383 1.383 0 0 1 1.383-1.342h26.679c10.229 0 18.522 8.292 18.522 18.522v361.098c0 10.23-8.293 18.522-18.522 18.522H194.759c-10.229 0-18.522-8.292-18.522-18.522V179.935Z"
      fill="#fff"
    />
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={176}
      y={161}
      width={187}
      height={399}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.237 179.935c0-10.23 8.293-18.522 18.522-18.522h26.679c.749 0 1.361.595 1.383 1.342a9.91 9.91 0 0 0 9.906 9.624h73.199a9.91 9.91 0 0 0 9.906-9.624 1.383 1.383 0 0 1 1.383-1.342h26.679c10.229 0 18.522 8.292 18.522 18.522v361.098c0 10.23-8.293 18.522-18.522 18.522H194.759c-10.229 0-18.522-8.292-18.522-18.522V179.935Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)" fill="#E97EFF" fillOpacity={0.549}>
      <ellipse cx={246.823} cy={403.738} rx={15.969} ry={15.951} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.916 235.001a5.234 5.234 0 0 1 5.234-5.234h131.754a5.234 5.234 0 1 1 0 10.468H204.15a5.235 5.235 0 0 1-5.234-5.234Z"
      />
    </g>
    <path
      d="M280.571 160.309c1.723 0 3.119 1.339 3.119 2.99 0 1.616-1.335 2.932-3.004 2.989l-.115.002h-35.267c-1.722 0-3.119-1.339-3.119-2.991 0-1.615 1.335-2.931 3.005-2.988l.114-.002h35.267ZM293.21 159.81a3.117 3.117 0 0 1 3.119 3.116 3.117 3.117 0 0 1-3.004 3.113l-.115.002h-.163a3.117 3.117 0 0 1-3.119-3.115 3.117 3.117 0 0 1 3.005-3.114l.114-.002h.163Z"
      fill="#FFCC4D"
    />
    <g filter="url(#c)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.229 359.27a8.757 8.757 0 0 1 8.757-8.756h191.189a8.756 8.756 0 0 1 8.756 8.756v119.255a8.756 8.756 0 0 1-8.756 8.756H196.986a8.757 8.757 0 0 1-8.757-8.756V359.27Z"
        fill="#fff"
      />
    </g>
    <mask
      id="d"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={188}
      y={350}
      width={209}
      height={138}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.229 359.27a8.757 8.757 0 0 1 8.757-8.756h191.189a8.756 8.756 0 0 1 8.756 8.756v119.255a8.756 8.756 0 0 1-8.756 8.756H196.986a8.757 8.757 0 0 1-8.757-8.756V359.27Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.547 345.15h218.736v40.226H183.547V345.15Z"
        fill="#A8EDF6"
      />
      <path
        opacity={0.366}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.621 374.649c0-1.111.901-2.011 2.012-2.011h66.882a2.01 2.01 0 1 1 0 4.022h-66.882c-1.111 0-2.012-.9-2.012-2.011Z"
        fill="#FFCC4D"
      />
      <rect
        x={205.621}
        y={361.241}
        width={37.459}
        height={4.023}
        rx={2.011}
        fill="#fff"
      />
      <rect
        x={212.311}
        y={452.419}
        width={37.459}
        height={4.693}
        rx={2.346}
        fill="#EA5455"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.311 468.174a2.346 2.346 0 0 1 2.346-2.346h19.388a2.346 2.346 0 1 1 0 4.693h-19.388a2.346 2.346 0 0 1-2.346-2.347Z"
        fill="#F8F9FA"
      />
      <rect
        x={271.844}
        y={452.419}
        width={37.459}
        height={4.693}
        rx={2.346}
        fill="#FF9F43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.844 468.174a2.346 2.346 0 0 1 2.346-2.346h19.388a2.346 2.346 0 1 1 0 4.693H274.19a2.347 2.347 0 0 1-2.346-2.347Z"
        fill="#F8F9FA"
      />
      <rect
        x={333.384}
        y={452.419}
        width={37.459}
        height={4.693}
        rx={2.346}
        fill="#28C76F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.384 468.174a2.346 2.346 0 0 1 2.347-2.346h19.388a2.346 2.346 0 1 1 0 4.693h-19.388a2.347 2.347 0 0 1-2.347-2.347Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M352.114 438.843c10.621 0 19.231-8.63 19.231-19.275 0-10.645-8.61-19.275-19.231-19.275-10.621 0-19.231 8.63-19.231 19.275 0 10.645 8.61 19.275 19.231 19.275Zm0-7.71c-6.373 0-11.539-5.178-11.539-11.565s5.166-11.565 11.539-11.565c6.373 0 11.539 5.178 11.539 11.565s-5.166 11.565-11.539 11.565Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.345 419.568c0-10.645-8.61-19.275-19.231-19.275a3.85 3.85 0 0 0-3.846 3.855 3.85 3.85 0 0 0 3.846 3.855c6.3 0 11.421 5.061 11.537 11.348l.002.217a3.85 3.85 0 0 0 3.846 3.855 3.85 3.85 0 0 0 3.846-3.855Z"
        fill="#28C76F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.912 438.843c10.621 0 19.231-8.63 19.231-19.275 0-10.645-8.61-19.275-19.231-19.275-10.622 0-19.232 8.63-19.232 19.275 0 10.645 8.61 19.275 19.232 19.275Zm0-7.71c-6.373 0-11.539-5.178-11.539-11.565s5.166-11.565 11.539-11.565c6.372 0 11.538 5.178 11.538 11.565s-5.166 11.565-11.538 11.565Z"
        fill="#F2F2F2"
      />
      <path
        d="M291.911 438.843c10.622 0 19.232-8.63 19.232-19.275 0-10.645-8.61-19.275-19.232-19.275a3.85 3.85 0 0 0-3.846 3.855 3.85 3.85 0 0 0 3.846 3.855c6.373 0 11.539 5.178 11.539 11.565 0 6.314-5.049 11.447-11.322 11.563l-.217.002a3.85 3.85 0 0 0-3.846 3.855 3.85 3.85 0 0 0 3.846 3.855Z"
        fill="#FF9F43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.709 438.843c10.621 0 19.231-8.63 19.231-19.275 0-10.645-8.61-19.275-19.231-19.275-10.621 0-19.231 8.63-19.231 19.275 0 10.645 8.61 19.275 19.231 19.275Zm0-7.71c-6.373 0-11.539-5.178-11.539-11.565s5.166-11.565 11.539-11.565c6.373 0 11.539 5.178 11.539 11.565s-5.166 11.565-11.539 11.565Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.478 419.568c0 10.645 8.61 19.275 19.231 19.275 10.621 0 19.231-8.63 19.231-19.275 0-10.645-8.61-19.275-19.231-19.275a3.85 3.85 0 0 0-3.846 3.855 3.85 3.85 0 0 0 3.846 3.855c6.373 0 11.539 5.178 11.539 11.565s-5.166 11.565-11.539 11.565c-6.373 0-11.539-5.178-11.539-11.565a3.85 3.85 0 0 0-3.846-3.855 3.85 3.85 0 0 0-3.846 3.855Z"
        fill="#EA5455"
      />
    </g>
    <g filter="url(#e)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.441 206.352h207.05a4.482 4.482 0 0 1 4.477 4.489v117.842c0 2.48-2.004 4.49-4.477 4.49h-207.05a4.483 4.483 0 0 1-4.477-4.49V210.841a4.482 4.482 0 0 1 4.477-4.489Z"
        fill="#8D82FD"
      />
    </g>
    <ellipse cx={343.994} cy={239.358} rx={12.983} ry={12.935} fill="#D8D8D8" />
    <ellipse cx={361.901} cy={239.358} rx={12.983} ry={12.935} fill="#93C2F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m352.83 230.104.119-.111a12.876 12.876 0 0 1 4.028 9.365c0 3.684-1.547 7.009-4.028 9.365a12.865 12.865 0 0 1-4.031-9.365c0-3.627 1.499-6.905 3.912-9.254l.119-.111-.119.111Z"
      fill="#fff"
      fillOpacity={0.416}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.973 247.609h27.085c.989 0 1.79.799 1.79 1.784v19.626c0 .985-.801 1.784-1.79 1.784h-27.085a1.788 1.788 0 0 1-1.791-1.784v-19.626c0-.985.802-1.784 1.791-1.784Z"
      fill="#8D82FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.211 283.961h22.384a4.02 4.02 0 0 1 4.029 4.014 4.02 4.02 0 0 1-4.029 4.014h-22.384a4.022 4.022 0 0 1-4.029-4.014 4.022 4.022 0 0 1 4.029-4.014ZM220.211 304.032h55.96a4.022 4.022 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.015h-55.96a4.022 4.022 0 0 1-4.029-4.015 4.022 4.022 0 0 1 4.029-4.014ZM347.799 304.032h22.384a4.022 4.022 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.015h-22.384a4.022 4.022 0 0 1-4.029-4.015 4.022 4.022 0 0 1 4.029-4.014ZM220.211 225.977h22.384a4.02 4.02 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.015h-22.384a4.022 4.022 0 0 1-4.029-4.015 4.022 4.022 0 0 1 4.029-4.014ZM262.293 283.961h22.384a4.022 4.022 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.014h-22.384a4.022 4.022 0 0 1-4.029-4.014 4.022 4.022 0 0 1 4.029-4.014ZM305.27 283.961h22.384a4.022 4.022 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.014H305.27a4.022 4.022 0 0 1-4.029-4.014 4.022 4.022 0 0 1 4.029-4.014ZM347.352 283.961h22.384a4.022 4.022 0 0 1 4.029 4.014 4.022 4.022 0 0 1-4.029 4.014h-22.384a4.022 4.022 0 0 1-4.029-4.014 4.022 4.022 0 0 1 4.029-4.014Z"
      fill="#fff"
      fillOpacity={0.647}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.482 541.999s-.154 9.857 5.899 13.358c6.053 3.502 15.431 10.008 15.431 10.008l-35.675 1.208 1.017-24.662 13.328.088Z"
      fill="url(#f)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.761 553.935c2.759.285 2.156 4.275 7.161 4.081 3.647-.142 9.336-6.869 14.015-4.377 7.142 3.803 15.516 8.931 17.569 10.152 2.054 1.221 2.865 5.827-3.203 6.392-5.802.541-13.634.481-15.596.123-1.962-.358-5.602-2.86-8.53-2.46-2.928.4-3.261 1.41-4.898 1.857-1.636.447-6.575.544-7.087-.835-.588-1.583-1.178-15.112.569-14.933Z"
      fill="#482B61"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.996 332.889s13.609 106.815 14.747 116.917c1.137 10.102 7.385 81.964 6.53 91.55-.871 9.762-19.133 9.814-21.583 2.449-3.353-10.081-11.105-60.465-12.387-70.986-1.282-10.522-.832-22.737-5.984-33.51-3.689-7.713-15.896-103.682-15.896-103.682l34.573-2.738Z"
      fill="#2F2B57"
    />
    <path
      opacity={0.591}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m116.997 456.323.049.481c.519 5.58.955 24.061 1.306 55.445l-.783-4.688c-2.457-14.863-4.638-29.587-5.266-34.742-1.255-10.302-.85-22.229-5.669-32.835l-.315-.675c-1.624-3.395-4.897-23.883-8.054-45.762l.992 2.653c10.538 28.342 16.452 48.383 17.74 60.123Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      d="m151.575 555.354-1.707 2.553M154.555 556.352l-1.707 2.553"
      stroke="#fff"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m59.587 544.261-3.193 21.556 15.431-.078-.505-21.879-11.733.401Z"
      fill="url(#g)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.55 569.304c-.051-1.257 1.12-9.414 2.844-12.644 1.25-2.343 4.023-2.892 8.08-2.908 5.233-.021 9.607.771 9.6 2.908-.018 6.538 1.64 11.567 1.752 12.142.327 1.686-4.353 2.944-10.746 2.88-5.233-.052-11.45-.416-11.53-2.378Z"
      fill="#482B61"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.504 336.396s-7.076 47.673-11.358 77.837c-4.209 29.64-16.672 118.052-17.96 127.307-1.183 8.496-22.764 8.496-22.537-1.794.227-10.29-1.771-74.411 2.248-83.905 4.018-9.494.52-20.543.666-29.765.146-9.223 5.925-89.064 5.925-89.064l43.016-.616Z"
      fill="#3A3669"
    />
    <path
      opacity={0.462}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m68.797 398.216-.338 1.227c-4.097 14.72-5.51 16.046-4.35 39.523l.012.478c.04 5.906-2.768 36.472-8.427 91.698l-.026-3.433c-.198-22.53-1.028-63.264 2.126-71.613l.102-.255c4.019-9.494.52-20.543.666-29.766.147-9.222 5.926-89.063 5.926-89.063l7.382-.107.076 3.658c.53 28.562-.519 47.78-3.15 57.653Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      d="M62.605 558.533h5.648M62.605 561.528h5.648"
      stroke="#fff"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.035 240.078s5.911-1.395 6.116-11.13c.205-9.734.061-13.057.061-13.057l14.014-.236s.142 10.849.144 14.773c.005 10.54 7.312 9.65 7.312 9.65s-1.519 8.546-13.317 9.212c-9.211.522-14.33-9.212-14.33-9.212Z"
      fill="url(#h)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.926 210.97c-5.809-.226-4.861 8.32 1.172 8.309 0 0 6.485 13.104 16.666 13.138 4.903.016 6.505-4.374 7.975-7.456 1.47-3.082 2.549-7.692 2.638-10.514.097-3.01.109-12.897.109-12.897l-28.786-.013.226 9.433Z"
      fill="url(#i)"
    />
    <path
      d="M103.356 226.173c.871.48 1.907.362 2.709 0"
      stroke="#F49494"
      strokeLinecap="round"
    />
    <path
      d="M86.832 215.849c.06-1.371-.357-2.459-1.748-2.678"
      stroke="#FFCC4D"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.926 210.97s2.67 3.099 3.658 3.184c.989.085-.138-8.385 1.317-9.928 3.197-3.392 11.548 2.371 20.331.963 3.254-.521 3.196 5.039 3.196 5.039 8.21-7.525 11.186-13.461 1.376-15.94-.079-6.368-3.724-7.967-10.772-5.012-.942-8.603-13.12-7.305-18.664 3.64-4.012-3.924-9.652-.757-7.258 5.148-4.321 0-2.07 11.608 3.022 15.596.608-1.742 1.718-2.69 3.794-2.69Z"
      fill="#422851"
    />
    <path
      d="M101.386 223.414s2.766 2.615 6.238.485"
      stroke="#FFCC4D"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.553 271.334s1.662-9.239 3.861-12.477c2.199-3.238 2.84-8.678 4.915-8.478 2.075.2-.003 8.885-.003 8.885s9.676-7.259 14.066-9.76c4.005-2.282 1.808 9.522 1.182 11.026-1.491 3.582-18.741 17.099-18.741 17.099s-4.904-5.473-5.28-6.295Z"
      fill="url(#j)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.326 259.264s-.715 2.971-.358 2.971 6.924-7.748 6.924-7.748l-6.566 4.777Z"
      fill="#FFCC4D"
      fillOpacity={0.53}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.693 250.655c4.732 10.26 22.428 36.771 22.428 36.771s28.998-18.906 32.688-18.945c3.69-.039 8.7 4.857 8.385 8.236-.315 3.379-32.065 33.015-42.248 32.766-15.833-.388-35.688-41.561-37.727-47.064-5.46-14.741 11.859-21.772 16.474-11.764Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.015 287.407s3.349 4.905 3.949 4.583c.6-.322-1.317-5.996-1.317-5.996l-2.632 1.413Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.657 252.296c-1.485 7.124 8.689 43.028 5.89 59.825-2.214 13.285-4.328 31.576 1.41 35.637 5.842 4.135 56.13 7.418 63.298-4.061 5.687-9.106 1.031-29.302 0-38.514-2.057-18.38 1.049-50.141-2.44-56.637-2.165-4.03-16.923-9.227-17.477-9.066-.554.161-1.858 5.871-11.19 5.871-9.33 0-13.451-5.871-13.953-6.032-.501-.161-24.193 6.532-25.538 12.977Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m82.172 239.316.023.003c.502.161 4.622 6.032 13.954 6.032 9.331 0 10.635-5.71 11.189-5.871.2-.058 2.25.582 4.891 1.613l-.012.121c-6.67 11.2-25.013 12.581-34.817-.233l-.223-.297 1.094-.339c2.003-.609 3.436-.975 3.826-1.024l.075-.005Z"
      fill="#FF9B86"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m56.689 257.244.822.114c8.722 1.248 13.838 3.222 15.346 5.922.99 1.772.53 7.764-1.38 17.977l-.203 1.071a35.083 35.083 0 0 0 5.603 26.56l.3.427c1.81 2.531 3.478 5.864 5.004 9.998 3.54 9.59-3.99 17.67.78 31.936l-.974-.071c-8.867-.679-16.038-2.01-18.03-3.42-5.738-4.061-3.624-22.352-1.41-35.637 2.382-14.292-4.63-42.418-5.858-54.877Z"
      fill="#000"
      fillOpacity={0.067}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.88 328.606s3.348 3.949 6.205 4.441c2.858.491 14.816 2.83 15.546 3.176.69.328 3.049 1.57 5.066 3.562 1.748 1.727 4.771 5.82 3.758 6.525-1.084.753-4.01-2.939-5.478-3.852-1.54-.957-3.905-1.805-3.905-1.805s5.852 7.06 5.48 8.293c-.272.9-5.248 2.437-6.312 2.322-1.064-.115-6.927-4.408-9.563-4.908-1.828-.346-10.011-2.563-11.737-4.227-1.725-1.665-5.607-5.044-5.607-5.044l6.547-8.483Z"
      fill="url(#k)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.424 349.764s-6.124-6.17-6.38-6.065c-.255.105 5.336 6.495 5.336 6.495l1.044-.43ZM89.694 350.777s-4.587-4.203-4.887-4c-.3.202 3.674 4.343 3.674 4.343l1.213-.343Z"
      fill="#FFCC4D"
      fillOpacity={0.53}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.209 246.344c9.456 6.653 3.138 18.748-4.706 29.842-7.328 10.362-18.435 25.971-18.435 25.971s20.885 22.876 22.902 27.109c2.017 4.233-3.134 12.182-9.539 10.011-6.405-2.171-42.853-29.17-35.441-41.93 11.32-19.488 34.05-58.862 45.219-51.003Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.068 302.157s-4.286-4.534-3.885-5.058c.4-.525 5.23 3.181 5.23 3.181l-1.345 1.877Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M390.956 492.784c0-13.317 10.795-24.112 24.112-24.112h81.13v82.46a4 4 0 0 1-4 4h-97.242a4 4 0 0 1-4-4v-58.348Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M400.465 538.065h76.779v17.067h-76.779v-17.067ZM498.245 554.514l4.843 12.13h-23.413l4.057-12.13h14.513Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m418.474 554.514 4.844 12.13h-23.414l4.057-12.13h14.513ZM577.019 554.514l4.843 12.13h-23.413l4.057-12.13h14.513ZM450.796 403.493c0-13.113 10.479-23.822 23.588-24.106l22.412-.486v97.927h-46v-73.335Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M459.77 403.012c0-13.316 10.795-24.111 24.111-24.111h76.231c12.062 0 21.84 9.778 21.84 21.84v76.336c0 .965-.782 1.747-1.747 1.747H459.77v-75.812Z"
      fill="#C6F9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M547.805 396.818c0-9.895 8.022-17.917 17.918-17.917 8.963 0 16.229 7.266 16.229 16.229v71.97c0 .965-.782 1.747-1.747 1.747h-32.4v-72.029Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M400.465 490.474c0-6.959 5.641-12.6 12.6-12.6h78.28v65.754h-90.88v-53.154Z"
      fill="#C6F9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M494.784 370.394c12.198-2.842 13.001-19.594 3.727-25.405 9.565-20.06-12.553-39.977-27.703-27.694-9.932-4.254-18.185 12.345-8.943 25.237-3.875 22.135 4.011 27.856 18.227 28.1l14.692-.238Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M494.632 366.394s-5.934-1.394-6.14-11.122c-.205-9.729-.061-13.049-.061-13.049l-13.07-.236s-.143 10.842-.145 14.763c-.005 10.533-7.34 9.644-7.34 9.644s1.524 8.54 13.368 9.206c9.247.521 13.388-9.206 13.388-9.206Z"
      fill="url(#l)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M491.478 337.365c5.831-.226 4.88 8.314-1.177 8.303 0 0-3.518 12.132-15.732 12.132-9.92 0-10.565-14.14-10.655-16.961-.097-3.008-.109-12.889-.109-12.889l27.9-.012-.227 9.427Z"
      fill="url(#m)"
    />
    <path
      d="M476.923 349.649s-2.909 2.465-6.278.157"
      stroke="#F77B7B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M475.003 352.504c-.905.418-1.777.448-2.555.032"
      stroke="#F49494"
      strokeLinecap="round"
    />
    <path
      d="M490.884 341.958c-.06-1.37.357-2.458 1.754-2.677"
      stroke="#F77B7B"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M492.4 336.309s-3.195 4.291-3.843 4.314c-.647.023-.874-5.58-.874-5.58-3.92-.3-13.842-2.277-16.274-6.882-1.701 4.063-4.952 5.868-7.088 5.522l-.405 8.632-.997-17.355h30.263l-.782 11.349Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M434.913 537.945s5.23 10.571.942 13.902c-4.294 3.332-15.638 9.575-15.638 9.575l30.054-1.89-6.681-23.054-8.677 1.467Z"
      fill="url(#n)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M447.364 548.005c-1.483.174.101 5.17-3.111 5.55-3.205.379-4.762-4.354-8.399-2.854-3.637 1.501-16.9 9.259-17.423 12.431-.529 3.171 4.413 4.104 11.19 3.388 6.777-.716 8.027-3.129 13.114-2.157 5.081.971 8.888-.577 8.72-4.292-.167-3.714-2.608-12.239-4.091-12.066Z"
      fill="#222D4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m434.195 551.471.66.99a1 1 0 0 1-.344 1.428l-8.966 5.006a1 1 0 0 1-.94.018l-1.392-.706c2.186-1.584 3.973-2.774 5.949-3.943a120.458 120.458 0 0 1 5.033-2.793Z"
      fill="#fff"
      fillOpacity={0.541}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M480.185 444.779c-9.098.406-59.738 11.613-68.939 13.517-9.2 1.904-16.978 12.206-9.963 24.135 7.88 13.391 28.212 51.422 33.148 59.996 2.443 4.243 14.769 1.864 11.874-5.258-5.398-13.277-7.904-46.63-21.665-58.791 0 0 46.277 8.058 66.267-1.172 14.528-6.71 11.241-33.41-10.722-32.427Z"
      fill="url(#o)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M415.718 390.947s.103-7.407-2.487-11.278c-2.589-3.871-2.692-7.983-4.652-7.848-1.96.134-.019 9.692-.019 9.692s-2.487-.951-4.274-2.153c-1.786-1.203-7.203-7.13-8.469-6.079-2.146 1.789 1.311 11.884 3.194 13.903 1.889 2.019 12.209 8.657 12.209 8.657l4.498-4.894Z"
      fill="url(#p)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M471.631 386.508c-4.232 9.822-11.982 27.866-15.177 33.249-4.003 6.741-10.317 9.711-17.709 4.71-7.767-5.256-29.154-25.756-29.782-30.399-.621-4.644 4.614-10.215 9.35-7.331 4.737 2.885 24.418 19.273 24.418 19.273s7.943-20.373 14.333-32.174c5.534-10.21 21.01-2.259 14.567 12.672Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M442.649 406.021s-2.133 7.239-2.632 6.82c-.498-.419.381-8.713.381-8.713l2.251 1.893Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      d="M388.709 372.331a2.4 2.4 0 0 1 1.541-3.024l11.367-3.694a2.4 2.4 0 0 1 3.024 1.541l8.876 27.316a2.402 2.402 0 0 1-1.541 3.025l-11.367 3.693a2.399 2.399 0 0 1-3.024-1.541l-8.876-27.316Z"
      fill="#FFCC4D"
    />
    <rect
      x={389.031}
      y={369.239}
      width={16.752}
      height={33.522}
      rx={2.4}
      transform="rotate(-18 389.031 369.239)"
      fill="#93C2F9"
    />
    <path
      d="m392.249 369.453 1.208-.393a.528.528 0 0 1 .628.252l.037.087c.09.277.387.429.664.339l5.827-1.893a.527.527 0 0 0 .359-.572l-.021-.093a.527.527 0 0 1 .339-.664l1.202-.391a1.79 1.79 0 0 1 2.204 1.012l.052.14 8.401 25.933a2.16 2.16 0 0 1-1.385 2.715l-9.559 3.106a2.151 2.151 0 0 1-2.661-1.237l-.053-.145.002-.002-8.399-25.927a1.8 1.8 0 0 1 1.155-2.267Z"
      fill="#E6F3FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M513.929 374.674s-10.199 34.171-10.501 40.639c-.302 6.469 8.342 25.481 6.87 30.286-3.393 11.105-46.717 15.886-48.372.948-.772-6.967.835-13.219-1.016-21.428-1.845-8.209-9.259-20.408-5.054-31.877 4.206-11.469 3.079-22.079 6.087-24.085 3.007-2.005 10.038-4.135 10.038-4.135 3.419 5.034 14.962 4.961 20.47-.355 0 0 13.683 2.656 21.478 10.007Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M504.79 380.208c-6.799 10.796-6.779 29.591-10.43 32.053-3.651 2.461-17.863.046-17.863 1.995s19.639 19.815 16.415 28.355c-1.688 4.472-1.23 8.473 1.374 12.002l.85-.185c7.738-1.75 13.959-4.892 15.162-8.829 1.472-4.805-8.169-23.817-7.867-30.286.256-5.491 8.363-30.943 10.808-38.511-1.991-3.513-4.807-2.378-8.449 3.406Z"
      fill="#000"
      fillOpacity={0.199}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M493.196 408.849s-8.591-7.608-11.221-8.491c-2.63-.883-18.032-1.943-17.935.052.111 2.137 8.383 2.014 9.24 3.065.858 1.051 1.052 8.238 4.293 9.045 3.233.807 12.928 2.197 12.928 2.197l2.695-5.868Z"
      fill="url(#q)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M516.051 376.638c9.051 12.781 16.779 32.249 19.745 39.839 2.966 7.591-.026 19.039-13.069 16.791-13.02-2.241-33.735-14.422-36.027-17.975-2.292-3.553 1.19-10.018 5.575-9.172 4.062.783 24.213 9.356 24.213 9.356s-12.964-22.991-16.129-29.154c-5.288-10.293 7.72-20.947 15.692-9.685Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M516.282 415.319s6.79 3.376 5.962 1.96c-.828-1.415-8.553-6.388-8.553-6.388l2.591 4.428Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M403.31 538.37s.158 12.441-10.089 17.093c-10.24 4.651-17.535 4.371-16.607 7.967.929 3.596 27.582 3.451 30.071.638 2.122-2.392 6.735-24.669 8.005-22.966l-11.38-2.732Z"
      fill="url(#r)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M411.417 552.296c-1.145-2.211-1.217 4.011-5.73 2.64-3.662-1.113-4.914-7.482-7.229-6.087-2.314 1.394-22.687 12.211-22.803 12.276-2.508 1.352-1.807 5.447 2.429 6.162 4.708.794 29.099.26 30.408-.996 1.309-1.255 3.842-12.221 2.925-13.995Z"
      fill="#222D4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M395.445 552.569c.398 0 .778.5 1.052.981.267.469.018 1.039-.482 1.245l-10.511 4.33c-.34.14-.737.088-.981-.188-.223-.252-.431-.566-.328-.781.199-.415 10.482-5.587 11.25-5.587Z"
      fill="#fff"
      fillOpacity={0.541}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M509.691 440.933s13.227 27.95-5.362 37.548c-17.977 9.281-71.794-1.696-71.794-1.696s.962 7.746 0 14.655c-2.512 18.046-14.457 48.11-16.984 51.887-3.495 5.224-14.142 1.284-14.342-3.065-.201-4.35 8.026-59.904 9.277-70.498 1.244-10.595 5.173-12.134 16.649-15.03 2.636-.665 33.814-8.417 36.67-8.191 24.54 1.941 45.886-5.61 45.886-5.61Z"
      fill="#2C3757"
    />
    <path
      opacity={0.462}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M495.191 475.596c-17.798 1.995-64.918-2.118-66.81-.249-1.807 1.785-1.506 32.828-5.558 50.796l.616-1.697c3.812-10.592 7.769-23.475 9.096-33.006.962-6.909 0-14.656 0-14.656l2.17.421c10.23 1.922 53.805 9.443 69.624 1.276 8.572-4.426 12.07-11.778 10.494-22.058-2.714 11.619-9.258 18.01-19.632 19.173Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M476.71 492.784c0-13.317 10.795-24.112 24.111-24.112h81.131v84.713c0 .965-.782 1.747-1.747 1.747H476.71v-62.348Z"
      fill="#91D6FF"
    />
    <path
      d="M595 508h8c7.732 0 14 6.268 14 14h-8c-7.732 0-14-6.268-14-14Z"
      fill="#2CE780"
    />
    <path
      d="M605 500h6a7 7 0 0 1 7 7h-6a7 7 0 0 1-7-7ZM636 507h-7c-6.627 0-12 5.373-12 12h7c6.627 0 12-5.373 12-12Z"
      fill="#28C76F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m627.408 494.212-5.694 2.312a8.71 8.71 0 0 0-4.804 11.317l5.695-2.312a8.708 8.708 0 0 0 4.803-11.317Z"
      fill="#2CE780"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M612.765 493c3.128 1.983 5.112 5.756 5.229 9.897l.006.389V533h-1.605v-29.714c0-3.484-1.576-6.697-4.128-8.458l-.267-.177.765-1.651Z"
      fill="#28C76F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M605.059 523h24.946c1.165 0 2.11.925 2.11 2.067v.059a3.94 3.94 0 0 0 1.652 3.196l.646.466a3.941 3.941 0 0 1 1.652 3.196v30.888c0 2.191-1.814 3.967-4.051 3.967h-28.963c-2.237 0-4.051-1.776-4.051-3.967v-30.888a3.94 3.94 0 0 1 1.651-3.196l.647-.466a3.945 3.945 0 0 0 1.643-2.945l.009-.31c0-1.142.944-2.067 2.109-2.067Z"
      fill="#DA4E4F"
    />
    <mask
      id="s"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={599}
      y={523}
      width={38}
      height={44}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M605.059 523h24.946c1.165 0 2.11.925 2.11 2.067v.059a3.94 3.94 0 0 0 1.652 3.196l.646.466a3.941 3.941 0 0 1 1.652 3.196v30.888c0 2.191-1.814 3.967-4.051 3.967h-28.963c-2.237 0-4.051-1.776-4.051-3.967v-30.888a3.94 3.94 0 0 1 1.651-3.196l.647-.466a3.945 3.945 0 0 0 1.643-2.945l.009-.31c0-1.142.944-2.067 2.109-2.067Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#s)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M597.987 521.016h10.127c-4.469 17.047 6.416 31.184 0 48.6h-10.127v-48.6Z"
        fill="#E95B5C"
      />
      <path
        fill="#FFDFE2"
        fillOpacity={0.258}
        d="M599 549.779h37.065v3.967H599zM599 538.869h37.065v3.967H599z"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m607.371 488.916 4.716 1.359a7.461 7.461 0 0 1 5.109 9.216l-4.717-1.36a7.459 7.459 0 0 1-5.108-9.215Z"
      fill="#2CE780"
    />
    <defs>
      <linearGradient
        id="f"
        x1={121.964}
        y1={535.907}
        x2={123.277}
        y2={565.377}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCC4D" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={53.724}
        y1={538.515}
        x2={56.114}
        y2={564.585}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCC4D" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={72.035}
        y1={210.657}
        x2={82.805}
        y2={260.315}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAFAF" />
        <stop offset={1} stopColor="#F07777" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={74.742}
        y1={201.186}
        x2={81.656}
        y2={234.797}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={180.59}
        y1={240.178}
        x2={180.945}
        y2={275.551}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCC4D" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={53.874}
        y1={323.088}
        x2={54.935}
        y2={350.187}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCC4D" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={503.342}
        y1={336.993}
        x2={492.262}
        y2={386.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAFAF" />
        <stop offset={1} stopColor="#F07777" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={502.481}
        y1={327.599}
        x2={495.833}
        y2={360.118}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={455.471}
        y1={530.406}
        x2={453.878}
        y2={560.186}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={424.4}
        y1={568.758}
        x2={376.326}
        y2={476.481}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28324F" />
        <stop offset={1} stopColor="#222735" />
      </linearGradient>
      <linearGradient
        id="p"
        x1={419.28}
        y1={365.969}
        x2={417.128}
        y2={394.572}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={498.241}
        y1={395.514}
        x2={497.611}
        y2={413.984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="r"
        x1={421.293}
        y1={531.606}
        x2={419.735}
        y2={564.801}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <filter
        id="a"
        x={0.563}
        y={153.777}
        width={241.934}
        height={243.659}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={2.718}
          result="effect1_foregroundBlur_674_93180"
        />
      </filter>
      <filter
        id="c"
        x={173.229}
        y={335.514}
        width={248.702}
        height={176.768}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={5} dy={5} />
        <feGaussianBlur stdDeviation={10} />
        <feColorMatrix values="0 0 0 0 0.0755213 0 0 0 0 0.582569 0 0 0 0 0.878793 0 0 0 0.229458 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93180"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={5.5} />
        <feColorMatrix values="0 0 0 0 0.503982 0 0 0 0 0.700178 0 0 0 0 1 0 0 0 0.233829 0" />
        <feBlend
          in2="effect1_dropShadow_674_93180"
          result="effect2_dropShadow_674_93180"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_674_93180"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x={177.964}
        y={196.352}
        width={244.004}
        height={154.821}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={6} dy={4} />
        <feGaussianBlur stdDeviation={7} />
        <feColorMatrix values="0 0 0 0 0.0189934 0 0 0 0 0.0189934 0 0 0 0 0.0189934 0 0 0 0.11 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93180"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_674_93180"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default BackgroundChangePass
