import { FC } from "react";
import CalendarIcon from "@assets/SvgComponents/CalendarIcon";
import { makeStyles } from "@mui/styles";
import { Colors } from "@themes/colors";
import DatePicker, { DatePickerProps } from "react-date-picker";
import { Box, Typography } from "@mui/material";
import { TextStyles } from "@themes/textStyles";

interface DatePickerCustomProps
  extends Omit<DatePickerProps, "value" | "onChange"> {
  value?: Date;
  onChange?: (e: any) => void;
  error?: string;
}

const DatePickerCustom: FC<DatePickerCustomProps> = ({
  value,
  onChange = () => {},
  error,
  maxDate,
  ...props
}) => {
  const classes = useStyles();
  const textStyles = TextStyles();
  return (
    <Box component="div">
      <DatePicker
        className={`${classes.root} ${error && "error"}`}
        dayPlaceholder="JJ"
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        format="dd/MM/yyyy"
        calendarIcon={<CalendarIcon />}
        value={value}
        onChange={onChange}
        locale="fr"
        maxDate={maxDate ? maxDate : new Date("9999-12-31T23:59")}
        {...props}
      />
      {error && (
        <Typography
          className={textStyles.paragraph_small_error}
          sx={{ mt: 0.3 }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    fontSize: 12,
    "& .react-date-picker__wrapper": {
      padding: "5px 5px 5px 10px",
      border: "1px solid #D8D6DE",
      borderRadius: 5,
      "& .react-date-picker__inputGroup": {
        display: "flex",
        alignItems: "center",
        "& .react-date-picker__inputGroup__input": {
          "&:invalid": {
            background: "unset",
          },
          "&:focus-visible": {
            outline: "none",
          },
        },
      },
      "& .react-date-picker__clear-button": {
        display: "none",
      },
    },
    "& .react-date-picker__calendar": {
      inset: "95% 0 auto auto !important",
      color: Colors.secondary,
      width: 266,
      zIndex: 9,
      background: "#fff",
      "& .react-calendar": {
        padding: 8,
        width: 266,
        border: "unset",
        borderRadius: "0px 0px 4px 4px",
        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.08)",
        "& .react-calendar__navigation": {
          marginBottom: 0,
          height: 35,
          paddingTop: 10,
          "& button": {
            "&:disabled": {
              backgroundColor: "#fff !important",
            },
          },
          "& .react-calendar__navigation__label": {
            "&:enabled": {
              "&:hover": {
                backgroundColor: "unset",
              },
            },
            "& .react-calendar__navigation__label__labelText": {
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontSize: 14,
              fontWeight: 500,
              color: "#5E5873",
            },
          },
          "& .react-calendar__navigation__prev2-button": {
            display: "none",
          },
          "& .react-calendar__navigation__next2-button": {
            display: "none",
          },
          "& .react-calendar__navigation__prev-button": {
            color: Colors.secondary,
            fontSize: 22,
            display: "flex",
            alignItems: "center",
            "&:enabled": {
              "&:hover": {
                backgroundColor: "unset",
              },
              "&:focus": {
                backgroundColor: "unset",
              },
            },
          },
          "& .react-calendar__navigation__next-button": {
            color: Colors.secondary,
            fontSize: 22,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "&:enabled": {
              "&:hover": {
                backgroundColor: "unset",
              },
              "&:focus": {
                backgroundColor: "unset",
              },
            },
          },
        },
        "& .react-calendar__viewContainer": {
          "& .react-calendar__month-view__weekdays": {
            margin: "5px 0 10px 0",
            "& .react-calendar__month-view__weekdays__weekday": {
              textTransform: "capitalize",
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "Montserrat",
              color: "#6E6B7B",
              "& abbr": {
                textDecoration: "none",
              },
            },
          },
          "& .react-calendar__tile": {
            fontFamily: "Montserrat",
            color: "#6E6B7B",
            fontSize: 14,
            fontWeight: 400,
            "&.react-calendar__month-view__days__day--neighboringMonth": {
              color: "#B9B9C3",
            },
            "&:enabled": {
              "&:hover": {
                borderRadius: "50%",
                backgroundColor: "#F8F8F9",
              },
            },
            "&.react-calendar__tile--now": {
              background: "unset",
            },
          },
          "& .react-calendar__tile--active": {
            color: "#fff !important",
            background: "#FFDB5C !important",
            borderRadius: "50%",
          },
        },
      },
    },
    "&.error": {
      "& .react-date-picker__wrapper": {
        borderColor: "#EA5455 !important",
      },
    },
  },
});

export default DatePickerCustom;
