import MontserratBold from "@assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "@assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "@assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "@assets/fonts/Montserrat_SemiBold.ttf";
import CheckIcon from "@assets/Images/radio.png";
import generatingResultPDFStyles from "@pages/Contract/GeneratingResultPDF/generatingResultPDFStyles";
import { Font, Image, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import formatMoney from "@utils/formatMoney";
import { FC } from "react";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage5Props {
  franchiseDuContrat?: number;
}

const ContentPage4: FC<IContentPage5Props> = ({ franchiseDuContrat = 0 }) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Garantie et franchises accordées
        </Text>
      </View>

      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            Responsabilité civile décennale (assurance obligatoire)
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            Responsabilité civile Générale
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            Dommages avant reception
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <View
            style={{
              width: 22,
              height: 22,
              marginRight: 14,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Image src={CheckIcon} />
          </View>
          <Text style={generatingResultPDFStyles.textRadio}>
            Défense pénale / Protection juridique
          </Text>
        </View>
      </View>

      <View style={{ height: 10 }} />

      <View style={generatingResultPDFStyles.specialTable}>
        <View style={generatingResultPDFStyles.headerSpecialTable}>
          <View style={{ flex: 3 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Nature des garanties
            </Text>
          </View>

          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 2, padding: 4 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Montant des garanties
            </Text>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              par année d’assurance
            </Text>
          </View>

          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 1, marginRight: -8 }}>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              Franchises
            </Text>
            <Text style={generatingResultPDFStyles.titleHeaderTable}>
              (1) (2)
            </Text>
          </View>
        </View>
        <Text
          style={{
            color: "#5E5873",
            fontSize: "9px",
            fontFamily: "Montserrat",
            padding: 8,
            backgroundColor: "#FFF9E8",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          RESPONSABILITE CIVILE PROFESSIONNELLE
        </Text>
        {/* Odd and even in here is not correct and it's just for have correct styles */}
        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              A. Responsabilité civile décennale ouvrages soumis à obligation
              d'assurance
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            {/* <Text style={generatingResultPDFStyles.textMoney}>5 000 000, 00 €</Text> */}
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            {/* <Text style={generatingResultPDFStyles.textFranchise}>
              Franchise corporelle - Néant
            </Text> */}
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContent}>
                1) Responsabilité décennale locateur d'ouvrages (articles L241
                -1 et L241-2 du code des assurances)
              </Text>
            </View>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContent}>
                2) Responsabilité en qualité de sous-traitant (article 1792-4-2
                du code civil)
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <View style={{ padding: 4 }}>
              <Text style={generatingResultPDFStyles.mainContent}>
                A hauteur du coût des travaux de réparation des dommages à
                l'ouvrage.
              </Text>
              <Text style={generatingResultPDFStyles.mainContent}>
                Les travaux de réparation comprennent également les travaux de
                démolition, déblaiement, dépose ou démontage éventuellement
                nécessaires.
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              B. Responsabilité civile décennale ouvrages non soumis à
              obligation d'assurance (en cas d'atteinte à la solidité)
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              500 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={[generatingResultPDFStyles.textRadio, { padding: 4 }]}>
              C. Garanties complémentaires après réception
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            {/* <Text style={generatingResultPDFStyles.textMoney}>5 000 000, 00 €</Text> */}
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            {/* <Text style={generatingResultPDFStyles.textFranchise}>
              Franchise corporelle - Néant
            </Text> */}
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContent}>
                1) Bon fonctionnement des éléments d'équipement sur ouvrages
                soumis à obligation d'assurance (Art. 1792-3 du Code Civil)
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              50 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContent}>
                2) Dommages aux existants (y compris frais de déblaiement)
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              80 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>

        <View style={generatingResultPDFStyles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContent}>
                3) Dommages immatériels consécutifs
              </Text>
            </View>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              80 000 €
            </Text>
          </View>

          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1 }}>
            <Text style={generatingResultPDFStyles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
      </View>

      <View style={{ height: 25 }} />

      <View style={generatingResultPDFStyles.li}>
        <View style={generatingResultPDFStyles.dotLi} />
        <Text
          style={[
            generatingResultPDFStyles.mainContent,
            generatingResultPDFStyles.textItalic,
          ]}
        >
          (1) Montant maximum par année d'assurance
        </Text>
      </View>

      <View style={generatingResultPDFStyles.li}>
        <View style={generatingResultPDFStyles.dotLi} />
        <Text
          style={[
            generatingResultPDFStyles.mainContent,
            generatingResultPDFStyles.textItalic,
          ]}
        >
          (2) Pour un même sinistre (hors catastrophes naturelles), il sera
          procédé à un cumul des franchises afférentes à chaque garantie.
        </Text>
      </View>
    </View>
  );
};

export default ContentPage4;
