import React from "react";
import { Font, Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import Optim from "../../../assets/Images/optim.png";
import CF from "../../../assets/Images/cf.png";
import MADE from "../../../assets/Images/logo.png";
import Pama from "../../../assets/Images/pama.png";
import ACPR from "../../../assets/Images/acpr.png";
import Signature from "../../../assets/Images/signature.png";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage7 = () => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Mentions légales
        </Text>
      </View>
      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Image src={Optim} style={{ width: 62, height: 44 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>L’Assureur :</Text> Société
              d’Assurance{" "}
              <Text style={styles.mainTextBold}>Mutuelle Optim Assurance</Text>,
              en activité depuis 1897 (membre de l’union UNIRE – Matricule ACPR
              n°4050548) – Siège social : 14 Rue Pasteur 01000 – Bourg en Bresse
              – Enregistrée au RCS sous le numéro : 77931332900020 –{" "}
              <Link src="www.optimassurance.fr">www.optimassurance.fr</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={CF} style={{ width: 62, height: 24 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>Le courtier grossiste</Text>{" "}
              est <Text style={styles.mainTextBold}>CF SOUSCRIPTION</Text> –
              Marque de la société International Insurance Underwriting (IIU) –
              Société de courtage d’assurance au capital de 10 000 € - Siège
              social : Zone des Beurrons, RD191, 78680 Epône – RCS Versailles
              823 943 212 –{" "}
              <Link src="www.cf-souscription.com">www.cf-souscription.com</Link>{" "}
              - ORIAS : 17000414 - site web Orias :{" "}
              <Link src="www.orias.fr">www.orias.fr</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={MADE} style={{ width: 62, height: 16 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>La souscription</Text> a été
              confiée à<Text style={styles.mainTextBold}>MADECENNALE</Text> –{" "}
              Société par action simplifiée de courtage d’assurance au capital
              de social de 500€ - Siège social : 1047 Route des Dolines,
              Business Pôle Bat A, 06560 Valbonne – RCS Grasse 811 021 260 –
              Orias : 15003712 -{" "}
              <Link src="www.madecennale.com">www.madecennale.com</Link>
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={Pama} style={{ width: 62, height: 18 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>
                Pour la garantie DPRSA/PJ :
              </Text>{" "}
              <Text style={styles.mainTextBold}>
                GROUPAMA PROTECTION JURIDIQUE
              </Text>{" "}
              – Entreprise régie par le Code des assurances – Société Anonyme au
              capital de 2 216 500 € - RCS PARIS B 321 776 775 – Siège social :
              8-10 rue d’Astorg 75008 PARIS
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <Image src={ACPR} style={{ width: 62, height: 18 }} />
          <View style={{ width: 12 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.mainText}>
              <Text style={styles.mainTextYellow}>ACPR :</Text> Autorité de
              Contrôle Prudentiel et de Résolution : 4 Place de Budapest, CS
              92459, 75436 PARIS CEDEX 09
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 20,
          paddingRight: 20,
        }}
      >
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text
            style={{
              fontSize: 9,
              color: "#5E5873",
              fontFamily: "Montserrat",
              fontWeight: 500,
              textAlign: "center",
              marginBottom: 15,
            }}
          >
            Le souscripteur
          </Text>
          <Image src={Signature} style={{ width: 239, height: 70 }} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  itemCheckbox: {
    borderBottom: "1px dashed #FFCC4D",
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
  },
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
  mainTextCheckbox: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  mainTextBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: "bold",
  },
  mainTextYellow: {
    fontSize: 9,
    color: Colors.primary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.7,
  },
  checkbox: {
    width: 11,
    height: 11,
    border: "1.2px solid #FFCC4D",
    borderRadius: 2,
    marginRight: 6,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.8,
    height: 1.8,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage7;
