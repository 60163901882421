import {
  IListContractValidated,
  IListQuoteCreated,
  IStatisticDto,
} from "./statistic.dto";

export enum StatisticTypes {
  GET_STATISTIC_REQUESTED = "statistic/GET_STATISTIC_REQUESTED",
  GET_STATISTIC_SUCCEEDED = "statistic/GET_STATISTIC_SUCCEEDED",
  GET_STATISTIC_FAILED = "statistic/GET_STATISTIC_FAILED",

  GET_LIST_CONTRACT_VALIDATED_REQUESTED = "statistic/GET_LIST_CONTRACT_VALIDATED_REQUESTED",
  GET_LIST_CONTRACT_VALIDATED_SUCCEEDED = "statistic/GET_LIST_CONTRACT_VALIDATED_SUCCEEDED",
  GET_LIST_CONTRACT_VALIDATED_FAILED = "statistic/GET_LIST_CONTRACT_VALIDATED_FAILED",

  SET_LIST_STATISTIC = "statistic/SET_LIST_STATISTIC",
  SET_LIST_QUOTE_CREATED = "statistic/SET_LIST_QUOTE_CREATED",
}

export interface IGetStatisticRequestedAction {
  type: typeof StatisticTypes.GET_STATISTIC_REQUESTED;
}
export interface IGetStatisticSucceededAction {
  type: typeof StatisticTypes.GET_STATISTIC_SUCCEEDED;
}
export interface IGetStatisticFailedAction {
  type: typeof StatisticTypes.GET_STATISTIC_FAILED;
  error?: any;
}

//

export interface IGetListContractValidatedRequestedAction {
  dateRange: Array<string>;
  type: typeof StatisticTypes.GET_LIST_CONTRACT_VALIDATED_REQUESTED;
}
export interface IGetListContractValidatedSucceededAction {
  listContractValidated: Array<IListContractValidated>;
  type: typeof StatisticTypes.GET_LIST_CONTRACT_VALIDATED_SUCCEEDED;
}
export interface IGetListContractValidatedFailedAction {
  type: typeof StatisticTypes.GET_LIST_CONTRACT_VALIDATED_FAILED;
  error?: any;
}

//

export interface ISetListStatisticAction {
  type: typeof StatisticTypes.SET_LIST_STATISTIC;
  listStatistic: IStatisticDto[];
}

export interface ISetListQuoteCreatedAction {
  type: typeof StatisticTypes.SET_LIST_QUOTE_CREATED;
  listQuoteCreated: IListQuoteCreated[];
}

export type StatisticActions =
  | IGetStatisticRequestedAction
  | IGetStatisticSucceededAction
  | IGetStatisticFailedAction
  | IGetListContractValidatedRequestedAction
  | IGetListContractValidatedSucceededAction
  | IGetListContractValidatedFailedAction
  | ISetListStatisticAction
  | ISetListQuoteCreatedAction;
