import * as React from "react";

const ChartIcon: React.FC<{ color: string }> = ({ color = "#6E6B7B" }) => (
  <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 .25c-.45 0-.75.3-.75.75v12c0 .45.3.75.75.75s.75-.3.75-.75V1c0-.45-.3-.75-.75-.75Zm5.25 5.25V13c0 .45-.3.75-.75.75s-.75-.3-.75-.75V5.5c0-.45.3-.75.75-.75s.75.3.75.75Zm-10.5 3c0-.45.3-.75.75-.75s.75.3.75.75V13c0 .45-.3.75-.75.75s-.75-.3-.75-.75V8.5Z"
      fill={color}
    />
  </svg>
);

export default ChartIcon;
