import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack';
import { theme } from 'App';
import { ThemeProvider } from '@mui/material/styles';

// add a <div> child to body under which to mount the snackbars
const mountPoint = document.createElement('snackbar-helper');
document.body.appendChild(mountPoint);

const toast = function (msg: string, variant: VariantType = 'default') {
  const ShowSnackbar = ({ message }: { message: string; variant: VariantType }) => {
    const { enqueueSnackbar } = useSnackbar();
    enqueueSnackbar(message, { variant });
    return null;
  };
  ReactDOM.render(
    // see https://github.com/iamhosseindhv/notistack#snackbarprovider
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <ShowSnackbar message={msg} variant={variant} />
      </SnackbarProvider>
    </ThemeProvider>,
    mountPoint,
  );
};

export const notistack = {
  success: function (msg: string = '') {
    toast(msg, 'success');
  },
  warning: function (msg: string = '') {
    toast(msg, 'warning');
  },
  info: function (msg: string = '') {
    toast(msg, 'info');
  },
  error: function (msg: string = '') {
    toast(msg, 'error');
  },
};
