import { IFilterContractDto, IResponseQuote } from "./listQuotes.dto";

export enum ListQuotesTypes {
  GET_LIST_QUOTES_REQUESTED = "listQuotes/GET_LIST_QUOTES_REQUESTED",
  GET_LIST_QUOTES_SUCCEEDED = "listQuotes/GET_LIST_QUOTES_SUCCEEDED",
  GET_LIST_QUOTES_FAILED = "listQuotes/GET_LIST_QUOTES_FAILED",

  CREATE_DUPLICATE_QUOTE_REQUESTED = "listQuotes/CREATE_DUPLICATE_REQUESTED",
  CREATE_DUPLICATE_QUOTE_SUCCEEDED = "listQuotes/CREATE_DUPLICATE_SUCCEEDED",
  CREATE_DUPLICATE_QUOTE_FAILED = "listQuotes/CREATE_DUPLICATE_FAILED",
  CLEAR_RESPONSE_DUPLICATE = "listQuotes/CLEAR_RESPONSE_DUPLICATE",

  RESET_LIST_QUOTES_STATE = "listQuotes/RESET_STATE",
}

export interface IGetListQuotesRequestedAction {
  type: typeof ListQuotesTypes.GET_LIST_QUOTES_REQUESTED;
  filterContractDto?: IFilterContractDto;
}

export interface IGetListQuotesSucceededAction {
  type: typeof ListQuotesTypes.GET_LIST_QUOTES_SUCCEEDED;
  listQuotes: IResponseQuote;
}

export interface IGetListQuotesFailedAction {
  type: typeof ListQuotesTypes.GET_LIST_QUOTES_FAILED;
  error?: any;
}

export interface ICreateDuplicateQuoteRequestedAction {
  type: typeof ListQuotesTypes.CREATE_DUPLICATE_QUOTE_REQUESTED;
  id: string;
}

export interface ICreateDuplicateQuoteSucceededAction {
  type: typeof ListQuotesTypes.CREATE_DUPLICATE_QUOTE_SUCCEEDED;
  message?: string;
}

export interface ICreateDuplicateQuoteFailedAction {
  type: typeof ListQuotesTypes.CREATE_DUPLICATE_QUOTE_FAILED;
  error?: string;
}

export interface IClearResponseDuplicateAction {
  type: typeof ListQuotesTypes.CLEAR_RESPONSE_DUPLICATE;
}

export interface IResetListQuotesStateAction {
  type: typeof ListQuotesTypes.RESET_LIST_QUOTES_STATE;
}

export type ListQuotesActions =
  | IGetListQuotesRequestedAction
  | IGetListQuotesSucceededAction
  | IGetListQuotesFailedAction
  | ICreateDuplicateQuoteRequestedAction
  | ICreateDuplicateQuoteSucceededAction
  | ICreateDuplicateQuoteFailedAction
  | IClearResponseDuplicateAction
  | IResetListQuotesStateAction;
