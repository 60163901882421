import * as React from "react";

const HouseIcon: React.FC<{ color: string }> = ({ color }) => (
  <svg width={16} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2 6.163 8.45.919c-.3-.225-.675-.225-.9 0L.8 6.163c-.225.15-.3.374-.3.599v8.24c0 1.274.975 2.248 2.25 2.248h10.5c1.275 0 2.25-.974 2.25-2.247V6.762c0-.225-.075-.45-.3-.6ZM9.5 9.759v5.993h-3V9.759h3Zm3.75 5.993c.45 0 .75-.3.75-.75V7.137L8 2.492 2 7.136v7.867c0 .449.3.749.75.749H5V9.009c0-.45.3-.749.75-.749h4.5c.45 0 .75.3.75.75v6.742h2.25Z"
      fill={color}
    />
  </svg>
);

export default HouseIcon;
