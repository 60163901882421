import WarningIcon from "@assets/SvgComponents/WarningIcon";
import ButtonCustom from "@components/ButtonCustom";
import Spacing from "@components/Spacing";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { dataLegalStatus } from "@pages/Contract/InformationCompany";
import {
  dataReason,
  IActivitiesSelected,
} from "@pages/Contract/InsuranceHistory";
import {
  clearAllDataContract,
  getDetailContract,
  passInGuarantee,
} from "@stores/contract/contract.creator";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { clearAllActivities } from "@stores/savedActivities/savedActivities.creator";
import {
  clearDataSearchCompany,
  clearDetailCompany,
} from "@stores/searchCompany/searchCompany.creator";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import {
  downloadAttestationPDF,
  downloadConditionPDF,
} from "@utils/downloadDocuments";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IWarningBoxProps {
  open?: boolean;
  onClose?: () => void;
  idContract?: string;
  contract_id?: string;
  onRefresh?: () => void;
}

const WarningBox: FC<IWarningBoxProps> = ({
  open = false,
  onClose = () => {},
  idContract,
  contract_id,
  onRefresh = () => {},
}) => {
  const textStyles = TextStyles();
  const dispatch = useDispatch();

  const { data_contract_update, loadingGet, responsePassGuarantee } =
    useSelector((state: IAppState) => state.dataContract);
  const { activities } = useSelector(
    (state: IAppState) => state.dataActivities
  );

  const [qrCode, setQrCode] = useState<any>();

  const isPIB = data_contract_update?.type === ContractType.PIB;
  const contractId = data_contract_update?.contract_id;
  const companyName = data_contract_update?.company?.name?.replaceAll(" ", "_");

  const handleDownloadConditionPDF = async (
    infoInsurance: IInfoContractPDF
  ) => {
    downloadConditionPDF({
      infoInsurance,
      companyName,
      contractId,
      isPIB,
    });

    onRefresh();
  };

  const handleDownloadAttestationPDF = async (
    infoInsurance: IInfoContractPDF
  ) => {
    downloadAttestationPDF({
      infoInsurance,
      companyName,
      contractId,
      isPIB,
      qrCode,
    });

    dispatch(clearAllDataContract());
    dispatch(clearAllActivities());
    dispatch(clearDetailCompany());
    dispatch(clearDataSearchCompany());
  };

  const onConfirm = () => {
    idContract && dispatch(passInGuarantee(idContract));
  };

  // Because activities(activities.data) can be old (activities of other product type)
  // so we need check it before generate documents
  const checkIsCorrectActivitiesOfProductType = useCallback(() => {
    return activities.data.some(
      (activity) =>
        activity._id === data_contract_update.activities?.[0]?.activityId
    );
  }, [activities?.data, data_contract_update?.activities]);

  useEffect(() => {
    if (
      data_contract_update &&
      activities.data &&
      checkIsCorrectActivitiesOfProductType()
    ) {
      const legalStatus = dataLegalStatus.find(
        (item) => item.value === data_contract_update?.company?.legalStatus
      );
      let newActivities: Array<IActivitiesSelected> = [];
      for (let i = 0; i < activities.data?.length; i++) {
        for (let j = 0; j < data_contract_update.activities.length; j++) {
          if (
            activities.data[i]._id ===
            data_contract_update.activities[j].activityId
          ) {
            const indefOfHaveExclusion =
              activities.data[i].definition?.indexOf("Exclusion");

            if (indefOfHaveExclusion && indefOfHaveExclusion !== -1) {
              // length of Exclusion to the fist HTML tag of this is 69

              const exclusionActivity = activities.data[i].definition?.slice(
                indefOfHaveExclusion - 69
              );

              const definitionActivity = activities.data[i].definition?.slice(
                0,
                indefOfHaveExclusion - 69
              );

              newActivities.push({
                ...activities.data[i],
                definition: definitionActivity,
                exclusion: exclusionActivity,
                percent: data_contract_update.activities[j].percent,
              });
            } else {
              newActivities.push({
                ...activities.data[i],
                percent: data_contract_update.activities[j].percent,
              });
            }
          }
        }
      }
      const reason = dataReason.find(
        (item) =>
          item.value ===
          data_contract_update?.insuranceHistory?.progressTerminationReason
      );
      let taux_de_revision_du_contrat: number = 0;
      if (
        data_contract_update?.pricingByFranchise?.NETCompanyAvecCom &&
        data_contract_update?.company?.companyDetails?.revenue
      ) {
        taux_de_revision_du_contrat =
          data_contract_update?.pricingByFranchise.NETCompanyAvecCom /
          data_contract_update?.company.companyDetails.revenue;
      }
      const dataPDF: IInfoContractPDF = {
        contract_id: data_contract_update?.contract_id,
        contract_code: `BMK-${data_contract_update?.contract_code}-A`,
        NETCompanyAvecCom: parseFloat(
          data_contract_update?.pricingByFranchise?.NETCompanyAvecCom?.toFixed(
            2
          ) || "0"
        ),
        fractionnement: data_contract_update?.contractOption?.period,
        frais_de_souscription:
          data_contract_update?.contractOption?.subscriptionFee,
        informations_du_souscripteur_1: {
          souscripteur: data_contract_update?.company?.name,
          forme_juridique: legalStatus?.label,
          phone:
            data_contract_update?.company?.legalRepresentative?.phoneNumber,
          email: data_contract_update?.company?.legalRepresentative?.email,
          dimmatriculation: data_contract_update?.company?.siren,
          date_creation: moment(data_contract_update?.company?.birthday).format(
            "DD/MM/YYYY"
          ),
          effectif: data_contract_update?.company?.companyDetails?.workforce,
          chiffre_HT:
            data_contract_update?.company?.companyDetails?.revenue?.toString(),
          dirigeant: data_contract_update?.company?.legalRepresentative?.name,
          address: data_contract_update?.company?.headquarterAddress,
          siren: data_contract_update?.company?.siren,
          city: data_contract_update?.company?.city,
          codePostal: data_contract_update?.company?.codePostal,
        },
        activities: newActivities,
        franchise_du_contrat:
          data_contract_update?.contractOption?.franchise?.money?.toFixed(0) ||
          0,
        taux_de_revision_du_contrat: parseFloat(
          (taux_de_revision_du_contrat * 100).toFixed(2)
        ),
        informations_du_souscripteur_2: {
          hasRCD: data_contract_update?.insuranceHistory?.hasBeenRCD
            ? "Oui"
            : "Non",
          contrat_en_cours: data_contract_update?.insuranceHistory?.isInProgress
            ? "Oui"
            : "Non",
          date_de_resiliation:
            !data_contract_update?.insuranceHistory?.isInProgress &&
            data_contract_update?.insuranceHistory?.progressCancellationDate
              ? moment(
                  data_contract_update?.insuranceHistory
                    ?.progressCancellationDate
                ).format("DD/MM/YYYY")
              : "",
          assure_lors_des_24_derniers_mois:
            data_contract_update?.insuranceHistory?.claimDetails?.reduce(
              (acc: any, current: any) => acc + current.numberOfClaims,
              0
            ),
          pour_un_montant_de:
            data_contract_update?.insuranceHistory?.claimDetails?.reduce(
              (acc: any, current: any) => acc + current.amount,
              0
            ),
          esilie_non_paiement:
            reason?.label === "Non paiement de prime" ? "Oui" : "Non",
          resilie_pour_fausse_declaration:
            reason?.label === "Fausse déclaration" ? "Oui" : "Non",
          de_sous_traitance:
            data_contract_update?.company?.companyDetails
              ?.percentEntrustToSubContractors &&
            data_contract_update?.company?.companyDetails
              ?.percentEntrustToSubContractors > 30
              ? "Oui"
              : "Non",
          en_redressement_judiciaire: data_contract_update?.isInReceivership
            ? "Oui"
            : "Non",
        },
        page6: {
          amount_franchise_2:
            data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
          amount_franchise_3:
            data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
        },
        pricingByPeriod:
          data_contract_update?.pricingByFranchise?.pricingByPeriod,
        cotisations: {
          JURY_LAW: data_contract_update?.pricingByFranchise?.JURY_LAW || 0,
          PJ_GROUPAMA:
            data_contract_update?.pricingByFranchise?.PJ_GROUPAMA || 0,
          fractionement:
            data_contract_update?.pricingByFranchise?.fractionement,
          fraisInclus5LPP:
            data_contract_update?.pricingByFranchise?.fraisInclus5LPP || 0,
          primeCODE: data_contract_update?.pricingByFranchise?.primeCode || 0,
          primeNetHT: data_contract_update?.pricingByFranchise?.primeNetHT,
          totalMAJO_MINO:
            data_contract_update?.pricingByFranchise?.totalMAJO_MINO,
        },
        contractStartDate: data_contract_update?.insuranceHistory
          ?.contractStartDate
          ? data_contract_update.insuranceHistory.contractStartDate
          : "",
        resumptionDatePast: data_contract_update?.insuranceHistory
          ?.resumptionDatePast
          ? data_contract_update.insuranceHistory.resumptionDatePast
          : "",
        dataActivities: newActivities,
        date_decheance: data_contract_update?.insuranceHistory
          ?.contractStartDate
          ? moment(data_contract_update?.insuranceHistory?.contractStartDate)
              .format("LL")
              .slice(0, -5)
          : "",
        workforce: data_contract_update?.company?.companyDetails?.workforce,
      };
      handleDownloadConditionPDF(dataPDF);
      handleDownloadAttestationPDF(dataPDF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_contract_update, activities]);

  useEffect(() => {
    if (responsePassGuarantee) {
      dispatch(clearAllDataContract());
      setTimeout(() => {
        idContract && dispatch(getDetailContract(idContract));
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsePassGuarantee, idContract]);

  const qrCodeCanvas = document.querySelector("canvas");

  useEffect(() => {
    if (qrCodeCanvas) {
      const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
      setQrCode(qrCodeDataUri);
    }
  }, [qrCodeCanvas]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 6,
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexDirection="column"
        sx={{ px: 6 }}
      >
        <WarningIcon />
        <Typography className={textStyles.h3} sx={{ mb: 1, mt: 2 }}>
          Vous êtes sur le point de crée le contrat
        </Typography>
        <Typography className={textStyles.paragraph} sx={{ mb: 1 }}>
          Cette action est irréversible !
        </Typography>
        <Box sx={{ position: "absolute", left: 0, top: 0, opacity: 0 }}>
          <QRCodeCanvas
            value={`https://attestations.madecennale.com/?id=${contract_id}`}
          />
        </Box>
      </Box>
      <Box display="flex" sx={{ mt: 2, mb: 5, px: 4 }} justifyContent="center">
        <Box width={200} style={{}}>
          <ButtonCustom
            onClick={onConfirm}
            disabled={loadingGet}
            title="Oui , je confirme"
            endIcon={
              loadingGet && (
                <CircularProgress size={14} style={{ color: "#fff" }} />
              )
            }
            backgroundColor={Colors.primary}
          />
        </Box>
        <Spacing width={12} />
        <Box component="div" width={65}>
          <ButtonCustom
            onClick={onClose}
            title="Non"
            color={Colors.error}
            backgroundColor="#fff"
            borderColor={Colors.error}
            variant="outlined"
          />
        </Box>
      </Box>
    </Dialog>
  );
};
export default WarningBox;
