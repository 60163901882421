import {
  ICreateUserDto,
  IFilterUsersDto,
  IResponseUsers,
  IUser,
} from "./users.dto";
import {
  IClearUserUpdateAction,
  IClearMessageResponseAction,
  ICreateUserFailedAction,
  ICreateUserRequestedAction,
  ICreateUserSucceededAction,
  IDeleteUserFailedAction,
  IDeleteUserRequestedAction,
  IDeleteUserSucceededAction,
  IGetDetailUserFailedAction,
  IGetDetailUserRequestedAction,
  IGetDetailUserSucceededAction,
  IGetUsersFailedAction,
  IGetUsersRequestedAction,
  IGetUsersSucceededAction,
  IUpdateUserFailedAction,
  IUpdateUserRequestedAction,
  IUpdateUserSucceededAction,
  UsersTypes,
} from "./users.type";

export function getUsers(
  filterUsersDto: IFilterUsersDto
): IGetUsersRequestedAction {
  return {
    type: UsersTypes.GET_USERS_REQUESTED,
    filterUsersDto,
  };
}

export function getUsersSucceeded(
  dataUsers: IResponseUsers
): IGetUsersSucceededAction {
  return {
    type: UsersTypes.GET_USERS_SUCCEEDED,
    dataUsers,
  };
}

export function getUsersFailed(error?: any): IGetUsersFailedAction {
  return {
    type: UsersTypes.GET_USERS_FAILED,
    error,
  };
}

export function getDetailUser(id: string): IGetDetailUserRequestedAction {
  return {
    type: UsersTypes.GET_DETAIL_USER_REQUESTED,
    id,
  };
}

export function getDetailUserSucceeded(
  dataUser: IUser
): IGetDetailUserSucceededAction {
  return {
    type: UsersTypes.GET_DETAIL_USER_SUCCEEDED,
    dataUser,
  };
}

export function getDetailUserFailed(error?: any): IGetDetailUserFailedAction {
  return {
    type: UsersTypes.GET_DETAIL_USER_FAILED,
    error,
  };
}

export function deleteUser(id: string): IDeleteUserRequestedAction {
  return {
    type: UsersTypes.DELETE_USER_REQUESTED,
    id,
  };
}

export function deleteUserSucceeded(
  message?: string
): IDeleteUserSucceededAction {
  return {
    type: UsersTypes.DELETE_USER_SUCCEEDED,
    message,
  };
}

export function deleteUserFailed(error?: any): IDeleteUserFailedAction {
  return {
    type: UsersTypes.DELETE_USER_FAILED,
    error,
  };
}

export function updateUser(
  user: ICreateUserDto,
  id: string
): IUpdateUserRequestedAction {
  return {
    type: UsersTypes.UPDATE_USER_REQUESTED,
    user,
    id,
  };
}

export function updateUserSucceeded(
  message?: string
): IUpdateUserSucceededAction {
  return {
    type: UsersTypes.UPDATE_USER_SUCCEEDED,
    message,
  };
}

export function updateUserFailed(error?: any): IUpdateUserFailedAction {
  return {
    type: UsersTypes.UPDATE_USER_FAILED,
    error,
  };
}

export function createUser(user: ICreateUserDto): ICreateUserRequestedAction {
  return {
    type: UsersTypes.CREATE_USER_REQUESTED,
    user,
  };
}

export function createUserSucceeded(
  message?: string
): ICreateUserSucceededAction {
  return {
    type: UsersTypes.CREATE_USER_SUCCEEDED,
    message,
  };
}

export function createUserFailed(error?: any): ICreateUserFailedAction {
  return {
    type: UsersTypes.CREATE_USER_FAILED,
    error,
  };
}

export function clearIdUserUpdate(): IClearUserUpdateAction {
  return {
    type: UsersTypes.CLEAR_USER_UPDATE,
  };
}

export function clearMessageResponse(): IClearMessageResponseAction {
  return {
    type: UsersTypes.CLEAR_MESSAGE_RESPONSE,
  };
}
