import moment from "moment";

const validContractStartDate = (contractStartDate: string) => {
  const firstDayOfCurrentMonth = new Date()
    .toISOString()
    .split("T")[0]
    .slice(0, -2)
    .concat("01");

  const dateExpected = moment(contractStartDate).isAfter(firstDayOfCurrentMonth)
    ? contractStartDate
    : firstDayOfCurrentMonth;

  return dateExpected;
};

export default validContractStartDate;
