import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { Colors } from "@themes/colors";
import formatMoney from "@utils/formatMoney";
import moment from "moment";
import { FC } from "react";
import { calculatePackagesInfo } from "./calculatePackagesInfo";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import ManImg from "../../../assets/Images/man.png";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage1: FC<IContentPage1Props> = ({
  infoInsurance,
  isPIB = true,
}) => {
  const revenue = infoInsurance?.informations_du_souscripteur_1?.chiffre_HT;
  const { bundle } = calculatePackagesInfo(revenue ? +revenue : 0);

  return (
    <View>
      <Text style={styles.titleHeader}>PROPOSITION D’ASSURANCE</Text>
      <Text style={styles.titleHeader2}>RC DECENNALE {isPIB && "PIB"}</Text>
      {!isPIB ? <Text style={styles.titleHeader2}>{bundle}</Text> : null}

      <View style={{ height: isPIB ? 40 : 30 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
            marginBottom: 3,
          }}
        >
          ASSURANCE RESPONSABILITE CIVILE & DECENNALE
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          {isPIB
            ? "des professions intellectuelles du bâtiment"
            : "des entreprises du bâtiment"}
        </Text>
      </View>
      <View style={styles.imgContainer}>
        <View style={{ width: "50%", paddingRight: "30px" }}>
          <Image
            style={{
              width: "100%",
            }}
            src={ManImg}
          />
        </View>
        <View style={styles.resultsContainer}>
          <View style={{ marginBottom: 15 }}>
            <Text
              style={{
                color: "#6E6B7B",
                fontSize: 12,
                fontWeight: 500,
                fontFamily: "Montserrat",
              }}
            >
              Proposition n°{infoInsurance?.contract_id}
            </Text>
            <View style={generatingResultPDFStyles.underlineTitleBoxContent} />
          </View>
          <View style={styles.inforResultsContainer}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                Montant de prime annuelle
              </Text>
              <Text
                style={{
                  color: "#5E5873",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: 10,
                }}
              >
                {`${formatMoney(
                  infoInsurance?.NETCompanyAvecCom,
                  3,
                  ",",
                  " ",
                  2
                )} €`}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.subInfoResult}>
                Fractionnement {infoInsurance?.fractionnement}
              </Text>
              {infoInsurance?.NETCompanyAvecCom && (
                <Text style={styles.subInfoResult}>
                  {infoInsurance?.fractionnement === "Mensuel"
                    ? `${formatMoney(
                        infoInsurance.NETCompanyAvecCom / 12,
                        3,
                        ",",
                        " ",
                        2
                      )} €`
                    : infoInsurance?.fractionnement === "Annuel"
                    ? `${formatMoney(
                        infoInsurance.NETCompanyAvecCom,
                        3,
                        ",",
                        " ",
                        2
                      )} €`
                    : infoInsurance?.fractionnement === "Semestriel"
                    ? `${formatMoney(
                        infoInsurance.NETCompanyAvecCom / 2,
                        3,
                        ",",
                        " ",
                        2
                      )} €`
                    : `${formatMoney(
                        infoInsurance.NETCompanyAvecCom / 4,
                        3,
                        ",",
                        " ",
                        2
                      )} €`}
                </Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.subInfoResult}>Franchise du contrat</Text>
              <Text style={styles.subInfoResult}>{`${formatMoney(
                infoInsurance?.franchise_du_contrat
              )} €`}</Text>
            </View>
            {!isPIB ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.subInfoResult}>Reprise du passé</Text>
                <Text style={styles.subInfoResult}>
                  {infoInsurance?.resumptionDatePast
                    ? moment(infoInsurance.resumptionDatePast).format(
                        "DD/MM/YYYY"
                      )
                    : "Non"}
                </Text>
              </View>
            ) : null}
            {/* <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.subInfoResult}>
                Taux de révision du contrat
              </Text>
              <Text
                style={styles.subInfoResult}
              >{`${infoInsurance?.taux_de_revision_du_contrat} %`}</Text>
            </View> */}
          </View>
        </View>
      </View>
      <View style={{ marginTop: 8, marginBottom: 50 }}>
        <View style={styles.itemInfoDetail}>
          <Text style={styles.labelItemInfo}>Assureur :</Text>
          <Text style={styles.contentItemInfo}>Optim Assurance</Text>
        </View>
        <View style={styles.itemInfoDetail}>
          <Text style={styles.labelItemInfo}>Assureur DPRSA & PJ :</Text>
          <Text style={styles.contentItemInfo}>
            Groupama protection juridique
          </Text>
        </View>
        <View style={styles.itemInfoDetail}>
          <Text style={styles.labelItemInfo}>Courtier :</Text>
          <Text style={styles.contentItemInfo}>Madecennale</Text>
        </View>

        <View style={styles.itemInfoDetail}>
          <Text style={styles.contentItemInfo}>
            Proposition valable 2 mois à partir du{" "}
            {moment(new Date()).format("DD/MM/YYYY")}
          </Text>
        </View>
      </View>
      <View>
        <Text style={generatingResultPDFStyles.subTitleYellow}>
          La présente proposition a pour objet de répondre :
        </Text>
        <Text
          style={{
            color: "#5E5873",
            fontSize: 10,
            fontFamily: "Montserrat",
            marginTop: "5px",
            lineHeight: 1.6,
          }}
        >
          <Text
            style={{
              color: "#5E5873",
              fontSize: 10,
              fontFamily: "Montserrat",
              marginTop: "5px",
              lineHeight: 1.6,
              fontWeight: 300,
            }}
          >
            Conformément à la loi
          </Text>{" "}
          à l'obligation d'assurance qui pèse sur les personnes réputées
          constructeur en matière d'assurance de Responsabilité Civile Décennale
          au sens des articles L.241-1 et L.241-2 du Code des assurances.
        </Text>
        <Text
          style={{
            color: "#5E5873",
            fontSize: 10,
            fontFamily: "Montserrat",
          }}
        >
          Seule la garantie légale obligatoire est proposée sauf si mention
          contraire en est faite dans les pages suivantes.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleHeader: {
    fontSize: 19,
    fontWeight: "bold",
    color: Colors.primary,
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 20,
  },
  titleHeader2: {
    fontSize: 18,
    color: "#5E5873",
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 5,
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginTop: 40,
    marginBottom: 35,
    padding: "8px 0px",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row",
  },
  resultsContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  inforResultsContainer: {
    border: "1px solid #FFCC4D",
    borderRadius: 5,
    padding: "10px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subInfoResult: {
    color: "#5E5873",
    fontSize: 10,
    fontFamily: "Montserrat",
  },
  itemInfoDetail: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
  },
  labelItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    marginRight: 4,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  contentItemInfo: {
    fontSize: "10px",
    color: "#6E6B7B",
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
});

export default ContentPage1;
