import { ICreateCompanyDto } from "@stores/createNewCompany/createNewCompany.dto";
import {
  CreateContractTypes,
  ICreateContractRequestedAction,
  ISaveInformationCompanyAction,
  ISaveHistoryInsurance,
  ISaveDraftContractRequestedAction,
  ISaveDraftContractSucceededAction,
  ISaveDraftContractFailedAction,
  IClearResponseDraftContractAction,
  ISaveInfoContractIntoRedux,
  IClearInfoContractRedux,
  IUpdateDraftContractRequestedAction,
  IUpdateDraftContractSucceededAction,
  IUpdateDraftContractFailedAction,
  IInfoDraftContractUpdate,
  IClearInfoDraftContractUpdate,
  IGetDetailContractRequestedAction,
  IGetDetailContractSucceededAction,
  IGetDetailContractFailedAction,
  IClearAllDataContractAction,
  ICalculateResultRequestedAction,
  ICalculateResultSucceededAction,
  ICalculateResultFailedAction,
  IAddCommentsAction,
  IClearResultCalculateAction,
  IArchiveDraftContractRequestedAction,
  IArchiveDraftContractSucceededAction,
  IArchiveDraftContractFailedAction,
  IPassInGuaranteeRequestedAction,
  IPassInGuaranteeSucceededAction,
  IPassInGuaranteeFailedAction,
  IClearMessageErrorAction,
} from "./contract.type";
import {
  ICalculateAmountDto,
  ICreateContractDto,
  ICreateHistoryInsuranceDto,
  IPricingByFranchise,
} from "./contract.dto";

export function createContract(): ICreateContractRequestedAction {
  return {
    type: CreateContractTypes.CREATE_CONTRACT_REQUESTED,
  };
}

export function getDetailContract(
  idContract: string
): IGetDetailContractRequestedAction {
  return {
    type: CreateContractTypes.GET_DETAIL_CONTRACT_REQUESTED,
    idContract,
  };
}

export function getDetailContractSucceeded(
  response?: any
): IGetDetailContractSucceededAction {
  return {
    type: CreateContractTypes.GET_DETAIL_CONTRACT_SUCCEEDED,
    response,
  };
}

export function getDetailContractFailed(
  error?: any
): IGetDetailContractFailedAction {
  return {
    type: CreateContractTypes.GET_DETAIL_CONTRACT_FAILED,
    error,
  };
}

export function saveDraftContract(
  information_contract: ICreateContractDto
): ISaveDraftContractRequestedAction {
  return {
    type: CreateContractTypes.SAVE_DRAFT_CONTRACT_REQUESTED,
    information_contract,
  };
}

export function saveDraftContractSucceeded(
  response?: any,
  message?: any
): ISaveDraftContractSucceededAction {
  return {
    type: CreateContractTypes.SAVE_DRAFT_CONTRACT_SUCCEEDED,
    response,
    message,
  };
}

export function saveDraftContractFailed(
  error?: any
): ISaveDraftContractFailedAction {
  return {
    type: CreateContractTypes.SAVE_DRAFT_CONTRACT_FAILED,
    error,
  };
}

export function saveInfoContractIntoRedux(
  info_contract: ICreateContractDto
): ISaveInfoContractIntoRedux {
  return {
    type: CreateContractTypes.SAVE_INFO_CONTRACT_INTO_REDUX,
    info_contract,
  };
}

export function clearInfoContractRedux(): IClearInfoContractRedux {
  return {
    type: CreateContractTypes.CLEAR_INFO_CONTRACT_REDUX,
  };
}

export function updateDraftContract(
  information_contract: ICreateContractDto,
  id_contract: string
): IUpdateDraftContractRequestedAction {
  return {
    type: CreateContractTypes.UPDATE_DRAFT_CONTRACT_REQUESTED,
    information_contract,
    id_contract,
  };
}

export function updateDraftContractSucceeded(
  response: any
): IUpdateDraftContractSucceededAction {
  return {
    type: CreateContractTypes.UPDATE_DRAFT_CONTRACT_SUCCEEDED,
    response,
  };
}

export function updateDraftContractFailed(
  error?: any
): IUpdateDraftContractFailedAction {
  return {
    type: CreateContractTypes.UPDATE_DRAFT_CONTRACT_FAILED,
    error,
  };
}

export function passInGuarantee(
  id_contract: string,
  isPass?: boolean
): IPassInGuaranteeRequestedAction {
  return {
    type: CreateContractTypes.PASS_IN_GUARANTEE_REQUESTED,
    id_contract,
    isPass,
  };
}
export function passInGuaranteeSucceeded(
  response: any
): IPassInGuaranteeSucceededAction {
  return {
    type: CreateContractTypes.PASS_IN_GUARANTEE_SUCCEEDED,
    response,
  };
}
export function passInGuaranteeFailed(
  error?: any
): IPassInGuaranteeFailedAction {
  return {
    type: CreateContractTypes.PASS_IN_GUARANTEE_FAILED,
    error,
  };
}

export function archiveDraftContract(
  id_contract: string
): IArchiveDraftContractRequestedAction {
  return {
    type: CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_REQUESTED,
    id_contract,
  };
}

export function archiveDraftContractSucceeded(
  message?: string
): IArchiveDraftContractSucceededAction {
  return {
    type: CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_SUCCEEDED,
    message,
  };
}

export function archiveDraftContractFailed(
  error?: any
): IArchiveDraftContractFailedAction {
  return {
    type: CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_FAILED,
    error,
  };
}

export function infoDraftContractUpdate(
  idContract: string
): IInfoDraftContractUpdate {
  return {
    type: CreateContractTypes.INFO_DRAFT_CONTRACT_UPDATE,
    idContract,
  };
}

export function clearInfoDraftContractUpdate(): IClearInfoDraftContractUpdate {
  return {
    type: CreateContractTypes.CLEAR_INFO_DRAFT_CONTRACT_UPDATE,
  };
}

export function clearResponseDraftContract(): IClearResponseDraftContractAction {
  return {
    type: CreateContractTypes.CLEAR_RESPONSE_DRAFT_CONTRACT,
  };
}

export function saveInformationCompany(
  informationCompany: ICreateCompanyDto
): ISaveInformationCompanyAction {
  return {
    type: CreateContractTypes.SAVE_INFORMATION_COMPANY,
    informationCompany,
  };
}

export function saveHistoryInsurance(
  historyInsurance: ICreateHistoryInsuranceDto
): ISaveHistoryInsurance {
  return {
    type: CreateContractTypes.SAVE_HISTORY_INSURANCE,
    historyInsurance,
  };
}

export function calculateResult(
  data: ICalculateAmountDto
): ICalculateResultRequestedAction {
  return {
    type: CreateContractTypes.CALCULATE_RESULT_REQUESTED,
    data,
  };
}

export function calculateResultSucceeded(
  response?: IPricingByFranchise[]
): ICalculateResultSucceededAction {
  return {
    type: CreateContractTypes.CALCULATE_RESULT_SUCCEEDED,
    response,
  };
}

export function calculateResultFailed(
  error?: any
): ICalculateResultFailedAction {
  return {
    type: CreateContractTypes.CALCULATE_RESULT_FAILED,
    error,
  };
}

export function addComments(comments: string): IAddCommentsAction {
  return {
    type: CreateContractTypes.ADD_COMMENTS,
    comments,
  };
}

export function clearResultCalculate(): IClearResultCalculateAction {
  return {
    type: CreateContractTypes.CLEAR_RESULT_CALCULATE,
  };
}

export function clearAllDataContract(): IClearAllDataContractAction {
  return {
    type: CreateContractTypes.CLEAR_ALL_DATA_CONTRACT,
  };
}
export function clearMessageError(): IClearMessageErrorAction {
  return {
    type: CreateContractTypes.CLEAR_MESSAGE_ERROR,
  };
}
