import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { clearAllDataContract } from "@stores/contract/contract.creator";
import { resetListQuotesState } from "@stores/listQuotes/listQuotes.creator";
import { clearAllActivities } from "@stores/savedActivities/savedActivities.creator";
import { clearDataLogin } from "@stores/login/login.creator";
import { LogoutTypes } from "./logout.type";

function* logoutRequested() {
  try {
    localStorage.removeItem("AccessToken");
    yield all([
      put(clearAllDataContract()),
      put(resetListQuotesState()),
      put(clearAllActivities()),
      put(clearDataLogin()),
    ]);
  } catch (err) {
    console.log(err);
  }
}

function* watchLogoutRequest() {
  yield takeLatest(LogoutTypes.LOGOUT_REQUESTED, logoutRequested);
}

function* logoutSaga() {
  yield all([fork(watchLogoutRequest)]);
}

export default logoutSaga;
