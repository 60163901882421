import * as React from "react";

const PaperIcon: React.FC<{ color: string }> = ({ color = "#6E6B7B" }) => (
  <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.192 4.516a.305.305 0 0 1-.059-.087.303.303 0 0 0-.058-.088L6.992.258C6.933.2 6.875.141 6.817.141 6.758.083 6.642.083 6.583.083H2.5C1.508.083.75.841.75 1.833v9.333c0 .992.758 1.75 1.75 1.75h7c.992 0 1.75-.758 1.75-1.75V4.75c0-.059 0-.175-.058-.234Zm-4.025-2.45 2.1 2.1h-2.1v-2.1ZM2.5 11.75h7c.35 0 .583-.234.583-.584V5.333h-3.5C6.233 5.333 6 5.1 6 4.75v-3.5H2.5c-.35 0-.583.233-.583.583v9.333c0 .35.233.584.583.584Z"
      fill={color}
    />
  </svg>
);

export default PaperIcon;
