import { Font, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage7Artisans = () => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.specialTable}>
        <View style={[generatingResultPDFStyles.headerSpecialTable]}>
          <Text
            style={[generatingResultPDFStyles.titleHeaderTable, { padding: 4 }]}
          >
            Garantie DPRSA/PJ GROUPAMA n°504 982 dans la limite du plafond (Cf.
            Annexe DG
          </Text>
          <Text style={[generatingResultPDFStyles.titleHeaderTable]}>
            GROUPAMA)
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text
            style={{
              color: "#5E5873",
              fontSize: "9px",
              fontFamily: "Montserrat",
              padding: 8,
              backgroundColor: "#FFF9E8",
              textAlign: "left",
              fontWeight: 500,
              flex: 1,
            }}
          >
            Nature des garanties
          </Text>
          <View
            style={{
              width: 0.5,
              height: "100%",
              backgroundColor: Colors.primary,
            }}
          />
          <Text
            style={{
              color: "#5E5873",
              fontSize: "9px",
              fontFamily: "Montserrat",
              padding: 8,
              backgroundColor: "#FFF9E8",
              textAlign: "left",
              fontWeight: 500,
              flex: 1,
            }}
          >
            Domaines
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <View style={generatingResultPDFStyles.itemTableEven}>
              <Text
                style={[generatingResultPDFStyles.textRadio, { padding: 8 }]}
              >
                Conseil juridique, Intervention auprès de la partie adverse,
                Recherche de solution amiable
              </Text>
            </View>
            <View style={generatingResultPDFStyles.itemTableOdd}>
              <Text
                style={[generatingResultPDFStyles.textRadio, { padding: 8 }]}
              >
                Mise en œuvre de l'action judiciaire avec l'avocat Suivi de
                l'affaire jusqu'à l'exécution des décisions rendues
              </Text>
            </View>
          </View>
          <View
            style={{
              width: 0.5,
              height: "100%",
              backgroundColor: Colors.primary,
            }}
          />
          <View style={{ flex: 1 }}>
            <Text style={[styles.textMoney, { lineHeight: 1.8 }]}>
              Activité professionnelle, Administrative, Aide aux victimes,
              Automobile, Défense pénale et disciplinaire, Locaux
              professionnels, Protection sociale, Prud’homale, Recouvrement
              créances
            </Text>
          </View>
        </View>
      </View>

      <View style={{ marginTop: 30 }}>
        <Text
          style={{
            fontSize: 9,
            fontFamily: "Montserrat",
            fontWeight: "bold",
            color: Colors.primary,
            paddingLeft: 12,
            paddingBottom: 4,
          }}
        >
          JURI’LAW
        </Text>
        <View style={styles.boxLaw}>
          <Text style={styles.textLaw}>
            La prestation JURI’LAW est un service d’information juridique et
            administrative en ligne via le site internet{" "}
            <Link src="www.juri-law.fr">www.juri-law.fr</Link>.
          </Text>
          <Text style={styles.textLaw}>
            Le site vous permet de bénéficier de : la mise à disposition de
            modèles d’actes juridiques et administratifs à compléter par
            vous-même ; la mise à disposition de résultats de recherches de
            similarités de marques limités à cinq marques par an ; de réponses à
            cinq questions techniques par an ; la mise en relation avec des
            avocats spécialisés ; d’un lexique des termes couramment utilisés en
            construction; de fiches pratiques; d’actualités en ligne ainsi que
            d’une foire aux questions.
          </Text>
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Montserrat",
              color: "#5E5873",
              textAlign: "left",
              lineHeight: 1.6,
              textDecoration: "underline",
              marginTop: 8,
            }}
          >
            JURI’LAW est une activité de prestation de services soumise à TVA
            comme suit :
          </Text>
          <Text style={styles.textLaw}>
            . TVA pour un paiement annuel ={" "}
            <Text style={generatingResultPDFStyles.textRadio}>10 €</Text> pour
            prestation JURI’LAW de{" "}
            <Text style={generatingResultPDFStyles.textRadio}>60 € TTC</Text> ;
          </Text>
          <Text style={styles.textLaw}>
            . TVA pour un paiement semestriel ={" "}
            <Text style={generatingResultPDFStyles.textRadio}>5 €</Text> ;
          </Text>
          <Text style={styles.textLaw}>
            . TVA pour un paiement trimestriel ={" "}
            <Text style={generatingResultPDFStyles.textRadio}>2.5 €</Text>.
          </Text>
        </View>
      </View>

      <View style={{ height: 25 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Champ d’application
        </Text>
      </View>
      <View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            La garantie proposée ne pourra engager l'Assureur en dehors des
            termes et limites précisés par les clauses et conditions de la
            garantie au sein de la présente proposition d’assurance, des
            Conditions générales CG_RCD_V2793 et de la Nomenclature_V0993.
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            Il est également précisé que, par la présente, il est proposé de
            couvrir uniquement la responsabilité civile et décennale du
            souscripteur liée aux activités de pose, de bâtiment ci-dessus
            énumérées.
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
             Les garanties s’appliqueront aux seuls chantiers démarrés durant
            la période d'effet du contrat.
          </Text>
        </View>
      </View>

      <View style={{ height: 25 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Territorialités
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            color: "#5E5873",
            fontFamily: "Montserrat",
            lineHeight: 1.6,
          }}
        >
          Ce contrat couvre les chantiers réalisés par l'Assuré en France
          métropolitaine.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "left",
    padding: "8px 9px 8px 9px",
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  liRed: {
    position: "relative",
    paddingLeft: 12,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  dotLiRed: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#EA5455",
    left: 5,
    top: 4.5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
  mainContentRed: {
    fontSize: 9,
    color: "#EA5455",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  contentTablePage2Red: {
    fontSize: 9,
    color: "#F8191A",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  textRadio: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    padding: "8px 0 0 8px",
    fontWeight: 500,
    lineHeight: 1.6,
  },
  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
    lineHeight: 1.6,
  },
  textFranchise: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "center",
    padding: 8,
  },
  boxLaw: {
    padding: "2px 0 0 10px",
    borderLeft: "2px solid #FFCC4D",
  },
  textLaw: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    lineHeight: 1.6,
  },
});

export default ContentPage7Artisans;
