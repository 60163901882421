import { all, fork } from "redux-saga/effects";
import getActivitiesSaga from "./activitiesOfContract/activitiesOfContract.saga";
import draftContractSaga from "./contract/contract.saga";
import createNewCompanySaga from "./createNewCompany/createNewCompany.saga";
import franchiseSaga from "./franchises/franchises.saga";
import listQuotesSaga from "./listQuotes/listQuotes.saga";
import loginSaga from "./login/login.saga";
import logoutSaga from "./logout/logout.saga";
import searchCompanySaga from "./searchCompany/searchCompany.saga";
import statisticSaga from "./statistic/statistic.saga";
import usersSaga from "./users/users.saga";

function* rootSaga(): any {
  yield all([
    fork(loginSaga),
    fork(searchCompanySaga),
    fork(createNewCompanySaga),
    fork(getActivitiesSaga),
    fork(draftContractSaga),
    fork(listQuotesSaga),
    fork(franchiseSaga),
    fork(logoutSaga),
    fork(statisticSaga),
    fork(usersSaga),
  ]);
}

export default rootSaga;
