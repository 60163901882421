import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TextStyles } from "@themes/textStyles";
import EditIcon from "@assets/SvgComponents/EditIcon";
import { makeStyles } from "@mui/styles";
import DeleteUserIcon from "@assets/SvgComponents/DeleteUserIcon";
import ConfirmDialog from "@components/ConfirmDialog";
import { deleteUser, getDetailUser } from "@stores/users/users.creator";
import { useNavigate } from "react-router-dom";

interface IActionsUser {
  id: string;
  loading?: boolean;
}

const ActionsUser: FC<IActionsUser> = ({ id, loading = false }) => {
  const textStyles = TextStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const handleEditUser = () => {
    dispatch(getDetailUser(id));
    navigate("/management-users/information-detail");
  };

  return (
    <>
      <Box component="div" position="relative" sx={{ width: "fit-content" }}>
        <Box
          component="p"
          sx={{ m: 0, display: "flex", cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          <MoreVertIcon style={{ color: "#5E5873" }} />
        </Box>
        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 300 } : {})}
        >
          <Box
            component="div"
            className={classes.rootList}
            sx={{ zIndex: 999 }}
          >
            <List className={classes.listActions}>
              <ListItem button onClick={handleEditUser}>
                <ListItemIcon>
                  <EditIcon color="#6E6B7B" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={textStyles.paragraph}>
                      Modifier
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem button onClick={() => setOpenConfirm(true)}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress size={14} style={{ color: "#6E6B7B" }} />
                  ) : (
                    <DeleteUserIcon color="#6E6B7B" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={textStyles.paragraph}>
                      Supprimer
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grow>
      </Box>
      {open && (
        <Box
          component="span"
          sx={{
            m: 0,
            width: "100vw",
            height: "100vh",
            left: 0,
            top: 0,
            zIndex: 9,
          }}
          display="flex"
          position="fixed"
          onClick={() => setOpen(false)}
        />
      )}
      <ConfirmDialog
        titleCancel="Annuler"
        titleConfirm="Supprimer"
        titleDialog="Etes-vous sûr que vous voulez supprimer?"
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
          dispatch(deleteUser(id));
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  rootList: {
    position: "absolute",
    zIndex: 9,
    right: 0,
  },
  listActions: {
    borderRadius: 6,
    background: "#fff",
    boxShadow: "0px 4.43679px 26.6208px rgb(0 0 0 / 6%)",
    "& .MuiListItem-root": {
      padding: "3px 30px 3px 16px",
      "&:hover": {
        backgroundColor: "#F8F8F8",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "unset",
        marginRight: "8px",
      },
    },
  },
});

export default ActionsUser;
