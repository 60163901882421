import { makeStyles } from "@mui/styles";

export const TextStyles = makeStyles({
  display_1: {
    fontSize: "5.25rem !important",
    color: "#5E5873",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  display_2: {
    fontSize: "4.813rem !important",
    color: "#5E5873",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  display_3: {
    fontSize: "3.938rem !important",
    color: "#5E5873",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  display_4: {
    fontSize: "3.063rem !important",
    color: "#5E5873",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  h1: {
    fontSize: "1.75rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  h2: {
    fontSize: "1.5rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  h3: {
    fontSize: "1.313rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  h4: {
    fontSize: "1.125rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  h5: {
    fontSize: "0.938rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  h6: {
    fontSize: "0.875rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_lead: {
    fontSize: "1rem !important",
    color: "#6E6B7B",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_semi_bold: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "600 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_bold: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "700 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_small: {
    fontSize: "0.75rem !important",
    color: "#6E6B7B",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_small_500: {
    fontSize: "0.75rem !important",
    color: "#6E6B7B",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_small_error: {
    fontSize: "0.7rem !important",
    color: "#EA5455",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_small_semi_bold: {
    fontSize: "0.75rem !important",
    color: "#6E6B7B",
    fontWeight: "600 !important",
    fontFamily: 'Montserrat !important',
  },
  paragraph_small_bold: {
    fontSize: "0.75rem !important",
    color: "#6E6B7B",
    fontWeight: "700 !important",
    fontFamily: 'Montserrat !important',
  },
  delete: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "400  !important",
    textDecoration: "line-through !important",
    fontFamily: 'Montserrat !important',
  },
  italicized: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "400 !important",
    fontFamily: 'Montserrat !important',
  },
  underline: {
    fontSize: "0.875rem !important",
    color: "#6E6B7B",
    fontWeight: "400 !important",
    textDecoration: "underline",
    fontFamily: 'Montserrat !important',
  },
  button_lg: {
    fontSize: "1rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    letterSpacing: "0.4px !important",
    fontFamily: 'Montserrat !important',
  },
  button_md: {
    fontSize: "0.875rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
  button_sm: {
    fontSize: "0.688rem !important",
    color: "#5E5873",
    fontWeight: "500 !important",
    fontFamily: 'Montserrat !important',
  },
});
