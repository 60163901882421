import * as React from "react";

const ArchiveIcon: React.FC<{ color: string }> = ({ color = "#000" }) => (
  <svg width={16} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.667.333h14.666c.368 0 .667.298.667.667v3.333a.667.667 0 0 1-.667.667h-.666v-.667A.667.667 0 0 0 14 3.666h.667v-2H1.333v2H2a.667.667 0 0 0-.667.667V5H.667A.667.667 0 0 1 0 4.333V1C0 .63.298.333.667.333Zm2 4.667H1.333v8c0 .368.299.666.667.666h12a.667.667 0 0 0 .667-.666V5h-1.334v-.667c0-.368.299-.667.667-.667H2c.368 0 .667.299.667.667V5Zm0 0h10.666v7.333H2.667V5ZM6 7c0 .368.298.666.667.666h2.666a.667.667 0 0 0 0-1.333H6.667A.667.667 0 0 0 6 7Z"
      fill={color}
    />
  </svg>
);

export default ArchiveIcon;
