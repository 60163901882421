import React, { FC } from "react";
import { Button, Typography, styled } from "@mui/material";
import { TextStyles } from "themes/textStyles";
import { Colors } from "themes/colors";

type VariantButton = "text" | "contained" | "outlined";

type TypeButton = "reset" | "submit" | "button";

type SizeTitle = "lg" | "md" | "sm";

interface ButtonCustomProps {
  color?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  backgroundColor?: string;
  variant?: VariantButton;
  title?: string;
  sizeTitle?: SizeTitle;
  type?: TypeButton;
  disabled?: boolean;
  boxShadow?: string;
  borderRadius?: number;
  padding?: string;
  borderColor?: string;
}

const ButtonCustom: FC<ButtonCustomProps> = ({
  color = "#fff",
  startIcon,
  endIcon,
  onClick = () => {},
  backgroundColor = Colors.primary,
  variant = "contained",
  title,
  sizeTitle = "md",
  type = "button",
  disabled,
  boxShadow,
  borderRadius = 5,
  padding,
  borderColor = "#fff",
}) => {
  const textStyles = TextStyles();

  return (
    <ButtonStyled
      type={type}
      variant={variant}
      style={{backgroundColor: variant === "contained" ? backgroundColor : "#fff",
        boxShadow: boxShadow || "unset",
        borderRadius,
        padding,
        minWidth: "unset",
        borderColor,
        borderWidth: variant === "outlined" ? 1 : 0,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        style={{ color }}
        className={
          sizeTitle === "lg"
            ? textStyles.button_lg
            : sizeTitle === "md"
            ? textStyles.button_md
            : textStyles.button_sm
        }
      >
        {title}
      </Typography>
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)(() => ({
  textTransform: "none",
  paddingTop: 8,
  paddingBottom: 8,
  width: "100%",
  height: "100%",
  "&:hover": {
    boxShadow: "0px 4px 14px rgba(115, 103, 240, 0.38) !important",
  },
  "& .MuiButton-startIcon": {
    margin: 0,
  },
}));

export default ButtonCustom;
