import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import { ICalculateAmountDto, ICreateContractDto } from "./contract.dto";

export function saveDraftContractService(draftContractDto: ICreateContractDto) {
  const apiUrl = BaseUrl.dev + Endpoint.draft_contract;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data: draftContractDto,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function getDetailDraftContractService(idContract: string) {
  const apiUrl = BaseUrl.dev + Endpoint.contracts + `/${idContract}/detailed`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
    })
      .then((res) => resolve(res))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function updateDraftContractService(
  draftContractDto: ICreateContractDto,
  idContract: string
) {
  const apiUrl = BaseUrl.dev + Endpoint.contracts + `/${idContract}`;

  let payload: any = {};
  if (draftContractDto?.contractOption?.franchiseId?._id) {
    payload = {
      ...draftContractDto,
      contractOption: {
        ...draftContractDto.contractOption,
        franchiseId: draftContractDto?.contractOption?.franchiseId?._id,
      },
    };
  } else {
    payload = { ...draftContractDto };
  }

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "PUT",
      url: apiUrl,
      data: payload,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function calculateAmountService(data: ICalculateAmountDto) {
  const apiUrl = BaseUrl.dev + Endpoint.calculate_amount;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        rejects(err?.response?.data);
      });
  });
}

export function archiveContractService(id: string) {
  const apiUrl = BaseUrl.dev + Endpoint.archive_contract + `/${id}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "PUT",
      url: apiUrl,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        rejects(err?.response?.data);
      });
  });
}
export function passInGuaranteeService(id_contract: string, isPass = true) {
  const endpoint = isPass
    ? Endpoint.pass_in_guarantee
    : Endpoint.generate_contact_code;

  const apiUrl = BaseUrl.dev + endpoint;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
      data: {
        contractId: id_contract,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        rejects(err?.response?.data);
      });
  });
}
