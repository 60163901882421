import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import formatMoney from "@utils/formatMoney";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage6Props {
  infoInsurance?: IInfoContractPDF;
}

const ContentPage6: FC<IContentPage6Props> = ({ infoInsurance }) => {
  return (
    <View break>
      <View style={styles.specialTable}>
        <View style={styles.headerSpecialTable}>
          <View style={{ flex: 3 }}>
            <Text style={styles.titleHeaderTable}>Nature des garanties</Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 2.6 }}>
            <Text style={styles.titleHeaderTable}>
              Montant des garanties par année d’assurance
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text
              style={{
                fontSize: 10,
                color: "#fff",
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Franchises
            </Text>
          </View>
        </View>
        <Text
          style={{
            color: "#5E5873",
            fontSize: "9px",
            fontFamily: "Montserrat",
            padding: 8,
            backgroundColor: "#FFF9E8",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          RESPONSABILITE CIVILE DECENNALE
        </Text>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>Garantie Légale Obligatoire</Text>
            <Text style={styles.textMoney}>
              (La franchise applicable sera multipliée par deux en cas de
              non-respect de la Réglementation Thermique 2012 mettant en cause
              la responsabilité de l’article 1792 du Code civil - Applicable en
              France Métropolitaine)
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2.6 }}>
            <Text style={styles.textRadio}>Montant des garanties :</Text>
            <View>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>Habitation :</Text>
              </View>
              <Text style={styles.textMoney}>
                A hauteur du coût des travaux de réparation des dommages à
                l’ouvrage. Les travaux de réparation comprennent également les
                travaux de démolition, déblaiement, dépose ou démontages
                éventuellement nécessaires
              </Text>
            </View>
            <View>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>Hors Habitation :</Text>
              </View>
              <Text style={styles.textMoney}>
                A hauteur du coût des travaux de réparation des dommages à
                l’ouvrage dans la limite du coût total de construction déclaré
                par le maître d’ouvrage sans pouvoir être supérieur au montant
                prévu au I de l’article R243-3 du Code des assurances
              </Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>
              {infoInsurance?.page6?.amount_franchise_2
                ? formatMoney(infoInsurance.franchise_du_contrat)
                : ""}{" "}
              €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              {" "}
              Garantie décennale en cas d’intervention en qualité de
              sous-traitant
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 2.6 }}>
            <Text style={styles.textMoney}>
              50 000.00 € par contrat de mission
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>
              {infoInsurance?.page6?.amount_franchise_3 &&
                formatMoney(infoInsurance.franchise_du_contrat)}{" "}
              €
            </Text>
          </View>
        </View>
      </View>
      <View style={{ height: 30 }} />
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Territorialités
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            color: "#5E5873",
            fontFamily: "Montserrat",
            lineHeight: 1.6,
          }}
        >
          Ce contrat couvre les chantiers réalisés par l'Assuré en France
          métropolitaine.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "left",
    padding: "8px 9px 8px 9px",
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  contentTablePage2Red: {
    fontSize: 9,
    color: "#F8191A",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  textRadio: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    padding: "8px 0 0 8px",
    fontWeight: 500,
    lineHeight: 1.6,
  },
  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
    lineHeight: 1.6,
  },
  textFranchise: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "center",
    padding: 8,
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.2,
  },
  boxLaw: {
    padding: "2px 0 0 10px",
    borderLeft: "2px solid #FFCC4D",
  },
  textLaw: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    lineHeight: 1.6,
  },
});

export default ContentPage6;
