import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { ICreateCompanyDto } from "./createNewCompany.dto";
import {
  CreateNewCompanyTypes,
  IClearResponseCreateCompanyAction,
  IClearSelectedCreateCompanyAction,
  ICreateCompanyFailedAction,
  ICreateCompanyRequestedAction,
  ICreateCompanySucceededAction,
  ISaveSelectedTypeAction,
  IUpdateCompanyFailedAction,
  IUpdateCompanyRequestedAction,
  IUpdateCompanySucceededAction,
} from "./createNewCompany.type";

export function createNewCompany(
  dataCompany: ICreateCompanyDto
): ICreateCompanyRequestedAction {
  return {
    type: CreateNewCompanyTypes.CREATE_COMPANY_REQUESTED,
    dataCompany,
  };
}

export function createNewCompanySucceeded(
  response?: any
): ICreateCompanySucceededAction {
  return {
    type: CreateNewCompanyTypes.CREATE_COMPANY_SUCCEEDED,
    response,
  };
}

export function createNewCompanyFailed(
  error?: any
): ICreateCompanyFailedAction {
  return {
    type: CreateNewCompanyTypes.CREATE_COMPANY_FAILED,
    error,
  };
}

export function saveSelectedType(
  selected_type: ContractType
): ISaveSelectedTypeAction {
  return {
    type: CreateNewCompanyTypes.SAVE_SELECTED_TYPE,
    selected_type,
  };
}

export function clearResponseCompany(): IClearResponseCreateCompanyAction {
  return {
    type: CreateNewCompanyTypes.CLEAR_RESPONSE,
  };
}

export function clearSelectedType(): IClearSelectedCreateCompanyAction {
  return {
    type: CreateNewCompanyTypes.CLEAR_SELECTED,
  };
}

export function updateCompany(
  dataCompany: ICreateCompanyDto,
  idCompany: string
): IUpdateCompanyRequestedAction {
  return {
    type: CreateNewCompanyTypes.UPDATE_COMPANY_REQUESTED,
    dataCompany,
    idCompany,
  };
}

export function updateCompanySucceeded(
  response?: any
): IUpdateCompanySucceededAction {
  return {
    type: CreateNewCompanyTypes.UPDATE_COMPANY_SUCCEEDED,
    response,
  };
}

export function updateCompanyFailed(error?: any): IUpdateCompanyFailedAction {
  return {
    type: CreateNewCompanyTypes.UPDATE_COMPANY_FAILED,
    error,
  };
}
