import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";
import { IFilterContractDto } from "./listQuotes.dto";

export function getListQuotesService(filterContractDto?: IFilterContractDto) {
  const apiUrl = BaseUrl.dev + Endpoint.contracts;

  const params: IFilterContractDto = {};

  if (filterContractDto?.limit) {
    params.limit = filterContractDto.limit;
  }
  if (filterContractDto?.page) {
    params.page = filterContractDto.page;
  }
  if (filterContractDto?.sortBy) {
    params.sortBy = filterContractDto.sortBy;
  }
  if (filterContractDto?.companyName) {
    params.companyName = filterContractDto.companyName;
  }
  if (filterContractDto?.type) {
    params.type = filterContractDto.type;
  }
  if (filterContractDto?.status) {
    params.status = filterContractDto.status;
  }

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function createDuplicateContractService(id: string) {
  const apiUrl = BaseUrl.dev + Endpoint.duplicate_contracts + `/${id}`;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "POST",
      url: apiUrl,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        rejects(err?.response?.data);
      });
  });
}
