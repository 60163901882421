import * as React from "react";

const ClientIcon: React.FC<{ color?: string }> = ({ color = "#BABFC7" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    fill="none"
    viewBox="0 0 16 18"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.793 4.834C3.793 2.5 5.613.667 7.93.667c2.317 0 4.138 1.833 4.138 4.167C12.07 7.167 10.25 9 7.931 9 5.614 9 3.793 7.167 3.793 4.834zm11.587 10V16.5c0 .5-.331.834-.828.834-.496 0-.828-.334-.828-.834v-1.666c0-1.417-1.075-2.5-2.482-2.5H4.62c-1.407 0-2.483 1.083-2.483 2.5V16.5c0 .5-.331.834-.828.834-.497 0-.828-.334-.828-.834v-1.666c0-2.334 1.821-4.167 4.139-4.167h6.62c2.318 0 4.139 1.833 4.139 4.167zm-7.449-7.5c-1.407 0-2.483-1.084-2.483-2.5 0-1.417 1.076-2.5 2.483-2.5s2.483 1.083 2.483 2.5c0 1.416-1.076 2.5-2.483 2.5z"
      fill={color}
    ></path>
  </svg>
);

export default ClientIcon;
