import * as React from "react";

const AdminIcon: React.FC<{ color?: string }> = ({ color = "#BABFC7" }) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.241 10.667h1.655c1.821 0 3.31 1.5 3.31 3.333 0 1.834-1.489 3.334-3.31 3.334-1.82 0-3.31-1.5-3.31-3.334v-1.666h-3.31V14c0 1.834-1.49 3.334-3.311 3.334S.655 15.834.655 14c0-1.833 1.489-3.333 3.31-3.333H5.62V7.334H3.965C2.144 7.334.655 5.834.655 4c0-1.833 1.489-3.333 3.31-3.333 1.82 0 3.31 1.5 3.31 3.333v1.667h3.31V4c0-1.833 1.49-3.333 3.311-3.333s3.31 1.5 3.31 3.333c0 1.834-1.489 3.334-3.31 3.334h-1.655v3.333Zm1.655-8.333c-.91 0-1.655.75-1.655 1.666v1.667h1.655c.91 0 1.655-.75 1.655-1.667 0-.916-.744-1.666-1.655-1.666ZM5.62 14c0 .917-.745 1.667-1.655 1.667S2.31 14.917 2.31 14c0-.916.744-1.666 1.655-1.666H5.62V14ZM3.965 5.667H5.62V4c0-.916-.745-1.666-1.655-1.666S2.31 3.084 2.31 4c0 .917.744 1.667 1.655 1.667Zm6.62 5h-3.31V7.334h3.31v3.333ZM12.242 14c0 .917.745 1.667 1.655 1.667s1.655-.75 1.655-1.667c0-.916-.744-1.666-1.655-1.666h-1.655V14Z"
      fill={color}
    />
  </svg>
);

export default AdminIcon;
