import { IResponseCompanyPappers } from "./searchCompany.dto";

export enum SearchCompanyTypes {
  SEARCH_COMPANY_REQUESTED = "searchCompany/REQUESTED",
  SEARCH_COMPANY_SUCCEEDED = "searchCompany/SUCCEEDED",
  SEARCH_COMPANY_FAILED = "searchCompany/FAILED",
  CLEAR_DATA_SEARCH = "searchCompany/CLEAR_DATA",

  GET_DETAIL_COMPANY_REQUESTED = "searchCompany/DETAIL_REQUESTED",
  GET_DETAIL_COMPANY_MONGO_REQUESTED = "searchCompany/DETAIL_MONGO_REQUESTED",
  GET_DETAIL_COMPANY_SUCCEEDED = "searchCompany/DETAIL_SUCCEEDED",
  GET_DETAIL_COMPANY_FAILED = "searchCompany/DETAIL_FAILED",
  CLEAR_DATA_DETAIL_COMPANY = "searchCompany/CLEAR_DETAIL_COMPANY",
}

export interface ISearchCompanyRequestedAction {
  type: typeof SearchCompanyTypes.SEARCH_COMPANY_REQUESTED;
  q: string;
}

export interface ISearchCompanySucceededAction {
  type: typeof SearchCompanyTypes.SEARCH_COMPANY_SUCCEEDED;
  data: IResponseCompanyPappers;
}

export interface ISearchCompanyFailedAction {
  type: typeof SearchCompanyTypes.SEARCH_COMPANY_FAILED;
  error?: any;
}

export interface IClearDataSearchCompanyAction {
  type: typeof SearchCompanyTypes.CLEAR_DATA_SEARCH;
}

export interface IGetDetailCompanyRequestedAction {
  type: typeof SearchCompanyTypes.GET_DETAIL_COMPANY_REQUESTED;
  siren: string;
}

export interface IGetDetailCompanyMongoRequestedAction {
  type: typeof SearchCompanyTypes.GET_DETAIL_COMPANY_MONGO_REQUESTED;
  id: string;
}

export interface IGetDetailCompanySucceededAction {
  type: typeof SearchCompanyTypes.GET_DETAIL_COMPANY_SUCCEEDED;
  data: any;
}

export interface IGetDetailCompanyFailedAction {
  type: typeof SearchCompanyTypes.GET_DETAIL_COMPANY_FAILED;
  error?: any;
}

export interface IClearDetailCompanyAction {
  type: typeof SearchCompanyTypes.CLEAR_DATA_DETAIL_COMPANY;
}

export type SearchCompanyActions =
  | ISearchCompanyRequestedAction
  | ISearchCompanySucceededAction
  | ISearchCompanyFailedAction
  | IClearDataSearchCompanyAction
  | IGetDetailCompanyRequestedAction
  | IGetDetailCompanySucceededAction
  | IGetDetailCompanyFailedAction
  | IClearDetailCompanyAction
  | IGetDetailCompanyMongoRequestedAction;
