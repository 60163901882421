import { ContractType } from "@stores/listQuotes/listQuotes.dto";

export enum CompanyLegalStatus {
  AutoEntrepreneur = "AutoEntrepreneur",
  EI = "EI",
  EIRL = "EIRL",
  EURL = "EURL",
  SA = "SA",
  SARL = "SARL",
  SAS = "SAS",
  SASU = "SASU",
  SCI = "SCI",
  Autre = "Autre",
}

export type CompanyLegalStatusType =
  | "AutoEntrepreneur"
  | "EI"
  | "EIRL"
  | "EURL"
  | "SA"
  | "SARL"
  | "SAS"
  | "SASU"
  | "SCI"
  | "Autre";

export enum LeaderExperience {
  LESS_THAN_ONE_YEAR = "<1",
  FROM_1_TO_3_YEARS = "1-3",
  FROM_3_TO_5_YEARS = "3-5",
  FROM_5_TO_10_YEARS = "5-10",
  MORE_THAN_10_YEARS = ">10",
}

export type LeaderExperienceType = "<1" | "1-3" | "3-5" | "5-10" | ">10";

export enum Certification {
  Aucune = "Aucune",
  Opqibi = "Opqibi",
  Opqtecc = "Opqtecc",
  Chai = "Chai",
  Autre = "Autre",
  Qualibat = "Qualibat",
  Qualifelec = "Qualifelec",
  QualitEnR = "QualitEnR",
  Ecoartisan = "Ecoartisan",
}

export type CertificationType =
  | "Aucune"
  | "Opqibi"
  | "Opqtecc"
  | "Chai"
  | "Autre"
  | "Qualibat"
  | "Qualifelec"
  | "QualitEnR"
  | "Ecoartisan";

export enum Diploma {
  Bac = "Bac",
  Bac23 = "Bac +2/3",
  Bac45 = "Bac +4/5",
  Ingenieur = "Ingénieur",
  Architecte = "Architecte",
  Aucun = "Aucun",
  CAP = "CAP",
  BEP = "BEP",
}

export type DiplomaType =
  | "Bac"
  | "Bac +2/3"
  | "Bac +4/5"
  | "Ingénieur"
  | "Architecte"
  | "Aucun"
  | "CAP"
  | "BEP";

export interface ILegalRepresentative {
  name?: string;
  birthday?: string;
  email?: string;
  phoneNumber?: string;
}

export interface ICompanyDetails {
  revenue?: number;
  percentMadeBySubContractors?: number;
  percentEntrustToSubContractors?: number;
  // isInReceivership?: boolean;
  workforce?: string;
  percentIndividuals?: number;
  percentEnterprises?: number;
  percentRenovation?: number;
  percentNew?: number;
  diploma?: DiplomaType;
  leaderExperience?: LeaderExperienceType;
  certification?: CertificationType;
}

export interface ICreateCompanyDto {
  name?: string;
  siren?: string;
  codeNAF?: string;
  legalStatus?: CompanyLegalStatusType;
  headquarterAddress?: string;
  city?: string;
  codePostal?: string;
  capitalSocial?: string;
  birthday?: string;
  professionIds?: Array<string>;
  legalRepresentative?: ILegalRepresentative;
  companyDetails?: ICompanyDetails;
}

export interface IResponseCreateNewCompany {
  selected_type?: ContractType;
  response?: any;
  loading?: boolean;
  error?: any;
}
