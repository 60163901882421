import AddDocumentIcon from "@assets/SvgComponents/AddDocumentIcon";
import FilterTableHead from "@assets/SvgComponents/FilterTableHead";
import SearchIcon from "@assets/SvgComponents/SearchIcon";
import InputCustom from "@components/InputCustom";
import LinearLoading from "@components/LinearLoading";
import PaginationCustom from "@components/PaginationCustom";
import { PaperShadowCustom } from "@components/PaperCustom";
import SelectCustom from "@components/SelectCustom";
import Spacing from "@components/Spacing";
import TopBar from "@components/TopBar";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getActivitiesContract } from "@stores/activitiesOfContract/activitiesOfContract.creator";
import { IdProfessionType } from "@stores/activitiesOfContract/activitiesOfContract.dto";
import { getListQuotes } from "@stores/listQuotes/listQuotes.creator";
import {
  ContractStatus,
  ContractType,
  IQuote,
  ISortByContractDto,
} from "@stores/listQuotes/listQuotes.dto";
import { IAppState } from "@stores/state";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import formatMoney from "@utils/formatMoney";
import { notistack } from "@utils/notistack";
import * as _ from "lodash";
import { debounce } from "lodash";
import moment from "moment";
import "moment/locale/fr";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ActionsContract from "./ActionsContract";
import BoxStatus from "./BoxStatus";
import DownloadDocument from "./DownloadDocument";

interface TypeData {
  value: any;
  label: string;
}

interface Filters {
  type?: string;
  status?: string;
}

const dataPage: TypeData[] = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 50, label: "50" },
];

const dataFilterByType: TypeData[] = [
  {
    value: null,
    label: ContractType.ALL,
  },
  {
    value: ContractType.PIB,
    label: ContractType.PIB,
  },
  {
    value: ContractType.Artisans_BTP,
    label: ContractType.Artisans_BTP,
  },
];

const dataFilterByStatus: TypeData[] = [
  {
    value: null,
    label: ContractStatus.ALL,
  },
  {
    value: ContractStatus.DRAFT,
    label: ContractStatus.DRAFT,
  },
  {
    value: ContractStatus.IN_PROGRESS,
    label: ContractStatus.IN_PROGRESS,
  },
  {
    value: ContractStatus.VALIDATE,
    label: ContractStatus.VALIDATE,
  },
  {
    value: ContractStatus.INVALIDATE,
    label: "Refusé",
  },
  {
    value: ContractStatus.OBSOLETE,
    label: ContractStatus.OBSOLETE,
  },
];

const ListQuotes = () => {
  const textStyles = TextStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control } = useForm();

  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortBy, setSortBy] = useState<ISortByContractDto>({});
  const [filters, setFilters] = useState<Filters>({});
  const [valueSearch, setValueSearch] = useState<any>();
  const [idProfession, setIdProfession] = useState<
    IdProfessionType | undefined
  >();

  const { listQuotes, error, loading, message, loading_get } = useSelector(
    (state: IAppState) => state.listQuotes
  );
  const { response } = useSelector((state: IAppState) => state.dataContract);
  const messageContract = useSelector(
    (state: IAppState) => state.dataContract.message
  );
  const errorContract = useSelector(
    (state: IAppState) => state.dataContract.error
  );

  // Handlers
  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue) => {
      setValueSearch(nextValue);
      setPage(1);
    }, 500),
    []
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    debounceSearch(e.target.value);
  };

  // Queries data
  const handleGetListQuote = useCallback(() => {
    const sortByToFetch = Object.keys(sortBy).length > 0 ? sortBy : undefined;

    dispatch(
      getListQuotes({
        page,
        limit: rowsPerPage,
        sortBy: sortByToFetch,
        companyName: valueSearch,
        ...filters,
      })
    );
  }, [dispatch, filters, page, rowsPerPage, sortBy, valueSearch]);

  useEffect(() => {
    moment.locale("fr");
    handleGetListQuote();
  }, [handleGetListQuote]);

  useEffect(() => {
    if (message || response) {
      message && notistack.success(message);
      response && notistack.success("Mise à jour du statut réussie");
      handleGetListQuote();
    }
    error && notistack.error(error);
  }, [error, handleGetListQuote, message, response]);

  useEffect(() => {
    if (messageContract) {
      notistack.success(messageContract);
      handleGetListQuote();
    }
    errorContract && notistack.error(errorContract);
  }, [errorContract, handleGetListQuote, messageContract]);

  useEffect(() => {
    if (!idProfession) return;

    dispatch(
      getActivitiesContract({
        idProfession,
      })
    );
  }, [dispatch, idProfession]);

  const refreshData = () => {
    handleGetListQuote();
  };

  return (
    <Grid container>
      <TopBar />

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography sx={{ pt: 4, px: 3, pb: 2 }} className={textStyles.h3}>
          Liste des devis réalisés
        </Typography>

        <PaperShadowCustom sx={{ position: "relative" }}>
          {loading_get && <LinearLoading />}

          <Box
            component="div"
            display="flex"
            sx={{ mx: 3, pt: 2.5, pb: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component="div"
              display="flex"
              alignItems="center"
              flexWrap={{ xs: "wrap", lg: "nowrap" }}
              width="70%"
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ width: { xs: "100%", lg: "auto" }, mr: { xs: 0, lg: 2 } }}
              >
                <Typography className={textStyles.paragraph}>
                  Afficher
                </Typography>
                <Spacing width={5} />
                <Box component="div" width={60}>
                  <SelectCustom
                    onChange={(value: number) => {
                      if (typeof value === "number") {
                        handleChangeRowsPerPage(value);
                      }
                    }}
                    data={dataPage}
                    defaultValue={rowsPerPage}
                    border={false}
                  />
                </Box>
              </Stack>

              <Box
                sx={{
                  minWidth: "195px",
                  flex: 1,
                }}
              >
                <Controller
                  name="name_company"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputCustom
                      placeholder="Rechercher"
                      endAdornmentIcon={<SearchIcon color="#BABFC7" />}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleSearch(e);
                      }}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  maxWidth: "20%",
                  minWidth: "200px",
                  ml: { xs: 1, lg: 2 },
                  mr: { xs: 1, lg: 2 },
                }}
              >
                <Controller
                  name="filter_by_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SelectCustom
                      onChange={(e) => {
                        onChange(e);
                        setPage(1);
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          type: e,
                        }));
                      }}
                      placeholder="Type de contrat"
                      data={dataFilterByType}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  maxWidth: "20%",
                  minWidth: "110px",
                }}
              >
                <Controller
                  name="filter_by_status"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SelectCustom
                      onChange={(e) => {
                        onChange(e);
                        setPage(1);
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          status: e,
                        }));
                      }}
                      placeholder="Satut"
                      data={dataFilterByStatus}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              width="fit-content"
              justifyContent="flex-end"
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              <Link
                to="/select-type"
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <AddDocumentIcon color={Colors.primary} />
                <Typography
                  className={textStyles.paragraph_bold}
                  sx={{
                    color: Colors.primary,
                    textDecoration: "underline",
                    ml: 1,
                  }}
                >
                  Créer un devis
                </Typography>
              </Link>
            </Box>
          </Box>

          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        contract_id: (prevSortBy?.contract_id ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      CONTRAT ID
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        company: (prevSortBy?.company ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      NOM DE LA SOCIETE
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        type: (prevSortBy?.type ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      TYPE DE CONTRAT
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        createdAt: (prevSortBy?.createdAt ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      DATE DE CREATION
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        amount: (prevSortBy?.amount ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      MONTANT
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell align="center" sx={{ pl: "0 !important" }}>
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setSortBy((prevSortBy) => ({
                        status: (prevSortBy?.status ?? -1) * -1,
                      }))
                    }
                  >
                    <Typography
                      className={textStyles.paragraph_small_semi_bold}
                    >
                      STATUT DEVIS
                    </Typography>
                    <FilterTableHead />
                  </Box>
                </TableCell>

                <TableCell>
                  <Typography className={textStyles.paragraph_small_semi_bold}>
                    ACTIONS
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isArray(listQuotes.data) &&
                listQuotes.data.map((quote: IQuote, index: number) => {
                  // 1: Question
                  // 2: Result
                  // 3: Attestation, Condition
                  let typeContract = 0;
                  if (quote?.amount && quote.amount > 0) {
                    if (
                      quote?.status &&
                      (quote.status === ContractStatus.IN_PROGRESS ||
                        quote.status === ContractStatus.DRAFT)
                    ) {
                      typeContract = 2;
                    } else if (
                      quote?.status &&
                      quote.status === ContractStatus.VALIDATE
                    ) {
                      typeContract = 3;
                    }
                  } else {
                    if (
                      quote?.status &&
                      quote.status === ContractStatus.DRAFT
                    ) {
                      typeContract = 1;
                    }
                  }
                  return (
                    <TableRow key={index} className={classes.rowBody}>
                      <TableCell>
                        <Typography className={textStyles.paragraph_semi_bold}>
                          {quote?.contract_id}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography className={textStyles.paragraph_semi_bold}>
                          {quote?.company?.name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography className={textStyles.paragraph}>
                          {quote?.type}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          className={textStyles.paragraph}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {moment(quote?.createdAt).format("LL")}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography className={textStyles.paragraph}>
                          {`${
                            quote?.amount
                              ? formatMoney(quote.amount, 3, ",", " ", 2)
                              : 0
                          } €`}
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ pl: "0 !important", pr: "0 !important" }}
                      >
                        <BoxStatus
                          id={quote._id}
                          status={quote?.status}
                          contractType={quote.type ?? ContractType.PIB}
                        />
                      </TableCell>

                      <TableCell>
                        <Box component="div" display="flex" alignItems="center">
                          <ActionsContract
                            isPassGuarantee={
                              quote?.status !== ContractStatus.IN_PROGRESS
                            }
                            loading={loading}
                            id={quote._id}
                            contract_id={quote?.contract_id}
                            isEdit={
                              quote?.isGuaranteedToPass ||
                              quote?.status === ContractStatus.INVALIDATE ||
                              quote?.status === ContractStatus.VALIDATE ||
                              quote?.status === ContractStatus.OBSOLETE
                            }
                            onRefresh={refreshData}
                            isArchive={
                              quote?.status !== ContractStatus.VALIDATE
                            }
                            setIdProfession={setIdProfession}
                            quoteType={quote.type ?? ContractType.PIB}
                          />
                          <DownloadDocument
                            setIdProfession={setIdProfession}
                            quoteType={quote.type ?? ContractType.PIB}
                            type={typeContract}
                            contract_id={quote._id}
                            onRefresh={refreshData}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box
            component="div"
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ mx: 3, py: 2.5 }}
          >
            <Typography
              className={textStyles.paragraph}
              sx={{ color: "#B9B9C3" }}
            >
              {`${
                _.isArray(listQuotes.data) ? listQuotes.data.length : 0
              } éléments affichés sur ${
                !_.isEmpty(listQuotes) ? listQuotes.total : 0
              }`}
            </Typography>
            <PaginationCustom
              onChange={(_, page) => setPage(page)}
              count={listQuotes?.last_page}
              page={page}
            />
          </Box>
        </PaperShadowCustom>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  tableHead: {
    background: "#F3F2F7",
    borderTop: "1px solid #EFEDF4",
    "& .MuiTableCell-head": {
      padding: "8px 0 8px 25px",
      "& .MuiTypography-root": {
        color: "#5E5873",
      },
      borderBottom: "1px solid #EFEDF4",
    },
  },
  rowBody: {
    "& .MuiTableCell-root": {
      paddingLeft: "25px",
      "& .MuiTypography-root": {
        color: "#5E5873",
      },
      borderBottom: "1px solid #EFEDF4",
    },
  },
});

export default ListQuotes;
