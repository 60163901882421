import {
  FiltersActivities,
  IFilterActivitiesDto,
  IResponseActivitiesOfContract,
} from "./activitiesOfContract.dto";

export enum ActivitiesOfContractTypes {
  GET_ACTIVITIES_REQUESTED = "activitiesOfContract/GET_REQUESTED",
  GET_ACTIVITIES_SUCCEEDED = "activitiesOfContract/GET_SUCCEEDED",
  GET_ACTIVITIES_FAIL = "activitiesOfContract/GET_FAIL",
  CHANGE_FILTERS = "activitiesOfContract/CHANGE_FILTERS",
  SAVE_CURRENT_ID_PROFESSION = "activitiesOfContract/SAVE_CURRENT_ID_PROFESSION",
  CLEAR_MESSAGE_ERROR = "activitiesOfContract/CLEAR",
}

export interface IGetActivitiesRequestedAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_REQUESTED;
  filterDto?: IFilterActivitiesDto;
}

export interface IGetActivitiesSucceededAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_SUCCEEDED;
  activities: IResponseActivitiesOfContract;
  message?: string;
}

export interface IGetActivitiesFailedAction {
  type: typeof ActivitiesOfContractTypes.GET_ACTIVITIES_FAIL;
  error?: any;
}

export interface IChangeActivitiesFiltersAction {
  type: typeof ActivitiesOfContractTypes.CHANGE_FILTERS;
  filters: Partial<FiltersActivities>;
}

export interface ISaveCurrentIdProfessionAction {
  type: typeof ActivitiesOfContractTypes.SAVE_CURRENT_ID_PROFESSION;
  currentIdProfession: string;
}

export interface IClearMessageErrorAction {
  type: typeof ActivitiesOfContractTypes.CLEAR_MESSAGE_ERROR;
}

export type ActivitiesContractActions =
  | IGetActivitiesRequestedAction
  | IGetActivitiesSucceededAction
  | IGetActivitiesFailedAction
  | IChangeActivitiesFiltersAction
  | ISaveCurrentIdProfessionAction
  | IClearMessageErrorAction;
