import { ISavedActivities } from "./savedActivities.dto";
import {
  SavedActivitiesActions,
  SavedActivitiesTypes,
} from "./savedActivities.type";

const initialSavedActivitiesState: ISavedActivities = {
  activities: [],
};

export function savedActivitiesReducer(
  state = initialSavedActivitiesState,
  action: SavedActivitiesActions
): ISavedActivities {
  switch (action.type) {
    case SavedActivitiesTypes.ADD_ACTIVITY: {
      return {
        ...state,
        activities: [...state.activities, action.activity],
      };
    }

    case SavedActivitiesTypes.ADD_MANY_ACTIVITY: {
      const newActivities = [...state.activities];
      action.activities.forEach((activity) => {
        const checkActivity = newActivities.find(
          (savedActivity) => savedActivity.id === activity.id
        );
        if (!checkActivity) {
          newActivities.push(activity);
        }
      });
      return {
        ...state,
        activities: [...newActivities],
      };
    }

    case SavedActivitiesTypes.UPDATE_ACTIVITY: {
      const activityUpdateIndex = state.activities.findIndex(
        (activity) => activity.id === action.activity.id
      );
      const newActivities = [...state.activities];
      newActivities[activityUpdateIndex] = action.activity;
      return {
        ...state,
        activities: [...newActivities],
      };
    }

    case SavedActivitiesTypes.REMOVE_ACTIVITY: {
      const newActivities = state.activities.filter(
        (activity) => activity.id !== action.idActivity
      );
      return {
        ...state,
        activities: newActivities,
      };
    }

    case SavedActivitiesTypes.CLEAR_ALL_ACTIVITY: {
      return {
        ...state,
        activities: [],
      };
    }

    default:
      return state;
  }
}
