import GeneratingQuestionPDF from "@pages/Contract/GeneratingQuestionPDF";
import GeneratingResultPDF from "@pages/Contract/GeneratingResultPDF";
import { IActivitiesSelected } from "@pages/Contract/InsuranceHistory";
import PDFAttestation from "@pages/ListQuotes/PDFAttestation";
import PDFCondition from "@pages/ListQuotes/PDFConditions";
import { pdf } from "@react-pdf/renderer";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { saveAs } from "file-saver";
import moment from "moment";

const currentDate = moment(new Date()).format("DD-MM-YYYY");

interface GeneralPDFProps {
  infoInsurance: IInfoContractPDF;
  companyName: string;
  contractId?: string;
  isPIB: boolean;
}

export const downloadConditionPDF = async ({
  infoInsurance,
  companyName,
  contractId,
  isPIB,
}: GeneralPDFProps) => {
  const asPDF = pdf();

  asPDF.updateContainer(
    <PDFCondition infoInsurance={infoInsurance} isPIB={isPIB} />
  );
  const blob = await asPDF.toBlob();
  saveAs(blob, `Conditions_${companyName}_${currentDate}_${contractId}.pdf`);
};

export const downloadAttestationPDF = async ({
  infoInsurance,
  companyName,
  contractId,
  isPIB,
  qrCode,
}: GeneralPDFProps & {
  qrCode?: any;
}) => {
  const asPDF = pdf();

  asPDF.updateContainer(
    <PDFAttestation
      qrCode={qrCode}
      infoInsurance={infoInsurance}
      isPIB={isPIB}
    />
  );

  const blob = await asPDF.toBlob();
  saveAs(blob, `Attestation_${companyName}_${currentDate}_${contractId}.pdf`);
};

export const downloadGeneratingResultPDF = async ({
  infoInsurance,
  companyName,
  price,
  franchies,
  isPIB,
}: GeneralPDFProps & {
  companyName: string;
  price: string;
  franchies: string;
}) => {
  const asPDF = pdf();

  asPDF.updateContainer(
    <GeneratingResultPDF infoInsurance={infoInsurance} isPIB={isPIB} />
  );

  const blob = await asPDF.toBlob();
  saveAs(blob, `${companyName}_${currentDate}_${price}_${franchies}.pdf`);
};

export const downloadGeneratingQuestionPDF = async ({
  companyName,
  contract,
  reason,
  activities,
  company,
}: {
  companyName: string;
  contract?: any;
  reason?: string;
  activities?: IActivitiesSelected[];
  company?: any;
}) => {
  const asPDF = pdf();
  asPDF.updateContainer(
    <GeneratingQuestionPDF
      activities={activities}
      company={company}
      contract={contract}
      reason={reason}
    />
  );
  const blob = await asPDF.toBlob();
  saveAs(blob, `${companyName}_QUESTIONNAIRE D’ETUDE_${currentDate}.pdf`);
};
