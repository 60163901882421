import * as React from "react"

const BackgroundLogin = () => (
  <svg
    width={746}
    height={544}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.085}
      d="M674.32 164.249c9.378-12.518 27.133-15.069 39.659-5.697 12.4 9.278 15.026 26.763 5.978 39.258l-.278.377-74.3 99.187c-9.463 12.633-27.039 15.836-40.339 7.428l-.401-.258-46.774-30.596-47.16 68.645c-8.768 12.763-26.141 16.101-39.003 7.578l-.389-.262c-12.77-8.763-16.109-26.126-7.581-38.981l.262-.388 61.799-89.954c9.206-13.401 27.348-17.094 41.048-8.442l.414.266 47.643 31.165 59.422-79.326ZM431.118 3.294c2.378-3.454 7.114-4.333 10.578-1.962a7.572 7.572 0 0 1 2.06 10.41l-.092.137-18.516 26.9c-2.715 3.79-7.967 4.704-11.803 2.082l-.137-.096-10.634-7.57-11.526 18.259c-2.209 3.5-6.814 4.583-10.352 2.465l-.139-.086a7.573 7.573 0 0 1-2.472-10.322l.085-.139 15.285-24.214c2.518-3.989 7.802-5.188 11.803-2.677l.209.136.206.141 10.857 7.73 14.588-21.194Z"
      fill="#FFCC4D"
    />
    <path
      opacity={0.07}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M633.403 129.308a2.5 2.5 0 0 0 2.5-2.5v-17.977h17.989a2.5 2.5 0 0 0 2.5-2.5v-8.655a2.5 2.5 0 0 0-2.5-2.5h-17.989v-18.11a2.5 2.5 0 0 0-2.5-2.5h-8.796a2.5 2.5 0 0 0-2.5 2.5v18.11h-17.989a2.5 2.5 0 0 0-2.5 2.5v8.655a2.5 2.5 0 0 0 2.5 2.5h17.989v17.977a2.5 2.5 0 0 0 2.5 2.5h8.796Z"
      fill="#00CFE8"
    />
    <path
      opacity={0.074}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.603 20c50.591 0 91.603 40.989 91.603 91.551 0 50.562-41.012 91.55-91.603 91.55-50.592 0-91.604-40.988-91.604-91.55S42.01 20 92.603 20Zm0 45.303c-25.557 0-46.275 20.706-46.275 46.248 0 25.541 20.718 46.247 46.275 46.247 25.556 0 46.274-20.706 46.274-46.247 0-25.542-20.718-46.248-46.275-46.248Z"
      fill="#00CFE8"
    />
    <g filter="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.596 101c0-8.837 7.164-16 16-16h470.129c8.836 0 16 7.163 16 16v204.224c0 8.837-7.164 16-16 16H77.596c-8.836 0-16-7.163-16-16V101Z"
        fill="#E7FCFF"
      />
      <path
        opacity={0.383}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.147 101c0-8.837 7.163-16 16-16h462.577c8.837 0 16.001 7.163 16.001 16v204.224c0 8.837-7.164 16-16 16H85.147c-8.837 0-16-7.163-16-16V101Z"
        fill="#00CFE8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.698 102.559c0-5.523 4.477-10 10-10h459.476c5.523 0 10 4.477 10 10v201.106c0 5.523-4.477 10-10 10H86.698c-5.523 0-10-4.477-10-10V102.559Z"
        fill="#E7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.85 299.822a6 6 0 0 1 6-6h69.171a6 6 0 0 1 6 6v13.843H275.85v-13.843Z"
        fill="#D4EFFF"
      />
      <path
        d="M283.401 299.822a6 6 0 0 1 6-6h69.171a6 6 0 0 1 6 6v21.402h-81.171v-21.402Z"
        fill="#91D6FF"
      />
    </g>
    <g filter="url(#b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.856 107.397a5.389 5.389 0 0 1 5.388-5.389h125.024a5.39 5.39 0 0 1 5.389 5.389v40.65a5.39 5.39 0 0 1-5.389 5.389H96.244a5.389 5.389 0 0 1-5.388-5.389v-40.65Z"
        fill="#fff"
      />
    </g>
    <path
      opacity={0.454}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.036 132.559c0-.843.684-1.527 1.528-1.527h25.427a1.528 1.528 0 1 1 0 3.055h-25.427a1.528 1.528 0 0 1-1.528-1.528Z"
      fill="#C7C1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.036 123.903c0-.844.684-1.528 1.528-1.528h15.255a1.527 1.527 0 0 1 0 3.056h-15.255a1.528 1.528 0 0 1-1.528-1.528ZM205.812 120.284a6.482 6.482 0 0 1 10.726.592.77.77 0 0 1-1.319.792 4.945 4.945 0 0 0-8.08-.583l-.103.131-2.385 3.137a15.18 15.18 0 0 1-15.959 5.495l-.254-.069-1.918-.542a6.61 6.61 0 0 0-8.212 4.773l-.04.172-.396 1.798a4.658 4.658 0 0 1-7.314 2.755.77.77 0 0 1 .912-1.241 3.12 3.12 0 0 0 4.861-1.687l.039-.158.396-1.798c.968-4.4 5.316-7.181 9.711-6.212l.231.055.229.061 1.919.542c5.315 1.501 11-.352 14.415-4.675l.156-.201 2.385-3.137Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.881 119.353a6.931 6.931 0 0 1 11.074-2.211c1.32 1.242 2.541 3.205 3.686 5.893a.77.77 0 0 1-1.415.604c-1.069-2.509-2.184-4.301-3.324-5.375a5.393 5.393 0 0 0-8.549 1.574l-.069.146-.495 1.102a6.253 6.253 0 0 1-9.015 2.748 3.71 3.71 0 0 0-5.544 2.188l-.034.135-.856 3.742a6.642 6.642 0 0 1-1.588 3.018 6.636 6.636 0 0 1-9.24.517l-.143-.129-3.487-3.213a.77.77 0 0 1 .977-1.187l.065.054 3.487 3.214a5.099 5.099 0 0 0 8.38-2.419l.05-.199.856-3.742a5.248 5.248 0 0 1 7.703-3.397l.187.111a4.716 4.716 0 0 0 6.708-1.881l.092-.191.494-1.102Z"
      fill="#C7C1FF"
    />
    <ellipse cx={113.489} cy={128.231} rx={10.427} ry={10.438} fill="#FFCC4D" />
    <g filter="url(#c)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.856 165.592a5 5 0 0 1 5-5h158.949a5 5 0 0 1 5 5v132.679a5 5 0 0 1-5 5H95.855a5 5 0 0 1-5-5V165.592Z"
        fill="#fff"
      />
    </g>
    <mask
      id="d"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={90}
      y={160}
      width={170}
      height={144}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.856 165.592a5 5 0 0 1 5-5h158.949a5 5 0 0 1 5 5v132.679a5 5 0 0 1-5 5H95.855a5 5 0 0 1-5-5V165.592Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#d)">
      <g filter="url(#e)">
        <path
          d="M113.508 270.2s20.085-5.775 28.941-17.561c8.856-11.786 11.842-23.211 27.073-23.211 15.232 0 17.184 17.497 33.65 17.497 16.466 0 17.366-22.313 22.839-31.173 5.472-8.86 14.917-8.86 14.917-8.86"
          stroke="#8EEAF6"
          strokeWidth={4.004}
          strokeLinecap="round"
        />
      </g>
      <path
        opacity={0.415}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.508 268.982s20.085-5.75 28.941-17.485c8.856-11.736 11.842-23.111 27.073-23.111 15.232 0 17.184 17.422 33.65 17.422 16.466 0 17.366-22.218 22.839-31.039 5.472-8.822 14.917-8.822 14.917-8.822v74.401a3.08 3.08 0 0 1-3.08 3.08h-121.26a3.08 3.08 0 0 1-3.08-3.08v-11.366Z"
        fill="#CEF9FF"
      />
      <rect
        x={104.069}
        y={174.765}
        width={52.856}
        height={5.669}
        rx={2.835}
        fill="#8EEAF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.069 191.774a2.835 2.835 0 0 1 2.835-2.835h18.871a2.834 2.834 0 0 1 0 5.669h-18.871a2.834 2.834 0 0 1-2.835-2.834Z"
        fill="#8EEAF6"
        fillOpacity={0.402}
      />
      <ellipse
        cx={227.242}
        cy={178.072}
        rx={3.303}
        ry={3.307}
        fill="#8EEAF6"
        fillOpacity={0.452}
      />
      <ellipse
        cx={236.68}
        cy={178.072}
        rx={3.303}
        ry={3.307}
        fill="#8EEAF6"
        fillOpacity={0.452}
      />
      <ellipse
        cx={246.119}
        cy={178.072}
        rx={3.303}
        ry={3.307}
        fill="#8EEAF6"
        fillOpacity={0.452}
      />
    </g>
    <g filter="url(#f)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.232 122.721a7.485 7.485 0 0 1 7.485-7.484h225.212a7.485 7.485 0 0 1 7.485 7.484v150.516a7.484 7.484 0 0 1-7.485 7.484H293.717a7.484 7.484 0 0 1-7.485-7.484V122.721Z"
        fill="#fff"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.851 200.101v59.186h-8.83v-59.186h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.851 214.245v45.042h-8.83v-45.042h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.851 242.533v16.754h-8.83v-16.754h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M348.044 185.957v73.33h-8.831v-73.33h8.831Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M348.044 207.172v52.115h-8.831v-52.115h8.831Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M348.044 242.533v16.754h-8.831v-16.754h8.831Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M369.236 196.565v62.722h-8.83v-62.722h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M369.236 217.781v41.506h-8.83v-41.506h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M369.236 246.069v13.218h-8.83v-13.218h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M390.429 178.885v80.402h-8.83v-80.402h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M390.429 200.101v59.186h-8.83v-59.186h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M390.429 228.389v30.898h-8.83v-30.898h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M411.621 164.741v94.546h-8.83v-94.546h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M411.621 193.029v66.258h-8.83v-66.258h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M411.621 235.46v23.827h-8.83V235.46h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.814 185.957v73.33h-8.831v-73.33h8.831Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.814 207.172v52.115h-8.831v-52.115h8.831Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.814 239.704v19.583h-8.831v-19.583h8.831Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M454.006 193.029v66.258h-8.83v-66.258h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M454.006 207.173v52.114h-8.83v-52.114h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M454.006 234.753v24.534h-8.83v-24.534h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M475.198 178.885v80.402h-8.83v-80.402h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M475.198 207.173v52.114h-8.83v-52.114h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M475.198 241.826v17.461h-8.83v-17.461h8.83Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M496.391 193.029v66.258h-8.83v-66.258h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.31}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M496.391 207.173v52.114h-8.83v-52.114h8.83Z"
      fill="#FFCC4D"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M496.391 241.826v17.461h-8.83v-17.461h8.83Z"
      fill="#FFCC4D"
    />
    <path
      opacity={0.576}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.195 145.647c0-1.172.95-2.122 2.122-2.122h70.637a2.122 2.122 0 0 1 0 4.243h-70.637a2.122 2.122 0 0 1-2.122-2.121Z"
      fill="#FFCC4D"
    />
    <rect
      x={315.195}
      y={131.502}
      width={39.559}
      height={4.243}
      rx={2.122}
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m559.039 449.882 11.443 90.408-5.999-.08-11.443-89.482 5.999-.846Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M481.616 464.093a9.445 9.445 0 0 1 0-18.89h37.762a11.089 11.089 0 0 0 11.04-10.05l6.038-64.193a11.087 11.087 0 0 1 11.039-10.05h23.7c6.459 0 11.55 5.498 11.055 11.938l-6.224 81.006a11.088 11.088 0 0 1-11.055 10.239h-83.355Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m472.173 454.567.156.001h83.13c5.793 0 10.61-4.46 11.055-10.236l6.22-80.718c.067-.88.031-1.742-.099-2.576a11.248 11.248 0 0 0-1.694-.128h-23.588a11.088 11.088 0 0 0-11.039 10.047l-6.033 63.959a11.09 11.09 0 0 1-11.039 10.047h-37.65a9.421 9.421 0 0 0-9.419 9.604Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M546.275 345.34c12.198-2.842 13.001-19.594 3.727-25.406 9.564-20.059-12.553-39.976-27.703-27.693-9.932-4.254-18.186 12.345-8.944 25.236-3.875 22.136 4.012 27.857 18.228 28.1l14.692-.237Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M546.123 341.339s-5.934-1.393-6.14-11.122c-.205-9.728-.061-13.048-.061-13.048l-13.07-.236s-.143 10.841-.145 14.763c-.005 10.533-7.34 9.643-7.34 9.643s1.524 8.541 13.368 9.207c9.247.521 13.388-9.207 13.388-9.207Z"
      fill="url(#g)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M542.969 312.311c5.83-.226 4.879 8.314-1.178 8.303 0 0-3.518 12.132-15.732 12.132-9.919 0-10.564-14.14-10.654-16.961-.097-3.008-.109-12.889-.109-12.889l27.9-.013-.227 9.428Z"
      fill="url(#h)"
    />
    <path
      d="M528.414 324.595s-2.909 2.465-6.279.157"
      stroke="#F77B7B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M526.494 327.45c-.906.418-1.777.448-2.555.031"
      stroke="#F49494"
      strokeLinecap="round"
    />
    <path
      d="M542.374 316.904c-.06-1.37.358-2.458 1.754-2.677"
      stroke="#F77B7B"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M543.891 311.255s-3.196 4.291-3.843 4.314c-.647.023-.874-5.58-.874-5.58-3.921-.3-13.842-2.277-16.274-6.882-1.701 4.063-4.952 5.868-7.089 5.521l-.405 8.633-.997-17.355h30.264l-.782 11.349Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M486.403 512.891s5.231 10.571.943 13.902c-4.294 3.331-15.638 9.575-15.638 9.575l30.053-1.89-6.68-23.054-8.678 1.467Z"
      fill="url(#i)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M498.854 522.951c-1.483.173.101 5.169-3.11 5.549-3.205.38-4.763-4.354-8.399-2.853-3.637 1.501-16.901 9.259-17.423 12.431-.529 3.171 4.413 4.104 11.19 3.388 6.777-.717 8.026-3.129 13.114-2.157 5.081.971 8.887-.577 8.72-4.292-.168-3.714-2.609-12.239-4.092-12.066Z"
      fill="#222D4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m485.686 526.417.66.99a1.001 1.001 0 0 1-.345 1.428l-8.965 5.006a1.002 1.002 0 0 1-.941.018l-1.391-.706c2.185-1.584 3.973-2.775 5.948-3.943a120.53 120.53 0 0 1 5.034-2.793Z"
      fill="#fff"
      fillOpacity={0.541}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M531.676 419.725c-9.099.406-59.738 11.613-68.939 13.517-9.2 1.903-16.979 12.206-9.963 24.135 7.88 13.391 28.211 51.422 33.148 59.995 2.443 4.244 14.769 1.865 11.873-5.258-5.397-13.276-7.903-46.629-21.664-58.79 0 0 46.277 8.058 66.266-1.173 14.528-6.709 11.242-33.409-10.721-32.426Z"
      fill="url(#j)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M467.209 365.893s.103-7.407-2.487-11.278c-2.59-3.871-2.692-7.983-4.652-7.849-1.96.135-.019 9.692-.019 9.692s-2.487-.95-4.274-2.152c-1.786-1.203-7.203-7.13-8.469-6.08-2.146 1.79 1.311 11.885 3.194 13.903 1.889 2.019 12.209 8.658 12.209 8.658l4.498-4.894Z"
      fill="url(#k)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M523.122 361.454c-4.232 9.822-11.982 27.865-15.177 33.249-4.003 6.741-10.317 9.711-17.709 4.709-7.767-5.256-29.154-25.755-29.782-30.398-.621-4.644 4.614-10.215 9.35-7.331 4.737 2.885 24.418 19.273 24.418 19.273s7.943-20.373 14.333-32.174c5.534-10.211 21.01-2.26 14.567 12.672Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M494.14 380.966s-2.133 7.24-2.632 6.821c-.499-.419.381-8.713.381-8.713l2.251 1.892Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M565.42 349.619s-10.199 34.171-10.501 40.639c-.302 6.469 8.342 25.481 6.87 30.287-3.393 11.104-46.717 15.886-48.372.947-.772-6.966.835-13.219-1.016-21.428-1.845-8.209-9.259-20.408-5.054-31.877 4.206-11.468 3.079-22.079 6.087-24.084 3.007-2.006 10.038-4.136 10.038-4.136 3.419 5.035 14.962 4.961 20.47-.355 0 0 13.683 2.656 21.478 10.007Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M556.281 355.154c-6.799 10.795-6.779 29.59-10.43 32.052-3.651 2.462-17.863.046-17.863 1.995 0 1.95 19.639 19.815 16.415 28.355-1.688 4.472-1.23 8.473 1.374 12.002l.85-.185c7.738-1.75 13.959-4.892 15.162-8.828 1.472-4.806-8.169-23.818-7.867-30.287.256-5.49 8.363-30.943 10.807-38.511-1.99-3.513-4.806-2.378-8.448 3.407Z"
      fill="#000"
      fillOpacity={0.199}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M544.687 383.794s-8.591-7.607-11.221-8.49c-2.63-.883-18.032-1.944-17.935.051.111 2.138 8.383 2.015 9.24 3.066.858 1.051 1.052 8.238 4.293 9.044 3.233.807 12.928 2.198 12.928 2.198l2.695-5.869Z"
      fill="url(#l)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M567.542 351.583c9.051 12.781 16.779 32.25 19.745 39.84 2.966 7.59-.026 19.039-13.069 16.791-13.02-2.241-33.735-14.422-36.027-17.975-2.292-3.553 1.19-10.018 5.575-9.172 4.062.783 24.213 9.356 24.213 9.356s-12.964-22.991-16.129-29.154c-5.288-10.293 7.72-20.947 15.692-9.686Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M567.773 390.265s6.79 3.375 5.962 1.96c-.828-1.416-8.553-6.389-8.553-6.389l2.591 4.429Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.061 509.194s-.136 8.711 5.21 11.806c5.346 3.094 13.629 8.844 13.629 8.844l-31.508 1.068.898-21.796 11.771.078Z"
      fill="#F8CFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m221.292 518.794-.513 14.132h7.319l.501-1.821s2.756-.113 4.348.673c1.683.832 1.909 1.236 1.909 1.236l23.214.049s1.145-4.568-5.931-7.872c-7.076-3.304-12.229-7.994-13.207-8.474-.986-.484-2.975-.757-4.376.379-1.4 1.136-2.508 4.122-5.682 4.216-4.085.121-6.432-4.908-7.582-2.518Z"
      fill="#4B4B4B"
    />
    <path
      d="m241.131 518.25-2.407 2.327M244.64 520.014l-2.407 2.328M248.149 521.779l-2.407 2.328"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M212 321s8.753 96.38 9.026 103.079c.272 6.7-2.812 18.263-2.752 30.795.06 12.532 1.126 56.701 1.126 56.701l19.31.409s8.115-79.817 8.203-91.786c.087-11.969.831-98.046.831-98.046L212 321Z"
      fill="#383838"
    />
    <path
      opacity={0.284}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m225.919 427.08.021.238c.291 4.086-2.247 17.528-7.613 40.327l-.053-12.771c-.059-12.308 2.915-23.681 2.763-30.431l-.011-.363c-.092-2.258-1.147-14.701-2.505-30.207l.634 2.271c3.723 13.452 5.977 23.764 6.764 30.936Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m188.477 507.967-3.724 22.558 13.728-.068.434-22.844-10.438.354Z"
      fill="#F8CFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187 517.724a3 3 0 0 0-2.363 2.833l-.111 3.353c-1.942 2.154-2.859 8.377-2.956 9.067a.55.55 0 0 0-.005.128c.285 3.05 20.255 3.011 22.129.144.085-.13.091-.286.066-.44-.21-1.27-1.326-7.423-3.471-9.102l-.013-3.322a3 3 0 0 0-2.222-2.887l-2.063-.553c-2.007-2.927-4.742-2.824-7.085.366l-1.906.413Z"
      fill="#4B4B4B"
    />
    <path
      d="M189.685 519.749h5.557M189.685 522.393h5.557M190.569 525.038h3.79"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M195.4 322.265s-6.133 66.066-6.05 78.718c.083 12.652 1.888 18.208-.68 27.284-2.569 9.076-4.759 81.519-4.759 81.519l18.991 2.079s12.133-77.518 13.452-88.266c1.319-10.747 17.625-102.245 17.625-102.245l-38.579.911Z"
      fill="#2E2E2E"
    />
    <path
      opacity={0.284}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m200.53 367.105-1.159 4.194c-3.392 12.375-6.522 25.462-5.703 44.157l.04.853c.202 4.05-2.374 27.552-7.728 70.508l-.51 3.019a470.368 470.368 0 0 1-1.402 7.882l.116-3.805c.683-20.998 2.519-58.697 4.486-65.645 2.568-9.076.763-14.633.68-27.285-.059-8.856 2.93-43.885 4.728-64.106l1.322-14.612 8.017-.189.019 2.251c.141 20.772-.827 35.032-2.906 42.778Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M288.834 240.566s-1.585-8.738-1.015-10.508c.487-1.529 5.089-3.734 5.089-3.734s8.004-12.506 9.756-11.233c1.425 1.032-4.168 10.252-4.168 10.252s5.1 5.293 5.029 7.659c-.071 2.366-8.325 9.772-8.325 9.772l-6.366-2.208Z"
      fill="#F8CFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M293.08 226.198s3.545-.477 4.109.614c.481.93-2.464 3.567-2.464 4.308 0 .741.042 6.352.279 6.403.237.051 3.551-9.156 2.844-11.103-.582-1.599-4.768-.222-4.768-.222Z"
      fill="#F28585"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M246.546 251.684c-9.286-8.022-23.229 6.44-7.681 19.886 5.931 5.129 24.563 17.924 29.13 20.737 7.271 4.479 13.246.437 15.702-6.609 2.083-5.975 14.541-37.955 12.956-42.652-1.034-3.064-6.582-6.044-9.368-3.634-4.07 3.524-16.519 34.428-16.519 34.428s-13.663-13.035-24.22-22.156Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M270.766 273.839s4.077 4.947 4.372 4.191c.294-.755-3.234-7.112-3.234-7.112l-1.138 2.921Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      d="M286.204 241.393c3.24-1.5 6.779.354 8.729 3.33"
      stroke="#5386BF"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M206.741 242.432s5.338-1.229 5.524-9.804c.185-8.575.055-11.502.055-11.502l12.656-.208s.127 9.557.13 13.014c.004 7.525 5.705 9.558 5.705 9.558s-.473 8.229-11.128 8.816c-8.319.459-12.942-9.874-12.942-9.874Z"
      fill="#F8C5C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m236.251 211.882-.283 8.104c2.392-2.756 4.007-6.172 4.486-10.553l-4.203 2.449Z"
      fill="url(#m)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.158 216.791c-5.246-.199-4.39 7.33 1.059 7.319 0 0 5.856 11.544 15.05 11.574 4.429.014 5.875-3.853 7.203-6.568 1.327-2.715 2.302-6.776 2.383-9.262.087-2.651.098-11.361.098-11.361l-25.997-.012.204 8.31Z"
      fill="#F8CFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M236.951 212.489h-18.283c-.192 3.239-1.29 5.929-4.076 7.913.079 4.744 1.698 8.199 4.506 10.621 2.444-6.868 17.397-4.989 16.309-.508-2.285 9.414-23.7 9.627-22.401-12.039-.854-1.142-3.909-1.355-4.915-.213-1.718-4.734-.985-11.43 3.983-12.399-3.9-10.087 49.122-12.221 24.877 6.625Z"
      fill="#424242"
    />
    <path
      d="M225.236 230.099s2.382 1.716 5.518-.16"
      stroke="#CC6969"
      strokeLinecap="round"
    />
    <path
      d="M211.976 221.089c.054-1.208-.322-2.167-1.578-2.36"
      stroke="#DE6767"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m209.089 241 15.102 9.796 6.81-7.909s14.347 4.44 17.008 9.631c3.841 7.49-1.219 67.504.987 71.125 4.7 7.717-1.549 13.015-22.239 14.961-20.69 1.946-39.046.353-35.715-9.946 3.332-10.299-9.523-77.511 1.81-81.213 3.541-1.157 16.237-6.445 16.237-6.445Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.897 259.529c.914 1.615.443 7.208-1.415 16.78a30.567 30.567 0 0 0 5.005 23.396l.268.376c1.616 2.23 3.105 5.166 4.468 8.808 3.344 8.931-4.368 16.377 1.542 30.35l-.715-.034c-11.596-.599-19.26-3.582-17.008-10.547 2.732-8.444-5.418-55.148-2.196-73.737 5.58 1.094 8.93 2.63 10.051 4.608Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m209.089 241 13.509 8.795c-1.98 2.298-3.042 4.971-3.443 7.914-5.192-4.053-10.589-9.717-12.261-15.807l2.195-.902ZM225.259 249.566c2.284 1.542 4.106 4.273 5.59 7.378 1.778-3.568 2.459-8.28 2.459-13.305l-2.231-.88-5.818 6.807Z"
      fill="#5E54C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m222.598 249.794.176.099c.81.451 1.82.318 2.485-.327 5.093 18.64 8.675 39.158 9.758 60.01l-5.315 8.813-6.217-8.248c.81-21.672.804-42.802-.887-60.347Z"
      fill="#CBC6FF"
    />
    <path
      d="M236.082 296.991a2.465 2.465 0 0 1 2.807-2.181l12.306 1.799a2.464 2.464 0 0 1 2.094 2.696l-2.208 21.013a2.465 2.465 0 0 1-2.807 2.181l-12.306-1.799a2.464 2.464 0 0 1-2.094-2.696l2.208-21.013Z"
      fill="#6182EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M237.548 296.567a2.464 2.464 0 0 1 2.924-2.16l12.868 2.524 8.804-.148a2.465 2.465 0 0 1 2.492 2.722l-2.205 20.981a2.463 2.463 0 0 1-2.409 2.206l-9.407.158-13.298-2.609a2.463 2.463 0 0 1-1.976-2.675l2.207-20.999Z"
      fill="#96EFFA"
    />
    <path
      d="M239.873 301.488a1.6 1.6 0 0 1 1.879-1.407l5.905 1.081a1.6 1.6 0 0 1 1.304 1.741l-.252 2.394a1.6 1.6 0 0 1-1.879 1.407l-5.906-1.081a1.6 1.6 0 0 1-1.303-1.741l.252-2.394Z"
      fill="#74CFDB"
    />
    <path
      d="M252.357 297.632a.799.799 0 0 1 .879-.712l.517.054a.8.8 0 0 1 .712.879l-2.548 24.244a.8.8 0 0 1-.879.712l-.517-.054a.8.8 0 0 1-.712-.88l2.548-24.243Z"
      fill="#EAEFFF"
    />
    <path
      d="M253.059 297.705a.801.801 0 0 1 .88-.712l.517.055a.8.8 0 0 1 .712.879l-2.548 24.244a.801.801 0 0 1-.88.712l-.517-.055a.8.8 0 0 1-.712-.879l2.548-24.244Z"
      fill="#7AD2DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.89 306.414s4.586-.916 6.463-2.38c1.877-1.464 7.562-2.499 7.562-2.499l-1.819 3.805s13.932-1.299 14.262.066c.331 1.366-3.699 2.85-3.699 2.85s-1.987 3.688-3.911 5.826c-1.82 2.023-10.864 1.783-14.034.52-2.739-1.09-5.718-.545-5.718-.545l.894-7.643Z"
      fill="#F8CFCF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.001 253.239c4.982-10.697 22.041-3.49 17.742 7.97-4.164 11.101-14.254 37.021-14.254 37.021s31.972 4.033 34.465 5.914c3.325 2.509 1.27 9.669-1.557 10.944-2.391 1.078-20.153 1.708-37.531.347-8.598-.673-14.956-4.343-15.843-13.876-.621-6.676 12.179-38.015 16.978-48.32Z"
      fill="#FFCC4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.501 298.224s-2.657 5.019-1.898 5.121c.76.102 4.621-4.754 4.621-4.754l-2.723-.367Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      d="M221.703 303.599c.986 3.128.293 6.797-1.074 9.389"
      stroke="#5386BF"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.363 443.346c8.266-3.712 17.974.015 21.633 8.304l2.863 6.485c-8.266 3.712-17.974-.015-21.633-8.304l-2.863-6.485Z"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.174 447.302c8.25 5.87 13.599 12.412 16.064 19.624 2.477 7.247 3.492 19.395 3.031 36.421a.474.474 0 1 0 .947.026c.464-17.132-.559-29.376-3.083-36.759-2.535-7.417-8.011-14.114-16.413-20.093a.471.471 0 0 0-.66.115.48.48 0 0 0 .114.666Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.489 439.32c-8.135-3.826-17.829-.298-21.602 7.862l-3.214 6.951c8.135 3.826 17.829.298 21.602-7.862l3.214-6.951Z"
      fill="#02D3AF"
    />
    <path
      d="M91.498 423.385c7.843-1.667 15.554 3.34 17.221 11.184l1.35 6.35c-7.844 1.667-15.554-3.34-17.222-11.184l-1.35-6.35Z"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.266 428.452c6.046 4.22 10.135 8.771 12.28 13.647l.132.307.133.323c2.198 5.456 5.836 19.746 10.896 42.816a.475.475 0 0 0 .565.365.476.476 0 0 0 .36-.568l-.627-2.846-.732-3.294c-4.571-20.441-7.853-32.921-9.861-37.487-2.223-5.053-6.429-9.734-12.608-14.048a.471.471 0 0 0-.659.12.481.481 0 0 0 .121.665Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.624 418.56c-8.645-.152-15.776 6.735-15.926 15.38l-.133 7.713c8.645.152 15.776-6.734 15.925-15.379l.134-7.714Z"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.95 421.662c-5.561 8.16-9.624 16.037-12.188 23.631-2.559 7.581-5.112 19.288-7.663 35.131a.478.478 0 0 0 .392.548.474.474 0 0 0 .543-.395l.314-1.926.215-1.298c2.366-14.167 4.733-24.753 7.095-31.752 2.535-7.506 6.558-15.306 12.073-23.398a.48.48 0 0 0-.122-.664.47.47 0 0 0-.659.123Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.905 416.317s-4.51 12.847-6.504 31.772c-1.993 18.925-.83 37.85-.83 37.85l2.201.2s-1.182-22.088.237-37.966c1.42-15.878 5.996-31.756 5.996-31.756l-1.1-.1Z"
      fill="#02B596"
    />
    <ellipse
      cx={129.201}
      cy={409.952}
      rx={7.264}
      ry={11.137}
      transform="rotate(14 129.201 409.952)"
      fill="#C2FFF4"
    />
    <ellipse
      cx={129.347}
      cy={410.625}
      rx={5.053}
      ry={7.261}
      transform="rotate(14 129.347 410.625)"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m128.725 417.005 4.058-12.664a1.435 1.435 0 0 0-.915-1.803 1.417 1.417 0 0 0-1.788.922l-4.058 12.664a1.435 1.435 0 0 0 .915 1.804 1.418 1.418 0 0 0 1.788-.923Z"
      fill="url(#n)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.951 403.586s5.828 12.306 9.789 30.919c3.96 18.612 4.782 37.555 4.782 37.555l-2.168.429s-1.133-22.09-4.205-37.733c-3.071-15.643-9.282-30.955-9.282-30.955l1.084-.215Z"
      fill="#02B596"
    />
    <ellipse
      rx={7.264}
      ry={11.137}
      transform="scale(-1 1) rotate(20 -1178.157 -90.493)"
      fill="#C2FFF4"
    />
    <ellipse
      rx={5.053}
      ry={7.261}
      transform="scale(-1 1) rotate(20 -1180.062 -89.938)"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m103.212 404.462-5.36-12.171a1.435 1.435 0 0 1 .722-1.889 1.418 1.418 0 0 1 1.875.73l5.359 12.171a1.434 1.434 0 0 1-.721 1.889 1.418 1.418 0 0 1-1.875-.73Z"
      fill="url(#o)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.019 441.348c-11.227 4.394-19.056 9.471-23.482 15.251-4.427 5.779-7.351 14.743-8.794 26.891a.473.473 0 1 0 .94.114c1.424-11.993 4.298-20.8 8.603-26.421 4.304-5.62 11.995-10.608 23.076-14.944a.478.478 0 0 0-.343-.891Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.929 476.595c-5.331-18.997-26.383-13.438-26.383-13.438s15.762 5.151 23.8 13.438h2.583ZM121.875 473.677c5.33-18.998 23.099-16.22 23.099-16.22s-10.792 8.871-20.516 16.22h-2.583Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.836 477.452c-4.766-16.853 6.351-31.36 6.351-31.36s-.564 19.96-4.427 30.13l-1.924 1.23Z"
      fill="#02B596"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.173 473.677c-2.107-6.09-4.203-14.595-5.774-22.84 7.763 3.122 10.257 15.56 7.763 22.84h-1.989Z"
      fill="#02D3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.022 469.762a2.016 2.016 0 0 1 2.008-2.191h30.652a2.016 2.016 0 0 1 2.009 2.191l-5.907 67.593a2.017 2.017 0 0 1-2.009 1.841h-18.838c-1.045 0-1.917-.8-2.008-1.841l-5.907-67.593Z"
      fill="#FFCC4D"
    />
    <mask
      id="p"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={102}
      y={467}
      width={35}
      height={73}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.022 469.762a2.016 2.016 0 0 1 2.008-2.191h30.652a2.016 2.016 0 0 1 2.009 2.191l-5.907 67.593a2.017 2.017 0 0 1-2.009 1.841h-18.838c-1.045 0-1.917-.8-2.008-1.841l-5.907-67.593Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#p)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.41 463.099h12.944c-8.022 30.453 16.125 51.389 0 81.842H97.409v-81.842Z"
        fill="#FFCC4D"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m679.746 449.882-11.443 90.408 6-.08 11.443-89.482-6-.846ZM646.447 449.882l-11.443 90.408 5.999-.08 11.443-89.482-5.999-.846Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m719.494 449.882 11.443 90.408-5.999-.08-11.443-89.482 5.999-.846Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M642.071 464.093a9.445 9.445 0 1 1 0-18.89h37.762a11.087 11.087 0 0 0 11.039-10.05l6.038-64.193a11.088 11.088 0 0 1 11.039-10.05h23.701c6.458 0 11.55 5.498 11.055 11.938l-6.224 81.006a11.089 11.089 0 0 1-11.056 10.239h-83.354Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m632.627 454.567.156.001h83.13c5.794 0 10.611-4.46 11.056-10.236l6.219-80.718c.068-.88.032-1.742-.099-2.576a11.235 11.235 0 0 0-1.693-.128h-23.589a11.088 11.088 0 0 0-11.039 10.047l-6.033 63.959a11.088 11.088 0 0 1-11.039 10.047h-37.65a9.421 9.421 0 0 0-9.421 9.421l.002.183Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M644.109 511.415s5.246 10.595.945 13.934c-4.307 3.339-15.683 9.597-15.683 9.597l30.14-1.894-6.7-23.108-8.702 1.471Z"
      fill="url(#q)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M656.525 521.002c-.949.453.611 4.71-2.726 4.411-3.336-.298-5.431-4.699-7.891-4.413-2.461.285-4.023 4.716-8.708 7.803-4.683 3.087-11.628 6.769-11.163 9.872.465 3.104 11.394 3.935 16.931 2.907 5.537-1.026 8.121-3.252 11.713-3.075 3.592.178 5.85-.262 6.976-2.569 1.125-2.306-4.038-15.458-5.132-14.936Z"
      fill="#EEDFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m656.259 535.167-.307-.018c-4.452-.375-7.506 2.424-11.743 3.214-4.238.79-15.414 1.063-17.199-1.588a5.434 5.434 0 0 1-.236-.385c-.599.738-.882 1.526-.737 2.445.466 2.954 11.395 3.745 16.933 2.767 5.537-.977 8.121-3.095 11.714-2.926l.638.024c3.224.087 5.28-.407 6.338-2.469.258-.502.2-1.583-.059-2.948-1.502 1.028-3.399 1.943-5.342 1.884Z"
      fill="#EC7AA5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M689.511 418.031c-9.124.408-59.91 11.64-69.136 13.549-9.227 1.908-17.028 12.234-9.992 24.191 7.902 13.423 28.292 51.542 33.243 60.135 2.45 4.254 14.811 1.869 11.908-5.27-5.413-13.308-7.927-46.738-21.727-58.927 0 0 46.41 8.077 66.457-1.175 14.569-6.726 11.273-33.488-10.753-32.503Z"
      fill="#413C75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M625.859 361.074s.104-5.424-2.494-9.304c-2.597-3.88-2.7-8.002-4.665-7.867-1.966.135-.02 9.715-.02 9.715s-2.493-.953-4.285-2.158c-1.791-1.205-7.224-7.146-8.494-6.094-2.152 1.794 1.315 11.913 3.203 13.936 1.895 2.024 11.244 7.678 11.244 7.678l27.535 27.739L652 379.993l-26.141-18.919Z"
      fill="url(#r)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M680.933 358.625c-4.244 9.845-12.016 27.93-15.22 33.326-4.015 6.757-10.347 9.734-17.76 4.721-1.551-1.049-6.006-4.718-6.006-4.718.659-6.422 3.059-11.79 7.195-16.106l2.808 2.324s7.966-20.42 14.374-32.249c5.55-10.234 21.07-2.264 14.609 12.702Z"
      fill="#EA5455"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M651.868 378.183s-2.139 7.256-2.639 6.836c-.501-.42.382-8.733.382-8.733l2.257 1.897Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M699.278 320.411v8.711l6.397-5.15s1.966-8.346 3.565-14.545c1.003-3.888 3.446-17.044-6.39-16.427-8.057-14.204-31.523-5.413-31.71 11.94-.225 20.871-.041 28.997-.041 28.997 13.269.761 20.738-1.157 28.179-13.526Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M705.813 336.997s-5.951-1.397-6.157-11.148c-.207-9.75-.062-13.079-.062-13.079l-13.108-.236s-.143 10.867-.145 14.797c-.005 9.558-7.034 10.341-7.034 10.341s1.202 7.885 13.079 8.553c9.274.523 13.427-9.228 13.427-9.228Z"
      fill="url(#s)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M702.65 307.901c5.847-.226 4.894 8.334-1.181 8.322 0 0-3.528 12.161-15.777 12.161-9.948 0-10.595-14.173-10.685-17.001-.097-3.014-.11-12.918-.11-12.918l27.981-.013-.228 9.449Z"
      fill="url(#t)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M703.103 307.845c-2.424.4-2.13 2.004-2.789 1.864-3.346-.712-16.89.227-18.634-7.221-2.17 4.653-2.7 5.797-7.478 7.046v-13.635L704.423 293s-.469 14.704-1.32 14.845Z"
      fill="#422851"
    />
    <path
      d="M688.054 320.214s-2.918 2.471-6.297.158"
      stroke="#F77B7B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M686.128 323.075c-.908.419-1.782.45-2.562.032"
      stroke="#F49494"
      strokeLinecap="round"
    />
    <path
      d="M702.054 312.505c-.06-1.374.359-2.464 1.759-2.683"
      stroke="#F77B7B"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M704.484 336c-7.468 4.574-16.102 6.251-24.139 1.284-13.115 25.861-16.384 56.718-13.229 90.822 19.125 7.572 35.199 6.083 48.884-2.572L704.484 336Z"
      fill="#FFE4F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M611.415 511.84s.159 12.471-10.117 17.133c-10.27 4.662-17.586 4.382-16.655 7.986.931 3.604 27.661 3.459 30.158.64 2.127-2.398 6.754-24.727 8.028-23.02l-11.414-2.739Z"
      fill="url(#u)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M618.879 529.452c-1.575-1.134-2.899 5.401-7.176 3.584-4.278-1.817-.905-7.823-3.597-8.931-2.692-1.108-7.89 4.848-14.794 6.296-6.904 1.446-12.509 2.713-14.102 5.4-1.372 2.31 0 3.048 0 3.048s6.646 3.319 10.776 4.05c6 1.061 23.339 1.768 25.239-.167 1.167-1.187 4.442-12.713 3.654-13.28Z"
      fill="#EEDFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M578.835 536.073c-1.301 2.122.753 3.226.999 3.349l.99.422c1.881.781 6.122 2.458 9.257 3.057 5.748 1.099 23.548 1.75 25.462-.14.337-.331.752-1.544 1.155-3.12l-1.222.133c-5.878.622-18.998 1.749-25.474.224-5.747-1.354-8.894-3.582-10.328-4.882a4.078 4.078 0 0 0-.839.957Z"
      fill="#EC7AA5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M718.102 414.176s13.266 28.016-5.377 37.636c-18.029 9.303-72.001-1.7-72.001-1.7s.965 7.764 0 14.69c-2.518 18.087-14.498 48.221-17.032 52.007-3.505 5.236-14.182 1.287-14.383-3.072-.202-4.36 8.048-60.044 9.303-70.663 1.248-10.619 5.188-12.161 16.697-15.064 2.644-.667 33.911-8.437 36.775-8.211 24.611 1.946 46.018-5.623 46.018-5.623Z"
      fill="#524D87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M703.56 448.92c-17.849 2-65.104-2.122-67.002-.249-1.812 1.789-1.509 32.904-5.574 50.914l.618-1.701c3.823-10.617 7.792-23.53 9.122-33.082.965-6.926 0-14.69 0-14.69l2.177.421c10.259 1.927 53.959 9.466 69.824 1.279 8.597-4.436 12.105-11.806 10.524-22.109-2.722 11.646-9.285 18.052-19.689 19.217Z"
      fill="#fff"
      fillOpacity={0.056}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M704.157 336.021c-12.368 40.516-19.786 97.958 18.843 87.965-20.52-37.781 7.375-54.785 1.053-74.31-2.162-6.676-19.734-14.179-19.896-13.655Z"
      fill="#EA5455"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M718.108 352.845c-13.979 2.11-2.456 26.517-7.07 30.194-4.614 3.677-12.646 4.074-13.512 6.32-1.741 4.515 6.673 15.912 25.241 34.191l.233.436c-17.874-32.911.988-50.056 1.969-66.835-2.071-3.249-4.358-4.684-6.861-4.306Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M681 337c-4.72 11.838-6.989 21.127-9.106 38.503-1.765 14.472-.881 44.337-.881 44.337l-5.833 1.147s-4.17-46.139-2.776-52.08c2.34-9.969 3.925-15.794 7.014-24.907 1.702-5.002 11.582-7 11.582-7Z"
      fill="#EA5455"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M701.56 382.017s-7.616-7.625-10.253-8.51c-2.637-.885-17.084-1.948-16.987.052.111 2.142 9.408 2.019 10.267 3.073.86 1.053 2.055 9.256 5.305 10.065 3.243.809 9.965 3.203 9.965 3.203l29.925 15.461-3.188-15.46-25.034-7.884Z"
      fill="url(#v)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M724.481 349.731c9.077 12.811 16.826 32.325 19.801 39.933 2.975 7.608-.026 19.083-13.106 16.83-1.558-.268-4.091-1.254-4.091-1.254-4.81-6.593-5.654-12.253-3.859-17.293l1.692.714s-13.001-23.044-16.175-29.222c-5.303-10.317 7.742-20.995 15.738-9.708Z"
      fill="#EA5455"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M724.918 388.661s6.604 3.225 5.773 1.807c-.83-1.419-8.288-6.286-8.288-6.286l2.515 4.479Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M586.967 346.045a2.464 2.464 0 0 1 1.795-2.987l23.469-5.852a2.464 2.464 0 0 1 2.987 1.795l8.522 34.18a2.464 2.464 0 0 1-1.794 2.987l-23.47 5.851a2.463 2.463 0 0 1-2.987-1.794l-8.522-34.18Z"
      fill="url(#w)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M587.883 346.595a2.465 2.465 0 0 1 1.794-2.987l23.47-5.851a2.464 2.464 0 0 1 2.987 1.795l8.522 34.18a2.465 2.465 0 0 1-1.795 2.987l-23.469 5.851a2.464 2.464 0 0 1-2.987-1.795l-8.522-34.18Z"
      fill="#518DF8"
    />
    <path
      d="M588.884 346.896a2.217 2.217 0 0 1 1.615-2.688l22.072-5.504a2.218 2.218 0 0 1 2.688 1.616l7.705 30.903a2.218 2.218 0 0 1-1.615 2.688l-22.072 5.503a2.217 2.217 0 0 1-2.688-1.615l-7.705-30.903Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384.204 395.872h11.417v144.879h-11.417V395.872Z"
      fill="#E9E9E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.375 400.634h90.188v26.908h-75.16v113.209h-15.028V400.634Z"
      fill="#D3D3D3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M271.792 400.634h8v140.117h-8V400.634Z"
      fill="#DBDBDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M664.43 400.634h16v140.117h-16V400.634Z"
      fill="#E9E9E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M664.43 395.872h10v144.879h-10V395.872Z"
      fill="#DBDBDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M532.006 400.634h139v26.556H550.714v113.561h-18.708V400.634Z"
      fill="#D3D3D3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M283.59 395.872h248.889v40.23H283.59v-40.23Z"
      fill="#DBDBDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M526.771 400.634h11.417v35.467h-11.417v-35.467Z"
      fill="#E9E9E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M532.042 400.634h9v140.117h-9V400.634Z"
      fill="#DBDBDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M533.476 403.015a7.143 7.143 0 0 1 7.144-7.143h145.125a7.144 7.144 0 1 1 0 14.286H540.62a7.143 7.143 0 0 1-7.144-7.143ZM280.735 398.729h248.89v14.287h-248.89v-14.287Z"
      fill="#E9E9E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.805 403.015a7.143 7.143 0 0 1 7.143-7.143h276.464a7.143 7.143 0 1 1 0 14.286H266.948a7.143 7.143 0 0 1-7.143-7.143Z"
      fill="#DBDBDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m304.388 505.181-7.898 25.643 16.567.601s-1.871-8.772.8-16.995c2.143-6.601 3.891-9.895 3.891-9.895l-13.36.646Z"
      fill="#92691F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M312.623 524.378s6.324 5.86 12.404 8.032c6.081 2.171 8.409 2.195 8.843 3.011l.078.156c1.251 2.582.493 6.012-13.368 6.202-3.37.046-6.872-1.911-16.171-3.981l-.671 3.619c-5.204-.068-8.241-.889-9.11-2.461-1.309-2.351.917-13.945 2.452-14.165 1.536-.219 1.839 4.496 5.962 4.599 4.129.104 9.581-5.012 9.581-5.012Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M324.882 532.357a5.331 5.331 0 0 1-.681.498c-.924.567-2.317 1.018-4.189.59-.952-.217-2.012-.826-3.054-1.592-1.047-.77-2.096-1.716-3.029-2.631a50.51 50.51 0 0 1-3.099-3.345c.137-.106.269-.209.394-.308l.058.069a49.931 49.931 0 0 0 2.997 3.227c.924.906 1.955 1.834 2.975 2.585 1.026.754 2.019 1.313 2.869 1.507 1.73.396 2.99-.022 3.817-.529.148-.091.283-.184.404-.277.179.071.358.14.538.206Z"
      fill="#81411E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.631 424.913s65.793 5.261 74.858 5.542c9.065.28 15.991 13.219 8.971 25.162-7.884 13.407-29.305 52.004-33.493 60.929-3.542 7.544-19.64 4.453-16.743-7.073 3.458-13.793 9.904-44.74 23.673-56.915 0 0-33.487 12.141-52.349.366-10.761-6.717-4.917-28.011-4.917-28.011Z"
      fill="#7A3636"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m350.46 455.617-1.025 1.759c-3.991 6.896-10.433 18.547-16.585 29.894l.324-1.039c4.757-15.139 12.149-32.973 15.308-38.414 1.108-1.907 2.616-3.851 4.525-5.832.809 4.155.194 8.969-2.547 13.632Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375.987 505.479s4.398 19.559 10.123 21.652c2.204.806-16.892 3.107-16.892 3.107l-4.794-18.764 11.563-5.995Z"
      fill="#92691F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m366.46 523.631-.319 16.511 6.582.05.067-3.712s5.13 3.076 8.604 4.158c4.672 1.459 23.131.695 25.216-1.528 2.086-2.233 2.131-5.786-2.151-6.223-4.281-.436-11.361-1.736-14.409-4.069-3.687-2.811-5.09-7.271-6.161-7.275-1.639-.006-1.766 5.241-10.085 5.315-3.167.028-7.344-3.227-7.344-3.227Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M382.186 523.015s4.245 6.624 6.769 7.583c2.523.95 4.557-.116 4.557-.116s-4.351-1.734-6.032-4.76c-3.808-6.855-4.569-3.367-5.294-2.707Z"
      fill="#81411E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.075 430.227s-.092 21.021 14.031 26.305c14.124 5.283 60.114 3.295 60.114 3.295s14.594 53.698 18.091 58.928c3.497 5.23 20.079 5.772 19.182-6.274-.903-12.046-7.374-56.768-8.626-67.374-1.245-10.607-5.177-13.147-16.659-15.047-4.036-.668-2.323-1.738-28.267-2.953-5.572-.261-14.921 1.825-23.512 2.77-8.592.945-34.354.35-34.354.35Z"
      fill="#7A3636"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M317.977 430.681v-2.441l1.15-.172c4.182-.621 7.985-1.094 10.814-.961l2.982.145c22.834 1.155 21.41 2.166 25.284 2.808l.747.126c10.662 1.847 14.521 4.428 15.827 14.233l.365 2.951c1.729 13.592 7.498 53.788 8.347 65.111a11.1 11.1 0 0 1-.081 2.508l-3.362-16.927c-4.953-25.096-7.729-39.966-8.328-44.61l-.125-.965c-1.118-8.243-4.047-18.471-13.495-17.804l-.817.068c-6.652.507-19.268-.769-37.848-3.828l-1.46-.242Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <ellipse
      cx={385.32}
      cy={446.912}
      rx={3.776}
      ry={6.614}
      transform="rotate(13 385.32 446.912)"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282.263 402.315s15.391 3.563 15.235 4.962c-.153 1.406-6.929 1.622-6.929 1.622s12.526 8.088 11.529 9.667c-.997 1.578-9.164 3.355-12.553 2.082-3.391-1.272-12.178-10.535-12.178-10.535l4.896-7.798Z"
      fill="url(#x)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282.402 356.716c-5.155 6.363-22.733 25.797-22.733 25.797s17.578 12.52 22.004 15.168c7.259 4.341-.122 15.856-5.445 14.577-7.214-1.738-25.422-14.299-33.977-19.382-8.556-5.084-5.282-13.291 1.048-20.781 4.862-5.752 15.433-17.538 22.87-27.884 7.4-10.294 26.237.159 16.233 12.505Z"
      fill="#467FFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.67 382.513s-4.949 4.768-4.435 5.137c.515.369 6.478-3.671 6.478-3.671l-2.043-1.466Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M313.094 310.007c4.323.474 4.017 8.082-1.246 8.479-1.075 7.335-4.911 10.829-9.587 11.234l.259 11.093-20.686-.012s5.825-7.837 5.535-18.566c-.29-10.729.195-20.808.195-20.808l26.792.481-1.262 8.099Z"
      fill="url(#y)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304.632 289.313c15.937 4.023 9.362 21.015 9.362 21.015-4.815-.657-9.449 5.186-11.206 10.916-5.081 5.223-11.662 5.214-16.038 2.172 0 0-6.021-18.623-4.052-24.824 1.97-6.201 7.408-12.945 21.934-9.279Z"
      fill="#2C272E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.958 343.287c-1.431 2.436-2.456 12.248 0 51.121 1.271 20.129-4.138 39.968-2.74 48.701 1.398 8.734 11.006 9.777 11.006 9.777l5.603-18.229 2.636 18.229s39.733-3.716 43.215-12.761c3.49-9.065-.892-30.375-1.934-50.442-1.047-20.146-.262-40.825-2.187-43.96-3.843-6.255-13.735-9.178-19.875-10.113-7.791-1.188-17.848-1.233-23.827.663-5.98 1.895-10.467 4.578-11.897 7.014Z"
      fill="#467FFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M307.387 357.13c3.136 23.135 7.329 39.368 12.579 48.699 2.171 3.859 4.376 5.45 6.616 4.773l-.532-5.127c-.53-5.188-1.033-10.533-1.306-15.792a438.97 438.97 0 0 1-.417-10.946l-16.94-21.607Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M278.854 336.273c-.362.115-.713.234-1.052.358 8.585 3.743 22.76 3.243 30.466.193a46.968 46.968 0 0 0-5.586-1.214c-7.791-1.188-17.848-1.233-23.828.663Z"
      fill="#E5AA41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384.154 377.168s12.785-8.535 15.044-10.249c2.252-1.707 5.079-14.651 3.327-16.467-.846-.878-7.242 5.034-8.383 5.826-1.134.784-6.933 4.278-6.933 4.278s1.641-9.907.228-10.706c-1.045-.582-3.136 1.968-3.415 4.663-.28 2.695-3.187 6.21-3.562 10.3-.17 1.787-1.929 5.2-1.929 5.2l5.623 7.155Z"
      fill="url(#z)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M308.014 360.801c4.899 10.179 13.219 25.062 17.789 33.788 5.408 10.326 11.852 14.701 24.849 7.039 10.885-6.417 26.581-16.738 34.691-22.347 7.547-5.22-1.374-18.106-8.504-13.752-9.459 5.768-33.646 17.199-33.646 17.199s-12.447-22.737-17.615-33.653c-6.574-13.874-27.045-7.974-17.564 11.726Z"
      fill="#467FFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M343.194 382.728s2.276 8.016 2.813 7.7c.537-.317-.377-8.849-.377-8.849l-2.436 1.149Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m292.768 461.22-11.443 80.959 5.999-.08 11.443-80.032-5.999-.847Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m330.93 461.22 11.443 80.959-5.999-.08-11.443-80.032 5.999-.847Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m257.883 461.22-11.443 80.959 5.999-.08 11.443-80.032-5.999-.847Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.306 465.983a9.445 9.445 0 0 0 9.445-9.446 9.445 9.445 0 0 0-9.445-9.445h-37.762a11.086 11.086 0 0 1-11.039-10.049l-6.038-64.194a11.088 11.088 0 0 0-11.04-10.049h-23.7c-6.458 0-11.55 5.498-11.055 11.937l6.224 81.007a11.089 11.089 0 0 0 11.056 10.239h83.354Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M344.75 456.457a8.113 8.113 0 0 1-.156.001h-51.983a11.088 11.088 0 0 1-11.056-10.236l-6.307-81.859a11.081 11.081 0 0 1 5.361 8.483l6.033 63.96a11.088 11.088 0 0 0 11.039 10.047h37.65a9.42 9.42 0 0 1 9.42 9.42l-.001.184Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M558.981 394.543a.747.747 0 0 0-.746-.747h-84.136a.747.747 0 0 0-.747.747v1.097c0 .412.334.746.747.746h84.136a.746.746 0 0 0 .746-.746v-1.097Z"
      fill="url(#A)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M558.989 394.543a.747.747 0 0 0-.746-.747h-32.327a.748.748 0 0 0-.747.747v1.097c0 .412.335.746.747.746h32.327a.746.746 0 0 0 .746-.746v-1.097Z"
      fill="#518DF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M565.093 358.353a3.6 3.6 0 0 0-3.571-4.057h-55.811a3.6 3.6 0 0 0-3.571 3.143l-4.151 32.413a3.6 3.6 0 0 0 3.571 4.057h55.811a3.6 3.6 0 0 0 3.571-3.143l4.151-32.413Z"
      fill="#518DF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M563.394 358.353a3.6 3.6 0 0 0-3.571-4.057h-54.631a3.6 3.6 0 0 0-3.571 3.143l-4.151 32.413a3.6 3.6 0 0 0 3.571 4.057h54.631a3.6 3.6 0 0 0 3.571-3.143l4.151-32.413Z"
      fill="url(#B)"
    />
    <path
      d="M561.445 359.821a3.24 3.24 0 0 0-3.214-3.651h-51.758a3.24 3.24 0 0 0-3.214 2.829l-3.74 29.237a3.24 3.24 0 0 0 3.214 3.651h51.758a3.24 3.24 0 0 0 3.214-2.829l3.74-29.237Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M424.644 404.22s13.601 3.173 13.463 4.418c-.135 1.252-6.123 1.444-6.123 1.444s11.069 7.203 10.188 8.608c-.881 1.405-8.098 2.988-11.093 1.855-2.997-1.133-10.762-9.381-10.762-9.381l-21.787-11.701 7.282-9.604 18.832 14.361Z"
      fill="url(#C)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M424.767 363.617c-4.556 5.665-20.089 22.97-20.089 22.97l3.066 2.301-9.972 12.097s-6.368-3.902-8.487-5.17c-7.561-4.527-4.668-11.835.926-18.504 4.297-5.122 13.638-15.616 20.21-24.829 6.54-9.166 23.187.141 14.346 11.135Z"
      fill="#FFB66F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M404.678 386.587s-4.373 4.246-3.919 4.575c.455.329 5.724-3.269 5.724-3.269l-1.805-1.306Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m407.126 387.623-12.998 12.868 6.499 4.253 8.929-15.285-2.43-1.836Z"
      fill="#FFEDC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m448.635 503.233-7.031 22.649 11.818.53s-1.666-7.747.712-15.009c1.907-5.831 3.464-8.74 3.464-8.74l-8.963.57Z"
      fill="url(#D)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M453.518 519.78s-.247 5.766 1.767 7.589c2.013 1.821 12.693 8.548 13.588 9.903.895 1.356-.418 3.011-.418 3.011s-10.904 1.71-16.048-.929c-3.713-1.906-6.366-3.463-6.366-3.463l-1.14 2.929-5.954-.855s-1.835-4.649-1.047-6.961c.789-2.312 4.858-13.115 4.858-13.115l10.76 1.891Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M414.248 426.031c8.889.867 52.673 11.295 61.688 13.147 9.015 1.852 16.637 11.873 9.762 23.476-7.721 13.025-29.548 53.504-29.548 53.504-.135.033-.267.091-.403.091-4.44 0-8.398-.608-12.209-3.264 0 0 5.791-42.445 19.274-54.273 0 0-34.818 12.519-52.23-1.141-10.272-8.058-6.13-32.496 3.666-31.54Z"
      fill="#33373E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m482.574 442.255-2.391 30.024.584-1.039 2.278-28.603c-.155-.13-.312-.258-.471-.382Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M514.095 501.444s3.877 17.27 8.923 19.118c1.943.711-11.986 2.743-11.986 2.743l-4.225-16.568 7.288-5.293Z"
      fill="url(#E)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m509.257 518.544 3.678 21.621 6.376-1.008-.787-5.346s2.796 4.657 5.915 4.456c3.128-.202 18.77-2.56 18.77-2.56s.352-5.178-6.843-6.361c-5.985-.983-16.19-12.537-16.19-12.537l-10.919 1.735Z"
      fill="#391B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M421.705 423.171s-12.831 27.179 5.201 36.511c17.44 9.026 63.843-2.619 63.843-2.619s-.933 7.533 0 14.251c2.437 17.547 14.419 39.135 15.509 42.693.549 1.792 13.913-.856 13.913-2.981s-6.819-50.489-8.032-60.79c-1.208-10.302-5.019-11.798-16.152-14.614-2.557-.647-35.703-8.185-38.474-7.966-23.806 1.888-35.808-4.485-35.808-4.485Z"
      fill="#42474F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M457.513 427.657c2.771-.22 35.917 7.318 38.474 7.965l1.828.469c9.495 2.484 13.015 4.338 14.237 13.456l.329 2.618c1.003 7.64 3.711 25.604 5.853 40.56-2.418-5.496-4.418-11.596-6.003-18.298-3.691-15.619-2.901-28.444-3.893-30.69-.991-2.246-49.479-9.417-57.811-10.896-3.626-.644-5.672-2.242-6.137-4.793 4.027.114 8.401-.017 13.123-.391Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M451.203 318.878c3.852.422 3.579 7.202-1.11 7.556-.959 6.536-4.377 9.65-8.544 10.011l.231 9.886-18.432-.011s5.19-6.984 4.932-16.545c-.259-9.562.173-18.544.173-18.544l23.874.429-1.124 7.218Z"
      fill="url(#F)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M452.041 319.386c-4.486-1.777-7.8 5.871-11.233 11.187-3.433 5.316-12.327 4.413-13.553 0-4.64-9.664-8.64-22.803-3.224-23.882.762-13.968 37.277-10.67 28.01 12.695Z"
      fill="#9E5229"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M427.017 301.156h8.025l.074.084c7.029 7.855 8.603 19.424 5.319 29.859a7.487 7.487 0 0 1-.826.902c3.679-10.53 2.269-22.383-4.791-30.345h-8.389c.187-.173.384-.34.588-.5Z"
      fill="#000"
      fillOpacity={0.199}
    />
    <ellipse
      rx={4.401}
      ry={4.406}
      transform="matrix(-1 0 0 1 431.868 301.406)"
      fill="#FB9934"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M455.709 348.001c4.081 5.986 10.882 26.302 11.267 30.872.435 5.272-5.225 13.67-5.318 21.302-.083 7.634 4.221 26.74 2.928 28.887-1.283 2.146-43.896 14.559-46.297 13.539-2.4-1.02-10.687-12.02-8.794-16.491 1.904-4.47 9.77-14.611 8.87-20.702-.89-6.09-6.058-28.573-8.624-36.224-2.555-7.652-1.703-15.686 3.067-18.978 2.36-1.629 8.658-6.258 19.874-6.237 11.216.022 22.711 3.568 23.027 4.032Z"
      fill="#FF9F43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m464.857 373.966-19.555-14.05.006 2.567c.072 15.847 1.07 24.242 17.667 50.212l-.384-2.647c-.541-3.842-.96-7.44-.933-9.872.058-4.815 2.333-9.935 3.895-14.383l-.696-11.827Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M443.045 343.532s.472 2.574-1.594 2.574c-2.067 0-15.927.456-17.804-.358-1.876-.814-1.313-2.216-1.313-2.216h20.711Z"
      fill="#FFF6E1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M512.124 378.861s11.287-7.533 13.282-9.045c1.988-1.507 4.483-12.93 2.936-14.533-.747-.776-6.393 4.442-7.4 5.141-1.001.693-6.121 3.776-6.121 3.776s1.449-8.744.201-9.449c-.922-.513-2.768 1.737-3.014 4.115-.247 2.379-2.814 5.481-3.145 9.091-.15 1.577-.734 3.62-.734 3.62l-31.315 13.448 3.276 14.623 32.034-20.787Z"
      fill="url(#G)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M444.907 364.416c4.324 8.984 11.67 22.118 15.704 29.82 4.774 9.113 10.463 12.974 21.937 6.212a561.682 561.682 0 0 0 4.248-2.556l-7.104-15.772-3.728 1.648s-10.989-20.066-15.551-29.7c-5.804-12.245-23.876-7.038-15.506 10.348Z"
      fill="#FFB66F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M475.964 383.768s2.009 7.074 2.483 6.795c.474-.28-.333-7.81-.333-7.81l-2.15 1.015Z"
      fill="#000"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m479.591 381.424 4.948 19.634 7.362-3.98-9.66-17.183-2.65 1.529Z"
      fill="#FFEDC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.339 298.15s-4.148-5.31-8.938-3.917c-4.791 1.393-9.124 9.932-7.067 17.958 2.047 8.028.359 12.606-2.124 18.016C407.538 344.76 430.636 354 430.636 354s-1.802-10.783 1.703-17.295c3.611-6.706 5.661-15.306 5.661-20.568 0-9.151-5.661-17.987-5.661-17.987Z"
      fill="#9E5229"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m431.657 461.22 11.443 80.959-5.999-.08-11.443-80.032 5.999-.847ZM464.956 461.22l11.443 80.959-5.999-.08-11.443-80.032 5.999-.847Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m391.909 461.22-11.443 80.959 6-.08 11.443-80.032-6-.847Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M469.333 465.983c5.216 0 9.445-4.229 9.445-9.446a9.445 9.445 0 0 0-9.445-9.445H431.57a11.088 11.088 0 0 1-11.039-10.049l-6.038-64.194a11.088 11.088 0 0 0-11.039-10.049h-23.7c-6.459 0-11.551 5.498-11.056 11.937l6.225 81.007a11.088 11.088 0 0 0 11.055 10.239h83.355Z"
      fill="#BFE7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M478.776 456.457a8.113 8.113 0 0 1-.156.001h-51.983c-5.793 0-10.61-4.46-11.055-10.236l-6.308-81.859a11.081 11.081 0 0 1 5.361 8.483l6.033 63.96a11.088 11.088 0 0 0 11.039 10.047h37.65c5.203 0 9.421 4.217 9.421 9.42l-.002.184Z"
      fill="#91D6FF"
    />
    <defs>
      <linearGradient
        id="g"
        x1={554.833}
        y1={311.939}
        x2={543.753}
        y2={361.416}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAFAF" />
        <stop offset={1} stopColor="#F07777" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={553.972}
        y1={302.545}
        x2={547.323}
        y2={335.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={506.962}
        y1={505.352}
        x2={505.368}
        y2={535.132}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={475.891}
        y1={543.704}
        x2={427.816}
        y2={451.426}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28324F" />
        <stop offset={1} stopColor="#222735" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={470.771}
        y1={340.915}
        x2={468.619}
        y2={369.518}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={549.732}
        y1={370.46}
        x2={549.102}
        y2={388.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={242.336}
        y1={202.269}
        x2={232.447}
        y2={203.194}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4D63A0" />
        <stop offset={1} stopColor="#222735" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={127.011}
        y1={401.433}
        x2={127.011}
        y2={417.998}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE62BE" />
        <stop offset={1} stopColor="#FDB3A8" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={103.289}
        y1={388.795}
        x2={105.021}
        y2={405.269}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE62BE" />
        <stop offset={1} stopColor="#FDB3A8" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={664.726}
        y1={503.858}
        x2={663.13}
        y2={533.708}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="r"
        x1={660.095}
        y1={331.529}
        x2={655.855}
        y2={392.079}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="s"
        x1={714.441}
        y1={307.528}
        x2={703.217}
        y2={357.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAFAF" />
        <stop offset={1} stopColor="#F07777" />
      </linearGradient>
      <linearGradient
        id="t"
        x1={713.684}
        y1={298.112}
        x2={707.024}
        y2={330.709}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="u"
        x1={629.45}
        y1={505.061}
        x2={627.889}
        y2={538.334}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="v"
        x1={739.379}
        y1={364.401}
        x2={737.871}
        y2={403.785}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="w"
        x1={586.221}
        y1={334.275}
        x2={598.146}
        y2={382.102}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9FF6EC" />
        <stop offset={1} stopColor="#7D9DFF" />
      </linearGradient>
      <linearGradient
        id="x"
        x1={291.949}
        y1={390.304}
        x2={303.7}
        y2={416.424}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#A47070" />
      </linearGradient>
      <linearGradient
        id="y"
        x1={322.017}
        y1={291.839}
        x2={318.541}
        y2={338.74}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#A47070" />
      </linearGradient>
      <linearGradient
        id="z"
        x1={407.318}
        y1={343.096}
        x2={404.968}
        y2={375.735}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#A47070" />
      </linearGradient>
      <linearGradient
        id="A"
        x1={552.737}
        y1={393.207}
        x2={552.737}
        y2={396.386}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9FF6EC" />
        <stop offset={1} stopColor="#7D9DFF" />
      </linearGradient>
      <linearGradient
        id="B"
        x1={559.03}
        y1={345.282}
        x2={559.03}
        y2={393.909}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9FF6EC" />
        <stop offset={1} stopColor="#7D9DFF" />
      </linearGradient>
      <linearGradient
        id="C"
        x1={422.208}
        y1={368.874}
        x2={442.753}
        y2={415.819}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <linearGradient
        id="D"
        x1={460.365}
        y1={496.881}
        x2={457.668}
        y2={525.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="E"
        x1={526.041}
        y1={496.123}
        x2={523.808}
        y2={522.105}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="F"
        x1={459.153}
        y1={302.686}
        x2={456.055}
        y2={344.483}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <linearGradient
        id="G"
        x1={537.825}
        y1={343.73}
        x2={534.833}
        y2={397.408}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FFCCA1" />
      </linearGradient>
      <filter
        id="a"
        x={57.596}
        y={85}
        width={510.128}
        height={244.225}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93375"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_674_93375"
          result="shape"
        />
      </filter>
      <filter
        id="b"
        x={70.856}
        y={83.008}
        width={191.801}
        height={107.428}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={8} dy={9} />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.0755213 0 0 0 0 0.582569 0 0 0 0 0.878793 0 0 0 0.229458 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93375"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={7.5} />
        <feColorMatrix values="0 0 0 0 0.503982 0 0 0 0 0.700178 0 0 0 0 1 0 0 0 0.233829 0" />
        <feBlend
          in2="effect1_dropShadow_674_93375"
          result="effect2_dropShadow_674_93375"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_674_93375"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={70.856}
        y={141.592}
        width={224.949}
        height={198.68}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={8} dy={9} />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.0755213 0 0 0 0 0.582569 0 0 0 0 0.878793 0 0 0 0.229458 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93375"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={7.5} />
        <feColorMatrix values="0 0 0 0 0.503982 0 0 0 0 0.700178 0 0 0 0 1 0 0 0 0.233829 0" />
        <feBlend
          in2="effect1_dropShadow_674_93375"
          result="effect2_dropShadow_674_93375"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_674_93375"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x={96.505}
        y={202.89}
        width={161.424}
        height={97.313}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={13} />
        <feGaussianBlur stdDeviation={7.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.144395 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93375"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_674_93375"
          result="shape"
        />
      </filter>
      <filter
        id="f"
        x={276.232}
        y={105.237}
        width={272.181}
        height={197.485}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={6} dy={6} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.70188 0 0 0 0 0.70188 0 0 0 0 0.70188 0 0 0 0.208725 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_674_93375"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_674_93375"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default BackgroundLogin
