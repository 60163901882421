import { StyleSheet } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";

const generatingResultPDFStyles = StyleSheet.create({
  // Table styles
  specialTable: {},

  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    padding: "0px 12px",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
  },

  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },

  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },

  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },

  contentTablePage2Red: {
    fontSize: 9,
    color: "#F8191A",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },

  // Text styles
  subTitleYellow: {
    fontSize: 10,
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: Colors.primary,
  },

  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },

  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },

  mainContentBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
    fontWeight: 500,
  },

  textUnderline: {
    fontFamily: "Montserrat",
    color: "#FFCC4D",
    fontSize: 10,
    textDecoration: "underline",
    fontWeight: 500,
  },

  textRadio: {
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#5E5873",
  },

  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
  },

  textFranchise: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },

  textItalic: {
    // fontStyle: "italic",
  },

  // <Li> tag style
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },

  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },

  // Checkbox styles
  itemCheckbox: {
    borderBottom: "1px dashed #FFCC4D",
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingTop: 6,
  },
  itemCheckboxStart: {
    borderBottom: "1px dashed #FFCC4D",
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: 15,
    paddingTop: 6,
  },

  mainTextCheckbox: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },

  checkbox: {
    width: 11,
    height: 11,
    border: "1.2px solid #FFCC4D",
    borderRadius: 2,
    marginRight: 6,
  },

  // Util styles
  underlineTitleBoxContent: {
    width: "52px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 4,
  },
});

export default generatingResultPDFStyles;
