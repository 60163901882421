import ChartIcon from "@assets/SvgComponents/ChartIcon";
import PaperIcon from "@assets/SvgComponents/PaperIcon";
import UserIcon from "@assets/SvgComponents/UserIcon";

interface NavigationButtonConfig {
  Icon: any;
  text: string;
  path: string;
}

export const navigationButtons: NavigationButtonConfig[] = [
  {
    Icon: ChartIcon,
    text: "Dashboard",
    path: "/",
  },
  {
    Icon: PaperIcon,
    text: "Liste des devis",
    path: "/liste-des-devis",
  },
  {
    Icon: UserIcon,
    text: "Gérer les utilisateur",
    path: "/management-users",
  },
];
