import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import formatMoney from "@utils/formatMoney";
import * as _ from "lodash";
import parse from "html-react-parser";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage1Props {
  infoInsurance?: IInfoContractPDF;
}

const ContentPage2Artisans: FC<IContentPage1Props> = ({ infoInsurance }) => {
  return (
    <View break>
      <View style={{ marginBottom: 40 }}>
        <View style={{ marginBottom: 20 }}>
          <Text style={styles.mainTitle}>
            Les garanties de la présente attestation s’appliquent :{" "}
          </Text>
          <View style={styles.underlineMainTitle} />
        </View>

        <View style={[styles.li, { marginBottom: 10 }]}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Aux activités professionnelles ou missions suivantes (selon les
            définitions données à l’Annexe ci-après)
          </Text>
        </View>

        <View style={styles.specialTable}>
          <View style={styles.headerSpecialTable}>
            <View style={{ flex: 0.3 }}>
              <Text style={styles.titleHeaderTable}>N°</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />

            <View style={{ flex: 0.9 }}>
              <Text style={styles.titleHeaderTable}>Activités</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />

            <View style={{ flex: 0.7 }}>
              <Text style={styles.titleHeaderTable}>Franchises</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />

            <View style={{ flex: 1.4 }}>
              <Text style={styles.titleHeaderTable}>Définitions</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />

            <View style={{ flex: 1.2 }}>
              <Text style={styles.titleHeaderTable}>Exclusion</Text>
            </View>
            <View
              style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
            />
          </View>

          {!_.isEmpty(infoInsurance?.activities) &&
            _.isArray(infoInsurance?.activities) &&
            infoInsurance?.activities.map((item, index) => (
              <View
                key={index}
                style={[
                  index % 2 === 0 ? styles.itemTableOdd : styles.itemTableEven,
                ]}
              >
                <View style={{ flex: 0.3 }}>
                  <Text style={styles.textRadio}>{index + 1}</Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />

                <View style={{ flex: 0.9 }}>
                  <Text style={styles.textMoney}>{item?.name}</Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />

                <View style={{ flex: 0.7 }}>
                  <Text style={styles.textMoney}>
                    {infoInsurance?.page6?.amount_franchise_3 &&
                      formatMoney(infoInsurance.franchise_du_contrat)}{" "}
                    €
                  </Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />

                <View style={{ flex: 1.4 }}>
                  <Text style={styles.textMoney}>
                    {item?.definition && parse(item.definition)}
                  </Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />

                <View style={{ flex: 1.2 }}>
                  <Text style={styles.textMoney}>
                    {item?.exclusion && parse(item.exclusion)}
                  </Text>
                </View>
                <View
                  style={{
                    width: 0.5,
                    height: "100%",
                    backgroundColor: "#FFCC4D",
                  }}
                />
              </View>
            ))}
        </View>
      </View>

      <Text style={styles.mainContent}>
        Attention : dès lors que figure dans la définition d’une activité la
        mention de « travaux accessoires et/ou complémentaires», il est rappelé
        que lesdits travaux répertoriés comme « accessoires et/ou
        complémentaires », ne peuvent en aucun cas faire l’objet d’un marché de
        travaux à part entière. Si tel était le cas, ces travaux seraient alors
        réputés non garantis.
      </Text>

      <View style={{ marginTop: 20 }}>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Aux travaux ayant fait l’objet d’une ouverture de chantier pendant
            la période de validité mentionnée ci-dessus. L’ouverture est définie
            à l’annexe I de l’article A.243-1 du Code des assurances.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Ce contrat couvre les chantiers réalisés par l'Assuré en France
            métropolitaine.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            La police et les garanties sont conditionnées au fait que le marché
            du client ne dépasse pas 5.000.000 € (HT).
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={[styles.mainContent, { fontWeight: 500 }]}>
            La police a pour objet de garantir les interventions de l'assuré sur
            les chantiers de construction à condition que le coût global des
            travaux tous corps d'état ne soit pas supérieur à 15 000 000 Euros
            (HT) (sauf si un CCRD a été conclu). Par ailleurs, le chiffre
            d’affaires de l’Assuré doit être inférieur à 5.000.000 (HT). Euros
            et l’effectif est limité à 49 employés.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={[styles.mainContent, { fontWeight: 500 }]}>
            Ces conditions cumulatives sont substantielles et déterminantes de
            l’engagement de l’assureur et de la mobilisation des garanties.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={[styles.mainContent, { fontWeight: 500 }]}>
            En cas de sous-traitance (limitée à 30% de l’activité sauf accord
            exprès de l’Assureur), la garantie est conditionnée à la production
            par l’assuré des attestations RC professionnelle et RC décennale du
            sous-traitant couvrant les activités réellement sous-traitées
            pendant la période de réalisation du chantier. Ces conditions
            cumulatives sont substantielles et déterminantes de l’engagement de
            l’assureur et de la mobilisation des garanties.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={[styles.mainContent, { fontWeight: 500 }]}>
            Lorsque la responsabilité de l’assuré se trouve engagée
            solidairement ou in solidum, la garantie est limitée à sa propre
            part de responsabilité dans ses rapports avec le ou les coobligés.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Aux travaux, produits et procédés de construction suivants :
          </Text>
        </View>

        <View style={styles.liChild1}>
          <View style={styles.dotLiChild1} />
          <Text style={styles.mainContent}>
            Travaux de construction répondant à une norme homologuée (NF DTU ou
            NF EN), à des règles professionnelles acceptées
          </Text>
        </View>

        <View style={styles.liChild1}>
          <View style={styles.dotLiChild1} />
          <Text style={styles.mainContent}>par la C2P (1)</Text>
        </View>

        <View style={styles.liChild1}>
          <View style={styles.dotLiChild1} />
          <Text style={styles.mainContent}>
            Procédés ou produits faisant l’objet au jour de la passation du
            marché :
          </Text>
        </View>

        <View style={styles.liChild2}>
          <Text style={styles.mainContent}>
            * D’un agrément technique européen (ATE) en cours de validité ou
            d’une évaluation technique européenne (ETE) bénéficiant d’un
            document technique d’application (DTA), ou d’un avis technique
            (ATEc), valides et non mis en observation par la C2P (2),
          </Text>
        </View>

        <View style={styles.liChild2}>
          <Text style={styles.mainContent}>
            * D’une appréciation technique d’expérimentation (ATEx) avec avis
            favorable,
          </Text>
        </View>

        <View style={styles.liChild2}>
          <Text style={styles.mainContent}>
            * D’un Pass innovation « vert » en cours de validité
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            (1) Les règles professionnelles acceptées par la C2P (Commission
            prévention produits mis en oeuvre par l’Agence qualité construction)
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            sont listées à l’annexe 2 de la publication semestrielle de la C2P
            et sont consultables sur le site de l’Agence qualité construction
            (www.qualitéconstruction.com)
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            (2) Les communiqués de la C2P sont accessibles sur le site de l’AQC
            (www.qualiteconstruction.com) Dans les cas où les travaux réalisés
            ne répondent pas aux caractéristiques énoncées ci-dessus, l’Assuré
            en informe l’Assureur.
          </Text>
        </View>

        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContent}>
            Dans les cas où les travaux réalisés ne répondent pas aux
            caractéristiques énoncées ci-dessus, l’Assuré en informe l’Assureur.
          </Text>
        </View>

        <Text style={[styles.mainContent, { fontWeight: 500 }]}>
          Dans les cas où les travaux réalisés ne répondent pas aux
          caractéristiques énoncées ci-dessus, l’Assuré en informe l’Assureur.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 12,
    color: "#5E5873",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  underlineMainTitle: {
    width: "69px",
    height: "4px",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    marginTop: 5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginBottom: 4,
  },
  liChild1: {
    position: "relative",
    paddingLeft: 24,
    marginBottom: 8,
  },
  liChild2: {
    position: "relative",
    paddingLeft: 36,
    marginBottom: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  dotLiChild1: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 16,
    top: 4.5,
  },
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "left",
    paddingLeft: 8,
    paddingVertical: 15,
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  textFranchise: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  textRadio: {
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#5E5873",
    padding: 8,
  },
  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
  },
});

export default ContentPage2Artisans;
