import { combineReducers } from "redux";
import { IAppState } from "stores/state";
import { getActivitiesReducer } from "./activitiesOfContract/activitiesOfContract.reducer";
import createContractReducer from "./contract/contract.reducer";
import { createNewCompanyReducer } from "./createNewCompany/createNewCompany.reducer";
import { franchiseReducer } from "./franchises/franchises.reducer";
import listQuotesReducer from "./listQuotes/listQuotes.reducer";
import loginReducer from "./login/login.reducer";
import { savedActivitiesReducer } from "./savedActivities/savedActivities.reducer";
import searchCompanyReducer from "./searchCompany/searchCompany.reducer";
import statisticReducer from "./statistic/statistic.reducer";
import { usersReducer } from "./users/users.reducer";

const rootReducer = combineReducers<IAppState>({
  responseLogin: loginReducer,
  dataSearchCompany: searchCompanyReducer,
  dataContract: createContractReducer,
  activitiesRedux: savedActivitiesReducer,
  dataActivities: getActivitiesReducer,
  responseCreateCompany: createNewCompanyReducer,
  listQuotes: listQuotesReducer,
  franchises: franchiseReducer,
  statistic: statisticReducer,
  users: usersReducer,
});
export default rootReducer;
