import { Box, Typography } from "@mui/material";
import { TextStyles } from "@themes/textStyles";
import * as React from "react";
import { Colors } from "@themes/colors";

export interface IActivitiesSelectionProps {
  selected: number;
  maxSelected: number;
}

export default function ActivitiesSelection({
  selected,
  maxSelected,
}: IActivitiesSelectionProps) {
  const textStyles = TextStyles();

  return (
    <Box component="div" display="flex" alignItems="center">
      <Typography className={textStyles.paragraph_semi_bold}>
        Activités sélectionnées:
      </Typography>
      <Typography
        className={textStyles.paragraph_semi_bold}
        sx={{ color: Colors.primary, ml: 0.5 }}
      >
        {`${selected}/${maxSelected}`}
      </Typography>
    </Box>
  );
}
