import * as React from "react";

const AddDocumentIcon: React.FC<{ color: string }> = ({
  color = "#D8D6DE",
}) => (
  <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8 6.45c.025.05.05.1.1.15.1.1.1.3.1.4v12c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h8c.1 0 .3 0 .4.1.1 0 .2.1.3.2l6 6c.05.05.075.1.1.15ZM14.6 6 12 3.4V6h2.6Zm.4 14H3c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1h7v5c0 .6.4 1 1 1h5v11c0 .6-.4 1-1 1Zm-3-5c.6 0 1-.4 1-1s-.4-1-1-1h-2v-2c0-.6-.4-1-1-1s-1 .4-1 1v2H6c-.6 0-1 .4-1 1s.4 1 1 1h2v2c0 .6.4 1 1 1s1-.4 1-1v-2h2Z"
      fill={color}
    />
  </svg>
);

export default AddDocumentIcon;
