import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { ICreateCompanyDto } from "./createNewCompany.dto";

export enum CreateNewCompanyTypes {
  CREATE_COMPANY_REQUESTED = "createNewCompany/REQUESTED",
  CREATE_COMPANY_SUCCEEDED = "createNewCompany/SUCCEEDED",
  CREATE_COMPANY_FAILED = "createNewCompany/FAILED",

  SAVE_SELECTED_TYPE = "createNewCompany/SAVE_SELECTED_TYPE",
  CLEAR_RESPONSE = "createNewCompany/CLEAR_RESPONSE",
  CLEAR_SELECTED = "createNewCompany/CLEAR_SELECTED",

  UPDATE_COMPANY_REQUESTED = "createNewCompany/UPDATE_REQUESTED",
  UPDATE_COMPANY_SUCCEEDED = "createNewCompany/UPDATE_SUCCEEDED",
  UPDATE_COMPANY_FAILED = "createNewCompany/UPDATE_FAILED",
}

export interface ICreateCompanyRequestedAction {
  type: typeof CreateNewCompanyTypes.CREATE_COMPANY_REQUESTED;
  dataCompany: ICreateCompanyDto;
}

export interface ICreateCompanySucceededAction {
  type: typeof CreateNewCompanyTypes.CREATE_COMPANY_SUCCEEDED;
  response?: any;
}

export interface ICreateCompanyFailedAction {
  type: typeof CreateNewCompanyTypes.CREATE_COMPANY_FAILED;
  error?: any;
}

export interface ISaveSelectedTypeAction {
  selected_type: ContractType;
  type: typeof CreateNewCompanyTypes.SAVE_SELECTED_TYPE;
}

export interface IClearResponseCreateCompanyAction {
  type: typeof CreateNewCompanyTypes.CLEAR_RESPONSE;
}

export interface IClearSelectedCreateCompanyAction {
  type: typeof CreateNewCompanyTypes.CLEAR_SELECTED;
}

export interface IUpdateCompanyRequestedAction {
  type: typeof CreateNewCompanyTypes.UPDATE_COMPANY_REQUESTED;
  dataCompany: ICreateCompanyDto;
  idCompany: string;
}

export interface IUpdateCompanySucceededAction {
  type: typeof CreateNewCompanyTypes.UPDATE_COMPANY_SUCCEEDED;
  response?: any;
}

export interface IUpdateCompanyFailedAction {
  type: typeof CreateNewCompanyTypes.UPDATE_COMPANY_FAILED;
  error?: any;
}

export type CreateCompanyActions =
  | ICreateCompanyRequestedAction
  | ICreateCompanySucceededAction
  | ICreateCompanyFailedAction
  | ISaveSelectedTypeAction
  | IClearResponseCreateCompanyAction
  | IClearSelectedCreateCompanyAction
  | IUpdateCompanyRequestedAction
  | IUpdateCompanySucceededAction
  | IUpdateCompanyFailedAction;
