import { IResponseCompanyPappers } from "./searchCompany.dto";
import {
  IClearDataSearchCompanyAction,
  IClearDetailCompanyAction,
  IGetDetailCompanyFailedAction,
  IGetDetailCompanyMongoRequestedAction,
  IGetDetailCompanyRequestedAction,
  IGetDetailCompanySucceededAction,
  ISearchCompanyFailedAction,
  ISearchCompanyRequestedAction,
  ISearchCompanySucceededAction,
  SearchCompanyTypes,
} from "./searchCompany.type";

export function searchCompany(q: string): ISearchCompanyRequestedAction {
  return {
    type: SearchCompanyTypes.SEARCH_COMPANY_REQUESTED,
    q,
  };
}

export function searchCompanySucceeded(
  data: IResponseCompanyPappers
): ISearchCompanySucceededAction {
  return {
    type: SearchCompanyTypes.SEARCH_COMPANY_SUCCEEDED,
    data,
  };
}

export function searchCompanyFailed(
  error?: string
): ISearchCompanyFailedAction {
  return {
    type: SearchCompanyTypes.SEARCH_COMPANY_FAILED,
    error,
  };
}

export function clearDataSearchCompany(): IClearDataSearchCompanyAction {
  return {
    type: SearchCompanyTypes.CLEAR_DATA_SEARCH,
  };
}

export function getDetailCompany(
  siren: string
): IGetDetailCompanyRequestedAction {
  return {
    type: SearchCompanyTypes.GET_DETAIL_COMPANY_REQUESTED,
    siren,
  };
}

export function getDetailCompanyMongo(
  id: string
): IGetDetailCompanyMongoRequestedAction {
  return {
    type: SearchCompanyTypes.GET_DETAIL_COMPANY_MONGO_REQUESTED,
    id,
  };
}

export function getDetailCompanySucceeded(
  data: any
): IGetDetailCompanySucceededAction {
  return {
    type: SearchCompanyTypes.GET_DETAIL_COMPANY_SUCCEEDED,
    data,
  };
}

export function getDetailCompanyFailed(
  error?: string
): IGetDetailCompanyFailedAction {
  return {
    type: SearchCompanyTypes.GET_DETAIL_COMPANY_FAILED,
    error,
  };
}

export function clearDetailCompany(): IClearDetailCompanyAction {
  return {
    type: SearchCompanyTypes.CLEAR_DATA_DETAIL_COMPANY,
  };
}
