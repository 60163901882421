import React, { FC } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { lighten, darken } from "@mui/system";
import { TextStyles } from "@themes/textStyles";

interface IBoxUsernameProps {
  name: string;
  email?: string;
}

const BoxUsername: FC<IBoxUsernameProps> = ({ name, email }) => {
  const textStyles = TextStyles();
  const stringToColor = (str: string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  };

  return (
    <Box component="div" display="flex" alignItems="center">
      <Avatar
        sx={{
          width: 32,
          height: 32,
          mr: 1,
          backgroundColor: lighten(stringToColor(name), 0.8),
        }}
      >
        <Typography
          sx={{ color: `${darken(stringToColor(name), 0.2)} !important` }}
          className={textStyles.paragraph_bold}
        >
          {name.trim().indexOf(" ") !== -1
            ? `${name.trim().split(" ")[0][0]}${name.trim().split(" ")[1][0]}`
            : `${name[0]}`}
        </Typography>
      </Avatar>
      <Box component="div">
        <Typography className={textStyles.paragraph_semi_bold}>
          {name}
        </Typography>
        <Typography
          className={textStyles.paragraph_small}
          sx={{ color: "#B9B9C3 !important" }}
        >
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

export default BoxUsername;
