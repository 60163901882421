import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { TextStyles } from "@themes/textStyles";

interface NumberInputCustomProps {
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  background?: string;
  border?: string;
  error?: string;
  color?: string;
  fontWeight?: number;
}

const NumberInputCustom = React.forwardRef<any, NumberInputCustomProps>(
  (
    {
      placeholder,
      disabled = false,
      value,
      onChange = () => {},
      background = "#F8F8F8",
      border,
      error,
      color = "#5E5873",
      fontWeight = 500,
    },
    ref
  ) => {
    const textStyles = TextStyles();
    return (
      <Box component="div" sx={{ m: 0 }}>
        <InputStyled
          inputMode="numeric"
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          value={value}
          ref={ref}
          onChange={onChange}
          className={error && "TextField-error"}
          style={{
            fontWeight: value ? 600 : fontWeight,
            background,
            border: border ? border : "none",
            color,
          }}
        />
        {error && (
          <Typography
            className={textStyles.paragraph_small_error}
            sx={{ mt: 0.3 }}
            component="h6"
          >
            {error}
          </Typography>
        )}
      </Box>
    );
  }
);

const InputStyled = styled("input")({
  width: "100%",
  "&:focus-visible": {
    outline: "1px solid #D8D6DE",
    background: "#fff !important",
  },
  borderRadius: 5,
  height: 32,
  padding: "0 10px",
  fontSize: 15,
  textAlign: "center",
  fontFamily: "Montserrat",
  "&.TextField-error": {
    borderColor: "#EA5455 !important",
  },
});

export default NumberInputCustom;
