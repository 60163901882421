import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IInfoContractPDF } from "@stores/contract/contract.dto";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage3Props {
  infoInsurance?: IInfoContractPDF;
  isPIB?: boolean;
}

const ContentPage3: FC<IContentPage3Props> = ({ infoInsurance, isPIB }) => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Objet de la garantie proposée
        </Text>
      </View>

      <View>
        <View>
          <Text style={styles.titleBoxSubContent}>
            Nature, durée et maintien des garanties :
          </Text>
          <View style={styles.underlineTitleBoxContent} />
        </View>

        <View style={{ marginTop: 12, paddingRight: 20 }}>
          <Text style={styles.textUnderline}>
            Responsabilité civile décennale obligatoire :
          </Text>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              Le contrat proposé garantit la responsabilité décennale du
              souscripteur instaurée par les articles 1792 et suivants du Code
              civil, dans le cadre et les limites prévues par les dispositions
              des articles L.241-1 et L.241-2 du Code des assurances relatives à
              l’obligation d’assurance décennale, et pour des travaux de
              construction d’ouvrages qui y sont soumis, au regard de l’article
              L.243-1-1 du même code.
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              La garantie couvre les travaux de réparation, notamment en cas de
              remplacement des ouvrages, qui comprennent également les travaux
              de démolition, déblaiement, dépose ou de démontage éventuellement
              nécessaires.
            </Text>
          </View>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              La garantie s’applique aux travaux ayant fait l’objet d’une
              ouverture de chantier pendant la période de validité mentionnée
              ci-dessus. L’ouverture est définie à l’annexe I de l’article
              A.243-1 du Code des assurances. La garantie s’applique pour la
              durée de la responsabilité décennale pesant sur l'assuré en vertu
              des articles 1792 et suivants du Code civil. Elle est maintenue
              dans tous les cas pour la même durée.
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 15, paddingRight: 20 }}>
          <Text style={styles.textUnderline}>
            Responsabilité civile professionnelle :
          </Text>
          <View style={styles.li}>
            <View style={styles.dotLi} />
            <Text style={styles.mainContent}>
              La garantie proposée couvre les conséquences pécuniaires de la
              responsabilité civile du souscripteur pour les dommages corporels,
              matériels et immatériels causés aux tiers et résultant de ses
              activités professionnelles déclarées. Conformément aux
              dispositions de l'article L 124-5 alinéas 4 et 5 du Code des
              assurances, le contrat est établi en "base réclamation" pour ces
              chapitres du contrat.
            </Text>
          </View>
        </View>

        {!isPIB ? (
          <View>
            <View>
              <Text style={styles.titleBoxSubContent}>
                Montant de la garantie responsabilité décennale obligatoire :
              </Text>
              <View style={styles.underlineTitleBoxContent} />
            </View>

            <View style={{ marginTop: 12, paddingRight: 20 }}>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  En habitation : le montant de la garantie couvre le coût des
                  travaux de réparation des dommages à l’ouvrage.
                </Text>
              </View>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  Hors habitation : le montant de la garantie couvre le coût des
                  travaux de réparation des dommages à l’ouvrage dans la limite
                  du coût total de la construction déclaré par le maître
                  d’ouvrage et sans pouvoir être supérieur au montant prévu à
                  l’article R.243-3 alinéa 1er du Code des assurances.
                </Text>
              </View>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  Lorsqu’un Contrat Collectif de Responsabilité Décennale est
                  souscrit au bénéfice de l’assuré, le montant de la garantie
                  est égal au montant de la franchise absolue stipulée par ledit
                  contrat collectif.
                </Text>
              </View>
            </View>

            <View>
              <Text style={styles.titleBoxSubContent}>
                Durée et maintien de la garantie responsabilité civile décennale
              </Text>
              <View style={styles.underlineTitleBoxContent} />
            </View>

            <View style={{ marginTop: 12, paddingRight: 20 }}>
              <View style={styles.li}>
                <View style={styles.dotLi} />
                <Text style={styles.mainContent}>
                  La garantie s’applique pour la durée de la responsabilité
                  décennale pesant sur l’assuré en vertu des articles 1792 et
                  suivants du Code civil. Elle est maintenue dans tous les cas
                  pour la même durée.
                </Text>
              </View>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    padding: "4px 0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "center",
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginTop: 40,
    marginBottom: 25,
    padding: "8px 0px",
  },
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  underlineTitleBoxContent: {
    width: "69px",
    height: "4px",
    backgroundColor: "#FFCC4D",
    borderRadius: 5,
    marginTop: 4,
  },
  textUnderline: {
    fontFamily: "Montserrat",
    color: "#FFCC4D",
    fontSize: 10,
    textDecoration: "underline",
    fontWeight: 500,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage3;
