import { AxiosResponse } from "axios";
import { all, fork, delay } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  CreateContractTypes,
  IArchiveDraftContractRequestedAction,
  ICalculateResultRequestedAction,
  IGetDetailContractRequestedAction,
  IPassInGuaranteeRequestedAction,
  ISaveDraftContractRequestedAction,
  IUpdateDraftContractRequestedAction,
} from "./contract.type";
import {
  IContract,
  ICreateContractDto,
  ICreateHistoryInsuranceDto,
  IPricingByFranchise,
} from "./contract.dto";
import {
  addComments,
  archiveDraftContractFailed,
  archiveDraftContractSucceeded,
  calculateResultFailed,
  calculateResultSucceeded,
  clearResponseDraftContract,
  getDetailContractFailed,
  getDetailContractSucceeded,
  saveDraftContractFailed,
  saveDraftContractSucceeded,
  saveInfoContractIntoRedux,
  updateDraftContractFailed,
  updateDraftContractSucceeded,
  infoDraftContractUpdate,
  passInGuaranteeSucceeded,
  passInGuaranteeFailed,
  clearMessageError,
} from "./contract.creator";
import {
  archiveContractService,
  calculateAmountService,
  getDetailDraftContractService,
  passInGuaranteeService,
  saveDraftContractService,
  updateDraftContractService,
} from "./contract.service";
import { IResponseCompanyMongo } from "@stores/searchCompany/searchCompany.dto";
import { getDetailCompanySucceeded } from "@stores/searchCompany/searchCompany.creator";
import { IActivityRedux } from "@stores/savedActivities/savedActivities.dto";
import { addManyActivities } from "@stores/savedActivities/savedActivities.creator";
import moment from "moment";
import * as _ from "lodash";

function* saveDraftContract({
  information_contract,
}: ISaveDraftContractRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IContract> = yield call(
      saveDraftContractService,
      information_contract
    );
    const responseContract: any = dataResponse.data;
    yield put(infoDraftContractUpdate(responseContract?._id));
    yield put(
      saveDraftContractSucceeded(dataResponse.data, "save draft succeeded")
    );
    yield put(clearResponseDraftContract());
  } catch (err: any) {
    yield put(saveDraftContractFailed(err?.message));
    yield put(clearResponseDraftContract());
  }
}

function* watchSaveDraftContract() {
  yield takeLatest(
    CreateContractTypes.SAVE_DRAFT_CONTRACT_REQUESTED,
    saveDraftContract
  );
}

function* getDetailDraftContract({
  idContract,
}: IGetDetailContractRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IContract> = yield call(
      getDetailDraftContractService,
      idContract
    );
    const data: any = dataResponse.data;

    const dataCompany: IResponseCompanyMongo = data?.company;
    yield put(getDetailCompanySucceeded(dataCompany));

    const dataActivities: Array<IActivityRedux> = [];
    if (_.isArray(data?.activities)) {
      data?.activities?.forEach((activity: any) => {
        dataActivities.push({
          id: activity?.activityId,
          percent: activity?.percent,
          name: activity?.activity?.name,
        });
      });
    }
    if (dataActivities?.length > 0) {
      yield put(addManyActivities(dataActivities));
    }

    const dataContract: ICreateContractDto = {
      companyId: data?.company?._id,
      isInReceivership: data?.isInReceivership,
      contract_id: data?.contract_id,
    };
    if (data?.amount) {
      dataContract.amount = data?.amount;
    }
    if (data?.contractOption) {
      dataContract.contractOption = {
        commission: parseInt(data.contractOption?.commission),
        period: data.contractOption?.period,
        subscriptionFee: parseInt(data.contractOption?.subscriptionFee),
        franchiseId: data.contractOption?.franchise,
      };
    }
    if (data?.insuranceHistory) {
      const dataInsuranceHistory: ICreateHistoryInsuranceDto = {};
      if (data?.insuranceHistory?.hasBeenRCD) {
        dataInsuranceHistory.hasBeenRCD = data?.insuranceHistory?.hasBeenRCD;
      }
      if (data?.insuranceHistory?.dateWithoutInteruption) {
        dataInsuranceHistory.dateWithoutInteruption = moment(
          data?.insuranceHistory?.dateWithoutInteruption
        ).format("YYYY/MM/DD");
      }
      if (data?.insuranceHistory?.lastInsurer) {
        dataInsuranceHistory.lastInsurer = data?.insuranceHistory?.lastInsurer;
      }
      if (data?.insuranceHistory?.isInProgress) {
        dataInsuranceHistory.isInProgress =
          data?.insuranceHistory?.isInProgress;
      }
      if (data?.insuranceHistory?.progressDate) {
        dataInsuranceHistory.progressDate = moment(
          data?.insuranceHistory?.progressDate
        ).format("YYYY/MM/DD");
      }
      if (data?.insuranceHistory?.progressCancellationDate) {
        dataInsuranceHistory.progressCancellationDate = moment(
          data?.insuranceHistory?.progressCancellationDate
        ).format("YYYY/MM/DD");
      }
      if (data?.insuranceHistory?.progressTerminationReason) {
        dataInsuranceHistory.progressTerminationReason =
          data?.insuranceHistory?.progressTerminationReason;
      }
      if (data?.insuranceHistory?.isClaimed) {
        dataInsuranceHistory.isClaimed = data?.insuranceHistory?.isClaimed;
      }
      if (data?.insuranceHistory?.contractStartDate) {
        dataInsuranceHistory.contractStartDate = moment(
          data?.insuranceHistory?.contractStartDate
        ).format("YYYY/MM/DD");
      }
      if (data?.insuranceHistory?.isInsuredThePast) {
        dataInsuranceHistory.isInsuredThePast =
          data?.insuranceHistory?.isInsuredThePast;
      }
      if (data?.insuranceHistory?.claimDetails) {
        dataInsuranceHistory.claimDetails =
          data?.insuranceHistory?.claimDetails;
      }
      if (data?.insuranceHistory?.resumptionDatePast) {
        dataInsuranceHistory.resumptionDatePast =
          data?.insuranceHistory?.resumptionDatePast;
      }

      dataContract.insuranceHistory = dataInsuranceHistory;
    }
    if (data?.activities) {
      dataContract.activities = dataActivities;
    }

    yield put(saveInfoContractIntoRedux(dataContract));

    yield put(getDetailContractSucceeded(data));
  } catch (err: any) {
    yield put(getDetailContractFailed(err?.message));
    yield delay(500);
    yield put(clearMessageError());
  }
}

function* watchGetDetailDraftContract() {
  yield takeLatest(
    CreateContractTypes.GET_DETAIL_CONTRACT_REQUESTED,
    getDetailDraftContract
  );
}

function* updateDraftContract({
  id_contract,
  information_contract,
}: IUpdateDraftContractRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IContract> = yield call(
      updateDraftContractService,
      information_contract,
      id_contract
    );
    yield put(updateDraftContractSucceeded(dataResponse.data));
    yield put(clearResponseDraftContract());
  } catch (err: any) {
    yield put(updateDraftContractFailed(err?.message));
    yield put(clearResponseDraftContract());
    yield delay(500);
    yield put(clearMessageError());
  }
}

function* watchUpdateDraftContract() {
  yield takeLatest(
    CreateContractTypes.UPDATE_DRAFT_CONTRACT_REQUESTED,
    updateDraftContract
  );
}

function* archiveDraftContract({
  id_contract,
}: IArchiveDraftContractRequestedAction) {
  try {
    yield call(archiveContractService, id_contract);
    yield put(archiveDraftContractSucceeded("Archivage contractuel réussi"));
    yield put(clearResponseDraftContract());
  } catch (err: any) {
    yield put(
      archiveDraftContractFailed("Impossible d'enregistrer le contrat")
    );
    yield put(clearResponseDraftContract());
    yield delay(500);
    yield put(clearMessageError());
  }
}

function* watchArchiveDraftContract() {
  yield takeLatest(
    CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_REQUESTED,
    archiveDraftContract
  );
}

function* calculateResult({ data }: ICalculateResultRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IPricingByFranchise[]> = yield call(
      calculateAmountService,
      data
    );
    yield put(calculateResultSucceeded(dataResponse.data));
    yield put(clearResponseDraftContract());
  } catch (err: any) {
    yield put(addComments(err?.comments?.join(". ")));
    if (_.isArray(err?.message)) {
      yield put(calculateResultFailed(err?.message?.[0]));
    } else {
      yield put(calculateResultFailed(err?.message));
    }
    yield put(clearResponseDraftContract());
    yield delay(500);
    yield put(clearMessageError());
  }
}

function* watchCalculateResult() {
  yield takeLatest(
    CreateContractTypes.CALCULATE_RESULT_REQUESTED,
    calculateResult
  );
}

function* passInGuarantee({
  id_contract,
  isPass,
}: IPassInGuaranteeRequestedAction) {
  try {
    yield call(passInGuaranteeService, id_contract, isPass);
    // const data: any = dataResponse.data;

    // const dataCompany: IResponseCompanyMongo = data?.company;
    // yield put(getDetailCompanySucceeded(dataCompany));

    // const dataActivities: Array<IActivityRedux> = [];
    // if (_.isArray(data?.activities)) {
    //   data?.activities?.forEach((activity: any) => {
    //     dataActivities.push({
    //       id: activity?.activityId,
    //       percent: activity?.percent,
    //       name: activity?.activity?.name,
    //     });
    //   });
    // }
    // if (dataActivities?.length > 0) {
    //   yield put(addManyActivities(dataActivities));
    // }

    // const dataContract: ICreateContractDto = {
    //   companyId: data?.company?._id,
    //   isInReceivership: data?.isInReceivership,
    //   contract_id: data?.contract_id,
    // };
    // if (data?.amount) {
    //   dataContract.amount = data?.amount;
    // }
    // if (data?.contractOption) {
    //   dataContract.contractOption = {
    //     commission: parseInt(data.contractOption?.commission),
    //     period: data.contractOption?.period,
    //     subscriptionFee: parseInt(data.contractOption?.subscriptionFee),
    //     franchiseId: data.contractOption?.franchise,
    //   };
    // }
    // if (data?.insuranceHistory) {
    //   const dataInsuranceHistory: ICreateHistoryInsuranceDto = {};
    //   if (data?.insuranceHistory?.hasBeenRCD) {
    //     dataInsuranceHistory.hasBeenRCD = data?.insuranceHistory?.hasBeenRCD;
    //   }
    //   if (data?.insuranceHistory?.dateWithoutInteruption) {
    //     dataInsuranceHistory.dateWithoutInteruption = moment(
    //       data?.insuranceHistory?.dateWithoutInteruption
    //     ).format("YYYY/MM/DD");
    //   }
    //   if (data?.insuranceHistory?.lastInsurer) {
    //     dataInsuranceHistory.lastInsurer = data?.insuranceHistory?.lastInsurer;
    //   }
    //   if (data?.insuranceHistory?.isInProgress) {
    //     dataInsuranceHistory.isInProgress =
    //       data?.insuranceHistory?.isInProgress;
    //   }
    //   if (data?.insuranceHistory?.progressDate) {
    //     dataInsuranceHistory.progressDate = moment(
    //       data?.insuranceHistory?.progressDate
    //     ).format("YYYY/MM/DD");
    //   }
    //   if (data?.insuranceHistory?.progressCancellationDate) {
    //     dataInsuranceHistory.progressCancellationDate = moment(
    //       data?.insuranceHistory?.progressCancellationDate
    //     ).format("YYYY/MM/DD");
    //   }
    //   if (data?.insuranceHistory?.progressTerminationReason) {
    //     dataInsuranceHistory.progressTerminationReason =
    //       data?.insuranceHistory?.progressTerminationReason;
    //   }
    //   if (data?.insuranceHistory?.isClaimed) {
    //     dataInsuranceHistory.isClaimed = data?.insuranceHistory?.isClaimed;
    //   }
    //   if (data?.insuranceHistory?.contractStartDate) {
    //     dataInsuranceHistory.contractStartDate = moment(
    //       data?.insuranceHistory?.contractStartDate
    //     ).format("YYYY/MM/DD");
    //   }
    //   if (data?.insuranceHistory?.isInsuredThePast) {
    //     dataInsuranceHistory.isInsuredThePast =
    //       data?.insuranceHistory?.isInsuredThePast;
    //   }
    //   if (data?.insuranceHistory?.claimDetails) {
    //     dataInsuranceHistory.claimDetails =
    //       data?.insuranceHistory?.claimDetails;
    //   }
    //   if (data?.insuranceHistory?.resumptionDatePast) {
    //     dataInsuranceHistory.resumptionDatePast =
    //       data?.insuranceHistory?.resumptionDatePast;
    //   }

    //   dataContract.insuranceHistory = dataInsuranceHistory;
    // }
    // if (data?.activities) {
    //   dataContract.activities = dataActivities;
    // }

    // yield put(saveInfoContractIntoRedux(dataContract));

    // yield put(getDetailContractSucceeded(data));
    yield put(passInGuaranteeSucceeded("Pass in guarantee succeeded"));
    yield delay(500);
    yield put(clearResponseDraftContract());
  } catch (err: any) {
    yield put(passInGuaranteeFailed("Pass in guarantee failed"));
    yield delay(500);
    yield put(clearResponseDraftContract());
    yield delay(500);
    yield put(clearMessageError());
  }
}
function* watchPassInGuarantee() {
  yield takeLatest(
    CreateContractTypes.PASS_IN_GUARANTEE_REQUESTED,
    passInGuarantee
  );
}

function* draftContractSaga() {
  yield all([
    fork(watchSaveDraftContract),
    fork(watchUpdateDraftContract),
    fork(watchGetDetailDraftContract),
    fork(watchCalculateResult),
    fork(watchArchiveDraftContract),
    fork(watchPassInGuarantee),
  ]);
}

export default draftContractSaga;
