import { ILogin, ILoginParams, IResponseLogin } from "./login.dto";
import { LoginActions, LoginType } from "./login.type";

const initialLoginState: IResponseLogin = {
  data: {} as ILogin,
  loading: false,
  params: {} as ILoginParams,
};

export default function loginReducer(
  state = initialLoginState,
  action: LoginActions
): IResponseLogin {
  switch (action.type) {
    case LoginType.LOGIN_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoginType.LOGIN_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case LoginType.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LoginType.REQUEST_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoginType.REQUEST_EMAIL_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    }
    case LoginType.REQUEST_EMAIL_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LoginType.RESET_PASSWORD_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoginType.RESET_PASSWORD_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    }
    case LoginType.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case LoginType.SET_LOGIN_PARAMS: {
      return {
        ...state,
        params: action.params,
      };
    }
    case LoginType.CLEAR_LOGIN_PARAMS: {
      return {
        ...state,
        params: {} as ILoginParams,
      };
    }

    case LoginType.CLEAR_MESSAGE_ERROR: {
      return {
        ...state,
        error: undefined,
        message: undefined,
      };
    }
    case LoginType.CLEAR_DATA_LOGIN: {
      return {
        ...state,
        data: {} as ILogin,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
