import * as React from "react"

const HumanSitting = () => (
  <svg
    width={157}
    height={192}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.203 141.259c0-13.317-10.795-24.112-24.111-24.112H64.745v61.281a4 4 0 0 0 4 4h71.458a4 4 0 0 0 4-4v-37.169Z"
      fill="#86E5F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.024 169.541H79.055v12.886h57.969v-12.886ZM63.2 181.96l-3.657 9.159H77.22l-3.063-9.159H63.2Z"
      fill="#86E5F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m123.427 181.96-3.657 9.159h17.678l-3.064-9.159h-10.957Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.725 181.96-3.657 9.159h17.678l-3.064-9.159H3.725Z"
      fill="#86E5F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.024 73.713c0-13.113-10.48-23.822-23.589-24.106l-11.141-.242v73.939h34.73V73.713Z"
      fill="#91D6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.249 73.477c0-13.317-10.795-24.112-24.112-24.112H21.84C9.778 49.365 0 59.143 0 71.205v51.859c0 .965.783 1.747 1.748 1.747h90.5V73.477Z"
      fill="#C6F9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.782 62.893c0-7.471-6.057-13.528-13.528-13.528C5.487 49.365.001 54.851.001 61.62v53.912c0 .965.782 1.747 1.747 1.747h24.034V62.893Z"
      fill="#86E5F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.024 136.694c0-6.958-5.641-12.6-12.6-12.6H68.41v49.647h68.614v-37.047Z"
      fill="#C6F9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.813 42.943c-9.21-2.146-9.816-14.795-2.814-19.182C55.778 8.615 72.476-6.423 83.915 2.85c7.498-3.212 13.73 9.321 6.752 19.055 2.926 16.712-3.028 21.033-13.761 21.216l-11.093-.179Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.928 39.922s4.48-1.053 4.635-8.398c.156-7.345.046-9.852.046-9.852l9.869-.178s.107 8.186.109 11.147c.004 7.952 5.542 7.28 5.542 7.28s-1.151 6.449-10.093 6.952c-6.982.393-10.108-6.951-10.108-6.951Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.309 18.004c-4.402-.17-3.684 6.278.889 6.269 0 0 2.656 9.16 11.878 9.16 7.489 0 7.976-10.676 8.044-12.806.073-2.271.082-9.731.082-9.731l-21.064-.01.171 7.118Z"
      fill="url(#b)"
    />
    <path
      d="M79.298 27.28s2.196 1.862 4.74.119"
      stroke="#F77B7B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M80.748 29.435c.683.316 1.341.339 1.928.024"
      stroke="#F49494"
      strokeLinecap="round"
    />
    <path
      d="M68.757 21.472c.046-1.034-.27-1.856-1.324-2.02"
      stroke="#F77B7B"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.613 17.207s2.413 3.24 2.901 3.257c.49.017.66-4.213.66-4.213 2.96-.227 10.451-1.72 12.287-5.196 1.285 3.068 3.74 4.43 5.352 4.169l.306 6.518.753-13.104h-22.85l.59 8.569Z"
      fill="#422851"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.016 169.451s-3.949 7.981-.712 10.496c3.243 2.516 11.807 7.23 11.807 7.23l-22.69-1.427 5.044-17.407 6.551 1.108Z"
      fill="url(#c)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.616 177.047c1.119.131-.076 3.903 2.348 4.19 2.42.286 3.596-3.288 6.342-2.155 2.745 1.133 12.76 6.991 13.154 9.386.399 2.394-3.332 3.099-8.449 2.558-5.116-.541-6.06-2.362-9.901-1.629-3.836.734-6.71-.435-6.584-3.24.127-2.805 1.97-9.241 3.09-9.11Z"
      fill="#222D4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m111.558 179.663-.351.527a1 1 0 0 0 .345 1.428l6.423 3.586c.291.163.643.17.94.019l.935-.474c-1.65-1.196-3-2.095-4.491-2.977a90.09 90.09 0 0 0-3.801-2.109Z"
      fill="#fff"
      fillOpacity={0.541}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.835 99.106c6.87.307 45.103 8.768 52.049 10.206 6.947 1.438 12.819 9.216 7.523 18.223-5.95 10.111-21.3 38.826-25.027 45.299-1.845 3.204-11.151 1.408-8.965-3.97 4.075-10.024 5.968-35.207 16.357-44.389 0 0-34.94 6.084-50.032-.885-10.968-5.066-8.487-25.226 8.095-24.484Z"
      fill="url(#d)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.509 58.46s-.078-5.593 1.877-8.516c1.955-2.922 2.033-6.027 3.513-5.925 1.48.101.014 7.318.014 7.318s1.878-.718 3.227-1.626c1.348-.908 5.438-5.383 6.394-4.59 1.62 1.351-.99 8.973-2.411 10.497-1.427 1.525-9.218 6.537-9.218 6.537l-3.396-3.695Z"
      fill="url(#e)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.294 55.109c3.195 7.416 9.046 21.039 11.458 25.103 3.023 5.09 7.79 7.333 13.371 3.556 5.864-3.968 22.012-19.446 22.485-22.952.47-3.506-3.483-7.712-7.059-5.534s-18.436 14.551-18.436 14.551-5.997-15.382-10.82-24.292c-4.179-7.71-15.863-1.706-11 9.568Z"
      fill="#FFDB5C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.175 69.841s1.611 5.467 1.988 5.15c.376-.316-.288-6.578-.288-6.578l-1.7 1.428Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      d="M145.635 44.054c.456-1.229-.199-2.52-1.461-2.884l-6.036-1.74c-1.263-.363-2.656.338-3.111 1.567l-7.592 20.472c-.456 1.23.198 2.52 1.461 2.884l6.036 1.74c1.263.364 2.655-.338 3.111-1.566l7.592-20.473Z"
      fill="#FFCC4D"
      fillOpacity={0.43}
    />
    <rect
      width={11.137}
      height={26.883}
      rx={2.4}
      transform="matrix(-.9525 -.27451 -.34378 .92707 145.657 41.218)"
      fill="#93C2F9"
    />
    <path
      d="m143.389 41.588-.799-.232c-.163-.047-.35.052-.446.227l-.031.069c-.08.216-.293.348-.476.295l-3.857-1.116c-.163-.047-.247-.225-.208-.415l.021-.072c.079-.216-.004-.434-.188-.487l-.796-.23c-.591-.172-1.274.225-1.573.894l-.045.109-7.457 20.223c-.324.883.019 1.773.767 1.99l6.327 1.831c.716.208 1.544-.277 1.901-1.092l.045-.113v-.002l7.455-20.218c.272-.737-.014-1.48-.64-1.661Z"
      fill="#E6F3FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.358 46.173s7.701 25.8 7.93 30.684c.227 4.885-6.3 19.24-5.188 22.868 2.562 8.384 35.271 11.994 36.521.715.583-5.26-.63-9.98.767-16.179 1.393-6.198 6.99-15.408 3.816-24.068-3.175-8.66-2.324-16.67-4.595-18.185-2.271-1.514-7.58-3.122-7.58-3.122-2.581 3.8-11.296 3.745-15.454-.268 0 0-10.33 2.005-16.217 7.555Z"
      fill="#FFDB5C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.26 50.352c5.132 8.15 5.117 22.342 7.874 24.2 2.757 1.859 13.486.035 13.486 1.507s-14.828 14.96-12.393 21.41c1.274 3.376.929 6.396-1.038 9.061l-.641-.14c-5.843-1.321-10.54-3.693-11.448-6.666-1.11-3.628 6.168-17.983 5.94-22.867-.193-4.146-6.314-23.363-8.16-29.077 1.503-2.653 3.629-1.796 6.38 2.572Z"
      fill="#000"
      fillOpacity={0.199}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.012 71.976s6.486-5.744 8.472-6.41c1.986-.667 13.615-1.468 13.541.039-.083 1.613-6.33 1.52-6.976 2.314-.648.794-.795 6.22-3.241 6.83-2.442.609-9.761 1.659-9.761 1.659l-2.035-4.432Z"
      fill="url(#f)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.756 47.656c-6.833 9.65-12.668 24.35-14.907 30.08-2.24 5.731.02 14.375 9.867 12.678 9.83-1.692 25.47-10.889 27.2-13.572 1.731-2.683-.898-7.564-4.208-6.925-3.067.591-18.281 7.064-18.281 7.064s9.788-17.359 12.178-22.012c3.992-7.772-5.83-15.816-11.849-7.313Z"
      fill="#FFDB5C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.582 76.862s-5.127 2.549-4.501 1.48c.625-1.069 6.457-4.824 6.457-4.824l-1.956 3.344Z"
      fill="#000"
      fillOpacity={0.303}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.877 169.772s-.119 9.394 7.617 12.906c7.731 3.512 13.239 3.3 12.538 6.015-.701 2.715-20.825 2.606-22.704.482-1.602-1.806-5.085-18.626-6.044-17.34l8.593-2.063Z"
      fill="url(#g)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.756 180.287c.864-1.67.918 3.029 4.325 1.993 2.766-.84 3.711-5.649 5.458-4.596 1.748 1.053 17.129 9.22 17.217 9.269 1.893 1.021 1.364 4.113-1.834 4.653-3.554.599-21.97.196-22.958-.752-.988-.948-2.901-9.227-2.208-10.567Z"
      fill="#222D4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.815 180.492c-.244 0-.48.249-.67.538-.298.451-.041 1.027.459 1.233l7.581 3.124c.34.14.745.093.969-.199.127-.166.217-.346.154-.478-.15-.313-7.914-4.218-8.493-4.218Z"
      fill="#fff"
      fillOpacity={0.541}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.558 96.203s-9.987 21.103 4.048 28.35c13.573 7.008 54.205-1.281 54.205-1.281s-.726 5.849 0 11.066c1.897 13.625 10.915 36.324 12.824 39.176 2.638 3.945 10.676.97 10.828-2.314.151-3.284-6.06-45.23-7.004-53.229-.94-7.999-3.906-9.161-12.571-11.348-1.99-.502-25.53-6.355-27.686-6.185-18.528 1.466-34.644-4.235-34.644-4.235Z"
      fill="#2C3757"
    />
    <path
      opacity={0.462}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.506 122.375c13.438 1.506 49.013-1.599 50.442-.188 1.364 1.347 1.136 24.786 4.196 38.352l-.465-1.281c-2.878-7.997-5.866-17.724-6.868-24.92-.726-5.217 0-11.066 0-11.066l-1.638.317c-7.724 1.452-40.623 7.131-52.567.964-6.471-3.342-9.113-8.893-7.923-16.654 2.05 8.772 6.99 13.598 14.823 14.476Z"
      fill="#fff"
      fillOpacity={0.192}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.459 141.259c0-13.317-10.796-24.112-24.112-24.112H0v63.534c0 .965.782 1.747 1.747 1.747H79.46v-41.169Z"
      fill="#86E5F0"
    />
    <defs>
      <linearGradient
        id="a"
        x1={59.352}
        y1={17.723}
        x2={67.718}
        y2={55.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAFAF" />
        <stop offset={1} stopColor="#F07777" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={60.002}
        y1={10.63}
        x2={65.022}
        y2={35.183}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={95.495}
        y1={163.759}
        x2={96.698}
        y2={186.244}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={118.954}
        y1={192.715}
        x2={155.252}
        y2={123.044}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28324F" />
        <stop offset={1} stopColor="#222735" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={122.819}
        y1={39.601}
        x2={124.444}
        y2={61.197}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={63.203}
        y1={61.908}
        x2={63.679}
        y2={75.854}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={121.299}
        y1={164.665}
        x2={122.476}
        y2={189.729}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC6C6" />
        <stop offset={1} stopColor="#FAB2B2" />
      </linearGradient>
    </defs>
  </svg>
)

export default HumanSitting
