import * as React from "react";

const AddUserIcon: React.FC<{ color?: string }> = ({ color = "#BABFC7" }) => (
  <svg width={24} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 0c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5ZM17 19v-2c0-2.8-2.2-5-5-5H5c-2.8 0-5 2.2-5 5v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-1.7 1.3-3 3-3h7c1.7 0 3 1.3 3 3v2c0 .6.4 1 1 1s1-.4 1-1ZM5.5 5c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3-3 1.3-3 3ZM21 8h2c.6 0 1 .4 1 1s-.4 1-1 1h-2v2c0 .6-.4 1-1 1s-1-.4-1-1v-2h-2c-.6 0-1-.4-1-1s.4-1 1-1h2V6c0-.6.4-1 1-1s1 .4 1 1v2Z"
      fill={color}
    />
  </svg>
);

export default AddUserIcon;
