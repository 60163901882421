import {
  IResponseCompanyPappers,
  IResponseSearchCompany,
} from "./searchCompany.dto";
import { SearchCompanyActions, SearchCompanyTypes } from "./searchCompany.type";

const initialSearchCompanyState: IResponseSearchCompany = {
  data: {} as IResponseCompanyPappers,
  loading: false,
  loading_get_detail: false,
};

export default function searchCompanyReducer(
  state = initialSearchCompanyState,
  action: SearchCompanyActions
): IResponseSearchCompany {
  switch (action.type) {
    case SearchCompanyTypes.SEARCH_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case SearchCompanyTypes.SEARCH_COMPANY_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }

    case SearchCompanyTypes.SEARCH_COMPANY_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    case SearchCompanyTypes.CLEAR_DATA_SEARCH: {
      return {
        ...state,
        data: {} as IResponseCompanyPappers,
        error: null,
      };
    }

    case SearchCompanyTypes.GET_DETAIL_COMPANY_REQUESTED: {
      return {
        ...state,
        loading_get_detail: true,
      };
    }

    case SearchCompanyTypes.GET_DETAIL_COMPANY_MONGO_REQUESTED: {
      return {
        ...state,
        loading_get_detail: true,
      };
    }

    case SearchCompanyTypes.GET_DETAIL_COMPANY_SUCCEEDED: {
      return {
        ...state,
        loading_get_detail: false,
        detail_company: action.data,
      };
    }

    case SearchCompanyTypes.GET_DETAIL_COMPANY_FAILED: {
      return {
        ...state,
        loading_get_detail: false,
        error: action?.error,
      };
    }

    case SearchCompanyTypes.CLEAR_DATA_DETAIL_COMPANY: {
      return {
        ...state,
        detail_company: undefined,
        error: undefined,
      };
    }

    default:
      return state;
  }
}
