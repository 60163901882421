import React, { FC } from "react";
import { Radio, styled } from "@mui/material";
import RadioChecked from "@assets/SvgComponents/RadioChecked";

interface RadioCustomProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const RadioCustom: FC<RadioCustomProps> = ({
  checked = false,
  onChange = () => {},
}) => {
  return (
    <RadioStyled
      checkedIcon={
        <p
          style={{
            margin: 0,
            width: 24,
            height: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RadioChecked />
        </p>
      }
      checked={checked}
      onChange={onChange}
    />
  );
};

const RadioStyled = styled(Radio)({
  padding: 0,
  color: "#DFDDE4",
  "& .MuiTouchRipple-root": {
    display: "none",
  },
});

export default RadioCustom;
