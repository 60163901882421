import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import CheckedIcon from "@assets/SvgComponents/CheckedIcon";

type ColorCheckbox =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | "default"
  | undefined;

interface CheckboxProps {
  color?: ColorCheckbox;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxCustom: FC<CheckboxProps> = ({
  color = "primary",
  checked,
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <CheckboxStyled
      disabled={disabled}
      checkedIcon={
        <p
          style={{
            margin: 0,
            width: 24,
            height: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckedIcon />
        </p>
      }
      onChange={onChange}
      checked={checked}
      color={color}
    />
  );
};

const CheckboxStyled = styled(Checkbox)({
  // color: "#DFDDE4",
  color: "#999",
  padding: 0,
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  "&.Mui-disabled": {
    color: "#DFDDE4",
  },
});

export default CheckboxCustom;
