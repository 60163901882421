import { BaseUrl, Endpoint } from "@constants/handleRequest";
import clientRequest from "@utils/axiosRequest";

export function getStatisticService() {
  const apiUrl = BaseUrl.dev + Endpoint.statistic_contract;

  return new Promise((resolve, rejects) => {
    clientRequest({
      method: "GET",
      url: apiUrl,
    })
      .then((res) => resolve(res.data))
      .catch((err) => rejects(err?.response?.data));
  });
}

export function getContractValidatedService(dateRange: string[]) {
  // const apiUrl = BaseUrl.dev + Endpoint.statistic_contract;
  // return new Promise((resolve, rejects) => {
  //   clientRequest({
  //     method: "GET",
  //     params: { dateRange },
  //     url: apiUrl,
  //   })
  //     .then((res) => resolve(res.data))
  //     .catch((err) => rejects(err?.response?.data));
  // });
}

export function getQuoteCreatedService() {
  // const apiUrl = BaseUrl.dev + Endpoint.statistic_contract;
  // return new Promise((resolve, rejects) => {
  //   clientRequest({
  //     method: "GET",
  //     url: apiUrl,
  //   })
  //     .then((res) => resolve(res.data))
  //     .catch((err) => rejects(err?.response?.data));
  // });
}
