import * as React from "react";

const EyeIcon: React.FC<{ color?: string }> = ({ color = "#BABFC7" }) => (
  <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.931.5c6.22 0 9.704 6.833 9.87 7.167.083.166.083.5 0 .666-.166.334-3.65 7.167-9.87 7.167C4.077 15.5.647 9.446.126 8.526a2.854 2.854 0 0 0-.064-.11c-.083-.25-.083-.5 0-.75C.228 7.334 3.712.5 9.932.5ZM1.721 8c.83 1.333 3.732 5.833 8.21 5.833 4.479 0 7.382-4.5 8.211-5.833-.83-1.333-3.732-5.833-8.21-5.833-4.479 0-7.465 4.5-8.211 5.833Zm8.21-3.333c-1.824 0-3.317 1.5-3.317 3.333 0 1.833 1.493 3.333 3.317 3.333 1.825 0 3.318-1.5 3.318-3.333 0-1.833-1.493-3.333-3.318-3.333ZM8.274 8c0 .917.746 1.667 1.659 1.667a1.668 1.668 0 0 0 0-3.334c-.913 0-1.66.75-1.66 1.667Z"
      fill={color}
    />
  </svg>
);

export default EyeIcon;
