import { IResponseFranchise } from "./franchises.dto";
import {
  FranchiseTypes,
  IGetFranchiseFailedAction,
  IGetFranchiseRequestedAction,
  IGetFranchiseSucceededAction,
} from "./franchises.type";

export function getFranchise(
  professionId: string
): IGetFranchiseRequestedAction {
  return {
    type: FranchiseTypes.GET_FRANCHISE_REQUESTED,
    professionId,
  };
}

export function getFranchiseSucceeded(
  data?: IResponseFranchise
): IGetFranchiseSucceededAction {
  return {
    type: FranchiseTypes.GET_FRANCHISE_SUCCEEDED,
    data,
  };
}

export function getFranchiseFailed(error?: any): IGetFranchiseFailedAction {
  return {
    type: FranchiseTypes.GET_FRANCHISE_FAILED,
    error,
  };
}
