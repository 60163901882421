import * as React from "react";

const DuplicateIcon: React.FC<{ color: string }> = ({ color = "#000" }) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.334 10.667c.4 0 .666-.267.666-.667s-.266-.666-.666-.666h-.667c-.4 0-.667-.267-.667-.667v-6c0-.4.267-.667.667-.667h6c.4 0 .667.267.667.667v.667c0 .4.266.666.666.666s.667-.266.667-.666v-.667c0-1.133-.867-2-2-2h-6c-1.133 0-2 .867-2 2v6c0 1.133.867 2 2 2h.667Zm10-5.333h-6c-1.134 0-2 .866-2 2v6c0 1.133.866 2 2 2h6c1.133 0 2-.867 2-2v-6c0-1.134-.867-2-2-2Zm.666 8c0 .4-.266.666-.666.666h-6c-.4 0-.667-.266-.667-.666v-6c0-.4.267-.667.667-.667h6c.4 0 .666.267.666.667v6Z"
      fill={color}
    />
  </svg>
);

export default DuplicateIcon;
