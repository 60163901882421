import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  ICreateUserRequestedAction,
  IDeleteUserRequestedAction,
  IGetDetailUserRequestedAction,
  IGetUsersRequestedAction,
  IUpdateUserRequestedAction,
  UsersTypes,
} from "./users.type";
import {
  clearMessageResponse,
  createUserFailed,
  createUserSucceeded,
  deleteUserFailed,
  deleteUserSucceeded,
  getDetailUserFailed,
  getDetailUserSucceeded,
  getUsersFailed,
  getUsersSucceeded,
  updateUserFailed,
  updateUserSucceeded,
} from "./users.creator";
import { IResponseUsers, IUser } from "./users.dto";
import {
  createUserService,
  deleteUserService,
  getDetailUserService,
  getUsersService,
  updateUserService,
} from "./users.service";

function* getUsers({ filterUsersDto }: IGetUsersRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IResponseUsers> = yield call(
      getUsersService,
      filterUsersDto
    );
    yield put(getUsersSucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(getUsersFailed(err?.message));
    yield put(clearMessageResponse());
  }
}
function* watchGetUsers() {
  yield takeLatest(UsersTypes.GET_USERS_REQUESTED, getUsers);
}

function* getDetailUser({ id }: IGetDetailUserRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IUser> = yield call(
      getDetailUserService,
      id
    );
    yield put(getDetailUserSucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(getDetailUserFailed(err?.message));
    yield put(clearMessageResponse());
  }
}
function* watchGetDetailUser() {
  yield takeLatest(UsersTypes.GET_DETAIL_USER_REQUESTED, getDetailUser);
}

function* deleteUser({ id }: IDeleteUserRequestedAction) {
  try {
    yield call(deleteUserService, id);
    yield put(deleteUserSucceeded("Supprimer l'utilisateur avec succès"));
    yield put(clearMessageResponse());
  } catch (err: any) {
    yield put(deleteUserFailed("La suppression de l'utilisateur a échoué"));
    yield put(clearMessageResponse());
  }
}
function* watchDeleteUser() {
  yield takeLatest(UsersTypes.DELETE_USER_REQUESTED, deleteUser);
}

function* createUser({ user }: ICreateUserRequestedAction) {
  try {
    yield call(createUserService, user);
    yield put(
      createUserSucceeded("Le nouvel utilisateur a été créé avec succès")
    );
    yield put(clearMessageResponse());
  } catch (err: any) {
    yield put(createUserFailed("Impossible de créer un nouvel utilisateur"));
    yield put(clearMessageResponse());
  }
}
function* watchCreateUser() {
  yield takeLatest(UsersTypes.CREATE_USER_REQUESTED, createUser);
}

function* updateUser({ id, user }: IUpdateUserRequestedAction) {
  try {
    yield call(updateUserService, user, id);
    yield put(
      updateUserSucceeded(
        "Mettre à jour les informations utilisateur avec succès"
      )
    );
    yield put(clearMessageResponse());
  } catch (err: any) {
    yield put(
      updateUserFailed(
        "Impossible de mettre à jour les informations utilisateur"
      )
    );
    yield put(clearMessageResponse());
  }
}
function* watchUpdateUser() {
  yield takeLatest(UsersTypes.UPDATE_USER_REQUESTED, updateUser);
}

function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchDeleteUser),
    fork(watchUpdateUser),
    fork(watchCreateUser),
    fork(watchGetDetailUser),
  ]);
}

export default usersSaga;
