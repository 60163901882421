import { ICreateCompanyDto } from "@stores/createNewCompany/createNewCompany.dto";
import {
  ICalculateAmountDto,
  ICreateContractDto,
  ICreateHistoryInsuranceDto,
} from "./contract.dto";

export enum CreateContractTypes {
  CREATE_CONTRACT_REQUESTED = "contract/REQUEST",

  GET_DETAIL_CONTRACT_REQUESTED = "contract/GET_DETAIL_REQUESTED",
  GET_DETAIL_CONTRACT_SUCCEEDED = "contract/GET_DETAIL_SUCCEEDED",
  GET_DETAIL_CONTRACT_FAILED = "contract/GET_DETAIL_FAILED",

  SAVE_INFO_CONTRACT_INTO_REDUX = "contract/SAVE_INTO_REDUX",
  CLEAR_INFO_CONTRACT_REDUX = "contract/CLEAR_INFO_CONTRACT_REDUX",

  SAVE_DRAFT_CONTRACT_REQUESTED = "contract/SAVE_DRAFT_REQUESTED",
  SAVE_DRAFT_CONTRACT_SUCCEEDED = "contract/SAVE_DRAFT_SUCCEEDED",
  SAVE_DRAFT_CONTRACT_FAILED = "contract/SAVE_DRAFT_FAILED",
  CLEAR_RESPONSE_DRAFT_CONTRACT = "contract/CLEAR_DRAFT_CONTRACT",

  INFO_DRAFT_CONTRACT_UPDATE = "contract/INFO_DRAFT_CONTRACT_UPDATE",
  CLEAR_INFO_DRAFT_CONTRACT_UPDATE = "contract/CLEAR_INFO_DRAFT_CONTRACT_UPDATE",

  UPDATE_DRAFT_CONTRACT_REQUESTED = "contract/UPDATE_DRAFT_REQUESTED",
  UPDATE_DRAFT_CONTRACT_SUCCEEDED = "contract/UPDATE_DRAFT_SUCCEEDED",
  UPDATE_DRAFT_CONTRACT_FAILED = "contract/UPDATE_DRAFT_FAILED",

  PASS_IN_GUARANTEE_REQUESTED = "contract/PASS_IN_GUARANTEE_REQUESTED",
  PASS_IN_GUARANTEE_SUCCEEDED = "contract/PASS_IN_GUARANTEE_SUCCEEDED",
  PASS_IN_GUARANTEE_FAILED = "contract/PASS_IN_GUARANTEE_FAILED",

  ARCHIVE_DRAFT_CONTRACT_REQUESTED = "contract/ARCHIVE_DRAFT_REQUESTED",
  ARCHIVE_DRAFT_CONTRACT_SUCCEEDED = "contract/ARCHIVE_DRAFT_SUCCEEDED",
  ARCHIVE_DRAFT_CONTRACT_FAILED = "contract/ARCHIVE_DRAFT_FAILED",

  SAVE_INFORMATION_COMPANY = "contract/SAVE_COMPANY",

  SAVE_HISTORY_INSURANCE = "contract/SAVE_INSURANCE",

  CALCULATE_RESULT_REQUESTED = "contract/CALCULATE_REQUESTED",
  CALCULATE_RESULT_SUCCEEDED = "contract/CALCULATE_SUCCEEDED",
  CALCULATE_RESULT_FAILED = "contract/CALCULATE_FAILED",

  ADD_COMMENTS = "contract/ADD_COMMENTS",
  CLEAR_RESULT_CALCULATE = "contract/CLEAR_RESULT_CALCULATE",

  CLEAR_ALL_DATA_CONTRACT = "contract/CLEAR_ALL_DATA",
  CLEAR_MESSAGE_ERROR = "contract/CLEAR_MESSAGE_ERROR",
}

export interface ICreateContractRequestedAction {
  type: typeof CreateContractTypes.CREATE_CONTRACT_REQUESTED;
}

export interface IGetDetailContractRequestedAction {
  type: typeof CreateContractTypes.GET_DETAIL_CONTRACT_REQUESTED;
  idContract: string;
}

export interface IGetDetailContractSucceededAction {
  type: typeof CreateContractTypes.GET_DETAIL_CONTRACT_SUCCEEDED;
  response?: any;
}

export interface IGetDetailContractFailedAction {
  type: typeof CreateContractTypes.GET_DETAIL_CONTRACT_FAILED;
  error?: any;
}

// handle request sent information of the contract to database
export interface ISaveDraftContractRequestedAction {
  type: typeof CreateContractTypes.SAVE_DRAFT_CONTRACT_REQUESTED;
  information_contract: ICreateContractDto;
}

export interface ISaveDraftContractSucceededAction {
  type: typeof CreateContractTypes.SAVE_DRAFT_CONTRACT_SUCCEEDED;
  response?: any;
  message?: any;
}

export interface ISaveDraftContractFailedAction {
  type: typeof CreateContractTypes.SAVE_DRAFT_CONTRACT_FAILED;
  error?: any;
}

// handle save information of the contract into redux
export interface ISaveInfoContractIntoRedux {
  type: typeof CreateContractTypes.SAVE_INFO_CONTRACT_INTO_REDUX;
  info_contract: ICreateContractDto;
}

export interface IClearInfoContractRedux {
  type: typeof CreateContractTypes.CLEAR_INFO_CONTRACT_REDUX;
}

export interface IClearResponseDraftContractAction {
  type: typeof CreateContractTypes.CLEAR_RESPONSE_DRAFT_CONTRACT;
}

export interface IUpdateDraftContractRequestedAction {
  type: typeof CreateContractTypes.UPDATE_DRAFT_CONTRACT_REQUESTED;
  information_contract: ICreateContractDto;
  id_contract: string;
}

export interface IUpdateDraftContractSucceededAction {
  type: typeof CreateContractTypes.UPDATE_DRAFT_CONTRACT_SUCCEEDED;
  response?: any;
}

export interface IUpdateDraftContractFailedAction {
  type: typeof CreateContractTypes.UPDATE_DRAFT_CONTRACT_FAILED;
  error?: any;
}

export interface IPassInGuaranteeRequestedAction {
  type: typeof CreateContractTypes.PASS_IN_GUARANTEE_REQUESTED;
  id_contract: string;
  isPass?: boolean;
}
export interface IPassInGuaranteeSucceededAction {
  type: typeof CreateContractTypes.PASS_IN_GUARANTEE_SUCCEEDED;
  response?: any;
}
export interface IPassInGuaranteeFailedAction {
  type: typeof CreateContractTypes.PASS_IN_GUARANTEE_FAILED;
  error?: any;
}

export interface IArchiveDraftContractRequestedAction {
  type: typeof CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_REQUESTED;
  id_contract: string;
}

export interface IArchiveDraftContractSucceededAction {
  type: typeof CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_SUCCEEDED;
  message?: string;
}

export interface IArchiveDraftContractFailedAction {
  type: typeof CreateContractTypes.ARCHIVE_DRAFT_CONTRACT_FAILED;
  error?: any;
}

export interface IInfoDraftContractUpdate {
  type: typeof CreateContractTypes.INFO_DRAFT_CONTRACT_UPDATE;
  idContract: string;
}

export interface IClearInfoDraftContractUpdate {
  type: typeof CreateContractTypes.CLEAR_INFO_DRAFT_CONTRACT_UPDATE;
}

export interface ISaveInformationCompanyAction {
  type: typeof CreateContractTypes.SAVE_INFORMATION_COMPANY;
  informationCompany: ICreateCompanyDto;
}

export interface ICalculateResultRequestedAction {
  type: typeof CreateContractTypes.CALCULATE_RESULT_REQUESTED;
  data: ICalculateAmountDto;
}

export interface ICalculateResultSucceededAction {
  type: typeof CreateContractTypes.CALCULATE_RESULT_SUCCEEDED;
  response?: any;
}

export interface ICalculateResultFailedAction {
  type: typeof CreateContractTypes.CALCULATE_RESULT_FAILED;
  error?: any;
}

export interface ISaveHistoryInsurance {
  type: typeof CreateContractTypes.SAVE_HISTORY_INSURANCE;
  historyInsurance: ICreateHistoryInsuranceDto;
}

export interface IAddCommentsAction {
  type: typeof CreateContractTypes.ADD_COMMENTS;
  comments: string;
}

export interface IClearResultCalculateAction {
  type: typeof CreateContractTypes.CLEAR_RESULT_CALCULATE;
}

export interface IClearAllDataContractAction {
  type: typeof CreateContractTypes.CLEAR_ALL_DATA_CONTRACT;
}
export interface IClearMessageErrorAction {
  type: typeof CreateContractTypes.CLEAR_MESSAGE_ERROR;
}

export type CreateContractActions =
  | ICreateContractRequestedAction
  | ISaveInformationCompanyAction
  | ISaveHistoryInsurance
  | ISaveDraftContractRequestedAction
  | ISaveDraftContractSucceededAction
  | ISaveDraftContractFailedAction
  | ISaveInfoContractIntoRedux
  | IClearInfoContractRedux
  | IClearResponseDraftContractAction
  | IUpdateDraftContractRequestedAction
  | IUpdateDraftContractSucceededAction
  | IUpdateDraftContractFailedAction
  | IPassInGuaranteeRequestedAction
  | IPassInGuaranteeSucceededAction
  | IPassInGuaranteeFailedAction
  | IArchiveDraftContractRequestedAction
  | IArchiveDraftContractSucceededAction
  | IArchiveDraftContractFailedAction
  | IInfoDraftContractUpdate
  | IClearInfoDraftContractUpdate
  | IGetDetailContractRequestedAction
  | IGetDetailContractSucceededAction
  | IGetDetailContractFailedAction
  | ICalculateResultRequestedAction
  | ICalculateResultSucceededAction
  | ICalculateResultFailedAction
  | IAddCommentsAction
  | IClearResultCalculateAction
  | IClearAllDataContractAction
  | IClearMessageErrorAction;
