import { Font, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage14Props {
  isPIB?: boolean;
}

const ContentPage14 = ({ isPIB = true }: IContentPage14Props) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: "#5E5873",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          DOCUMENT PRECONTRACTUEL D’INFORMATION ET DE CONSEIL
        </Text>
      </View>
      <View>
        <Text style={generatingResultPDFStyles.mainContent}>
          Le présent document est établi préalablement à la conclusion d’un
          contrat d’assurance. Il nous permet de vous communiquer les
          informations réglementaires inhérentes à notre qualité d’intermédiaire
          en assurances, la nature de notre intervention et de préciser vos
          exigences et vos besoins à partir des éléments d’information que vous
          nous avez communiqués afin de vous accompagner dans le choix de vos
          contrats d’assurance.
        </Text>
      </View>
      <View style={{ margin: "20px 0 15px 0" }}>
        <Text style={styles.titleBoxSubContent}>
          Communication d’informations précontractuelles
        </Text>
        <View style={generatingResultPDFStyles.underlineTitleBoxContent} />
      </View>
      <View>
        <Text style={styles.mainTitle}>Présentation de MADECENNALE</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          MADECENNALE, dont le siège social se trouve 1047 route des Dolines,
          Business Pôle Bat A, 06560 Valbonne, est un intermédiaire en
          assurance, immatriculé au registre du commerce et des sociétés sous le
          numéro 829 863 760 00015 et enregistré à l’ORIAS en qualité de
          courtier en assurance sous le numéro : 15003712 (
          <Link src="www.orias.fr">www.orias.fr</Link>).
        </Text>
        <View style={{ height: 12 }} />
        <Text style={generatingResultPDFStyles.mainContent}>
          L’expertise en matière d’assurance RC Décennale est la raison d’être
          de notre société. Nous identifions précisément vos besoins afin de
          vous proposer une offre sur mesure. Nous vous apportons notre
          connaissance et notre expertise métier de l’assurance mais nous nous
          efforçons aussi de connaitre vos métiers. Pour cela nous sommes
          présents lors des manifestations organisées par la CAPEB, nous sommes
          abonnés à la base de données des DTU (documents techniques unifiés),
          nous échangeons régulièrement avec le CSTB.... Nous suivons aussi la
          jurisprudence des cours de cassation en matière de sinistralité pour
          l’assurance construction et nous vous tenons informés des évolutions
          en la matière afin que vous soyez protégé au mieux.
        </Text>
        <View style={{ height: 12 }} />
        <Text style={generatingResultPDFStyles.mainContent}>
          Relation client: Nous créons avec vous une relation personnalisée et
          privilégiée. Un conseiller EXPERT vous est dédié afin de vous
          accompagner dans vos démarches : souscription de contrat, avenants
          (ajout d’activité), nominatives de chantier, sinistres, et ce quelque
          soit la compagnie choisie. Ainsi nous développons une véritable
          relation humaine, à votre écoute par téléphone à tout moment. Nous
          nous engageons à vous apporter une réponse sous 24H
        </Text>
      </View>
      <View style={{ height: 15 }} />
      <View>
        <Text style={styles.mainTitle}>Nature de nos interventions</Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Pour l’exercice de son activité, MADECENNALE n’est lié par aucun
          accord d’exclusivité avec un ou plusieurs assureurs.
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Notre analyse porte cependant uniquement sur les contrats d’assurances
          de nos partenaires assureurs et non sur une analyse exhaustive de
          l’ensemble des contrats proposés par le marché.
        </Text>
      </View>
      <View style={{ height: 15 }} />
      <View>
        <Text style={styles.mainTitle}>
          {isPIB ? "Rémunération" : "Nature de nos interventions"}
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Pour la distribution des contrats d’assurance qu’il distribue,
          MADECENNALE est rémunéré sur la base d’une commission, c’est-à-dire
          une rémunération incluse dans la prime d’assurance du contrat.
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Sur simple demande le montant de cette commission peut vous être
          communiqué pour chaque offre.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  mainTitle: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: "underline",
    color: Colors.primary,
    marginBottom: 8,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.8,
  },
  titleBoxSubContent: {
    color: "#6E6B7B",
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  underlineTitleBoxContent: {
    width: "69px",
    height: "4px",
    backgroundColor: "#FFCC4D",
    borderRadius: 5,
    marginTop: 4,
  },
});

export default ContentPage14;
