import Artisans_type from "@assets/Images/Artisans_type.png";
import Pib_type from "@assets/Images/Pib_type.png";
import IconBack from "@assets/SvgComponents/IconBack";
import { PaperShadowCustom } from "@components/PaperCustom";
import Spacing from "@components/Spacing";
import { Box, Divider, Grid, styled, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { saveSelectedType } from "@stores/createNewCompany/createNewCompany.creator";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { Colors } from "@themes/colors";
import { TextStyles } from "@themes/textStyles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SelectType = () => {
  const classes = useStyles();
  const textStyles = TextStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateToContract = (selectType: ContractType) => {
    dispatch(saveSelectedType(selectType));
    navigate("/search-company");
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" alignItems="center">
        <Box component="div" display="flex" alignItems="center">
          <span style={{ display: "flex", transform: "scale(0.8)" }}>
            <IconBack color={Colors.secondary} />
          </span>
          <Spacing width={10} />
          <Typography
            className={textStyles.underline}
            style={{ color: "#5E5873" }}
          >
            Retour
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
        <PaperShadowCustom
          sx={{ pt: 7, px: 6, pb: 21 }}
          style={{ position: "relative" }}
        >
          <Typography className={textStyles.h3}>
            Pour quelle société ce devis s’adresse-t-il ?
          </Typography>
          <Grid
            display="flex"
            justifyContent="center"
            sx={{ mt: 2 }}
            style={{ minHeight: "30vh" }}
            container
            className={classes.containerActions}
          >
            <Grid
              item
              xs={5.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                className={classes.boxSelectType}
                onClick={() => handleNavigateToContract(ContractType.PIB)}
              >
                <p className={classes.logo}>
                  <img width="100%" height="100%" src={Pib_type} alt="PIB" />
                </p>
                <Spacing height={5} />
                <Typography className={textStyles.h6} textAlign="center">
                  Professions intellectuelles du bâtiment
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={1} sm={1} md={1} xl={1} lg={1}>
              <DividerStyled
                orientation="vertical"
                flexItem
                style={{ height: "100%" }}
              >
                <Typography
                  className={textStyles.h4}
                  style={{ color: "#979797" }}
                >
                  OU
                </Typography>
              </DividerStyled>
            </Grid>

            <Grid
              item
              xs={5.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                className={classes.boxSelectType}
                onClick={() =>
                  handleNavigateToContract(ContractType.Artisans_BTP)
                }
              >
                <p className={classes.logo}>
                  <img
                    width="100%"
                    height="100%"
                    src={Artisans_type}
                    alt="Artisans"
                  />
                </p>
                <Spacing height={5} />
                <Typography className={textStyles.h6} textAlign="center">
                  Artisans
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </PaperShadowCustom>
      </Grid>
    </Grid>
  );
};

const DividerStyled = styled(Divider)({
  "&::before": {
    borderLeft: "1px solid #FFDB5C",
  },
  "&::after": {
    borderLeft: "1px solid #FFDB5C",
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  boxSelectType: {
    border: "1px solid #B9B9C3",
    borderRadius: 6,
    width: 235,
    height: 235,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
    textDecoration: "none",
    cursor: "pointer",
  },
  containerActions: {
    paddingLeft: 144,
    paddingRight: 144,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  logo: {
    width: "190px",
    margin: 0,
  },
}));

export default SelectType;
