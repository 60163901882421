import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextStyles } from "@themes/textStyles";
import React, { FC } from "react";
import { BoxProps } from "@mui/material/Box";

interface IStatusContract extends BoxProps {
  title: string;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const StatusContract: FC<IStatusContract> = ({
  title,
  color = "#000",
  onClick = () => {},
  disabled = false,
  ...props
}) => {
  const textStyles = TextStyles();
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      style={{
        pointerEvents: disabled ? "none" : "auto",
      }}
      {...props}
      onClick={onClick}
    >
      <Typography
        sx={{ color: `${color} !important` }}
        className={textStyles.paragraph_small_semi_bold}
      >
        {title}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    margin: 0,
    display: "flex",
    width: "fit-content",
    padding: "2px 8px",
    borderRadius: "15px",
    cursor: "pointer",
  },
});

export default StatusContract;
