import React from "react";
import BackgroundLoading from "@assets/SvgComponents/BackgroundLoading";
import { SyncLoader } from "react-spinners";
import { TextStyles } from "@themes/textStyles";

const LoadingPage = () => {
  const textStyles = TextStyles();
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#D0F1F7",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SyncLoader margin={5} color="rgb(21, 84, 120)" size={12} />
        <span style={{ width: "1rem" }} />
        <p
          className={textStyles.paragraph_semi_bold}
          style={{ color: "rgb(21, 84, 120)" }}
        >
          Chargement en cours? S'il vous plaît, attendez...
        </p>
      </div>
      <BackgroundLoading />
      <span style={{ height: "1rem" }} />
    </div>
  );
};

export default LoadingPage;
