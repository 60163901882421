import PaperIcon from "@assets/SvgComponents/PaperIcon";
import { Box, CircularProgress } from "@mui/material";
import { IActivitiesSelected } from "@pages/Contract/CalculateResult";
import { dataLegalStatus } from "@pages/Contract/InformationCompany";
import { dataReason } from "@pages/Contract/InsuranceHistory";
import { IdProfessionType } from "@stores/activitiesOfContract/activitiesOfContract.dto";
import {
  clearAllDataContract,
  getDetailContract,
} from "@stores/contract/contract.creator";
import { IInfoContractPDF } from "@stores/contract/contract.dto";
import { ContractType } from "@stores/listQuotes/listQuotes.dto";
import { clearAllActivities } from "@stores/savedActivities/savedActivities.creator";
import {
  clearDataSearchCompany,
  clearDetailCompany,
} from "@stores/searchCompany/searchCompany.creator";
import { IAppState } from "@stores/state";
import {
  downloadAttestationPDF,
  downloadConditionPDF,
  downloadGeneratingQuestionPDF,
  downloadGeneratingResultPDF,
} from "@utils/downloadDocuments";
import { notistack } from "@utils/notistack";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
interface IDownloadDocumentProps {
  contract_id?: string;
  type: number;
  quoteType: ContractType;
  setIdProfession: Dispatch<SetStateAction<IdProfessionType | undefined>>;
  onRefresh?: () => void;
  isDisabled?: boolean;
}

const DownloadDocument: FC<IDownloadDocumentProps> = ({
  contract_id,
  type = 0,
  quoteType,
  setIdProfession,
  onRefresh = () => {},
  isDisabled = false,
}) => {
  const { activities } = useSelector(
    (state: IAppState) => state.dataActivities
  );
  const activitiesRedux = useSelector(
    (state: IAppState) => state.activitiesRedux.activities
  );
  const { data_contract_update, loadingGet, error } = useSelector(
    (state: IAppState) => state.dataContract
  );

  const [dataPDF, setDataPDF] = useState<any>(null);
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataActivities, setDataActivities] = useState<IActivitiesSelected[]>(
    []
  );
  const [dataPDFQuestion, setDataPDFQuestion] = useState<any>(null);
  const [qrCode, setQrCode] = useState<any>();

  const dispatch = useDispatch();

  const qrCodeCanvas = document.querySelector("canvas");

  const isPIB = data_contract_update?.type === ContractType.PIB;
  const companyName = data_contract_update?.company?.name?.replaceAll(" ", "_");

  const resetState = () => {
    setIsDownload(false);
    setLoading(false);
  };

  const clearReduxData = () => {
    dispatch(clearAllDataContract());
    dispatch(clearAllActivities());
    dispatch(clearDetailCompany());
    dispatch(clearDataSearchCompany());
  };

  // Because activities(activities.data) can be old (activities of other product type)
  // so we need check it before generate documents
  const checkIsCorrectActivitiesOfProductType = useCallback(() => {
    return activities.data.some(
      (activity) =>
        activity._id === data_contract_update.activities?.[0]?.activityId
    );
  }, [activities?.data, data_contract_update?.activities]);

  const handleDownloadConditionPDF = async (
    infoInsurance: IInfoContractPDF
  ) => {
    await downloadConditionPDF({
      infoInsurance,
      companyName,
      contractId: contract_id,
      isPIB,
    });

    onRefresh();

    setTimeout(() => {
      clearReduxData();
      resetState();
      setDataPDF(null);
    }, 2000);
  };

  const handleDownloadAttestationPDF = async (
    infoInsurance: IInfoContractPDF
  ) => {
    await downloadAttestationPDF({
      infoInsurance,
      companyName,
      contractId: contract_id,
      isPIB,
      qrCode,
    });
  };

  const handleDownloadGeneratingResultPDF = async (
    infoInsurance: IInfoContractPDF
  ) => {
    try {
      const maxAmountActivitiesToSelectEDBType = 12;

      // Only apply with EDB type, max amount of PIB is 4
      if (
        (infoInsurance?.dataActivities?.length ?? 0) >
        maxAmountActivitiesToSelectEDBType
      ) {
        notistack.error(
          "Impossible de générer un devis car le nombre d'activités est dépassé"
        );
      } else {
        const price = Math.floor(
          infoInsurance?.NETCompanyAvecCom ?? 0
        ).toFixed();
        const franchies = Math.floor(
          infoInsurance?.franchise_du_contrat ?? 0
        ).toFixed();

        await downloadGeneratingResultPDF({
          infoInsurance,
          companyName,
          price,
          franchies,
          contractId: contract_id,
          isPIB,
        });

        onRefresh();
      }

      setTimeout(() => {
        clearReduxData();
        resetState();
        setDataPDF(null);
      }, 2000);
    } catch (err) {
      onRefresh();
      console.log("error", err);
      clearReduxData();
      resetState();
      setDataPDF(null);
    }
  };

  const handleDownloadGeneratingQuestionPDF = async (
    info_contract: any,
    reason?: string
  ) => {
    try {
      await downloadGeneratingQuestionPDF({
        companyName,
        contract: info_contract,
        reason,
        activities: dataActivities,
        company: data_contract_update?.company,
      });

      onRefresh();
      setDataPDFQuestion(null);

      setTimeout(() => {
        clearReduxData();
        resetState();
      }, 2000);
    } catch {
      onRefresh();
      clearReduxData();
      resetState();
      setDataPDFQuestion(null);
    }
  };

  const handleGetDetail = () => {
    if (!contract_id) return;

    clearReduxData();
    dispatch(getDetailContract(contract_id));

    const newIdPro =
      quoteType === ContractType.PIB
        ? IdProfessionType.PIB
        : IdProfessionType.Artisans_BTP;

    setIdProfession(newIdPro);

    setIsDownload(true);

    setDataPDF(null);
    setDataPDFQuestion(null);
  };

  useEffect(() => {
    if (
      data_contract_update &&
      activities.data &&
      checkIsCorrectActivitiesOfProductType()
    ) {
      const legalStatus = dataLegalStatus.find(
        (item) => item.value === data_contract_update?.company?.legalStatus
      );

      let newActivities: Array<IActivitiesSelected> = [];
      for (let i = 0; i < activities.data?.length; i++) {
        for (let j = 0; j < data_contract_update.activities.length; j++) {
          if (
            activities.data[i]._id ===
            data_contract_update.activities[j].activityId
          ) {
            const indefOfHaveExclusion =
              activities.data[i].definition?.indexOf("Exclusion");

            if (indefOfHaveExclusion && indefOfHaveExclusion !== -1) {
              // length of Exclusion to the fist HTML tag of this is 69

              const exclusionActivity = activities.data[i].definition?.slice(
                indefOfHaveExclusion - 69
              );

              const definitionActivity = activities.data[i].definition?.slice(
                0,
                indefOfHaveExclusion - 69
              );

              newActivities.push({
                ...activities.data[i],
                definition: definitionActivity,
                exclusion: exclusionActivity,
                percent: data_contract_update.activities[j].percent,
              });
            } else {
              newActivities.push({
                ...activities.data[i],
                percent: data_contract_update.activities[j].percent,
              });
            }
          }
        }
      }
      setDataActivities(newActivities);
      const reason = dataReason.find(
        (item) =>
          item.value ===
          data_contract_update?.insuranceHistory?.progressTerminationReason
      );
      let taux_de_revision_du_contrat: number = 0;
      if (
        data_contract_update?.pricingByFranchise?.NETCompanyAvecCom &&
        data_contract_update?.company?.companyDetails?.revenue
      ) {
        taux_de_revision_du_contrat =
          data_contract_update?.pricingByFranchise.NETCompanyAvecCom /
          data_contract_update?.company.companyDetails.revenue;
      }

      if (type === 2 || type === 3) {
        const dataInsurance: IInfoContractPDF = {
          contract_id: data_contract_update?.contract_id,
          contract_code: data_contract_update?.contract_code
            ? `BMK-${data_contract_update.contract_code}-A`
            : undefined,
          NETCompanyAvecCom: parseFloat(
            data_contract_update?.pricingByFranchise?.NETCompanyAvecCom?.toFixed(
              2
            ) || "0"
          ),
          fractionnement: data_contract_update?.contractOption?.period,
          frais_de_souscription:
            data_contract_update?.contractOption?.subscriptionFee,
          franchise_du_contrat:
            data_contract_update?.contractOption?.franchise?.money ?? 0,
          taux_de_revision_du_contrat: parseFloat(
            (taux_de_revision_du_contrat * 100).toFixed(2)
          ),
          assureur: data_contract_update?.company?.name,
          assureur_DPRSA_PJ: "Groupama protection juridique",
          courtier: "Madecennale",
          informations_du_souscripteur_1: {
            souscripteur: data_contract_update?.company?.name,
            forme_juridique: legalStatus?.label,
            phone:
              data_contract_update?.company?.legalRepresentative?.phoneNumber,
            email: data_contract_update?.company?.legalRepresentative?.email,
            dimmatriculation: data_contract_update?.company?.siren,
            date_creation: moment(
              data_contract_update?.company?.birthday
            ).format("DD/MM/YYYY"),
            effectif: data_contract_update?.company?.companyDetails?.workforce,
            chiffre_HT:
              data_contract_update?.company?.companyDetails?.revenue?.toString(),
            dirigeant: data_contract_update?.company?.legalRepresentative?.name,
            address: data_contract_update?.company?.headquarterAddress,
            siren: data_contract_update?.company?.siren,
            city: data_contract_update?.company?.city,
            codePostal: data_contract_update?.company?.codePostal,
          },
          activities: newActivities,
          informations_du_souscripteur_2: {
            hasRCD: data_contract_update?.insuranceHistory?.hasBeenRCD
              ? "Oui"
              : "Non",
            contrat_en_cours: data_contract_update?.insuranceHistory
              ?.isInProgress
              ? "Oui"
              : "Non",
            date_de_resiliation:
              !data_contract_update?.insuranceHistory?.isInProgress &&
              data_contract_update?.insuranceHistory?.progressCancellationDate
                ? moment(
                    data_contract_update?.insuranceHistory
                      ?.progressCancellationDate
                  ).format("DD/MM/YYYY")
                : "",
            assure_lors_des_24_derniers_mois:
              data_contract_update?.insuranceHistory?.claimDetails?.reduce(
                (acc: any, current: any) => acc + current.numberOfClaims,
                0
              ),
            pour_un_montant_de:
              data_contract_update?.insuranceHistory?.claimDetails?.reduce(
                (acc: any, current: any) => acc + current.amount,
                0
              ),
            esilie_non_paiement:
              reason?.label === "Non paiement de prime" ? "Oui" : "Non",
            resilie_pour_fausse_declaration:
              reason?.label === "Fausse déclaration" ? "Oui" : "Non",
            de_sous_traitance:
              data_contract_update?.company?.companyDetails
                ?.percentEntrustToSubContractors &&
              data_contract_update?.company?.companyDetails
                ?.percentEntrustToSubContractors > 30
                ? "Oui"
                : "Non",
            en_redressement_judiciaire: data_contract_update?.isInReceivership
              ? "Oui"
              : "Non",
            reprise_du_passe: data_contract_update?.insuranceHistory
              ?.isInsuredThePast
              ? "Oui"
              : "Non",
          },
          page6: {
            amount_franchise_2:
              data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
            amount_franchise_3:
              data_contract_update?.pricingByFranchise?.NETCompanyAvecCom,
          },
          pricingByPeriod:
            data_contract_update?.pricingByFranchise?.pricingByPeriod,
          cotisations: {
            JURY_LAW: data_contract_update?.pricingByFranchise?.JURY_LAW || 0,
            PJ_GROUPAMA:
              data_contract_update?.pricingByFranchise?.PJ_GROUPAMA || 0,
            fractionement:
              data_contract_update?.pricingByFranchise?.fractionement,
            fraisInclus5LPP:
              data_contract_update?.pricingByFranchise?.fraisInclus5LPP || 0,
            primeCODE: data_contract_update?.pricingByFranchise?.primeCode || 0,
            primeNetHT: data_contract_update?.pricingByFranchise?.primeNetHT,
            totalMAJO_MINO:
              data_contract_update?.pricingByFranchise?.totalMAJO_MINO,
          },
          contractStartDate: data_contract_update?.insuranceHistory
            ?.contractStartDate
            ? data_contract_update.insuranceHistory.contractStartDate
            : "",
          resumptionDatePast: data_contract_update?.insuranceHistory
            ?.resumptionDatePast
            ? data_contract_update.insuranceHistory.resumptionDatePast
            : "",
          dataActivities: newActivities,
          date_decheance: data_contract_update?.insuranceHistory
            ?.contractStartDate
            ? moment(data_contract_update?.insuranceHistory?.contractStartDate)
                .format("LL")
                .slice(0, -5)
            : "",
          workforce: data_contract_update?.company?.companyDetails?.workforce,
        };
        setDataPDF(dataInsurance);
      }

      if (type === 1) {
        const data = {
          reason: reason?.label,
          detail_company: data_contract_update?.company,
          info_contract: {
            activities: activitiesRedux,
            insuranceHistory: data_contract_update?.insuranceHistory,
            isInReceivership: data_contract_update?.isInReceivership,
          },
        };
        setDataPDFQuestion(data);
      }
    }
  }, [
    data_contract_update,
    activities,
    type,
    activitiesRedux,
    checkIsCorrectActivitiesOfProductType,
  ]);

  useEffect(() => {
    setLoading(!!loadingGet);

    if (!isDownload || loadingGet) return;

    if (dataPDF) {
      if (type === 2) {
        handleDownloadGeneratingResultPDF(dataPDF);
      }

      if (type === 3 && dataPDF?.contract_code) {
        handleDownloadAttestationPDF(dataPDF);
        handleDownloadConditionPDF(dataPDF);
      }
    }

    if (dataPDFQuestion && type === 1) {
      handleDownloadGeneratingQuestionPDF(
        dataPDFQuestion?.info_contract,
        dataPDFQuestion?.reason
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPDF, dataPDFQuestion, isDownload, loadingGet, type]);

  useEffect(() => {
    if (!qrCodeCanvas) return;

    const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
    setQrCode(qrCodeDataUri);
  }, [qrCodeCanvas]);

  useEffect(() => {
    if (!error) return;

    notistack.error(error);
    clearReduxData();
    resetState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Box
        component="p"
        sx={{
          m: 0,
          transform: "scale(1.5)",
          ml: 2,
          cursor: isDisabled ? "auto" : "pointer",
        }}
        display="flex"
        onClick={() => (isDisabled ? null : handleGetDetail())}
      >
        {loading && isDownload ? (
          <CircularProgress size={14} style={{ color: "#6E6B7B" }} />
        ) : (
          <PaperIcon color={isDisabled ? "#B9B9C3" : "#5E5873"} />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          opacity: 0,
          visibility: "hidden",
        }}
      >
        <QRCodeCanvas
          value={`https://attestations.madecennale.com/?id=${contract_id}`}
        />
      </Box>
    </>
  );
};

export default DownloadDocument;
