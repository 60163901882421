import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import formatMoney from "@utils/formatMoney";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});
interface IContentPage10Props {
  marchePrice: number;
  isPIB?: boolean;
}

const ContentPage10 = ({ marchePrice, isPIB = true }: IContentPage10Props) => {
  return (
    <View break>
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Lois applicables
        </Text>
      </View>
      <View style={{ marginTop: 8, marginBottom: 30 }}>
        <Text style={styles.mainText}>
          Tout litige concernant l’interprétation ou l’exécution du contrat sera
          soumis aux règles et principes du droit français et au Code des
          assurances, et relève de la seule compétence des tribunaux français.
          Il est précisé que seule la société d’Assurance Mutuelle Optim
          Assurance porte le risque et la responsabilité contractuelle
          d'indemnisation en cas de sinistre. En cas de défaillance ou défaut
          seule la compagnie ou ses ré assureurs pourraient être recherchés en
          paiement. Madecennale a un statut de courtier en assurance uniquement.
        </Text>
        <Text style={styles.mainText}>
          Il n'intervient en aucun cas en tant que compagnie ou dans la présente
          proposition en conseil ou en négociation contractuelle avec le client
          final souscripteur.
        </Text>
      </View>

      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Correspondance
        </Text>
      </View>
      <View
        style={{ borderRadius: 5, backgroundColor: "#FFF9E8", padding: "15px" }}
      >
        <Text style={styles.mainText}>
          Toute correspondance devra être adressée dans un premier temps au
          courtier, et si cela est nécessaire directement à :
        </Text>
        <Text
          style={{
            fontSize: 9,
            fontFamily: "Montserrat",
            color: "#5E5873",
            fontWeight: 500,
            lineHeight: 1.7,
            marginTop: 10,
          }}
        >
          MADECENNALE
        </Text>
        <Text style={styles.mainText}>
          1047 Route des Dolines, Business Pôle Bat A, 06560 Valbonne
        </Text>
        <Text style={styles.mainText}>Par mail : info@madecennale.com</Text>
      </View>

      <View style={{ height: 30 }} />
      <View style={generatingResultPDFStyles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          {isPIB ? "Obligations du souscripteur" : "Obligations de l’assuré"}
        </Text>
      </View>

      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 9,
            textDecoration: "underline",
            color: Colors.primary,
            marginBottom: 8,
          }}
        >
          Le souscripteur a été informé qu’il doit :{" "}
        </Text>

        {isPIB ? (
          <View>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                transmettre chaque année et au plus tard dans un délai de 4 mois
                suivant la date d'échéance du contrat, les informations et
                éléments variables nécessaires au calcul de la prime dans le
                cadre de l’ajustement de la police, conformément au formulaire
                transmis par la société Leader Assurances.
              </Text>
            </View>
            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                déclarer à l’Assureur dès qu’il en a connaissance et au plus
                tard dans les 15 jours, tout nouvel élément qui viendrait
                modifier les réponses apportées à la souscription.
              </Text>
            </View>
            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                Ne pas renoncer à un recours contre quiconque. Une telle
                renonciation ne serait pas opposable à l’Assureur.
              </Text>
            </View>
          </View>
        ) : null}

        {!isPIB ? (
          <View>
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                L’Assuré a été informé qu’il doit :
              </Text>
            </View>

            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                {" "}
                disposer d’un code NAF conforme aux activités déclarées au
                présent contrat;
              </Text>
            </View>

            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                {" "}
                informer l'Assureur si son marché est supérieur à{" "}
                {isPIB ? "5.000.000" : formatMoney(marchePrice)} €;
              </Text>
            </View>

            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                {" "}
                informer l’Assureur, sous quinze jours, de toute modification du
                risque en cours de contrat conduisant à rendre inexactes les
                réponses faites aux questions posées (art. L.113-2 du Code des
                assurances), sous réserve des sanctions prévues (art. L.113-8 et
                L.113-9 du Code des assurances). Il s’agit notamment des
                changements relatifs à son activité, à une modification de son
                effectif ainsi qu’au dépassement éventuel de son chiffre
                d'affaires déclaré.
              </Text>
            </View>

            <View style={{ height: 10 }} />
            <View style={generatingResultPDFStyles.li}>
              <View style={generatingResultPDFStyles.dotLi} />
              <Text style={generatingResultPDFStyles.mainContentBold}>
                Satisfaire aux consignes de sécurité édictées à l’Annexe
                PREVENTION
              </Text>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
});

export default ContentPage10;
