import React from "react";
import { FC } from "react";
import Switch from "@components/SwitchCustom";
import { UseSwitchProps } from "@mui/base/SwitchUnstyled";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { TextStyles } from "@themes/textStyles";

export interface ISwitchWithLabelProps extends UseSwitchProps {
  inactiveLabel?: string;
  activeLabel?: string;
  LeftTypographyLabel?: TypographyProps;
  RightTypographyLabel?: TypographyProps;
}

const SwitchWithLabel: FC<ISwitchWithLabelProps> = ({
  inactiveLabel = "",
  activeLabel = "",
  LeftTypographyLabel = {},
  RightTypographyLabel = {},
  ...props
}) => {
  const textStyles = TextStyles();
  return (
    <Stack
      sx={{ display: "inline-flex" }}
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent={"space-between"}
    >
      <Typography
        className={textStyles.paragraph_small_500}
        {...LeftTypographyLabel}
      >
        {inactiveLabel || "Non"}
      </Typography>
      <Switch {...props} />
      <Typography
        className={textStyles.paragraph_small_500}
        {...RightTypographyLabel}
      >
        {activeLabel || "Oui"}
      </Typography>
    </Stack>
  );
};

export default SwitchWithLabel;
