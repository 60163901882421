import {
  ILogin,
  ILoginParams,
  IRequestEmail,
  IResetPassword,
} from "./login.dto";

export enum LoginType {
  LOGIN_REQUESTED = "login/LOGIN_REQUESTED",
  LOGIN_SUCCEEDED = "login/LOGIN_SUCCEEDED",
  LOGIN_FAILED = "login/LOGIN_FAILED",

  REQUEST_EMAIL_REQUESTED = "login/REQUEST_EMAIL_REQUESTED",
  REQUEST_EMAIL_SUCCEEDED = "login/REQUEST_EMAIL_SUCCEEDED",
  REQUEST_EMAIL_FAILED = "login/REQUEST_EMAIL_FAILED",

  RESET_PASSWORD_REQUESTED = "login/RESET_PASSWORD_REQUESTED",
  RESET_PASSWORD_SUCCEEDED = "login/RESET_PASSWORD_SUCCEEDED",
  RESET_PASSWORD_FAILED = "login/RESET_PASSWORD_FAILED",

  SET_LOGIN_PARAMS = "login/SET_LOGIN_PARAMS",
  CLEAR_LOGIN_PARAMS = "login/CLEAR_LOGIN_PARAMS",

  CLEAR_MESSAGE_ERROR = "login/CLEAR_MESSAGE_ERROR",
  CLEAR_DATA_LOGIN = "login/CLEAR_DATA_LOGIN",
}

export interface LoginRequestedAction {
  type: typeof LoginType.LOGIN_REQUESTED;
  params: ILoginParams;
}

export interface LoginSucceededAction {
  type: typeof LoginType.LOGIN_SUCCEEDED;
  data: ILogin;
}

export interface LoginFailedAction {
  type: typeof LoginType.LOGIN_FAILED;
  error?: string;
}

export interface RequestEmailRequestedAction {
  type: typeof LoginType.REQUEST_EMAIL_REQUESTED;
  data: IRequestEmail;
}

export interface RequestEmailSucceededAction {
  type: typeof LoginType.REQUEST_EMAIL_SUCCEEDED;
  message: string;
}

export interface RequestEmailFailedAction {
  type: typeof LoginType.REQUEST_EMAIL_FAILED;
  error?: string;
}

export interface ResetPasswordRequestedAction {
  type: typeof LoginType.RESET_PASSWORD_REQUESTED;
  data: IResetPassword;
}

export interface ResetPasswordSucceededAction {
  type: typeof LoginType.RESET_PASSWORD_SUCCEEDED;
  message: string;
}

export interface ResetPasswordFailedAction {
  type: typeof LoginType.RESET_PASSWORD_FAILED;
  error?: string;
}

export interface SetLoginParamsAction {
  type: typeof LoginType.SET_LOGIN_PARAMS;
  params: ILoginParams;
}
export interface ClearLoginParamsAction {
  type: typeof LoginType.CLEAR_LOGIN_PARAMS;
}

export interface ClearMessageErrorLogin {
  type: typeof LoginType.CLEAR_MESSAGE_ERROR;
}

export interface ClearDataLogin {
  type: typeof LoginType.CLEAR_DATA_LOGIN;
}

export type LoginActions =
  | LoginRequestedAction
  | LoginSucceededAction
  | LoginFailedAction
  | RequestEmailRequestedAction
  | RequestEmailSucceededAction
  | RequestEmailFailedAction
  | ResetPasswordRequestedAction
  | ResetPasswordSucceededAction
  | ResetPasswordFailedAction
  | SetLoginParamsAction
  | ClearLoginParamsAction
  | ClearMessageErrorLogin
  | ClearDataLogin;
