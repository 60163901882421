import { call, put, takeEvery } from "@redux-saga/core/effects";
import { generalMessage } from "@utils/generalMessage";
import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import {
  clearLoginParams,
  clearMessageErrorLogin,
  loginFailed,
  loginSucceeded,
  requestEmailFailed,
  requestEmailSucceeded,
  resetPasswordFailed,
  resetPasswordSucceeded,
} from "./login.creator";
import { ILogin } from "./login.dto";
import {
  loginService,
  requestEmailService,
  resetPasswordService,
} from "./login.service";
import {
  LoginRequestedAction,
  LoginType,
  RequestEmailRequestedAction,
  ResetPasswordRequestedAction,
} from "./login.type";

function* login({ params }: LoginRequestedAction) {
  try {
    const dataResponse: AxiosResponse<ILogin> = yield call(
      loginService,
      params
    );
    if (dataResponse?.data) {
      localStorage.setItem("AccessToken", dataResponse.data?.token);
    }
    yield put(loginSucceeded(dataResponse.data));
    yield put(clearLoginParams());
  } catch (err: any) {
    yield put(loginFailed(err?.message));
    yield put(clearMessageErrorLogin());
  }
}

function* requestEmail({ data }: RequestEmailRequestedAction) {
  try {
    const dataResponse: AxiosResponse = yield call(requestEmailService, data);

    if (!dataResponse) {
      throw new Error(generalMessage.unexpectedError);
    }

    yield put(
      requestEmailSucceeded(
        "Une demande de réinitialisation de mot de passe a été envoyée. Merci de consulter vos emails"
      )
    );
    yield put(clearMessageErrorLogin());
  } catch (err: any) {
    let errorMsg = err?.message;

    if (Array.isArray(errorMsg)) {
      errorMsg = errorMsg?.join(". ");
    }

    yield put(requestEmailFailed(errorMsg));
    yield put(clearMessageErrorLogin());
  }
}

function* resetPassword({ data }: ResetPasswordRequestedAction) {
  try {
    const dataResponse: AxiosResponse = yield call(resetPasswordService, data);

    if (!dataResponse) {
      throw new Error(generalMessage.unexpectedError);
    }

    yield put(
      resetPasswordSucceeded(
        "Mot de passe réinitialisé avec succès, veuillez vous reconnecter"
      )
    );
    yield put(clearMessageErrorLogin());
  } catch (err: any) {
    let errorMsg = err?.message;

    if (Array.isArray(errorMsg)) {
      errorMsg = errorMsg?.join(". ");
    }

    yield put(resetPasswordFailed(errorMsg));
    yield put(clearMessageErrorLogin());
  }
}

function* watchLoginRequest() {
  yield takeEvery(LoginType.LOGIN_REQUESTED, login);
}

function* watchRequestEmailRequest() {
  yield takeEvery(LoginType.REQUEST_EMAIL_REQUESTED, requestEmail);
}

function* watchResetPasswordRequest() {
  yield takeEvery(LoginType.RESET_PASSWORD_REQUESTED, resetPassword);
}

function* loginSaga() {
  yield all([
    fork(watchLoginRequest),
    fork(watchRequestEmailRequest),
    fork(watchResetPasswordRequest),
  ]);
}

export default loginSaga;
