import * as React from "react";

interface IconBackProps {
  color?: string;
}

const IconBack: React.FC<IconBackProps> = ({ color = "#FFCC4D" }) => (
  <svg width={8} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.083 10.416a.806.806 0 0 1 0 1.167.757.757 0 0 1-.583.25.757.757 0 0 1-.583-.25l-5-5a.806.806 0 0 1 0-1.167l5-5a.806.806 0 0 1 1.166 0 .806.806 0 0 1 0 1.167L2.667 6l4.416 4.416Z"
      fill={color}
    />
  </svg>
);

export default IconBack;
