import { call, put, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { all, fork } from "redux-saga/effects";
import {
  createNewCompanyFailed,
  createNewCompanySucceeded,
  updateCompanyFailed,
  updateCompanySucceeded,
} from "./createNewCompany.creator";
import {
  ICreateCompanyDto,
  IResponseCreateNewCompany,
} from "./createNewCompany.dto";
import {
  createNewCompanyService,
  updateCompanyService,
} from "./createNewCompany.service";
import {
  CreateNewCompanyTypes,
  ICreateCompanyRequestedAction,
  IUpdateCompanyRequestedAction,
} from "./createNewCompany.type";

function* createNewCompany({ dataCompany }: ICreateCompanyRequestedAction) {
  try {
    const dataResponse: AxiosResponse<IResponseCreateNewCompany> = yield call(
      createNewCompanyService,
      dataCompany
    );
    yield put(createNewCompanySucceeded(dataResponse.data));
  } catch (err: any) {
    yield put(createNewCompanyFailed(err?.message?.[0]));
  }
}

function* watchCreateNewCompany() {
  yield takeLatest(
    CreateNewCompanyTypes.CREATE_COMPANY_REQUESTED,
    createNewCompany
  );
}

function* updateCompany({
  dataCompany,
  idCompany,
}: IUpdateCompanyRequestedAction) {
  try {
    const dataResponse: AxiosResponse<ICreateCompanyDto> = yield call(
      updateCompanyService,
      dataCompany,
      idCompany
    );

    yield put(updateCompanySucceeded(dataResponse.data));
  } catch (err) {
    yield put(updateCompanyFailed(err));
  }
}

function* watchUpdateCompany() {
  yield takeLatest(
    CreateNewCompanyTypes.UPDATE_COMPANY_REQUESTED,
    updateCompany
  );
}

function* createNewCompanySaga() {
  yield all([fork(watchCreateNewCompany), fork(watchUpdateCompany)]);
}

export default createNewCompanySaga;
