import React, { FC } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import formatMoney from "@utils/formatMoney";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage5Props {
  franchiseDuContrat?: number;
}

const ContentPage5: FC<IContentPage5Props> = ({ franchiseDuContrat = 0 }) => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Garantie et franchises accordées
        </Text>
      </View>
      <View style={styles.specialTable}>
        <View style={styles.headerSpecialTable}>
          <View style={{ flex: 3 }}>
            <Text style={styles.titleHeaderTable}>Nature des garanties</Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.titleHeaderTable}>
              Montant des garanties par année d’assurance
            </Text>
          </View>
          <View
            style={{ height: "100%", width: 0.5, backgroundColor: "#fff" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text
              style={{
                fontSize: 9,
                color: "#fff",
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Franchises
            </Text>
          </View>
        </View>
        <Text
          style={{
            color: "#5E5873",
            fontSize: "9px",
            fontFamily: "Montserrat",
            padding: 8,
            backgroundColor: "#FFF9E8",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          RESPONSABILITE CIVILE PROFESSIONNELLE
        </Text>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Tous dommages confondus - Limite globale de garantie
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>5 000 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              Franchise corporelle - Néant
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Dommages matériels garantis et dommages immatériels en résultant
              causés par :
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>750 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>Néant</Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainContent}>Faute inexcusable</Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>350 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Dommages matériels garantis et/ou dommages immatériels en
              résultant dont :
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>200 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainContent}>
                Dommages matériels subis par les préposés
              </Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>350 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainContent}>Vols</Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>20 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainContent}>
                Escroquerie, détournement par préposés
              </Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>20 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <View style={styles.li}>
              <View style={styles.dotLi} />
              <Text style={styles.mainContent}>
                Négligeance facilitant un vol
              </Text>
            </View>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>20 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Dommages matériels et immatériels en résultant causés aux
              existants
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>150 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableEven}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Dommages immatériels non consécutifs à un dommage corporel ou
              matériel (dont 50 000 euros par an maximum garantis au titre des
              dommages consécutifs au non-respect de la Réglementation thermique
              2012 – Applicable en France Métropolitaine)
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>100 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
        <View style={styles.itemTableOdd}>
          <View style={{ flex: 3 }}>
            <Text style={styles.textRadio}>
              Dommages immatériels non consécutifs à un dommage non garanti
            </Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 1.2 }}>
            <Text style={styles.textMoney}>100 000, 00 €</Text>
          </View>
          <View
            style={{ width: 0.5, height: "100%", backgroundColor: "#FFCC4D" }}
          />
          <View style={{ flex: 0.8 }}>
            <Text style={styles.textFranchise}>
              {formatMoney(franchiseDuContrat)} €
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 25,
    padding: "8px 0px",
  },
  specialTable: {},
  headerSpecialTable: {
    backgroundColor: "#FFCC4D",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleHeaderTable: {
    fontSize: 10,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textAlign: "left",
    padding: "8px 0 8px 9px",
  },
  contentTablePage2: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
  },
  textFranchise: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: 8,
  },
  itemTableOdd: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
  },
  itemTableEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  textRadio: {
    fontSize: 9,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#5E5873",
    padding: 8,
  },
  textMoney: {
    fontSize: 9,
    fontFamily: "Montserrat",
    color: "#5E5873",
    textAlign: "left",
    padding: 8,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 8,
  },
  dotLi: {
    position: "absolute",
    width: 1.5,
    height: 1.2,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
  mainContent: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
    fontWeight: 300,
  },
});

export default ContentPage5;
