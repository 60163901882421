export interface IActivityParam {
  rate?: number;
  minimumPremium?: number;
  level?: string;
}

export interface IActivityOfContract {
  _id: string;
  name: string;
  definition?: string;
  exclusion?: string;
  param: IActivityParam[];
  seq_no: string;
  minTurnover?: number;
}

export interface IResponseActivitiesOfContract {
  data: IActivityOfContract[];
  total?: number;
  limit?: number;
  page?: number;
  last_page?: number;
}

export interface IFilterActivitiesDto {
  _id?: string;
  name?: string;
  limit?: number;
  page?: number;
  sortBy?: any;
  idProfession: string;
}

export type FiltersActivitiesSortValue = "number" | "name" | "percent" | string;
export type FiltersActivitiesSortType = "desc" | "asc" | string;

export interface FiltersActivities {
  page: number;
  rowsPerPage: number;
  searchTerm: string;
  sortBy: {
    sortValue: FiltersActivitiesSortValue;
    type: FiltersActivitiesSortType;
  };
}

export interface IListActivitiesOfContract {
  activities: IResponseActivitiesOfContract;
  loading?: boolean;
  message?: string;
  error?: any;
  filters: FiltersActivities;
  currentIdProfession: string;
}

export enum IdProfessionType {
  PIB = "620f0d2950c47b4d4f5cdc71",
  Artisans_BTP = "62f33952271458ce3cd53a3c",
}
