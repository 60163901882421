import { useRoutes } from "react-router-dom";
import { protectedRoutes, authRoutes } from "./routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Colors } from "./themes/colors";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "@pages/ErrorFallback";
import { useCheckToken } from "@hooks/useCheckToken";
import LoadingPage from "@pages/LoadingPage";

const makeTheme = () => {
  const originalTheme = createTheme();

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
        contrastText: "white",
      },
      secondary: {
        main: Colors.secondary,
      },
      success: {
        main: Colors.success,
      },
      error: {
        main: Colors.error,
      },
      warning: {
        main: Colors.warning,
      },
      info: {
        main: Colors.info,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            boxShadow: "none",
          },
          containedPrimary: {
            "&:hover": {
              boxShadow: "0px 4px 14px rgba(115, 103, 240, 0.38);",
            },
          },
        },
      },
    },
    shadows: originalTheme.shadows.map((shadow) => {
      shadow = shadow.replaceAll("rgba(0,0,0,0.2)", "rgba(0,0,0,0.01)");
      shadow = shadow.replaceAll("rgba(0,0,0,0.12)", "rgba(0,0,0,0.005)");
      return shadow;
    }) as any,
    typography: {
      fontFamily: "Montserrat",
    },
  });

  return theme;
};

export const theme = makeTheme();

function App() {
  const protectedElements = useRoutes(protectedRoutes);
  const authElements = useRoutes(authRoutes);

  const { resultCheck, loading }: any = useCheckToken();

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {loading ? (
          <LoadingPage />
        ) : (
          <>{resultCheck ? <>{protectedElements}</> : <>{authElements}</>}</>
        )}
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
