import React from "react";
import { Font, Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import CheckImg from "../../../assets/Images/check.png";
import generatingResultPDFStyles from "./generatingResultPDFStyles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

interface IContentPage15Props {
  isPIB?: boolean;
}

const ContentPage15 = ({ isPIB = true }: IContentPage15Props) => {
  return (
    <View break>
      <View>
        <Text style={styles.mainTitle}>
          {isPIB ? "Réclamation - médiation" : "Nature de nos interventions"}
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Si vous avez une réclamation à formuler quant à la souscription de
          votre(vos) contrat(s), leur distribution ou leur gestion vous pouvez
          nous contacter à l’adresse suivante MADECENNALE– Service Réclamations
          – 1047 route des Dolines, Business Pôle Bat A, 06560 Vabonne.
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Nous accuserons réception de votre réclamation dans les 48 heures et
          ferons le maximum pour vous apporter une réponse dans un délai maximal
          d’un mois. Nous nous engageons à vous tenir informé du déroulement du
          traitement de votre réclamation dans ce même délai si pour des raisons
          indépendantes de notre volonté celui-ci devait être prolongé.
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Vous pouvez également saisir, en cas de désaccord avec nos services,
          la médiation de l’Assurance en adressant un courrier : la Médiation de
          l’Assurance TSA 50110 – 75441 Paris Cedex 09. Par ailleurs, vous
          pouvez saisir l’Autorité de Contrôle Prudentiel et de Résolution
          (ACPR) : 4 place de Budapest CS 92459 -75436 Paris Cedex 09{" "}
          <Link src="http://acpr.banque-france.fr/accueil.html">
            http://acpr.banque-france.fr/accueil.html
          </Link>
          .
        </Text>
      </View>
      <View style={{ marginTop: 12 }}>
        <Text style={styles.mainTitle}>
          Protection des données personnelles
        </Text>
        <Text style={generatingResultPDFStyles.mainContent}>
          Les données personnelles collectées lors de nos entretiens et des
          différents documents remplis par vos soins font l’objet d’un
          traitement informatique.
        </Text>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={styles.mainTitleBlack}>
          Objet du traitement de données
        </Text>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Image
              src={CheckImg}
              style={{
                width: 8,
                height: 6,
              }}
            />
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                marginLeft: 5,
              }}
            >
              Finalités
            </Text>
          </View>
          <Text style={generatingResultPDFStyles.mainContent}>
            Les données personnelles collectées sont uniquement utilisées pour
            les finalités suivantes : Etudier vos besoins en assurances,
          </Text>
          <Text style={generatingResultPDFStyles.mainContent}>
            Vous apporter la meilleure réponse, procéder à la souscription et au
            suivi de votre(vos) contrat(s) Veiller à la lutte contre le
            blanchiment et le financement du terrorisme.
          </Text>
        </View>
        <View style={{ marginTop: 14 }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Image
              src={CheckImg}
              style={{
                width: 8,
                height: 6,
              }}
            />
            <Text
              style={{
                fontSize: 9,
                color: "#5E5873",
                fontFamily: "Montserrat",
                marginLeft: 5,
              }}
            >
              Bases légales
            </Text>
          </View>
          <Text style={generatingResultPDFStyles.mainContent}>
            Ce traitement de données est nécessaire au respect de l’obligation
            légale d’information précontractuelle et de conseil prévue par le
            code des assurances à laquelle le responsable du traitement est
            soumis ainsi qu’aux obligations prévues par le code monétaire et
            financier en matière de lutte contre le blanchiment des capitaux et
            le financement du terrorisme.
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={styles.mainTitleBlack}>Destinataire de données</Text>
        <View>
          <Text style={generatingResultPDFStyles.mainContent}>
            Seules les personnes ayant besoin de connaître vos données dans le
            cadre de leurs missions y ont accès.
          </Text>
          <Text style={generatingResultPDFStyles.mainContent}>
            Les données collectées à l’occasion du recueil de vos besoins et de
            la souscription d’un contrat d’assurance sont susceptibles d'être
            communiquées au personnel habilité de MADECENNALE, à ses partenaires
            assureurs, ainsi qu’aux prestataires sous-traitants. Nous vous
            rappelons que dans ce cadre, MADECENNALE demande à ses prestataires
            de mettre en place des mesures strictes de confidentialité et de
            protection de ces données. Durée de conservation des données
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={styles.mainTitleBlack}>
          Durée de conservation des données
        </Text>
        <View>
          <Text style={generatingResultPDFStyles.mainContent}>
            BMAKER s’engage à conserver vos données personnelles pour une durée
            n'excédant pas celle nécessaire aux finalités pour lesquelles elles
            sont traitées et conformément aux durées de conservation imposées
            par les lois applicables en vigueur.
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={generatingResultPDFStyles.mainContent}>
          Ainsi MADECENNALE conserve les données vous concernant dans les délais
          suivants :
        </Text>
      </View>
      <View style={{ marginTop: 14 }}>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            les données clients sont conservées au maximum 5 ans à compter de la
            fin de la relation commerciale (par exemple, soit à compter de
            l’expiration d’un contrat, soit à compter du dernier contact émanant
            du client) ;
          </Text>
        </View>
        <View style={generatingResultPDFStyles.li}>
          <View style={generatingResultPDFStyles.dotLi} />
          <Text style={generatingResultPDFStyles.mainContent}>
            les données des prospects non clients sont conservées au maximum 3
            ans à compter de leur collecte par nos services ou du dernier
            contact émanant du prospect (par exemple, demande de renseignements
            ou de documentation).
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={styles.mainTitleBlack}>
          Les droits concernant vos données
        </Text>
        <View>
          <Text style={generatingResultPDFStyles.mainContent}>
            Conformément aux dispositions de la Loi n°78--17 du 6 janvier 1978
            modifiée, vous bénéficiez :
          </Text>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              d’un droit d’accès,
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              de rectification,
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              d’effacement (des données inexactes, incomplètes, équivoques,
              périmées ou dont le traitement serait illicite),
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              d’opposition, de limitation du traitement
            </Text>
          </View>
          <View style={generatingResultPDFStyles.li}>
            <View style={generatingResultPDFStyles.dotLi} />
            <Text style={generatingResultPDFStyles.mainContent}>
              de portabilité des données qui vous concernent, ainsi que du droit
              de définir des directives relatives à la conservation,
              l’effacement et à la communication de ces données après votre
              décès.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={generatingResultPDFStyles.mainContent}>
          Vous pouvez exercer ces droits en nous adressant un courrier à
          MADECENNALE, 1047 route des Dolines, Business Pôle Bat A, 06560
          Valbonne.
        </Text>
      </View>
      <View style={{ marginTop: 14 }}>
        <Text style={generatingResultPDFStyles.mainContent}>
          Dans ce cadre, nous vous prions de bien vouloir accompagner votre
          demande des éléments nécessaires à votre identification (nom, prénom,
          e-mail) ainsi que toute autre information nécessaire à la confirmation
          de votre identité.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: "underline",
    color: Colors.primary,
    marginBottom: 8,
  },
  mainTitleBlack: {
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: "underline",
    color: "#5E5873",
    marginBottom: 8,
  },
});

export default ContentPage15;
