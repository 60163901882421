import { IActivityRedux } from "./savedActivities.dto";

export enum SavedActivitiesTypes {
  ADD_ACTIVITY = "savedActivity/ADD",
  ADD_MANY_ACTIVITY = "savedActivity/ADD_MANY",
  UPDATE_ACTIVITY = "savedActivity/UPDATE",
  REMOVE_ACTIVITY = "savedActivity/REMOVE",
  CLEAR_ALL_ACTIVITY = "savedActivity/CLEAR_ALL",
}

export interface IAddActivityAction {
  type: typeof SavedActivitiesTypes.ADD_ACTIVITY;
  activity: IActivityRedux;
}

export interface IAddManyActivitiesAction {
  type: typeof SavedActivitiesTypes.ADD_MANY_ACTIVITY;
  activities: Array<IActivityRedux>;
}

export interface IUpdateActivityAction {
  type: typeof SavedActivitiesTypes.UPDATE_ACTIVITY;
  activity: IActivityRedux;
}

export interface IRemoveActivityAction {
  type: typeof SavedActivitiesTypes.REMOVE_ACTIVITY;
  idActivity: string;
}

export interface IClearAllActivitiesAction {
  type: typeof SavedActivitiesTypes.CLEAR_ALL_ACTIVITY;
}

export type SavedActivitiesActions =
  | IAddActivityAction
  | IAddManyActivitiesAction
  | IUpdateActivityAction
  | IRemoveActivityAction
  | IClearAllActivitiesAction;
