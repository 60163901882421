import * as React from "react"

const DocumentIcon: React.FC<{color: string}> = ({color}) => (
    <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.496 11.867h-.372a.327.327 0 0 0-.319.334c0 .185.143.335.319.335h.372c.175 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334ZM5.62 11.867h-.372a.327.327 0 0 0-.319.334c0 .185.143.335.319.335h.372c.175 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334ZM7.743 11.867h-.371a.327.327 0 0 0-.319.334c0 .185.143.335.319.335h.371c.176 0 .319-.15.319-.335a.327.327 0 0 0-.319-.334ZM3.496 14.095h-.372a.327.327 0 0 0-.319.334c0 .185.143.334.319.334h.372c.175 0 .318-.15.318-.334a.327.327 0 0 0-.318-.334ZM5.62 14.095h-.372a.327.327 0 0 0-.319.334c0 .185.143.334.319.334h.372c.175 0 .318-.15.318-.334a.327.327 0 0 0-.318-.334ZM7.743 14.095h-.371a.327.327 0 0 0-.319.334c0 .185.143.334.319.334h.371c.176 0 .319-.15.319-.334a.327.327 0 0 0-.319-.334ZM3.496 16.325h-.372a.327.327 0 0 0-.319.335c0 .184.143.334.319.334h.372c.175 0 .318-.15.318-.334a.327.327 0 0 0-.318-.335ZM5.62 16.325h-.372a.327.327 0 0 0-.319.335c0 .184.143.334.319.334h.372c.175 0 .318-.15.318-.334a.327.327 0 0 0-.318-.335ZM7.743 16.325h-.371a.327.327 0 0 0-.319.335c0 .184.143.334.319.334h.371c.176 0 .319-.15.319-.334a.327.327 0 0 0-.319-.335ZM7.743 9.639h-4.62a.327.327 0 0 0-.318.334c0 .185.143.334.319.334h4.62c.175 0 .318-.15.318-.334a.327.327 0 0 0-.319-.334Z"
      fill={color}
    />
    <path
      d="M19.1 2.226c0-.35-.133-.686-.37-.935A1.242 1.242 0 0 0 17.832.9H7.478c-.338 0-.66.142-.898.39a1.36 1.36 0 0 0-.37.936v5.361H2.168c-.338 0-.66.142-.898.391a1.36 1.36 0 0 0-.37.935V17.774c0 .35.133.686.37.935.238.25.56.39.898.391H8.7c.338 0 .66-.142.898-.39l-.073-.07.073.07a1.36 1.36 0 0 0 .37-.936v-1.572h7.865c.338 0 .66-.142.898-.39a1.36 1.36 0 0 0 .37-.936V2.226ZM18.135 15.2a.418.418 0 0 1-.303.132H9.967v-6.42c0-.35-.133-.686-.37-.935l-.072.069.072-.07a1.242 1.242 0 0 0-.898-.39H7.047V2.226c0-.257.197-.457.431-.457h10.354c.113 0 .222.047.303.132a.472.472 0 0 1 .128.325v12.65c0 .123-.047.24-.128.325ZM9.13 17.774c0 .123-.046.24-.128.325a.418.418 0 0 1-.303.132H2.17a.445.445 0 0 1-.432-.457v-8.86c0-.258.198-.458.431-.458H8.7c.113 0 .222.047.303.132a.472.472 0 0 1 .128.325v8.86Z"
      fill={color}
      stroke={color}
      strokeWidth={0.2}
    />
    <path
      d="M9.018 3.842h-.372a.327.327 0 0 0-.318.334c0 .185.142.335.318.335h.372c.176 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334ZM16.027 3.842h-4.62a.327.327 0 0 0-.318.334c0 .185.142.335.318.335h4.62c.176 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334ZM9.018 6.071h-.372a.327.327 0 0 0-.318.335c0 .184.142.334.318.334h.372c.176 0 .318-.15.318-.334a.327.327 0 0 0-.318-.335ZM16.027 6.071h-4.62a.327.327 0 0 0-.318.335c0 .184.142.334.318.334h4.62c.176 0 .318-.15.318-.334a.327.327 0 0 0-.318-.335ZM16.027 8.3h-4.62a.327.327 0 0 0-.318.335c0 .185.142.335.318.335h4.62c.176 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334ZM16.027 10.528h-4.62a.327.327 0 0 0-.318.335c0 .184.142.334.318.334h4.62c.176 0 .318-.15.318-.334a.327.327 0 0 0-.318-.335ZM16.027 12.758h-4.62a.327.327 0 0 0-.318.334c0 .185.142.335.318.335h4.62c.176 0 .318-.15.318-.335a.327.327 0 0 0-.318-.334Z"
      fill={color}
    />
  </svg>
)

export default DocumentIcon
