import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Colors } from "@themes/colors";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

const ContentPage10 = () => {
  return (
    <View break>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Lois applicables
        </Text>
      </View>
      <View style={{ marginTop: 8, marginBottom: 30 }}>
        <Text style={styles.mainText}>
          Tout litige concernant l’interprétation ou l’exécution du contrat sera
          soumis aux règles et principes du droit français et au Code des
          assurances, et relève de la seule compétence des tribunaux français.
          Il est précisé que seule la société d’Assurance Mutuelle Optim
          Assurance porte le risque et la responsabilité contractuelle
          d'indemnisation en cas de sinistre. En cas de défaillance ou défaut
          seule la compagnie ou ses ré assureurs pourraient être recherchés en
          paiement. Madecennale a un statut de courtier en assurance uniquement.
        </Text>
        <Text style={styles.mainText}>
          Il n'intervient en aucun cas en tant que compagnie ou dans la présente
          proposition en conseil ou en négociation contractuelle avec le client
          final souscripteur.
        </Text>
      </View>
      <View style={styles.titleBody}>
        <Text
          style={{
            fontSize: 12,
            color: Colors.primary,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Engagements de l’Assuré
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 9,
            textDecoration: "underline",
            color: Colors.primary,
            marginBottom: 8,
          }}
        >
          L’assuré s’engage à :
        </Text>
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContentBold}>
            transmettre chaque année et au plus tard dans un délai de 4 mois
            suivant la date d'échéance du contrat, les informations et éléments
            variables nécessaires au calcul de la prime dans le cadre de
            l’ajustement de la police, conformément au formulaire transmis par
            la société Leader Assurances.
          </Text>
        </View>
        <View style={{ height: 10 }} />
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContentBold}>
            déclarer à l’Assureur dès qu’il en a connaissance et au plus tard
            dans les 15 jours, tout nouvel élément qui viendrait modifier les
            réponses apportées à la souscription.
          </Text>
        </View>
        <View style={{ height: 10 }} />
        <View style={styles.li}>
          <View style={styles.dotLi} />
          <Text style={styles.mainContentBold}>
            Ne pas renoncer à un recours contre quiconque. Une telle
            renonciation ne serait pas opposable à l’Assureur.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #5E5873",
    marginBottom: 15,
    padding: "8px 0px",
  },
  mainText: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.7,
  },
  mainContentBold: {
    fontSize: 9,
    color: "#5E5873",
    fontFamily: "Montserrat",
    lineHeight: 1.6,
  },
  li: {
    position: "relative",
    paddingLeft: 12,
    marginTop: 2,
  },
  dotLi: {
    position: "absolute",
    width: 1.8,
    height: 1.8,
    borderRadius: 1,
    backgroundColor: "#5E5873",
    left: 5,
    top: 4.5,
  },
});

export default ContentPage10;
