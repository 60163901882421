import * as React from "react";

const UserIcon: React.FC<{ color: string }> = ({ color = "#6E6B7B" }) => (
  <svg width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 4.25C3.25 2.15 4.9.5 7 .5s3.75 1.65 3.75 3.75S9.1 8 7 8 3.25 6.35 3.25 4.25Zm10.5 9v1.5c0 .45-.3.75-.75.75s-.75-.3-.75-.75v-1.5c0-1.275-.975-2.25-2.25-2.25H4c-1.275 0-2.25.975-2.25 2.25v1.5c0 .45-.3.75-.75.75s-.75-.3-.75-.75v-1.5C.25 11.15 1.9 9.5 4 9.5h6c2.1 0 3.75 1.65 3.75 3.75ZM7 6.5c-1.275 0-2.25-.975-2.25-2.25S5.725 2 7 2s2.25.975 2.25 2.25S8.275 6.5 7 6.5Z"
      fill={color}
    />
  </svg>
);

export default UserIcon;
