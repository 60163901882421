import * as React from "react";

const EditIcon: React.FC<{ color: string }> = ({ color = "#000" }) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.134 4.467-6.667 6.667a.605.605 0 0 1-.467.2H5.334c-.4 0-.667-.267-.667-.667V8c0-.2.067-.333.2-.466L11.534.867a.644.644 0 0 1 .933 0l2.667 2.667a.645.645 0 0 1 0 .933ZM14 9.8v3.534c0 1.133-.866 2-2 2H2.667c-1.133 0-2-.867-2-2V4c0-1.133.867-2 2-2H6.2c.4 0 .667.267.667.667s-.267.667-.667.667H2.667C2.267 3.334 2 3.6 2 4v9.334c0 .4.267.666.667.666H12c.4 0 .667-.266.667-.666V9.8c0-.4.267-.666.667-.666S14 9.4 14 9.8Zm-2-7.533L13.734 4l-6 6H6V8.267l6-6Z"
      fill={color}
    />
  </svg>
);

export default EditIcon;
