import { IResponseStatistic } from "./statistic.dto";
import { StatisticActions, StatisticTypes } from "./statistic.type";

const initialStatisticState: IResponseStatistic = {
  loading: false,
  loadingContract: false,
  listStatistic: [],
  listContractValidated: [],
  listQuoteCreated: [],
};

export default function statisticReducer(
  state = initialStatisticState,
  action: StatisticActions
): IResponseStatistic {
  switch (action.type) {
    case StatisticTypes.GET_STATISTIC_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case StatisticTypes.GET_STATISTIC_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      };
    }

    case StatisticTypes.GET_STATISTIC_FAILED: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }

    //

    case StatisticTypes.GET_LIST_CONTRACT_VALIDATED_REQUESTED: {
      return {
        ...state,
        loadingContract: true,
      };
    }

    case StatisticTypes.GET_LIST_CONTRACT_VALIDATED_SUCCEEDED: {
      return {
        ...state,
        listContractValidated: action?.listContractValidated,
        loadingContract: false,
      };
    }

    case StatisticTypes.GET_LIST_CONTRACT_VALIDATED_FAILED: {
      return {
        ...state,
        loadingContract: false,
        errorContract: action?.error,
      };
    }

    //

    case StatisticTypes.SET_LIST_STATISTIC: {
      return {
        ...state,
        listStatistic: action?.listStatistic,
      };
    }

    case StatisticTypes.SET_LIST_QUOTE_CREATED: {
      return {
        ...state,
        listQuoteCreated: action?.listQuoteCreated,
      };
    }

    default:
      return state;
  }
}
